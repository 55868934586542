const MskPoorPrognostic = [
  { value: "Karnofsky PS score <80", name: "Karnofsky PS score <80" },
  {
    value: "Time from diagnosis to treatment < 12 months",
    name: "Time from diagnosis to treatment < 12 months",
  },
  {
    value:
      "Serum lactate dehydrogenase (LDH) > 1.5 ULN (upper limit of normal)",
    name: "Serum lactate dehydrogenase (LDH) > 1.5 ULN (upper limit of normal)",
  },
  {
    value: "Corrected serum calcium >10 mg/dl",
    name: "Corrected serum calcium >10 mg/dl",
  },
  {
    value: " Hemoglobin < LLN (lower limit of normal)",
    name: " Hemoglobin < LLN (lower limit of normal)",
  },
  { value: "Prior radiotherapy", name: "Prior radiotherapy" },
  {
    value: "2 or 3 individual-organ metastatic sites",
    name: "2 or 3 individual-organ metastatic sites",
  },
];

export default MskPoorPrognostic;
