const EcgValues = [
  { value: "Within normal limits", name: "Within normal limits" },
  {
    value: "Non-significant abnormalities",
    name: "Non-significant abnormalities",
  },
  { value: "Significant abnormalities", name: "Significant abnormalities" },
];

export default EcgValues;
