//utilities
import React, { useEffect, useState } from "react";
import APIURL from "../APIURL";
import useSWR from "swr";
import moment from "moment";
//localization
import { useLocalization } from "@progress/kendo-react-intl";

//Component
import TrialMap from "../studysubject/TrialMap";
import Excel from "../studysubject/Excel";

export function DetailStudy(props) {
  //localization
  const localizationService = useLocalization();

  //data

  const { data, error } = useSWR(
    APIURL + `/common/detailstudy/${props.location.state.data.study}/`
  );
  console.log("YYYYYYYYYYYYYYYYY",data)

  //variables
  const [AllData, setAllData] = useState([]);
  //SWR ESSENTIAL
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );


  useEffect(() => {
    if (data) {
      setAllData(data);
    }
  }, [data]);
  if (error)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;
  return (
    <TrialMap>
      {!data && loadingPanel}
      <div className="container">
   
        <div className="row">
          <div className="study-title-style">{AllData.protocol_name}</div>
        </div>

        <div className="study-info-detail">
          <div className="row">
            <div className="col-6">
              {" "}
              {localizationService.toLanguageString(
                "custom.principalinvestigator"
              )}
            </div>
            <div className="col-6">{AllData.principal_investigator}</div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              {localizationService.toLanguageString("custom.studytype")}
            </div>
            <div className="col-6">{AllData.study_type}</div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              {localizationService.toLanguageString("custom.studysponsor")}
            </div>
            <div className="col-6">{AllData.study_sponsor}</div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              {localizationService.toLanguageString("custom.protocolid")}
            </div>
            <div className="col-6">{AllData.protocol_id}</div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              {localizationService.toLanguageString("custom.startdate")}
            </div>
            <div className="col-6">
              {moment(AllData.start_date).format("DD-MM-YYYY")}
            </div>
            <Excel SID={props.location.state.data.id} />
          </div>
        </div>
      </div>
    </TrialMap>
  );
}

export default DetailStudy;
