//utilities
import React from "react";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//component
import { BiErrorCircle } from "react-icons/bi";

export function NotFound(props) {
  //localization
  const localizationService = useLocalization();

  return (
    <>
      <div className="row" style={{ textAlign: "center" }}>
        <div className="col-12">
          <BiErrorCircle color={"red"} size={"400px"} />
        </div>
        <div className="col-12">
          <span style={{ color: "red", fontSize: "50px" }}>
            {localizationService.toLanguageString("custom.pagenotfound")}
          </span>
        </div>
      </div>
    </>
  );
}

export default NotFound;
