const StaticBloodChemistryTest = [
  {
    test_present: false,
    test_name: "ALT (SGPT)",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "AST (SGOT)",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Total Bilirubin",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Serum Creatinine",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Alkaline Phosphatase",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Glucose",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Albumin",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Blood Urea Nitrogen",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Sodium",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Potassium",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Carbon Dioxide",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Chloride",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Total Calcium",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
  {
    test_present: false,
    test_name: "Magnesium",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: false,
    notpresent_reason: "",
  },
];

export default StaticBloodChemistryTest;
