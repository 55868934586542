const DisconinuationReasons = [
  {
    value: 1,
    name: "Adverse event(s)",
  },
  {
    value: 2,
    name: "Abnormal laboratory value(s)",
  },
  {
    value: 3,
    name: "Unsatisfactory therapeutic effect",
  },
  {
    value: 4,
    name: "Subject’s condition no longer requires study drug",
  },
  {
    value: 5,
    name: "Subject withdrew consent",
  },
  {
    value: 6,
    name: "Lost to follow-up",
  },
  {
    value: 7,
    name: "Administrative problems ",
  },
  {
    value: 8,
    name: "Death ",
  },
];

export default DisconinuationReasons;
