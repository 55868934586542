//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//component
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
import TrialMap from "../studysubject/TrialMap";
import TabChange from "../common/commoncomponents/TabChange";
import AddFile from "../common/commoncomponents/AddFile";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import HematologyTest from "../laboratory/HematologyTest";
import BloodTest from "../laboratory/BloodTest";
import OtherTest from "../laboratory/OtherTest";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";

export function ListLaboratoryValues(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();

  //variable

  const [approvedformchanged, setapprovedformchanged] = useState(false);
  const [HematologyFormModified, setHematologyFormModified] = useState(false);
  const [BloodTestFormModified, setBloodTestFormModified] = useState(false);
  const [OtherTestModified, setOtherTestModified] = useState(false);
  const [AllData, setAllData] = useState(null);
  const [testarray, settestarray] = useState(null);
  const [test, settest] = useState();
  const [refresh, setRefresh] = useState(false);

  const refreshComponent = () => {
    setRefresh(!refresh)
  };
  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
          refresh: refresh
        },
      })
      .then((res) => res.data);
  const { data: notes, Noteserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
          refresh,
        ]
      : null,
    fetcher2
  );

  //check approve
  const { data: checkapprove, checkapproveerror } = useSWR(
    props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
          refresh,
        ]
      : null,
    fetcher2
  );

  //attachement data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
          refresh: refresh
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attchementserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
          refresh,
        ]
      : null,
    fetcher4
  );

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  //variables
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setapprovedformchanged(false);
    setTestSelected(value);
    setOtherTestModified(false);
    setHematologyFormModified(false);
    setBloodTestFormModified(false);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listlaboratoryvalues/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher1
  );

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                EditSubject ? toggleDeditNote() : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //USE EFFECT
  useEffect(() => {
    let array = [];
    let array2 = [];

    if (data) {
      if (data.message === "no data") {
        setAllData(null);
      } else {
        if (data.laboratorytest.HematologyTests) {
          data.laboratorytest.HematologyTests.map((d) => {
            array.push({
              id: d.id,
              section: d.section,
              study_subject: d.study_subject,
              test_present: d.test_present,
              notpresent_reason: d.notpresent_reason,
              test_name: d.test_name,
              result: d.result,
              unit: d.unit,
              lower_limit: d.lower_limit,
              upper_limit: d.upper_limit,
              normal: d.normal,
            });
          });
        }
        if (data.laboratorytest.BloodChemistryTests) {
          data.laboratorytest.BloodChemistryTests.map((d) => {
            array2.push({
              id: d.id,
              study_subject: d.study_subject,
              section: d.section,
              test_present: d.test_present,
              notpresent_reason: d.notpresent_reason,
              test_name: d.test_name,
              result: d.result,
              unit: d.unit,
              lower_limit: d.lower_limit,
              upper_limit: d.upper_limit,
              normal: d.normal,
            });
          });
        }
        setAllData({
          testdata: data.laboratorytest,
          hemotology_tests: array,
          blood_chemistry_tests: array2,
        });
      }
    }
  }, [data]);

  //callbacks
  const HematologyCallBack = (value) => {
    if (value.ref) {
      if (Object.keys(value.ref.modified).length !== 0) {
        setHematologyFormModified(true);
      } else {
        setHematologyFormModified(false);
      }
    }
  };

  const BloodTestCallBack = (value) => {
    if (value.ref) {
      if (Object.keys(value.ref.modified).length !== 0) {
        setBloodTestFormModified(true);
      } else {
        setBloodTestFormModified(false);
      }
    }
  };

  const OtherTestCallBack = (value) => {
    if (value.ref) {
      if (Object.keys(value.ref.modified).length !== 0) {
        setOtherTestModified(true);
      } else {
        setOtherTestModified(false);
      }
    }
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  //TAB STRIP For Notes and attachements
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  //TAB STRIP FOR TEST

  const [testselected, setTestSelected] = React.useState(0);

  const handleTestSelect = (e) => {
    //if we left other test tab
    if (testselected === 2) {
      if (OtherTestModified) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    } else if (testselected === 0) {
      if (HematologyFormModified) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    } else if (testselected === 1) {
      if (BloodTestFormModified) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    } else if (testselected === 3) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    } else {
      setTestSelected(e.selected);
    }
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (error && Noteserror && Attchementserror && checkapproveerror)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <TrialMap>
      {(!data || !notes || !attachements) && loadingPanel}

      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader className="DetailFormHeader">
              <div className="row">
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.subjectid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString(
                    "custom.subjectinitials"
                  )}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.study")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.protocolid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.sitenumber")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.createdby")}
                </div>
              </div>
              <div className="row">
                <div className="col text-responsive-overflow subject-id-style">
                  {props.location.state ? props.location.state.data.crf_id : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_initial
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_name
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_protocol
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_center
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.createdBy
                    : ""}
                </div>
              </div>
              <hr className="RowRoleHr" />
              <div className="row">
                <div className="col pages-big-title">
                  <strong>
                    {localizationService.toLanguageString(
                      "custom.laboratorytests"
                    )}
                  </strong>
                </div>
                <div className="col">
                  <div className="float-right">
                    <Button onClick={goBack} className="back-button-style">
                      {localizationService.toLanguageString("custom.back")}
                    </Button>
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <Card className="DetailEditCard">
            <CardBody>
              <TabStrip selected={testselected} onSelect={handleTestSelect}>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.hematologytest"
                  )}
                >
                  <HematologyTest
                    data={AllData}
                    subjectdata={props.location.state}
                    parentCallBack={HematologyCallBack}
                    approved={checkapprove ? checkapprove : false}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.bloodchemistrytest"
                  )}
                >
                  <BloodTest
                    data={AllData}
                    subjectdata={props.location.state}
                    parentCallBack={BloodTestCallBack}
                    approved={checkapprove ? checkapprove : false}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.othertest"
                  )}
                >
                  <OtherTest
                    data={data && data.message !== "no data" ? data : null}
                    subjectdata={props.location.state}
                    parentCallBack={OtherTestCallBack}
                    approved={checkapprove ? checkapprove : false}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.approvesection"
                  )}
                >
                  <ApproveStudySubject
                    study_subject={props.location.state.data.id}
                    section={props.location.state.section.id}
                    data={props.location.state.data}
                    parentCallBack={ChangeApproveSection}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.notesnattachements"
                  )}
                >
                  <TabStrip selected={selected} onSelect={handleSelect}>
                    <TabStripTab
                      title={localizationService.toLanguageString(
                        "custom.notes"
                      )}
                    >
                      <GridTelerik data={notes}>
                        <GridToolbar>
                          <Button
                            primary={true}
                            onClick={() => {
                              {
                                EditSubject
                                  ? toggleDaddNote()
                                  : toggleDPermission();
                              }
                            }}
                          >
                            {localizationService.toLanguageString("custom.new")}
                          </Button>
                        </GridToolbar>

                        <Column
                          field={"note"}
                          title={localizationService.toLanguageString(
                            "custom.note"
                          )}
                          cell={NoteNameTemplate}
                        />
                        <Column
                          field={"date_created"}
                          title={localizationService.toLanguageString(
                            "custom.datecreated"
                          )}
                          cell={DateCreatedTemplate}
                        />
                        <Column
                          title={localizationService.toLanguageString(
                            "custom.actions"
                          )}
                          width="100px"
                          cell={Noteactions}
                        />
                      </GridTelerik>
                    </TabStripTab>

                    <TabStripTab
                      title={localizationService.toLanguageString(
                        "custom.attachements"
                      )}
                    >
                      <GridTelerik data={attachements}>
                        <GridToolbar>
                          <Button
                            primary={true}
                            onClick={() => {
                              {
                                EditSubject
                                  ? toggleDaddFile()
                                  : toggleDPermission();
                              }
                            }}
                          >
                            {localizationService.toLanguageString("custom.new")}
                          </Button>
                        </GridToolbar>
                        <Column
                          field={"title"}
                          title={localizationService.toLanguageString(
                            "custom.title"
                          )}
                        />

                        <Column
                          field={"date_created"}
                          title={localizationService.toLanguageString(
                            "custom.dateadded"
                          )}
                          cell={DateCreatedTemplate}
                        />

                        <Column
                          title={localizationService.toLanguageString(
                            "custom.actions"
                          )}
                          width="100px"
                          cell={Fileactions}
                        />
                      </GridTelerik>
                    </TabStripTab>
                  </TabStrip>
                </TabStripTab>
              </TabStrip>
            </CardBody>
          </Card>
        </div>
      </div>

      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}

      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}

      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
    </TrialMap>
  );
}

export default ListLaboratoryValues;
