//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements

import { FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { FieldArray } from "@progress/kendo-react-form";
import { Hint, Error } from "@progress/kendo-react-labels";
import { Fade } from "@progress/kendo-react-animation";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import {
  Grid as GridTelerik,
  GridColumn as Column,
} from "@progress/kendo-react-grid";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  RequiredFormDatePicker,
  FormNumericTextBox,
  FormInput,
  FormTextArea,
  RequiredFormTextArea,
} from "../common/Kendo/form-components";

import {
  numberValidator,
  requiredValidator,
  requiredMax50Validator,
} from "../common/Kendo/validators";

//component
import StaticBloodChemistryTest from "../common/commoncomponents/StaticBloodChemistryTest";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";

const languageContext = React.createContext({
  intl: null,
});

const MyContext = React.createContext({
  FormRef: {},
});

export const FormCheckbox = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const currentContext = React.useContext(MyContext);
  return (
    <FieldWrapper>
      <Checkbox
        ariaDescribedBy={`${hintId} ${errorId}`}
        label={label}
        labelOptional={optional}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
        onChange={(e) => {
          fieldRenderProps.onChange(e);
          let fieldValue = fieldRenderProps.name.replace(
            "test_present",
            "notpresent_reason"
          );
          let fieldValue1 = fieldRenderProps.name.replace(
            "test_present",
            "result"
          );
          let fieldValue2 = fieldRenderProps.name.replace(
            "test_present",
            "unit"
          );
          let fieldValue3 = fieldRenderProps.name.replace(
            "test_present",
            "lower_limit"
          );
          let fieldValue4 = fieldRenderProps.name.replace(
            "test_present",
            "upper_limit"
          );

          currentContext.FormRef.current.valueSetter(fieldValue, "");
          currentContext.FormRef.current.valueSetter(fieldValue1, null);
          currentContext.FormRef.current.valueSetter(fieldValue2, "");
          currentContext.FormRef.current.valueSetter(fieldValue3, null);
          currentContext.FormRef.current.valueSetter(fieldValue4, null);
        }}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

//Blood Chemistry

const BloodChemistryCustomCell = (props) => {
  if (props.field === "test_present") {
    return (
      <td>
        <Field
          component={FormCheckbox}
          name={`blood_chemistry_tests[${props.dataIndex}].${props.field}`}
        />
      </td>
    );
  } else if (props.field === "notpresent_reason") {
    return (
      <td>
        <Field
          component={FormTextArea}
          name={`blood_chemistry_tests[${props.dataIndex}].${props.field}`}
          // validator={
          //   props.dataItem.test_present === false ? requiredValidator : ""
          // }
          rows={1}
          disabled={props.dataItem.test_present === true ? true : false}
        />
      </td>
    );
  } else if (props.field === "test_name") {
    return <td>{props.dataItem.test_name}</td>;
  } else if (props.field === "result") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`blood_chemistry_tests[${props.dataIndex}].${props.field}`}
          format={"n2"}
          min={0}
          validator={
            props.dataItem.test_present === true ? numberValidator : ""
          }
          disabled={props.dataItem.test_present === false ? true : false}
        />
      </td>
    );
  } else if (props.field === "unit") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`blood_chemistry_tests[${props.dataIndex}].${props.field}`}
          validator={
            props.dataItem.test_present === true ? requiredMax50Validator : ""
          }
          disabled={props.dataItem.test_present === false ? true : false}
        />
      </td>
    );
  } else if (props.field === "lower_limit") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`blood_chemistry_tests[${props.dataIndex}].${props.field}`}
          format={"n2"}
          min={0}
          validator={
            props.dataItem.test_present === true ? numberValidator : ""
          }
          disabled={props.dataItem.test_present === false ? true : false}
        />
      </td>
    );
  } else if (props.field === "upper_limit") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`blood_chemistry_tests[${props.dataIndex}].${props.field}`}
          format={"n2"}
          min={0}
          validator={
            props.dataItem.test_present === true ? numberValidator : ""
          }
          disabled={props.dataItem.test_present === false ? true : false}
        />
      </td>
    );
  }
};

const BloodChemistryFormGrid = (fieldArrayRenderProps) => {
  console.log(
    "fieldArrayRenderProps.valuefieldArrayRenderProps.value",
    fieldArrayRenderProps.value
  );
  const { validationMessage, visited } = fieldArrayRenderProps;
  return (
    <div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}

      <GridTelerik
        style={{
          maxHeight: "500px",
        }}
        data={fieldArrayRenderProps.value}
      >
        <Column
          field="test_present"
          title={
            <>
              <span
                title={languageContext.intl.toLanguageString("custom.presence")}
                className="text-responsive-overflow"
              >
                {languageContext.intl.toLanguageString("custom.presence")}
              </span>
              <span style={{ color: "red" }}>{"*"}</span>
            </>
          }
          cell={BloodChemistryCustomCell}
        />
        <Column
          field="notpresent_reason"
          title={
            <>
              <span
                title={languageContext.intl.toLanguageString(
                  "custom.testmissingreason"
                )}
                className="text-responsive-overflow"
              >
                {languageContext.intl.toLanguageString(
                  "custom.testmissingreason"
                )}
              </span>
              <span style={{ color: "red" }}>{"*"}</span>
            </>
          }
          cell={BloodChemistryCustomCell}
        />

        <Column
          field="test_name"
          title={
            <>
              <span
                title={languageContext.intl.toLanguageString("custom.test")}
                className="text-responsive-overflow"
              >
                {languageContext.intl.toLanguageString("custom.test")}
              </span>
            </>
          }
          cell={BloodChemistryCustomCell}
        />
        <Column
          field="result"
          title={
            <>
              <span
                title={languageContext.intl.toLanguageString("custom.result")}
                className="text-responsive-overflow"
              >
                {languageContext.intl.toLanguageString("custom.result")}
              </span>
              <span style={{ color: "red" }}>{"*"}</span>
            </>
          }
          cell={BloodChemistryCustomCell}
        />
        <Column
          field="unit"
          title={
            <>
              <span
                title={languageContext.intl.toLanguageString("custom.unit")}
                className="text-responsive-overflow"
              >
                {languageContext.intl.toLanguageString("custom.unit")}
              </span>
              <span style={{ color: "red" }}>{"*"}</span>
            </>
          }
          cell={BloodChemistryCustomCell}
        />
        <Column
          field="lower_limit"
          title={
            <>
              <span
                title={languageContext.intl.toLanguageString(
                  "custom.lowerlimitnormal"
                )}
                className="text-responsive-overflow"
              >
                {languageContext.intl.toLanguageString(
                  "custom.lowerlimitnormal"
                )}
              </span>
              <span style={{ color: "red" }}>{"*"}</span>
            </>
          }
          cell={BloodChemistryCustomCell}
        />
        <Column
          field="upper_limit"
          title={
            <>
              <span
                title={languageContext.intl.toLanguageString(
                  "custom.upperlimitnormal"
                )}
                className="text-responsive-overflow"
              >
                {languageContext.intl.toLanguageString(
                  "custom.upperlimitnormal"
                )}
              </span>
              <span style={{ color: "red" }}>{"*"}</span>
            </>
          }
          cell={BloodChemistryCustomCell}
        />
      </GridTelerik>
    </div>
  );
};

export function BloodTest(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();
  languageContext.intl = useLocalization();

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //variable
  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //for handling checkbox change
  const [ModifiedData, setModifiedData] = useState();

  const AllData = props.data;
  console.log("props.dataprops.data", props.data);
  //for tab modification
  setInterval(function () {
    props.parentCallBack({ ref: MyForm.current });
  }, 500);

  useEffect(() => {
    props.parentCallBack({ ref: MyForm.current });
  }, [MyForm.current]);

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  //create function
  const CreateBloodTest = (dataItem) => {
    console.log(
      "vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv",
      dataItem.values.blood_chemistry_tests
    );
    if (dataItem.isValid) {
      if (AllData && AllData.blood_chemistry_tests.length > 0) {
        if (dataItem.isModified) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let array = [];
        if (dataItem.values.blood_chemistry_tests) {
          dataItem.values.blood_chemistry_tests.map((d) => {
            array.push({
              study_subject: props.subjectdata.data.id,
              section: props.subjectdata.section.id,
              test_present: d.test_present,
              notpresent_reason: d.notpresent_reason,
              test_name: d.test_name,
              result: d.result,
              unit: d.unit,
              lower_limit: d.lower_limit,
              upper_limit: d.upper_limit,
              reeeeeeeeeeeeeeeeeeem: d.notpresent_reason,
            });
          });
        }
        setLoading(true);
        axios
          .post(APIURL + "/common/addbloodtest/", {
            created_by: UserDetail.id,
            study_subject: props.subjectdata.data.id,
            section: props.subjectdata.section.id,
            blood_chemistry_tests: array,
            date_bloodchemistrytest: dataItem.values.date_bloodchemistrytest
              ? moment(dataItem.values.date_bloodchemistrytest).format(
                  "YYYY-MM-DD"
                )
              : null,
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          });
      }
    }
  };

  const CreateModification = (dataItem) => {
    toggleDModifyreason(false);
    let array = [];
    if (ModifiedData.blood_chemistry_tests) {
      ModifiedData.blood_chemistry_tests.map((d) => {
        array.push({
          id: d.id,
          study_subject: props.subjectdata.data.id,
          section: props.subjectdata.section.id,
          test_present: d.test_present,
          notpresent_reason: d.notpresent_reason,
          test_name: d.test_name,
          result: d.result,
          unit: d.unit,
          lower_limit: d.lower_limit,
          upper_limit: d.upper_limit,
        });
      });
    }
    setLoading(true);
    axios
      .put(APIURL + "/common/updatebloodtest/", {
        created_by: AllData.testdata.created_by,
        modified_by: UserDetail.id,
        study_subject: props.subjectdata.data.id,
        section: props.subjectdata.section.id,
        blood_chemistry_tests: array,
        modification_reason: dataItem.modification_reason,
        date_bloodchemistrytest: ModifiedData.date_bloodchemistrytest
          ? moment(ModifiedData.date_bloodchemistrytest).format("YYYY-MM-DD")
          : null,
      })
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      });
  };

  //IF all tests are not present then the date section is not required
  //IF all tests are not present then the date section is not required
  const handlevalidator = (formRenderProps) => {
    if (formRenderProps.valueGetter("blood_chemistry_tests")) {
      let array = formRenderProps.valueGetter("blood_chemistry_tests");
      let newarray = array.filter((d) => d.test_present === true);

      if (newarray.length !== 0) {
        return requiredValidator;
      } else {
        return "";
      }
    }
  };

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && props.approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (props.approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || props.approved) {
      boolean = true;
    }
    return boolean;
  };
  console.log("ddddddddddddddddddddddddd", AllData);
  return (
    <>
      <MyContext.Provider value={{ FormRef: MyForm }}>
        <Form
          initialValues={{
            blood_chemistry_tests:
              AllData && AllData.blood_chemistry_tests.length !== 0
                ? AllData.blood_chemistry_tests
                : StaticBloodChemistryTest,
            date_bloodchemistrytest:
              AllData && AllData.testdata.date_bloodchemistrytest
                ? new Date(AllData.testdata.date_bloodchemistrytest)
                : null,
          }}
          ref={MyForm}
          onSubmitClick={CreateBloodTest}
          key={JSON.stringify(AllData)}
          render={(formRenderProps) => (
            <FormElement className="">
              <fieldset className={"k-form-fieldset"}>
                <Tooltip
                  openDelay={0}
                  anchorElement="target"
                  parentTitle={true}
                  position={"top"}
                >
                  <Prompt
                    when={PromptFunction(formRenderProps)}
                    message={localizationService.toLanguageString(
                      "custom.promptmessage"
                    )}
                  />
                  <div className="row">
                    <div className="col-6">
                      <Field
                        id={"date_bloodchemistrytest"}
                        name={"date_bloodchemistrytest"}
                        label={localizationService.toLanguageString(
                          "custom.sampledate"
                        )}
                        component={RequiredFormDatePicker}
                        validator={handlevalidator(formRenderProps)}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <FieldArray
                      name="blood_chemistry_tests"
                      component={BloodChemistryFormGrid}
                    />
                  </div>
                </Tooltip>
              </fieldset>
              <Tooltip
                openDelay={0}
                anchorElement="target"
                parentTitle={true}
                position={"left"}
              >
                <div className="float-right">
                  <div title={disabletitle()}>
                    <Button
                      primary={true}
                      type={"submit"}
                      className="mr-2em"
                      disabled={disablefunction()}
                    >
                      {loading
                        ? localizationService.toLanguageString("custom.loading")
                        : localizationService.toLanguageString("custom.save")}
                    </Button>
                  </div>
                </div>
              </Tooltip>
            </FormElement>
          )}
        />
      </MyContext.Provider>
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  );
}

export default BloodTest;
