const SurgerySite = [
  {
    value: "Unilateral",
    name: "Unilateral",
  },
  {
    value: "Bilateral ",
    name: "Bilateral ",
  },
];

export default SurgerySite;
