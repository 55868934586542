const PatientRiskGroup = [
  {
    value: "Favorable risk group (no poor prognostic factors)",
    name: "Favorable risk group (no poor prognostic factors)",
  },
  {
    value: "Intermediate-risk group (1 or 2 poor prognostic factors)",
    name: "Intermediate-risk group (1 or 2 poor prognostic factors)",
  },
  {
    value: "Poor-risk group (>2 poor prognostic factors)",
    name: "Poor-risk group (>2 poor prognostic factors)",
  },
];

export default PatientRiskGroup;
