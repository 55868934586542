//utilities
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import APIURL from "../../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
//kendo component
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { useLocalization } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { filterBy } from "@progress/kendo-data-query";
import {
  RequiredFormInput,
  RequiredDropDownList,
  FormCheckbox,
} from "../../common/Kendo/form-components";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";

import {
  requiredValidator,
  emailValidator,
  passwordValidator,
  requiredMax50Validator,
} from "../../common/Kendo/validators";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade as KendoFade } from "@progress/kendo-react-animation";



//component
import Centers from "../../common/commoncomponents/Centers";
import Countries from "../../common/commoncomponents/Countries";
import Settings from "../../setting/Settings";

//redux
import { user_detail } from "../../redux/action";
import { Fade } from "react-bootstrap";
const NewUser = (props) => {
  console.log("poropsporops",props)
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const dispatch = useDispatch();
  //localization
  const localizationService = useLocalization();

  //variables
  const [Promptsave, setPromptsave] = useState(false);
  const [emailerror, setEmailError] = useState("");
  const [usernameerror, setUsernameError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [showToast, setShowToast] = React.useState(false)
  //data fetching

  const { data: roles } = useSWR(APIURL + "/auth/rolelist");
  const { data: users } = useSWR(APIURL + "/auth/listuser/");
  
  const handleToastClick = () => {
    setShowToast(!showToast);
  };
  
  const [newpassworderror, setNewPasswordError] = useState("");
  const [showPasswordSuccess, setShowPasswordSuccess] = useState("");



  const role = roles
    ? roles.map((data) => ({
        name: data.role_name,
        value: data.id,
        is_assistant: data.is_assistant,
      }))
    : [];

  const userarray = users
    ? users.map((data) => ({
        name: data.first_name + " " + data.last_name,
        value: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
      }))
    : [];
console.log("userarrayuserarray",userarray)
  //user data fetching
  const { data: studies } = useSWR(APIURL + "/common/liststudy/");
  const studyarray = studies
    ? studies.map((val) => ({ value: val.id, name: val.study_name }))
    : [];

  const StudyDropDownList = (fieldRenderProps) => {
    const [data, setData] = React.useState(studyarray.slice());
    const filterData = (filter) => {
      const data = studyarray.slice();
      return filterBy(data, filter);
    };
    const filterChange = (event) => {
      setData(filterData(event.filter));
    };
    const handleChange = (e) => {
      fieldRenderProps.onChange({
        dataItem: fieldRenderProps.dataItem,
        field: fieldRenderProps.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value ? e.target.value.value : null,
      });
    };
    const { dataItem, field } = fieldRenderProps;
    const dataValue = fieldRenderProps;
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      wrapperStyle,
      ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    return (
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
          <span style={{ color: "red" }}>*</span>
        </Label>
        <ComboBox
          onChange={handleChange}
          value={data.find((c) => c.value === dataValue)}
          data={data}
          textField="name"
          dataItemKey="value"
          filterable={true}
          onFilterChange={filterChange}
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    );
  };

  //USERS DROPDOWN LIST
  const UserDropDownList = (fieldRenderProps) => {
    const [data, setData] = React.useState(userarray.slice());
    const filterData = (filter) => {
      const data = userarray.slice();
      return filterBy(data, filter);
    };
    const filterChange = (event) => {
      setData(filterData(event.filter));
    };
    const handleChange = (e) => {
      fieldRenderProps.onChange({
        dataItem: fieldRenderProps.dataItem,
        field: fieldRenderProps.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value ? e.target.value.value : null,
      });
    };
    const { dataItem, field } = fieldRenderProps;
    const dataValue = fieldRenderProps;
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      wrapperStyle,
      ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    return (
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
          <span style={{ color: "red" }}>*</span>
        </Label>
        <ComboBox
          onChange={handleChange}
          value={data.find((c) => c.value === dataValue)}
          data={data}
          textField="name"
          dataItemKey="value"
          filterable={true}
          onFilterChange={filterChange}
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    );
  };
  const UpdatePersonalInfo = (dataItem) => {
    //if (dataItem.isValid) {
      axios
        .put(APIURL + `/auth/updateuserpassword/${props.location.state.id}/`, {
          firstname: props.location.state.first_name,
          lastname: props.location.state.last_name,
          username: props.location.state.username,
          confirm_password: dataItem.confirm_password,
          new_password: dataItem.newpassword,
        })
        .then((res) => {
          if (res.data.newpasswordmatch == "passwordsnotmatching") {
            setNewPasswordError(
              localizationService.toLanguageString("custom.passwordnotmatching")
            );
          } else if (res.data.newpasswordstructure == "passwordnotallowed") {
            setNewPasswordError(
              localizationService.toLanguageString(
                "custom.yourpasswordnotpersonalinformation"
              )
            );
          } else if (res.data.message === "success") {
            // props.onClose();
            setShowPasswordSuccess(true)
          }
        });
    //}
  };
  //Create function
  const CreateUser = (dataItem) => {
    if (dataItem.isValid) {
      setPromptsave(true);

      if (props.location.state) {
        axios
          .put(
            APIURL + "/auth/customuserupdate/" + props.location.state.id + "/",
            {
              username: dataItem.values.username,
              first_name: dataItem.values.first_name,
              last_name: dataItem.values.last_name,
              email: dataItem.values.email,
              role: dataItem.values.role ? dataItem.values.role.value : null,
              is_assistant: dataItem.values.is_assistant,
              sub_investigator: dataItem.values.sub_investigator
                ? dataItem.values.sub_investigator.value
                : null,
              country: dataItem.values.country
                ? [dataItem.values.country]
                : null,

              center: dataItem.values.center
                ? dataItem.values.center.value
                : "IH",
              study: dataItem.values.study ? dataItem.values.study.value : null,
              is_active: dataItem.values.is_active,
            }
          )
          .then((res) => {
            if (
              res.data.checkemailusername === "email and username already exist"
            ) {
              setEmailError(
                localizationService.toLanguageString("custom.emailtaken")
              );
              setUsernameError(
                localizationService.toLanguageString("custom.usernametaken")
              );
            } else if (res.data.checkemail === "email already exist") {
              setEmailError(
                localizationService.toLanguageString("custom.emailtaken")
              );
            } else if (res.data.checkusername === "username already exist") {
              setUsernameError(
                localizationService.toLanguageString("custom.usernametaken")
              );
            } else if (res.data.message === "success")
              if (props.location.state.id === UserDetail.id) {
                dispatch(
                  user_detail({
                    id: props.location.state.id,
                    username: dataItem.values.username,
                    email: dataItem.values.email,
                    country: dataItem.values.country
                      ? [dataItem.values.country]
                      : null,

                    center: dataItem.values.center
                      ? dataItem.values.center.value
                      : "IH",
                    sub_investigator_fn: dataItem.values.sub_investigator
                      ? dataItem.values.sub_investigator.first_name
                      : "",
                    is_assistant: dataItem.values.is_assistant,
                    sub_investigator_ln: dataItem.values.sub_investigator
                      ? dataItem.values.sub_investigator.last_name
                      : "",
                    first_name: dataItem.values.first_name,
                    last_name: dataItem.values.last_name,
                    role: dataItem.values.role ? dataItem.values.role.name : "",
                    is_active: dataItem.values.is_active,
                    role_id: dataItem.values.role
                      ? dataItem.values.role.value
                      : null,
                    study_id: dataItem.values.study
                      ? dataItem.values.study.value
                      : null,
                    study_name: dataItem.values.study
                      ? dataItem.values.study.name
                      : null,
                  })
                );
              }
            props.history.push({
              pathname: "/settings/authentication/user/list",
              state: "edited",
            });
          });
      } else {
        axios
          .post(APIURL + "/auth/adduser/", {
            created_by: UserDetail.id,
            username: dataItem.values.username,
            first_name: dataItem.values.first_name,
            last_name: dataItem.values.last_name,
            email: dataItem.values.email,
            role: dataItem.values.role.value,
            sub_investigator: dataItem.values.sub_investigator
              ? dataItem.values.sub_investigator.value
              : null,
            is_assistant: dataItem.values.is_assistant,
            country: dataItem.values.country ? [dataItem.values.country] : null,

            is_active: dataItem.values.is_active,
            center: dataItem.values.center
              ? dataItem.values.center.value
              : "IH",
            study: dataItem.values.study ? dataItem.values.study.value : null,
            password: dataItem.values.password,
            confirm_password: dataItem.values.confirm_password,
          })
          .then((res) => {
            if(res.data.message === "Maximum user limit reached."){

              handleToastClick()
            }
            if (
              res.data.checkemailusername === "email and username already exist"
            ) {
              setEmailError(
                localizationService.toLanguageString("custom.emailtaken")
              );
              setUsernameError(
                localizationService.toLanguageString("custom.usernametaken")
              );
            } else if (res.data.checkemail === "email already exist") {
              setEmailError(
                localizationService.toLanguageString("custom.emailtaken")
              );
            } else if (res.data.mathchpassword === "Password does not match") {
              setPasswordError(
                localizationService.toLanguageString(
                  "custom.passwordnotmatching"
                )
              );
            } else if (res.data.checkusername === "username already exist") {
              setUsernameError(
                localizationService.toLanguageString("custom.usernametaken")
              );
            } else if (
              res.data.message ===
              "password must not contain your personal information"
            ) {
              setPasswordError(
                localizationService.toLanguageString(
                  "custom.yourpasswordnotpersonalinformation"
                )
              );
            } else if (res.data.message === "success")
              props.history.push({
                pathname: "/settings/authentication/user/list",
                state: "added",
              });
          })
      
      }
    }
  };

  const handleCountryChange = (e, formRenderProps) => {
    formRenderProps.onChange("center", { value: null });
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (!Promptsave && formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  return (
    <Settings>

        {showToast ?  (
             <NotificationGroup
             style={{
               left: "50%",
               top: "60px",
               alignItems: "flex-center",
               flexWrap: "wrap-reverse",
               width: "80%",
             }}
           >
            <KendoFade   timeout={500}>
        <Notification
          type={{
            style: "error",
            icon: true,
          }}
  
          onClose={handleToastClick}
        > <span>you don't have enough license.</span></Notification> 
        </KendoFade>
        </NotificationGroup>
      ): ''}
      <Card>

    
        <CardHeader>
          <CardTitle>
            {props.location.state
              ? localizationService.toLanguageString("custom.edituser")
              : localizationService.toLanguageString("custom.newuser")}
          </CardTitle>
        </CardHeader>
        <Form
          initialValues={{
            username: props.location.state ? props.location.state.username : "",
            first_name: props.location.state
              ? props.location.state.first_name
              : "",
            last_name: props.location.state
              ? props.location.state.last_name
              : "",
            email: props.location.state ? props.location.state.email : "",
            role: props.location.state
              ? {
                  name: props.location.state.role_name,
                  value: props.location.state.role,
                  is_assistant: props.location.state.role_is_assistant,
                }
              : null,
            sub_investigator: props.location.state
              ? props.location.state.sub_investigator
                ? {
                    name: props.location.state.sub_investigator_name,
                    value: props.location.state.sub_investigator,
                  }
                : null
              : null,
            is_assistant: props.location.state
              ? props.location.state.is_assistant
              : false,
            country:
              props.location.state && props.location.state.country
                ? props.location.state.country[0]
                : null,
            center:
              props.location.state &&
              props.location.state.country &&
              props.location.state.country[0]["value"] !== "LB"
                ? {
                    name: props.location.state.center,
                    value: props.location.state.center,
                  }
                : null,
            study:
              props.location.state && props.location.state.study
                ? {
                    name: props.location.state.study_name,
                    value: props.location.state.study,
                  }
                : null,
            password: "",
            confirm_password: "",
            is_active: props.location.state
              ? props.location.state.is_active
              : true,
          }}
          onSubmitClick={CreateUser}
          render={(formRenderProps) => (
            <CardBody>
              <FormElement>
                <fieldset>
                  <Prompt
                    when={PromptFunction(formRenderProps)}
                    message={localizationService.toLanguageString(
                      "custom.promptmessage"
                    )}
                  />
                  <div className="row">
                    <div className="col-4">
                      <Field
                        name={"first_name"}
                        component={RequiredFormInput}
                        label={localizationService.toLanguageString(
                          "custom.firstname"
                        )}
                        validator={requiredMax50Validator}
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        name={"last_name"}
                        component={RequiredFormInput}
                        label={localizationService.toLanguageString(
                          "custom.lastname"
                        )}
                        validator={requiredMax50Validator}
                      />
                    </div>

                    <div className="col-4">
                      <Field
                        name={"username"}
                        component={RequiredFormInput}
                        label={localizationService.toLanguageString(
                          "custom.username"
                        )}
                        validator={requiredMax50Validator}
                        onChange={() => setUsernameError("")}
                      />
                      <span className={"k-form-error"}> {usernameerror}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <Field
                        name={"email"}
                        component={RequiredFormInput}
                        label={localizationService.toLanguageString(
                          "custom.email"
                        )}
                        validator={emailValidator}
                        onChange={() => setEmailError("")}
                      />
                      <span className={"k-form-error"}>{emailerror}</span>
                    </div>

                    <div className="col-4">
                      <Field
                        data={role}
                        name={"role"}
                        component={RequiredDropDownList}
                        label={localizationService.toLanguageString(
                          "custom.role"
                        )}
                        validator={requiredValidator}
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        data={Countries}
                        name={"country"}
                        component={RequiredDropDownList}
                        label={localizationService.toLanguageString(
                          "custom.country"
                        )}
                        validator={requiredValidator}
                        onChange={(e) =>
                          handleCountryChange(e, formRenderProps)
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <Field
                        name={"study"}
                        component={StudyDropDownList}
                        label={localizationService.toLanguageString(
                          "custom.study"
                        )}
                        validator={requiredValidator}
                      />
                    </div>
                    {formRenderProps.valueGetter("country") &&
                    formRenderProps.valueGetter("country").value === "LB" ? (
                      ""
                    ) : (
                      <div className="col-4">
                        <Field
                          data={Centers}
                          name={"center"}
                          component={RequiredDropDownList}
                          label={localizationService.toLanguageString(
                            "custom.center"
                          )}
                          validator={requiredValidator}
                        />
                      </div>
                    )}
                  </div>

                  {!props.location.state ? (
                    <>
                      <div className="row">
                        <div className="col-4">
                          <Field
                            name={"password"}
                            type="password"
                            component={RequiredFormInput}
                            label={localizationService.toLanguageString(
                              "custom.password"
                            )}
                            validator={passwordValidator}
                            onChange={() => setPasswordError("")}
                          />
                          <span className={"k-form-error"}>
                            {passworderror}
                          </span>
                        </div>
                        <div className="col-4">
                          <Field
                            type="password"
                            name={"confirm_password"}
                            component={RequiredFormInput}
                            label={localizationService.toLanguageString(
                              "custom.confirmpassword"
                            )}
                            validator={passwordValidator}
                            onChange={() => setPasswordError("")}
                          />
                          <span className={"k-form-error"}>
                            {passworderror}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="row mt-2">
                    <div className="col-4">
                      <Field
                        name={"is_active"}
                        component={FormCheckbox}
                        label={localizationService.toLanguageString(
                          "custom.active"
                        )}
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        name={"is_assistant"}
                        component={FormCheckbox}
                        label={localizationService.toLanguageString(
                          "custom.isassistant"
                        )}
                      />
                    </div>
                    {formRenderProps.valueGetter("is_assistant") === true ? (
                      <div className="col-4">
                        <Field
                          name={"sub_investigator"}
                          component={UserDropDownList}
                          label={localizationService.toLanguageString(
                            "custom.subinvestigator"
                          )}
                          validator={requiredValidator}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  {!props.location.state ? (
                    <>
                      <div className="row mt-2">
                        <div className="col-12">
                          {localizationService.toLanguageString(
                            "custom.password"
                          )}
                          :
                        </div>
                      </div>
                      <div className="row ChangePasswordRecommendation">
                        <div>
                          <li>
                            {localizationService.toLanguageString(
                              "custom.passwordnotpersonalinformation"
                            )}
                          </li>
                        </div>

                        <div>
                          <li>
                            {" "}
                            {localizationService.toLanguageString(
                              "custom.passwordcharacternb"
                            )}
                          </li>
                        </div>
                        <div>
                          <li>
                            {localizationService.toLanguageString(
                              "custom.passwordnotnumeric"
                            )}
                          </li>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="float-right">
                    <Button primary={true} className={"mr-2 "}>
                      {localizationService.toLanguageString("custom.save")}
                    </Button>
                    <Button
                      onClick={() =>
                        props.history.push("/settings/authentication/user/list")
                      }
                    >
                      {localizationService.toLanguageString("custom.cancel")}
                    </Button>
                  </div>
                </fieldset>
              </FormElement>
            </CardBody>
          )}
        />
      </Card>
      {props.location.state?
      <Card style={{marginTop:"30px"}}>
      {showPasswordSuccess && (
      <NotificationGroup >
        <Notification
          type={{ style: 'success', icon: true }}
          closable={true}
          onClose={() => setShowPasswordSuccess(false)}
        >
          <span>Password updated successfully</span>
        </Notification>
      </NotificationGroup>
    )}
        <CardTitle>
          {localizationService.toLanguageString("custom.changepassword")}
        </CardTitle>
        <CardBody>
        <Form
        onSubmit={UpdatePersonalInfo}
        render={(formRenderProps) => (
          <FormElement style={{maxWidth: 500}}>
            <div className="row">
             <div className="col-4">
                          <Field
                            name={"newpassword"}
                            type="password"
                            component={RequiredFormInput}
                            label={localizationService.toLanguageString(
                              "custom.password"
                            )}
                            validator={passwordValidator}
                            onChange={() => setPasswordError("")}
                          />
                          <span className={"k-form-error"}>
                            {newpassworderror}
                            {/* <span className={"k-form-error"}>
                            {" "}
                            {newpassworderror}
                          </span> */}
                          </span>
                        </div>
            <div className="col-4">
                          <Field
                            type="password"
                            name={"confirm_password"}
                            component={RequiredFormInput}
                            label={localizationService.toLanguageString(
                              "custom.confirmpassword"
                            )}
                            validator={passwordValidator}
                            onChange={() => setPasswordError("")}
                          />
                          <span className={"k-form-error"}>
                            {passworderror}
                          </span>
                        </div>
                        </div>
            <div className="k-form-buttons">
              <button
                type="submit"
                className="k-button"
                disabled={!formRenderProps.allowSubmit}
              >
                Submit
              </button>
            </div>
          </FormElement>
        )}
    />


        </CardBody>
      </Card>
      :''}
     
    </Settings>
    
  );
};

export default NewUser;
