//utilities
import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { useSelector } from "react-redux";

//kendo component
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridFilterCell
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//access
import { useHistory } from "react-router-dom";

import { VscFeedback } from "react-icons/vsc";
//Component
import OverallRes from "./OverallRes";
import ScreeningPopup from "./ScreeningPopup";
import BaselinePopup from "./BaselinePopup";
import TreatmentPopup from "./TreatmentPopup";
import FollowupPopup from "./FollowupPopup";
import AddStudySubject from "./AddStudySubject";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import { CustomColumnMenu } from "../common/Kendo/CustomColumnMenu";
import Excel from "./Excel";
import AllExcel from "./AllExcel";
import { DatePicker } from '@progress/kendo-react-dateinputs';
import moment from "moment";
import {DateFilterCell} from "./dateFilterCell";
import {

  RequiredFormDatePicker,
 
} from "../common/Kendo/form-components";
import { downloadExcel } from "react-export-table-to-excel";
import Spinner from 'react-bootstrap/Spinner';

export function ListStudySubject(props) {
  //variale
  const [SelectedStudyFilter, setSelectedStudyFilter] = useState("All");
  const [selected_data, setSelected_data] = useState([]);

  //REDUX
  const UserDetail = useSelector((state) => state.UserDetail);
  const ViewAllSubject = useSelector((state) => state.ViewAllSubject);
  const ViewSubject = useSelector((state) => state.ViewSubject);
  const AddSubject = useSelector((state) => state.AddSubject);

  //data fetching by filter
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          studyfilter: SelectedStudyFilter,
          userloggedIn: UserDetail.id,
          permission: ViewAllSubject,
        },
      })
      .then((res) => res.data);

  const { data: subjects, Subjecterror } = useSWR(
    [
      APIURL + "/common/listsubject/",
      SelectedStudyFilter,
      UserDetail.id,
      ViewAllSubject,
    ],
    fetcher1
  );
  const { data: studies, Studieserror } = useSWR(APIURL + "/common/liststudy/");
  
  //Add Subject
  const [VAddSubject, setVAddSubject] = useState(false);

  const toggleDAddSubject = () => {
    setVAddSubject(!VAddSubject);
  };

  //Overall Response
  const [VOverallRes, setVOverallRes] = useState(false);

  const toggleDOverallRes = () => {
    setVOverallRes(!VOverallRes);
  };

  //PERMISSION DIALOG
  const [NoPermission, setNoPermission] = useState("");
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //localization
  const localizationService = useLocalization();

  //NOTIFICATION
  const [state, setState] = React.useState({
    success: false,
    added: false,
    edited: false,
    error: false,
    warning: false,
    info: false,
    none: false,
  });
  const { success, added, edited } = state;
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };
  const LoadingComponent = () => (
    <div className="loading">Loading...</div>
  );
  //access
  const history = useHistory();

  //multiple selection
  const [selected, setselected] = useState([]);
  const selectField = "selected";
  // FILTER PART
  const [filter, setFilter] = useState();

  const createDataState = (dataState) => {
    return {
      result: subjects ? process(subjects.slice(0), dataState) : [],
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 50,
    skip: 0,
  });
  
  const columns = [
    {
      title: localizationService.toLanguageString("custom.studysubjectid"),
      field: "crf_id",
      show: true,
      filter: "text",
      cell: (props) => {
        return (
          <td>
            <span
              onClick={() =>
                history.push({
                  pathname: `/crf/study/detail`,
                  state: { data: props.dataItem },
                })
              }
              className="crf-id-link"
            >
              {props.dataItem.crf_id}
            </span>
          </td>
        );
      },
    },
    // {
    //   title: localizationService.toLanguageString("custom.patientenrolmentdate"),
    //   field: "date_created",
    //   show: true,
    //   filter: "date",
    //   format: '{0:dd/MM/yyyy}',
    //   // filterCell : CustomDateFilterCell,
    //   cell: (props) => {
    //     return (
    //       <td>
    //     {props.dataItem.date_created}
    //           {/* {moment(props.dataItem.date_created).format("DD/MM/YYYY")} */}
    //       </td>
    //     );
    //   },
    // },
    {
      title: localizationService.toLanguageString("custom.patientstatus"),
      field: "latest_patient_status",
      show: true,
      filter: "text",
      cell: (props) => {
        return (
          <td>
            <span className={props.dataItem.latest_patient_status=== "Dead"?"inactive-user-status"
            :props.dataItem.latest_patient_status=== "Alive"?"active-user-status": 
            props.dataItem.latest_patient_status=== "Lost to follow-up"?"lost-user-status":''}>
              {props.dataItem.latest_patient_status}
              </span>
          </td>
        );
      },
    },
  ];

  const [data, setData] = useState(initialState.result);
  const [tempdata, setTempData] = useState([]);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(columns);
  const [showExcel, setShowExcel] = useState(false);

  const showexel =()=>{
    console.log("download excel");
    setShowExcel(false);
  }
  const downloadExcel =()=>{
    console.log("download excel");
    setShowExcel(true);
  }
  useEffect(() => {
    if (subjects) {
      let temp = subjects.map(item => {
        return {
          ...item,
          date_created: moment(item.date_created).format("DD/MM/YYYY")
        };
      });
      setTempData(temp);
    }

    if (subjects)
      setData(process(subjects ? subjects.slice(0) : [], dataState));
    if (props.location.add && props.location.add === "edited")
      setState({ edited: true });
    if (props.location.add && props.location.add === "added")
      setState({ added: true });
    setTimeout(() => {
      setState({ added: false, edited: false });
    }, 3000);
    if (studies) {
      let array = studies.map((d) => ({
        name: d.study_name,
        value: d.id,
      }));
      array.unshift({ name: "All", value: "All" });
      setdropdownData(array);
    }
  }, [subjects, studies]);

  const StudyFilterArray = studies
    ? studies.map((d) => ({
        name: d.study_name,
        value: d.id,
      }))
    : [];

  StudyFilterArray.unshift({
    name: "All",
    value: "All",
  });
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setData(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const ScreeningTemplate = (props) => {
    const anchor = React.createRef(null);
    const [show, setShow] = React.useState(false);
    const [anchorElement, setAnchorElement] = React.useState(anchor.current);
    const onClick = () => {
      setShow(!show);
    };
    React.useEffect(() => {
      setAnchorElement(anchor.current);
    }, []);

    const [anchorAlign, setAnchorAlign] = React.useState({
      horizontal: "center",
      vertical: "bottom",
    });

    const [popupAlign, setPopupAlign] = React.useState({
      horizontal: "left",
      vertical: "top",
    });

    const CloseScreeningPopup = () => {
      setShow(false);
    };

    return (
      <td>
        <span title={localizationService.toLanguageString("custom.dataentry")}>
          <i
            id={`screening${props.dataItem.crf_id}`}
            className="screening-popup k-icon k-i-track-changes-enable DocumentActionButtons"
            onClick={onClick}
            ref={anchor}
          ></i>
        </span>

        <Popup
          anchor={anchorElement}
          show={show}
          anchorAlign={anchorAlign}
          popupAlign={popupAlign}
          popupClass={"popup-content"}
          className="test"
        >
          <ScreeningPopup
            section_title={"screening"}
            data={props.dataItem}
            width={"200px"}
            parentCallback={CloseScreeningPopup}
          />
        </Popup>
      </td>
    );
  };

  const BaselineTemplate = (props) => {
    const anchor = React.createRef(null);
    const [showbaseline, setShowBaseline] = React.useState(false);
    const [anchorElement, setAnchorElement] = React.useState(anchor.current);
    const onClick = () => {
      setShowBaseline(!showbaseline);
    };
    React.useEffect(() => {
      setAnchorElement(anchor.current);
    }, []);

    const CloseBaselinePopup = () => {
      setShowBaseline(false);
    };
    const [anchorAlign, setAnchorAlign] = React.useState({
      horizontal: "center",
      vertical: "bottom",
    });

    const [popupAlign, setPopupAlign] = React.useState({
      horizontal: "left",
      vertical: "top",
    });

    return (
      <td>
        <span title={localizationService.toLanguageString("custom.dataentry")}>
          <i
            id={`baseline${props.dataItem.crf_id}`}
            className={`baseline-popup k-icon k-i-track-changes-enable DocumentActionButtons`}
            onClick={onClick}
            ref={anchor}
          ></i>
        </span>
        <Popup
          anchor={anchorElement}
          show={showbaseline}
          anchorAlign={anchorAlign}
          popupAlign={popupAlign}
          popupClass={"popup-content"}
        >
          <BaselinePopup
            data={props.dataItem}
            section_title={"baseline"}
            width={"200px"}
            parentCallback={CloseBaselinePopup}
          />
        </Popup>
      </td>
    );
  };
  const TreatmentTemplate = (props) => {
    const anchor = React.createRef(null);
    const [showtreatment, setShowTreatment] = React.useState(false);
    const [anchorElement, setAnchorElement] = React.useState(anchor.current);
    const onClick = () => {
      setShowTreatment(!showtreatment);
    };
    React.useEffect(() => {
      setAnchorElement(anchor.current);
    }, []);
    const CloseTreatmentPopup = () => {
      setShowTreatment(false);
    };
    const [anchorAlign, setAnchorAlign] = React.useState({
      horizontal: "center",
      vertical: "bottom",
    });

    const [popupAlign, setPopupAlign] = React.useState({
      horizontal: "left",
      vertical: "top",
    });

    return (
      <td>
        <span title={localizationService.toLanguageString("custom.dataentry")}>
          <i
            id={`treatment${props.dataItem.crf_id}`}
            className={`treatment-popup k-icon k-i-track-changes-enable DocumentActionButtons`}
            onClick={onClick}
            ref={anchor}
          ></i>
        </span>
        <Popup
          anchor={anchorElement}
          show={showtreatment}
          anchorAlign={anchorAlign}
          popupAlign={popupAlign}
          popupClass={"popup-content"}
        >
          <TreatmentPopup
            data={props.dataItem}
            section_title={"treatment"}
            width={"200px"}
            parentCallback={CloseTreatmentPopup}
          />
        </Popup>
      </td>
    );
  };

  const FollowupTemplate = (props) => {
    const anchor = React.createRef(null);
    const [showfollowup, setShowFollowup] = React.useState(false);
    const [anchorElement, setAnchorElement] = React.useState(anchor.current);
    const onClick = () => {
      setShowFollowup(!showfollowup);
    };
    React.useEffect(() => {
      setAnchorElement(anchor.current);
    }, []);

    const CloseFollowupPopup = () => {
      setShowFollowup(false);
    };
    const [anchorAlign, setAnchorAlign] = React.useState({
      horizontal: "center",
      vertical: "bottom",
    });

    const [popupAlign, setPopupAlign] = React.useState({
      horizontal: "left",
      vertical: "top",
    });

    return (
      <td>
        <span title={localizationService.toLanguageString("custom.dataentry")}>
          <i
            id={`followup${props.dataItem.crf_id}`}
            className={`followup-popup k-icon k-i-track-changes-enable DocumentActionButtons`}
            onClick={onClick}
            ref={anchor}
          ></i>
        </span>
        <Popup
          anchor={anchorElement}
          show={showfollowup}
          anchorAlign={anchorAlign}
          popupAlign={popupAlign}
          popupClass={"popup-content"}
        >
          <FollowupPopup
            data={props.dataItem}
            section_title={"followup"}
            width={"200px"}
            parentCallback={CloseFollowupPopup}
          />
        </Popup>
      </td>
    );
  };

  const handleOverallResponse = (dataItem) => {
    if (ViewSubject) {
      setSelected_data(dataItem);
      toggleDOverallRes();
    } else {
      setNoPermission("noaccess");
      toggleDPermission();
    }
  };
  const ActionTemplate = (props) => {
    return (
      <td>
        <span
          title={localizationService.toLanguageString("custom.overallresponse")}
        >
          <VscFeedback
            className="response-icon-style"
            onClick={() => {
              handleOverallResponse(props.dataItem);
            }}
          />
        </span>
      </td>
    );
  };

  const filterChange = (event) => {
        const filters = event?.filter?.filters?.map(filter => {
      if (filter?.field === 'date_created') {
        console.log("event",moment(filter.value).format("DD/MMM/YYYY"))

        return {
          ...filter,
          value: moment(filter.value).format("DD/MMM/YYYY")
        };
      }
      return filter;
    });
    setData(
      process(
        subjects ? filterBy(subjects.slice(0),filters) : [],
        dataState
      )
    );

    setFilter(event.filter);
  };
  

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  // dropdown filter
  const [dropdownvalue, setdropdownvalue] = useState({
    name: "All",
    value: "All",
  });
  const [dropdownData, setdropdownData] = React.useState([]);

  const filterData = (filter) => {
    const data = StudyFilterArray.slice();
    return filterBy(data, filter);
  };

  const filterDropdownChange = (event) => {
    setdropdownData(filterData(event.filter));
  };

  //Study Filter
  const handleStudyFilterClick = (e) => {
    setdropdownvalue(e.target.value);
    const text = e.target.value.name;
    setSelectedStudyFilter(text);
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  const FormDatePickerWithFormat = ({ filter, onChange }) => {
    const handleDateChange = (date) => {
      if (date) {
        onChange(moment(date).format("DD/MM/YYYY"));
      } else {
        onChange("");
      }
    };
  
    return <input type="date" value={filter} onChange={(e) => handleDateChange(e.target.value)} />;
  };
  const dateFilterCell = (props) => (
    <DateFilterCell
      {...props}
      value={props.value}
      name={props.value}
    />
  );

  return (
    <>
      {(!studies || !studies) && loadingPanel}
      <Tooltip
        openDelay={0}
        anchorElement="target"
        position="top"
        parentTitle="true"
      >
        <Grid
          className="full-page-style"
          data={data}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          pageable={true}
          pageSize={8}
          filterable={true}
          filter={filter}
          onFilterChange={filterChange}
        >
          <GridToolbar>
            <div className="col pages-big-title">
              <strong>
                {localizationService.toLanguageString("custom.studysubject")}
              </strong>
            </div>
            <div className="float-right">
              <strong className="mr-6em mt-1">
                {" "}
                {localizationService.toLanguageString("custom.filterbystudy")}
              </strong>
              <DropDownList
                data={dropdownData}
                textField="name"
                dataItemKey="value"
                filterable={true}
                className="mr-6em"
                onFilterChange={filterDropdownChange}
                value={dropdownvalue}
                onChange={handleStudyFilterClick}
              />

              <Button
                primary={true}
                className="mr-2em"
                onClick={() => {
                  setNoPermission("addnoaccess");
                  AddSubject ? toggleDAddSubject() : toggleDPermission();
                }}
              >
                {localizationService.toLanguageString("custom.newstudysubject")}
              </Button>
              {showExcel?
               <Button style={{ backgroundColor: '#FF6358', border: '0px solid #FF6358', width: '100px', height: '30px', borderRadius: '0px', color:'white' }} disabled>
                 <Spinner
                   as="span"
                   animation="grow"
                   size="sm"
                   role="status"
                   aria-hidden="YES"
                 />
                 Loading...
               </Button>:
            <Button style={{ backgroundColor: '#FF6358', border: '0px solid #FF6358', width: '100px', height: '30px', borderRadius: '0px', color: 'white' }} onClick={downloadExcel}>Export excel</Button>
              }
              {showExcel && <AllExcel showexel={showexel} />}
            </div>
          </GridToolbar>
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <Column
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filter={column.filter}
                  format={column.format}
                  filterCell ={column.filterCell}
                  cell={column.cell}
                  columnMenu={(props) => (
                    <CustomColumnMenu
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
          <Column field="date_created" title="Enrolment Date" filter="date" filterable={true} filterCell={(props) => dateFilterCell(props)} />
          
          <Column
            title={localizationService.toLanguageString("custom.screening")}
            cell={(props) => ScreeningTemplate(props)}
            filterable={false}
          />

          <Column
            title={localizationService.toLanguageString("custom.baseline")}
            cell={(props) => BaselineTemplate(props)}
            filterable={false}
          />
          <Column
            title={localizationService.toLanguageString("custom.treatment")}
            cell={(props) => TreatmentTemplate(props)}
            filterable={false}
          />

          <Column
            title={localizationService.toLanguageString("custom.followup")}
            cell={(props) => FollowupTemplate(props)}
            filterable={false}
          />

          <Column
            title={localizationService.toLanguageString(
              "custom.overallresponse"
            )}
            cell={ActionTemplate}
            filterable={false}
          />
          {/* <Column
          // field="id"
          filterable={false}

            title="export excel"
            cell={(props) => exportExcel(props)}
            
          /> */}
        </Grid>
      </Tooltip>

      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied
            onClose={toggleDPermission}
            NoPermission={NoPermission}
          />
        </Dialog>
      )}

      <NotificationGroup style={position.topRight}>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.studyadded")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {edited && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, edited: false })}
            >
              <span>
                {" "}
                {localizationService.toLanguageString("custom.studyedited")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>

      {/* ADD SUBJECT DIALOG */}
      {VAddSubject && (
        <Dialog
          title={localizationService.toLanguageString("custom.addstudysubject")}
          onClose={toggleDAddSubject}
          width={"900px"}
          height={"450px"}
        >
          <AddStudySubject onClose={toggleDAddSubject} />
        </Dialog>
      )}

      {/* INFORMED SUBJECT DIALOG */}
      {VOverallRes && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.subjectoverallresponse"
          )}
          onClose={toggleDOverallRes}
          width={"1000px"}
          height={"400px"}
        >
          <OverallRes onClose={toggleDOverallRes} data={selected_data} />
        </Dialog>
      )}
    </>
  );
}

export default ListStudySubject;
