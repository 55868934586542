const ListMetastaticSites = [
  {
    initial_data: true,
    spread_site: false,
    site: "Lung",
    date_assessment: null,
    method: null,
    other_method: "",
  },
  {
    initial_data: true,
    spread_site: false,
    site: "Liver",
    date_assessment: null,
    method: null,
    other_method: "",
  },
  {
    initial_data: true,
    spread_site: false,
    site: "Lymph Nodes",
    date_assessment: null,
    method: null,
    other_method: "",
  },
  {
    initial_data: true,
    spread_site: false,
    site: "Brain",
    date_assessment: null,
    method: null,
    other_method: "",
  },
  {
    initial_data: true,
    spread_site: false,
    site: "Bone",
    date_assessment: null,
    method: null,
    other_method: "",
  },
];

export default ListMetastaticSites;
