import { getter } from "@progress/kendo-react-common";
//Regular Expression
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[\d+-]*$/);
const NoSpaceRegex = new RegExp(/^\s*$/);
const ccardRegex = new RegExp(/^[0-9-/]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const passRegex = new RegExp(/(?!.*[\s])(?!^\d+$)^.+$/);
const websiteRegex = new RegExp(
  /^(www.)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
);
const LettersRegex = new RegExp(/^[a-zA-Z]*$/);
const DigitRegex = new RegExp(/^[0-9]*$/);
const BloodPressureRegex = new RegExp(/^\d{1,3}\/\d{1,3}$/);

export const requiredMax255Validator = (value) =>
  !value
    ? "Field is required"
    : value.length > 255
    ? "Field should be max 255 characters long."
    : NoSpaceRegex.test(value)
    ? "Field is required"
    : "";
export const bloodpressureValidator = (value) =>
  !value
    ? "Field is required"
    : !BloodPressureRegex.test(value)
    ? "Invalid Field format"
    : "";
//BirthDateValidator
export const BirthDateValidator = (value) =>
  !value
    ? "Field is required"
    : new Date() - value > 568024668000
    ? ""
    : "age should be above 18 years";

//Strings : Max 20 characters Long && required
export const requiredMaxSubject20Validator = (value) =>
  !value
    ? "Field is required"
    : value.length > 20
    ? "Field should be max 20 characters long."
    : NoSpaceRegex.test(value)
    ? "Field is required"
    : !DigitRegex.test(value)
    ? "Field should be contain only digits"
    : "";

//Strings : Max 1 characters Long && required
export const requiredMax1Validator = (value) =>
  !value
    ? "Field is required"
    : value.length > 1
    ? "Field should be max 1 characters long."
    : NoSpaceRegex.test(value)
    ? "Field is required"
    : !LettersRegex.test(value)
    ? "Field should be a letter"
    : "";

//Strings : Max 50 characters Long && required
export const requiredMax50Validator = (value) =>
  !value
    ? "Field is required"
    : value.length > 50
    ? "Field should be max 50 characters long."
    : NoSpaceRegex.test(value)
    ? "Field is required"
    : "";

//Strings : Max 20 characters Long && required
export const requiredMax20Validator = (value) =>
  !value
    ? "Field is required"
    : value.length > 20
    ? "Field should be max 20 characters long."
    : NoSpaceRegex.test(value)
    ? "Field is required"
    : "";

//String : Max 50 characters Long && not required
export const Max50Validator = (value) =>
  !value
    ? ""
    : value.length > 50
    ? "Field should be max 50 characters long."
    : NoSpaceRegex.test(value)
    ? "Field is required"
    : "";

//String : Max 20 characters Long && not required
export const Max20Validator = (value) =>
  !value
    ? ""
    : value.length > 20
    ? "Field should be max 20 characters long."
    : NoSpaceRegex.test(value)
    ? "Field is required"
    : "";

//email validator
export const emailValidator = (value) =>
  !value
    ? "Field is required"
    : emailRegex.test(value)
    ? ""
    : "Field is not in a valid format.";

//phone validator
export const phoneValidator = (value) =>
  !value
    ? "Field is required"
    : value.length > 20
    ? "Field should be max 20 characters long."
    : phoneRegex.test(value)
    ? ""
    : "Field is not in a valid format.";

//mobile validator
export const mobileValidator = (value) =>
  !value
    ? ""
    : value.length > 20
    ? "Field should be max 20 characters long."
    : phoneRegex.test(value)
    ? ""
    : "Field is not in a valid format.";

//website validator
export const websiteValidator = (value) =>
  !value
    ? ""
    : value.length > 50
    ? "Field should be max 50 characters long."
    : websiteRegex.test(value)
    ? ""
    : "Field is not in a valid format";

export const passwordValidator = (value) =>
  !value
    ? "Password is required."
    : value && value.length < 8
    ? "Field must be min 8 characters long."
    : passRegex.test(value)
    ? ""
    : "Field can't be entirely numeric or includes spaces";

export const requiredValidator = (value) => (value ? "" : "Field is required.");
export const numberValidator = (value) =>
  !value && value !== 0 ? "Field is required." : "";
const userNameGetter = getter("username");
const emailGetter = getter("email");

export const formValidator = (values) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: "Please fill in the following fields.",
    ["username"]: !userName ? "User Name is required." : "",
    ["email"]:
      emailValue && emailRegex.test(emailValue)
        ? ""
        : "Email is required and should be in a valid format.",
  };
};
