//utilities
import React, { useEffect, useRef } from "react";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Loader } from "@progress/kendo-react-indicators";
//access
import { useHistory } from "react-router-dom";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//kendo component
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

export function ScreeningPopup(props) {
  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          section_title: props.section_title,
        },
      })
      .then((res) => res.data);
  const { data: sections, error } = useSWR(
    [APIURL + "/common/listsection/", props.section_title],
    fetcher1
  );

  //localization
  const localizationService = useLocalization();

  //access
  const history = useHistory();

  //if the user click outside the component , the pop up will close

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        let element = document.getElementsByClassName(`screening-popup`)[0];
        let id = document.getElementById(`screening${props.data.crf_id}`)["id"];

        if (
          ref.current &&
          id &&
          !ref.current.contains(event.target) &&
          event.srcElement.id !== id
        ) {
          props.parentCallback();
        }
      }

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const ActionTemplate = (dataItem) => {
    let sectionName = dataItem.dataItem.section_name
      .replace(/\s/g, "")
      .replace("/", "")
      .toLowerCase();

    return (
      <td>
        <i
          className="k-icon k-i-track-changes-enable DocumentActionButtons"
          onClick={() =>
            history.push({
              pathname: `/crf/screening/${sectionName}/add`,

              state: { data: props.data, section: dataItem.dataItem },
            })
          }
        ></i>
      </td>
    );
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (error)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <>
      <div ref={wrapperRef}>
        {!sections && loadingPanel}
        <Grid data={sections}>
          <GridToolbar>
            <div className="col pages-medium-title">
              <strong>
                {localizationService.toLanguageString("custom.subject")}
                {" : "}
                {props.data.crf_id}
              </strong>
            </div>
          </GridToolbar>
          <GridToolbar>
            <div className="col pages-medium-title">
              <strong>
                {localizationService.toLanguageString("custom.section")}
                {" : "}
                {props.section_title}
              </strong>
            </div>
          </GridToolbar>
          <Column
            title={localizationService.toLanguageString("custom.crfname")}
            field={"section_name"}
          ></Column>
          <Column
            title={localizationService.toLanguageString("custom.actions")}
            cell={ActionTemplate}
          ></Column>
        </Grid>
      </div>
    </>
  );
}
export default ScreeningPopup;
