const TherapeuticAreas = [
  { value: "Bone/Osteoporosis", name: "Bone/Osteoporosis" },
  { value: "Cardiovascular", name: "Cardiovascular" },
  { value: "CNS", name: "CNS" },
  { value: "Dermatology", name: "Dermatology" },
  { value: "Diabetes", name: "Diabetes" },
  { value: "Endocrinology", name: "Endocrinology" },
  { value: "ENT", name: "ENT" },
  { value: "Gastroenterology", name: "Gastroenterology" },
  { value: "Haematology", name: "Haematology" },
  { value: "Immunology", name: "Immunology" },
  { value: "Infectious diseases", name: "Infectious diseases" },
  { value: "Inflammation", name: "Inflammation" },
  { value: "Internal diseases", name: "Internal diseases" },
  { value: "Metabolic diseases", name: "Metabolic diseases" },
  { value: "Nephrology", name: "Nephrology" },
  { value: "Neurology", name: "Neurology" },
  { value: "Oncology", name: "Oncology" },
  { value: "Ophthalmology", name: "Ophthalmology" },
  { value: "Orthopaedics", name: "Orthopaedics" },
  { value: "Pulmonology", name: "Pulmonology" },
  { value: "Psychiatry", name: "Psychiatry" },
  { value: "Respiratory", name: "Respiratory" },
  { value: "Rheumatology", name: "Rheumatology" },
  { value: "Surgery", name: "Surgery" },
  { value: "Urology", name: "Urology" },
  { value: "Vaccines", name: "Vaccines" },
  { value: "Thrombosis", name: "Thrombosis" },
  { value: "Other", name: "Other" },
];

export default TherapeuticAreas;
