//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import moment from "moment";

//localization
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useLocalization } from "@progress/kendo-react-intl";
import {
  RequiredFormDatePicker,
  FormCheckbox,
  RequiredDropDownList,
  RequiredFormInput,
  RequiredFormTextArea,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax255Validator,
} from "../common/Kendo/validators";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

//component
import DisconinuationReasons from "../common/commoncomponents/DiscontinuationReasons";
import PatientStatuses from "../common/commoncomponents/PatientStatuses";
import TreatmentResponses from "../common/commoncomponents/TreatmentResponses";
export function ResponseTreatment(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();

  //variable
  const [AllData, setAllData] = useState([]);
  const [ErrorMessage, setErrorMessage] = useState("");

  let list = [2, 3, 4, 5, 7];
  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.subject.id,
          section: props.section.id,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    [
      APIURL + "/opera/treatment/addtreatmentresponse/",
      props.subject.id,
      props.section.id,
    ],
    fetcher1
  );

  console.log("datadatadata", data);

  useEffect(() => {
    if (data) {
      if (data.message === "no data") {
        setAllData({});
      } else {
        setAllData({
          id: data.id,
          created_by: data.created_by,
          evaluated_response: data.evaluated_response,
          response: data.response
            ? { name: data.response, value: data.response }
            : null,
          other_response: data.other_response,
          progression_date: data.progression_date
            ? new Date(data.progression_date)
            : null,
          progression_site: data.progression_site,
          patient_status: data.patient_status
            ? { name: data.patient_status, value: data.patient_status }
            : null,
          death_date: data.death_date ? new Date(data.death_date) : null,
          last_date_drug: data.last_date_drug
            ? new Date(data.last_date_drug)
            : null,
          discont_reason: data.discont_reason ? data.discont_reason[0] : null,
          death_reason: data.death_reason,
          relevant_discont_info: data.relevant_discont_info,
          discont_date_signature: data.discont_date_signature
            ? new Date(data.discont_date_signature)
            : null,
          discont_responsable_signature: data.discont_responsable_signature
            ? data.discontresponsableSignature
            : UserDetail.id,
        });
      }
    }
  }, [data]);

  const UpdateTreatmentResponse = (dataItem) => {
    if (dataItem.isValid) {
      if (Object.keys(AllData).length !== 0) {
        axios
          .put(
            APIURL +
              "/opera/treatment/updatetreatmentresponse/" +
              AllData.id +
              "/",
            {
              section: props.section.id,
              study_subject: props.subject.id,
              created_by: AllData.created_by,
              modified_by: UserDetail.id,
              evaluated_response: dataItem.values.evaluated_response,
              response: dataItem.values.response
                ? dataItem.values.response.value
                : "",
              other_response: dataItem.values.other_response
                ? dataItem.values.other_response
                : "",
              progression_date: dataItem.values.progression_date
                ? moment(dataItem.values.progression_date).format("YYYY-MM-DD")
                : null,
              progression_site: dataItem.values.progression_site
                ? dataItem.values.progression_site
                : "",
              patient_status: dataItem.values.patient_status
                ? dataItem.values.patient_status.value
                : "",

              death_date: dataItem.values.death_date
                ? moment(dataItem.values.death_date).format("YYYY-MM-DD")
                : null,
              last_date_drug: dataItem.values.last_date_drug
                ? moment(dataItem.values.last_date_drug).format("YYYY-MM-DD")
                : null,
              discont_reason: dataItem.values.discont_reason
                ? [dataItem.values.discont_reason]
                : null,
              death_reason: dataItem.values.death_reason
                ? dataItem.values.death_reason
                : "",
              relevant_discont_info: dataItem.values.relevant_discont_info
                ? dataItem.values.relevant_discont_info
                : "",
              discont_responsable_signature: UserDetail.id,
              discont_date_signature: dataItem.values.discont_date_signature
                ? moment(dataItem.values.discont_date_signature).format(
                    "YYYY-MM-DD"
                  )
                : null,
            }
          )
          .then((res) => {
            props.onClose();
          })
          .catch((error) => {
            setErrorMessage(error.response.status);
          });
      } else {
        axios
          .post(APIURL + "/opera/treatment/addtreatmentresponse/", {
            section: props.section.id,
            study_subject: props.subject.id,
            created_by: UserDetail.id,
            evaluated_response: dataItem.values.evaluated_response,
            response: dataItem.values.response
              ? dataItem.values.response.value
              : "",
            other_response: dataItem.values.other_response
              ? dataItem.values.other_response
              : "",
            progression_date: dataItem.values.progression_date
              ? moment(dataItem.values.progression_date).format("YYYY-MM-DD")
              : null,
            progression_site: dataItem.values.progression_site
              ? dataItem.values.progression_site
              : "",
            patient_status: dataItem.values.patient_status
              ? dataItem.values.patient_status.value
              : "",

            death_date: dataItem.values.death_date
              ? moment(dataItem.values.death_date).format("YYYY-MM-DD")
              : null,
            last_date_drug: dataItem.values.last_date_drug
              ? moment(dataItem.values.last_date_drug).format("YYYY-MM-DD")
              : null,
            discont_reason: dataItem.values.discont_reason
              ? [dataItem.values.discont_reason]
              : null,
            death_reason: dataItem.values.death_reason
              ? dataItem.values.death_reason
              : "",
            relevant_discont_info: dataItem.values.relevant_discont_info
              ? dataItem.values.relevant_discont_info
              : "",
            discont_responsable_signature: UserDetail.id,
            discont_date_signature: dataItem.values.discont_date_signature
              ? moment(dataItem.values.discont_date_signature).format(
                  "YYYY-MM-DD"
                )
              : null,
          })
          .then((res) => {
            props.onClose();
          })
          .catch((error) => {
            setErrorMessage(
              error.response.status +
                " " +
                localizationService.toLanguageString("custom.errornosave")
            );
          });
      }
    }
  };

  const handleEvaluationStatusChange = (e, formRenderProps) => {
    formRenderProps.onChange("response", { value: "" });
    formRenderProps.onChange("other_response", { value: "" });
    formRenderProps.onChange("progression_date", { value: null });
    formRenderProps.onChange("progression_site", { value: "" });
    formRenderProps.onChange("patient_status", { value: "" });
    formRenderProps.onChange("death_date", { value: null });
    formRenderProps.onChange("last_date_drug", { value: null });
    formRenderProps.onChange("discont_reason", { value: "" });
    formRenderProps.onChange("death_reason", { value: "" });
    formRenderProps.onChange("relevant_discont_info", { value: "" });
    formRenderProps.onChange("discont_date_signature", { value: null });
  };

  const handlePatientStatusChange = (e, formRenderProps) => {
    formRenderProps.onChange("death_date", { value: null });
    formRenderProps.onChange("last_date_drug", { value: null });
    formRenderProps.onChange("discont_reason", { value: "" });
    formRenderProps.onChange("death_reason", { value: "" });
    formRenderProps.onChange("relevant_discont_info", { value: "" });
    formRenderProps.onChange("discont_date_signature", { value: null });
  };

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && props.approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (props.approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || props.approved) {
      boolean = true;
    }
    return boolean;
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <>
      {!data && loadingPanel}
      <Tooltip
        openDelay={0}
        anchorElement="target"
        parentTitle={true}
        position={"left"}
      >
        <Form
          initialValues={{
            evaluated_response:
              Object.keys(AllData).length !== 0
                ? AllData.evaluated_response
                : false,

            response:
              Object.keys(AllData).length !== 0 ? AllData.response : null,
            other_response:
              Object.keys(AllData).length !== 0 ? AllData.other_response : "",
            progression_date:
              Object.keys(AllData).length !== 0
                ? AllData.progression_date
                : null,
            progression_site:
              Object.keys(AllData).length !== 0 ? AllData.progression_site : "",
            patient_status:
              Object.keys(AllData).length !== 0 ? AllData.patient_status : null,
            death_date:
              Object.keys(AllData).length !== 0 ? AllData.death_date : null,
            last_date_drug:
              Object.keys(AllData).length !== 0 ? AllData.last_date_drug : null,
            discont_reason:
              Object.keys(AllData).length !== 0 ? AllData.discont_reason : null,
            death_reason:
              Object.keys(AllData).length !== 0 ? AllData.death_reason : null,
            relevant_discont_info:
              Object.keys(AllData).length !== 0
                ? AllData.relevant_discont_info
                : null,

            discont_date_signature:
              Object.keys(AllData).length !== 0
                ? AllData.discont_date_signature
                : null,
          }}
          onSubmitClick={UpdateTreatmentResponse}
          key={JSON.stringify(AllData)}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                <div className="row">
                  <div className="col-12 pages-medium-title">
                    <strong>
                      {" "}
                      {localizationService.toLanguageString("custom.subject")}
                      {" : "}
                      {props.subject.crf_id}
                    </strong>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <Field
                      id={"evaluated_response"}
                      name={"evaluated_response"}
                      component={FormCheckbox}
                      label={localizationService.toLanguageString(
                        "custom.evaluatedresponse"
                      )}
                      onChange={(e) => {
                        handleEvaluationStatusChange(e, formRenderProps);
                      }}
                    />
                  </div>
                </div>

                {formRenderProps.valueGetter("evaluated_response") === true ? (
                  <>
                    {" "}
                    <div className="row mt-2">
                      <div className="col-4">
                        <Field
                          id={"response"}
                          name={"response"}
                          label={localizationService.toLanguageString(
                            "custom.response"
                          )}
                          data={TreatmentResponses}
                          component={RequiredDropDownList}
                          validator={requiredValidator}
                          onChange={() => {
                            formRenderProps.onChange("other_response", {
                              value: "",
                            });
                            formRenderProps.onChange("progression_date", {
                              value: null,
                            });
                            formRenderProps.onChange("pprogression_site", {
                              value: "",
                            });
                          }}
                        />
                      </div>
                      {formRenderProps.valueGetter("response") &&
                      formRenderProps.valueGetter("response").value ===
                        "Dissociated Response" ? (
                        <>
                          <div className="col-4">
                            <Field
                              id={"other_response"}
                              name={"other_response"}
                              label={localizationService.toLanguageString(
                                "custom.specify"
                              )}
                              component={RequiredFormInput}
                              validator={requiredMax255Validator}
                            />
                          </div>
                        </>
                      ) : formRenderProps.valueGetter("response") &&
                        (formRenderProps.valueGetter("response").value ===
                          "PD - Progressive Disease" ||
                          formRenderProps.valueGetter("response").value ===
                            "RD – Recurrent Disease") ? (
                        <>
                          <div className="col-4">
                            <Field
                              id={"progression_date"}
                              name={"progression_date"}
                              label={localizationService.toLanguageString(
                                "custom.date"
                              )}
                              component={RequiredFormDatePicker}
                              validator={requiredValidator}
                            />
                          </div>

                          <div className="col-4">
                            <Field
                              id={"progression_site"}
                              name={"progression_site"}
                              label={localizationService.toLanguageString(
                                "custom.site"
                              )}
                              component={RequiredFormInput}
                              validator={requiredMax255Validator}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <Field
                          id={"patient_status"}
                          name={"patient_status"}
                          label={localizationService.toLanguageString(
                            "custom.patientstatus"
                          )}
                          data={PatientStatuses}
                          component={RequiredDropDownList}
                          validator={requiredValidator}
                          onChange={(e) =>
                            handlePatientStatusChange(e, formRenderProps)
                          }
                        />
                      </div>
                    </div>
                    {formRenderProps.valueGetter("patient_status") &&
                    formRenderProps.valueGetter("patient_status").value ===
                      "Dead" ? (
                      <>
                        <div className="row">
                          <div className="col-6">
                            <Field
                              id={"death_date"}
                              name={"death_date"}
                              label={localizationService.toLanguageString(
                                "custom.deathdate"
                              )}
                              component={RequiredFormDatePicker}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="col-6">
                            <Field
                              id={"death_reason"}
                              name={"death_reason"}
                              label={localizationService.toLanguageString(
                                "custom.deathreason"
                              )}
                              component={RequiredFormInput}
                              validator={requiredValidator}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {formRenderProps.valueGetter("patient_status") &&
                    (formRenderProps.valueGetter("patient_status").value ===
                      "Dead" ||
                      formRenderProps.valueGetter("patient_status").value ===
                        "Lost to follow-up") ? (
                      <>
                        <div className="row">
                          <div className="col-6">
                            <Field
                              id={"discont_reason"}
                              name={"discont_reason"}
                              label={localizationService.toLanguageString(
                                "custom.discontreason"
                              )}
                              data={DisconinuationReasons}
                              component={RequiredDropDownList}
                              validator={requiredValidator}
                              onChange={() =>
                                formRenderProps.onChange(
                                  "relevant_discont_info",
                                  { value: "" }
                                )
                              }
                            />
                          </div>
                          <div className="col-6">
                            <Field
                              id={"last_date_drug"}
                              name={"last_date_drug"}
                              label={localizationService.toLanguageString(
                                "custom.lastdrugdate"
                              )}
                              component={RequiredFormDatePicker}
                              validator={requiredValidator}
                            />
                          </div>
                        </div>
                        <div className="row">
                          {formRenderProps.valueGetter("discont_reason") &&
                          list.includes(
                            formRenderProps.valueGetter("discont_reason").value
                          ) ? (
                            <Field
                              id={"relevant_discont_info"}
                              name={"relevant_discont_info"}
                              label={localizationService.toLanguageString(
                                "custom.relevantreason"
                              )}
                              component={RequiredFormTextArea}
                              validator={requiredValidator}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="row mt-2">
                          <div className="col-6">
                            <Field
                              id={"discont_date_signature"}
                              name={"discont_date_signature"}
                              label={localizationService.toLanguageString(
                                "custom.signaturedate"
                              )}
                              component={RequiredFormDatePicker}
                              validator={requiredValidator}
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="row">
                            <div className="col-4">
                              <strong>
                                {localizationService.toLanguageString(
                                  "custom.signature"
                                )}
                              </strong>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              {Object.keys(AllData).length !== 0
                                ? AllData.discont_responsable_signature
                                : UserDetail.id}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col">
                    <span className="k-form-error">{ErrorMessage}</span>
                  </div>
                </div>
              </fieldset>
              <div className="float-right">
                <span title={disabletitle()}>
                  <Button
                    className="k-button mr-2em"
                    primary={true}
                    disabled={disablefunction()}
                  >
                    {localizationService.toLanguageString("custom.save")}
                  </Button>
                </span>
                <Button
                  className="k-button mr-2em"
                  onClick={props.onClose}
                  type="button"
                >
                  {localizationService.toLanguageString("custom.cancel")}
                </Button>
              </div>
            </FormElement>
          )}
        />
      </Tooltip>
    </>
  );
}

export default ResponseTreatment;
