const TreatmentResponses = [
  {
    value: "CR - Complete Response",
    name: "CR - Complete Response",
  },
  {
    value: "PR - Partial Response",
    name: "PR - Partial Response",
  },
  {
    value: "SD - Stable Disease",
    name: "SD - Stable Disease",
  },
  {
    value: "PD - Progressive Disease",
    name: "PD - Progressive Disease",
  },
  {
    value: "RD – Recurrent Disease",
    name: "RD – Recurrent Disease",
  },
  {
    value: "Dissociated Response",
    name: "Dissociated Response",
  },
  {
    value: "UE - Unable to Evaluate",
    name: "UE - Unable to Evaluate",
  },
];

export default TreatmentResponses;
