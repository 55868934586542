const ClearCellType = [
  {
    value: "Chromophobie",
    name: "Chromophobie",
  },
  {
    value: "Sarcomatoid",
    name: "Sarcomatoid",
  },
  {
    value: "Other, specify",
    name: "Other, specify",
  },
];

export default ClearCellType;
