const Nationalities = [
  { value: "Egyptian", name: "Egyptian" },
  { value: "Jordanian", name: "Jordanian" },
  { value: "Kuwaiti", name: "Kuwaiti" },
  { value: "Lebanese", name: "Lebanese" },
  { value: "Saudi Arabian", name: "Saudi Arabian" },
  { value: "Emirati", name: "Emirati" },
  { value: "Other", name: "Other" },
];

export default Nationalities;
