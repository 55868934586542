const Genders = [
  {
    value: "Female",
    name: "Female",
  },
  {
    value: "Male",
    name: "Male",
  },
];

export default Genders;
