//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  RequiredFormDatePicker,
  FormCheckbox,
  RequiredFormTextArea,
  RequiredFormNumericTextBox,
  RequiredFormInput,
  FormDropDownList,
  RequiredDropDownList,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax50Validator,
} from "../common/Kendo/validators";

//component
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
import ListCurrentTherapy from "../baseline/ListCurrentTherapy";
import BooleanData from "../common/commoncomponents/BooleanData";
import TrialMap from "../studysubject/TrialMap";
import TabChange from "../common/commoncomponents/TabChange";
import AddFile from "../common/commoncomponents/AddFile";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import ResponseTreatment from "./ResponseTreatment";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import AdverseEvent from "./AdverseEvent";
import HematologyTest from "../laboratory/HematologyTest";
import BloodTest from "../laboratory/BloodTest";
import OtherTest from "../laboratory/OtherTest";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";

export function ListTreatment(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);
  const[ dataForm , setDataForm ] = useState({})
  //localization
  const localizationService = useLocalization();

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  const [approvedformchanged, setapprovedformchanged] = useState(false);

  const [approved, setApproved] = useState(false);

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };
  const refreshComponent = () => {
    setRefresh(!refresh)
  };


  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setTestSelected(value);
    setOtherTestModified(false);
    setapprovedformchanged(false);
    setHematologyFormModified(false);
    setBloodTestFormModified(false);
    setAdverseEventModified(false);
  };

  //Current therapy Reason Dialog
  const [VCurrentTherapy, setVCurrentTherapy] = useState(false);

  const toggleDCurrentTherapy = () => {
    setVCurrentTherapy(!VCurrentTherapy);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  //dialog for adding and updating and deleting notes
  const [VaddResponse, setVaddResponse] = useState(false);

  const toggleDaddResponse = () => {
    setVaddResponse(!VaddResponse);
  };

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
          refresh: refresh,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listlaboratoryvalues/",
          props.location.state.data.id,
          props.location.state.section.id,
           refresh,
        ]
      : null,
    fetcher1
  );
  //data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
          refresh: refresh,
        },
      })
      .then((res) => res.data);
  const { data: continuity, Continuityerror } = useSWR(
    props.location.state
      ? [
          APIURL + "/opera/treatment/addtreatmentcontinuity/",
          props.location.state.data.id,
          props.location.state.section.id,
          refresh
        ]
      : null,
    fetcher2
  );
console.log("continuitycontinuity",continuity,Continuityerror)
  // //check if the there is a current treatment for this section
  // const { data: checkcurrent, Currenterror } = useSWR(
  //   props.location.state
  //     ? [
  //         APIURL + "/opera/treatment/checkcurrent/",
  //         props.location.state.data.id,
  //         props.location.state.section.id,
  //       ]
  //     : null,
  //   fetcher2
  // );

  //notes data
  const fetcher3 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
          refresh: refresh,
        },
      })
      .then((res) => res.data);

  const { data: notes, Noteserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
          refresh,
        ]
      : null,
    fetcher3
  );

  //attachement data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
          refresh : refresh,
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attchementserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
          refresh
        ]
      : null,
    fetcher4
  );
  //check approve
  const { data: checkapprove, checkapproveerror } = useSWR(
    props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
          refresh,
        ]
      : null,
    fetcher4
  );

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                EditSubject ? toggleDeditNote() : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  //variable
  const [CurrentTherapy_saved, setCurrentTherapy_saved] = useState(false);
  const [check_current, setcheck_current] = useState(false);
  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //form ref to detect modification of the form
  const [formvalues, setformvalues] = useState();
  const [HematologyFormModified, setHematologyFormModified] = useState(false);
  const [BloodTestFormModified, setBloodTestFormModified] = useState(false);
  const [OtherTestModified, setOtherTestModified] = useState(false);
  const [AdverseEventModified, setAdverseEventModified] = useState(false);
  const [AllData, setAllData] = useState(null);
  const [ContinuityData, setContinuityData] = useState(null);
  const [ModifiedData, setModifiedData] = useState();
  

  useEffect(() => {
    let array = [];
    let array2 = [];

    if (data) {
      if (data.message === "no data") {
        setAllData(null);
      } else {
        if (data.laboratorytest.HematologyTests) {
          data.laboratorytest.HematologyTests.map((d) => {
            array.push({
              id: d.id,
              section: d.section,
              study_subject: d.study_subject,
              test_present: d.test_present,
              notpresent_reason: d.notpresent_reason,
              test_name: d.test_name,
              result: d.result,
              unit: d.unit,
              lower_limit: d.lower_limit,
              upper_limit: d.upper_limit,
              normal: d.normal,
            });
          });
        }
        if (data.laboratorytest.BloodChemistryTests) {
          data.laboratorytest.BloodChemistryTests.map((d) => {
            array2.push({
              id: d.id,
              study_subject: d.study_subject,
              section: d.section,
              test_present: d.test_present,
              notpresent_reason: d.notpresent_reason,
              test_name: d.test_name,
              result: d.result,
              unit: d.unit,
              lower_limit: d.lower_limit,
              upper_limit: d.upper_limit,
              normal: d.normal,
            });
          });
        }
        setAllData({
          testdata: data.laboratorytest,
          hemotology_tests: array,
          blood_chemistry_tests: array2,
        });
      }
    }

    if (continuity) {
      if (continuity.message === "no data") {
        setContinuityData(null);
        setcheck_current(continuity.check_current);
      } else {
        setContinuityData(continuity.data);
        setcheck_current(continuity.check_current);
      }
    }

    if (checkapprove) {
      setApproved(checkapprove);
    }
  }, [data, continuity, checkapprove]);

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  //callbacks
  const HematologyCallBack = (value) => {
    if (value.ref) {
      if (Object.keys(value.ref.modified).length !== 0) {
        setHematologyFormModified(true);
      } else {
        setHematologyFormModified(false);
      }
    }
  };

  const BloodTestCallBack = (value) => {
    if (value.ref) {
      if (Object.keys(value.ref.modified).length !== 0) {
        setBloodTestFormModified(true);
      } else {
        setBloodTestFormModified(false);
      }
    }
  };

  const OtherTestCallBack = (value) => {
    if (value.ref) {
      if (Object.keys(value.ref.modified).length !== 0) {
        setOtherTestModified(true);
      } else {
        setOtherTestModified(false);
      }
    }
  };

  const AdverseEventCallBack = (value) => {
    if (value.ref) {
      if (Object.keys(value.ref.modified).length !== 0) {
        setAdverseEventModified(true);
      } else {
        setAdverseEventModified(false);
      }
    }
  };

  //TAB STRIP For Details
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  //TAB STRIP FOR TEST

  const [testselected, setTestSelected] = React.useState(0);

  const handleTestSelect = (e) => {
    //if we left other test tab
    if (testselected === 2) {
      if (OtherTestModified) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    } //hematology
    else if (testselected === 0) {
      if (HematologyFormModified) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    }
    //blood
    else if (testselected === 1) {
      if (BloodTestFormModified) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    }
    //adverse
    else if (testselected === 3) {
      if (AdverseEventModified) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    } else if (testselected === 4) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setTestSelected(e.selected);
      }
    }
    //other
    else {
      setTestSelected(e.selected);
    }
  };

  const CurrentTherapyCallback = () => {
    setCurrentTherapy_saved(true);
    axios
    .post(APIURL + "/opera/treatment/addtreatmentcontinuity/", {
      created_by: UserDetail.id,
      section: props.location.state.section.id,
      study_subject: props.location.state.data.id,
      subject_continuity:
        dataForm.values.subject_continuity.value === "yes"
          ? true
          : false,
      dose_change: dataForm.values.dose_change
        ? dataForm.values.dose_change.value === "yes"
          ? true
          : false
        : false,
      stop_period:
        dataForm.values.stop_period.value === "yes" ? true : false,
    })
    .then((res) => {
      if (res.data.message === "success") {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      }
    })
    .catch((error) => {
      setErrorNotification(error.response.status);
      onToggle("nosave");
      setTimeout(() => {
        setState({ ...state, nosave: false });
        setLoading(false);
      }, GlobalNotificationTimer);
    });
  };
  //SWR ESSENTIAL
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (
    error &&
    Continuityerror &&
    Noteserror &&
    Attchementserror &&
    checkapproveerror
  )
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  const checkInputs = (dataItem) => {
    let boolean = false;
    if (check_current === false) {
      if (dataItem.subject_continuity.value === "yes") {
        if (dataItem.dose_change.value === "yes") {
          boolean = true;
        } else if (dataItem.stop_period.value === "yes") {
          boolean = true;
        }
      } else if (dataItem.subject_continuity.value === "no") {
        boolean = true;
      }
    }

    return boolean;
  };

  const CreateSubjectContinuity = (dataItem) => {
    setDataForm(dataItem)
    if (dataItem.isValid) {
      if (
        
        checkInputs(dataItem.values) === true &&
        CurrentTherapy_saved === false
      ) {

        toggleDCurrentTherapy();
      } else {
        if (ContinuityData) {

          if (dataItem.isModified) {
            setModifiedData(dataItem.values);
            toggleDModifyreason();
          } else {

            onToggle("nomodify");
            setLoading(false);
            setTimeout(() => {
              setState({ ...state, nomodify: false });
            }, GlobalNotificationTimer);
          }
        } else {

          setLoading(true);
          axios
            .post(APIURL + "/opera/treatment/addtreatmentcontinuity/", {
              created_by: UserDetail.id,
              section: props.location.state.section.id,
              study_subject: props.location.state.data.id,
              subject_continuity:
                dataItem.values.subject_continuity.value === "yes"
                  ? true
                  : false,
              dose_change: dataItem.values.dose_change
                ? dataItem.values.dose_change.value === "yes"
                  ? true
                  : false
                : false,
              stop_period:
                dataItem.values.stop_period.value === "yes" ? true : false,
            })
            .then((res) => {
              if (res.data.message === "success") {
                setRefresh(!refresh)
                onToggle("success");
                setTimeout(() => {
                  setState({ ...state, success: false });
                  setLoading(false);
                }, GlobalNotificationTimer);
              }
            })
            .catch((error) => {
              setErrorNotification(error.response.status);
              onToggle("nosave");
              setTimeout(() => {
                setState({ ...state, nosave: false });
                setLoading(false);
              }, GlobalNotificationTimer);
            });
        }
      }
    }
  };

  const CreateModification = (dataItem) => {
    toggleDModifyreason(false);
    setLoading(true);
    axios
      .put(
        APIURL +
          "/opera/treatment/updatetreatmentcontinuity/" +
          ContinuityData.id +
          "/",
        {
          created_by: ContinuityData.created_by,
          modified_by: UserDetail.id,
          section: props.location.state.section.id,
          study_subject: props.location.state.data.id,
          subject_continuity:
            ModifiedData.subject_continuity.value === "yes" ? true : false,
          dose_change: ModifiedData.dose_change
            ? ModifiedData.dose_change.value === "yes"
              ? true
              : false
            : false,
          stop_period: ModifiedData.stop_period.value === "yes" ? true : false,
          modification_reason: dataItem.modification_reason,
        }
      )
      .then((res) => {
        if (res.data.message === "success") {
          onToggle("success");
          setTimeout(() => {
            setState({ ...state, success: false });
            setLoading(false);
          }, GlobalNotificationTimer);
        }
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      });
  };

  const handleSubjectContinuityChange = (e, formRenderProps) => {
    setformvalues(MyForm.current.values);
  };

  const handleDoseChange = (e, formRenderProps) => {
    setformvalues(MyForm.current.values);
  };
  const disabletitle = () => {
    let title = "";
    if (!EditSubject && approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || approved) {
      boolean = true;
    }
    return boolean;
  };

  return (
    <TrialMap condition={props.location ? "state" : "dialog"}>
      {(!data || !notes || !continuity || !attachements) && loadingPanel}
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader className="DetailFormHeader">
              <div className="row">
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.subjectid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString(
                    "custom.subjectinitials"
                  )}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.study")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.protocolid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.sitenumber")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.createdby")}
                </div>
              </div>
              <div className="row">
                <div className="col text-responsive-overflow subject-id-style">
                  {props.location.state ? props.location.state.data.crf_id : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_initial
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_name
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_protocol
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_center
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.createdBy
                    : ""}
                </div>
              </div>
              <hr className="RowRoleHr" />
              <div className="row">
                <div className="col pages-big-title">
                  <strong>
                    {props.location.state
                      ? props.location.state.section.section_name
                      : ""}
                  </strong>
                </div>
                <div className="col">
                  <div className="float-right">
                    <Button
                      primary={true}
                      onClick={() => {
                        toggleDaddResponse();
                      }}
                      className="mr-2em"
                    >
                      {localizationService.toLanguageString(
                        "custom.addresponsetotreatment"
                      )}
                    </Button>
                    <Button onClick={goBack} className="back-button-style">
                      {localizationService.toLanguageString("custom.back")}
                    </Button>
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <Tooltip
            openDelay={0}
            anchorElement="target"
            parentTitle={true}
            position={"left"}
          >
            <Card>
              <Form
                initialValues={{
                  subject_continuity: ContinuityData
                    ? ContinuityData.subject_continuity === true
                      ? { name: "yes", value: "yes" }
                      : { name: "no", value: "no" }
                    : null,

                  dose_change: ContinuityData
                    ? ContinuityData.dose_change === true
                      ? { name: "yes", value: "yes" }
                      : { name: "no", value: "no" }
                    : null,
                  stop_period: ContinuityData
                    ? ContinuityData.stop_period === true
                      ? { name: "yes", value: "yes" }
                      : { name: "no", value: "no" }
                    : null,
                }}
                ref={MyForm}
                onSubmitClick={CreateSubjectContinuity}
                key={JSON.stringify(ContinuityData)}
                render={(formRenderProps) => (
                  <FormElement className="">
                    <fieldset className={"k-form-fieldset"}>
                      <Prompt
                        when={PromptFunction(formRenderProps)}
                        message={localizationService.toLanguageString(
                          "custom.promptmessage"
                        )}
                      />

                      {formRenderProps.modified && !formRenderProps.submitted && (
                        <div className={"k-messagebox k-messagebox-error"}>
                          <div className="no-leave-change">
                            {localizationService.toLanguageString(
                              "custom.formmustbesubmitted"
                            )}
                          </div>
                        </div>
                      )}

                      <div className="row ml-2">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-8 mt-4">
                              {localizationService.toLanguageString(
                                "custom.subjectcontinuity"
                              )}
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="col-2">
                              <Field
                                id={"subject_continuity"}
                                name={"subject_continuity"}
                                component={FormDropDownList}
                                data={BooleanData}
                                validator={requiredValidator}
                                onChange={(e) => {
                                  handleSubjectContinuityChange(
                                    e,
                                    formRenderProps
                                  );
                                }}
                                disabled={
                                  check_current === true ||
                                  CurrentTherapy_saved === true
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          {formRenderProps.valueGetter("subject_continuity") &&
                          formRenderProps.valueGetter("subject_continuity")
                            .value === "yes" ? (
                            <>
                              <div className="row  mt-2">
                                <div className="col-8">
                                  {localizationService.toLanguageString(
                                    "custom.dosechange"
                                  )}
                                  <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className="col-2">
                                  <Field
                                    id={"dose_change"}
                                    name={"dose_change"}
                                    component={FormDropDownList}
                                    data={BooleanData}
                                    validator={requiredValidator}
                                    onChange={(e) =>
                                      handleDoseChange(e, formRenderProps)
                                    }
                                    disabled={
                                      check_current === true ||
                                      CurrentTherapy_saved === true
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-8 mt-4">
                              {localizationService.toLanguageString(
                                "custom.subjectstop"
                              )}
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="col-2">
                              <Field
                                id={"stop_period"}
                                name={"stop_period"}
                                component={FormDropDownList}
                                data={BooleanData}
                                validator={requiredValidator}
                                disabled={
                                  check_current === true ||
                                  CurrentTherapy_saved === true
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <div className="float-right">
                      <div title={disabletitle()}>
                        <Button
                          primary={true}
                          type={"submit"}
                          className="mr-2em"
                          disabled={disablefunction()}
                        >
                          {loading
                            ? localizationService.toLanguageString(
                                "custom.loading"
                              )
                            : localizationService.toLanguageString(
                                "custom.save"
                              )}
                        </Button>
                      </div>
                    </div>
                  </FormElement>
                )}
              />
            </Card>
          </Tooltip>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <Card className="DetailEditCard">
            <CardBody>
              <TabStrip selected={testselected} onSelect={handleTestSelect}>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.hematologytest"
                  )}
                >
                  <HematologyTest
                    data={AllData}
                    subjectdata={props.location.state}
                    parentCallBack={HematologyCallBack}
                    approved={checkapprove ? checkapprove : false}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.bloodchemistrytest"
                  )}
                >
                  <BloodTest
                    data={AllData}
                    subjectdata={props.location.state}
                    parentCallBack={BloodTestCallBack}
                    approved={checkapprove ? checkapprove : false}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.othertest"
                  )}
                >
                  <OtherTest
                    data={data && data.message !== "no data" ? data : null}
                    subjectdata={props.location.state}
                    parentCallBack={OtherTestCallBack}
                    approved={checkapprove ? checkapprove : false}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.adverseevent"
                  )}
                >
                  <AdverseEvent
                    data={AllData}
                    subjectdata={props.location.state}
                    parentCallBack={AdverseEventCallBack}
                    approved={checkapprove ? checkapprove : false}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.approvesection"
                  )}
                >
                  <ApproveStudySubject
                    study_subject={props.location.state.data.id}
                    section={props.location.state.section.id}
                    data={props.location.state.data}
                    parentCallBack={ChangeApproveSection}
                    refresh={refreshComponent}
                  />
                </TabStripTab>
                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.notesnattachements"
                  )}
                >
                  <TabStrip selected={selected} onSelect={handleSelect}>
                    <TabStripTab
                      title={localizationService.toLanguageString(
                        "custom.notes"
                      )}
                    >
                      <GridTelerik data={notes}>
                        <GridToolbar>
                          <Button
                            primary={true}
                            onClick={() => {
                              {
                                EditSubject
                                  ? toggleDaddNote()
                                  : toggleDPermission();
                              }
                            }}
                          >
                            {localizationService.toLanguageString("custom.new")}
                          </Button>
                        </GridToolbar>

                        <Column
                          field={"note"}
                          title={localizationService.toLanguageString(
                            "custom.note"
                          )}
                          cell={NoteNameTemplate}
                        />
                        <Column
                          field={"date_created"}
                          title={localizationService.toLanguageString(
                            "custom.datecreated"
                          )}
                          cell={DateCreatedTemplate}
                        />
                        <Column
                          title={localizationService.toLanguageString(
                            "custom.actions"
                          )}
                          width="100px"
                          cell={Noteactions}
                        />
                      </GridTelerik>
                    </TabStripTab>

                    <TabStripTab
                      title={localizationService.toLanguageString(
                        "custom.attachements"
                      )}
                    >
                      <GridTelerik data={attachements}>
                        <GridToolbar>
                          <Button
                            primary={true}
                            onClick={() => {
                              {
                                EditSubject
                                  ? toggleDaddFile()
                                  : toggleDPermission();
                              }
                            }}
                          >
                            {localizationService.toLanguageString("custom.new")}
                          </Button>
                        </GridToolbar>
                        <Column
                          field={"title"}
                          title={localizationService.toLanguageString(
                            "custom.title"
                          )}
                        />

                        <Column
                          field={"date_created"}
                          title={localizationService.toLanguageString(
                            "custom.dateadded"
                          )}
                          cell={DateCreatedTemplate}
                        />

                        <Column
                          title={localizationService.toLanguageString(
                            "custom.actions"
                          )}
                          width="100px"
                          cell={Fileactions}
                        />
                      </GridTelerik>
                    </TabStripTab>
                  </TabStrip>
                </TabStripTab>
              </TabStrip>
            </CardBody>
          </Card>
        </div>
      </div>
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}
      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}
      {VaddResponse && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString(
            "custom.responsetotreatment"
          )}
          onClose={toggleDaddResponse}
        >
          <ResponseTreatment
            onClose={toggleDaddResponse}
            subject={props.location.state.data}
            section={props.location.state.section}
            approved={checkapprove ? checkapprove : false}
          />
        </Dialog>
      )}
      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}
      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
      {/* Curren therapy DIALOG */}
      {VCurrentTherapy && (
        <Dialog
          title={localizationService.toLanguageString("custom.currenttherapy")}
          width={"1500px"}
          height={"900px"}
          onClose={toggleDCurrentTherapy}
        >
          <ListCurrentTherapy
            onClose={toggleDCurrentTherapy}
            data={props.location.state.data}
            section={props.location.state.section}
            dialog={true}
            TreatmentCallBack={CurrentTherapyCallback}
          />
        </Dialog>
      )}
    </TrialMap>
  );
}

export default ListTreatment;
