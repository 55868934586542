//utilities
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import APIURL from "./APIURL";
import useSWR from "swr";
import axios from "axios";
//Kendo Component
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
//localization
import { useLocalization } from "@progress/kendo-react-intl";
//component:

import UserSideBar from "./authentication/user/UserSideBar";
import { useLocation } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { RiClipboardFill } from "react-icons/ri";
import { RiFileListFill } from "react-icons/ri";

export function AppTopBar(props) {
  //Redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const ViewUserSetting = useSelector((state) => state.ViewUserSetting);

  //data fetching

  const { data: company } = useSWR(APIURL + "/common/getcompany/");
  const [imageurl, setimageurl] = useState(null);
  //localization
  const localizationService = useLocalization();

  const CustomItem = (props) => {
    const { visible, ...others } = props;
    const arrowDir = props["data-expanded"]
      ? "k-i-arrow-chevron-down"
      : "k-i-arrow-chevron-right";
    return (
      <React.Fragment>
        {props.visible === false ? null : (
          <DrawerItem {...others}>
            <span>{props.customicon}</span>
            <span className={"k-item-text"}>{props.text}</span>
            {props["data-expanded"] !== undefined && (
              <span
                className={"k-icon" + arrowDir}
                style={{
                  position: "absolute",
                  right: 10,
                }}
              />
            )}
          </DrawerItem>
        )}
      </React.Fragment>
    );
  };

  const sideBarIconSize = "25px";
  const [drawerExpanded, setDrawerExpanded] = useState(true);
  const [items, setItems] = React.useState([
    {
      text: localizationService.toLanguageString("custom.study"),
      customicon: <RiClipboardFill size={sideBarIconSize} color={"#232B4E"} />,
      id: 1,
      selected: true,
      route: "/crf/study/list",
    },
    {
      separator: true,
    },

    {
      text: localizationService.toLanguageString("custom.studysubject"),

      customicon: <FaUserAlt size={sideBarIconSize} color={"#232B4E"} />,
      id: 2,
      route: "/crf/studysubject/list",
    },
    {
      separator: true,
    },
    {
      text: localizationService.toLanguageString("custom.logs"),

      customicon: <RiFileListFill size={sideBarIconSize} color={"#232B4E"} />,
      id: 2,
      route: "/crf/listmodification",
    },

    {
      separator: true,
    },
  ]);

  const handleClick = () => {
    setDrawerExpanded(!drawerExpanded);
  };

  const location = useLocation();

  const onSelect = (ev) => {
    if (
      location.pathname !== ev.itemTarget.props.route &&
      ev.itemTarget.props.route !== undefined
    ) {
      props.history.push(ev.itemTarget.props.route);
    }

    const currentItem = ev.itemTarget.props;
    const isParent = currentItem["data-expanded"] !== undefined;
    const nextExpanded = !currentItem["data-expanded"];
    const newData = items.map((item) => {
      const {
        selected,
        ["data-expanded"]: currentExpanded,
        id,
        ...others
      } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        selected: isCurrentItem,
        ["data-expanded"]:
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });
    setItems(newData);
  };

  const data = items.map((item) => {
    const { parentId, ...others } = item;

    if (parentId !== undefined) {
      const parent = items.find((parent) => parent.id === parentId);
      return { ...others, visible: parent["data-expanded"] };
    }

    return item;
  });

  const setSelectedItem = (pathName) => {
    let currentPath = items.find((item) => item.route === pathName);

    if (pathName === "/crf/study/add" || pathName === "/crf/study/edit") {
      return "Study";
    }

    if (pathName === "/crf/study/add" || pathName === "/crf/study/edit") {
      return "Study";
    }

    if (currentPath) {
      if (currentPath.text) {
        return currentPath.text;
      }
    } else {
      return "";
    }
  };

  let selected = setSelectedItem(props.location.pathname);

  //variable for show up of the pop up of the notification
  const anchor = useRef(null);
  const [show, setShow] = useState(false);
  const [popupAlign, setPopupAlign] = React.useState({
    horizontal: "left",
    vertical: "top",
  });

  useEffect(() => {
    //remove title from the appbar : telerik are giving title to this class
    let x = document.getElementsByClassName("k-drawer-items")[0];
    x.removeAttribute("title");
    if (company && company.data) {
      setimageurl(company.data.image);
    }
  }, [company]);

  return (
    <div>
      <AppBar className="topbar-custom">
        {" "}
        {/*  k-appbar-dark */}
        <AppBarSection>
          <Button icon="layout" look="flat" onClick={handleClick}>
            {/* <h4 className="app-topbar-title"> */}
            {/* {localizationService.toLanguageString("custom.ecrf")} */}
            {imageurl === "" || imageurl === null ? (
              ""
            ) : (
              <img src={imageurl} width="300" height="40"></img>
            )}
            {/* </h4> */}
          </Button>
        </AppBarSection>
        <AppBarSpacer />
        {/* <AppBarSection>
          <Button icon="search" className="btn-custom-topbar"></Button>
          <Input style={{ height: "27px", width: "170px" }} />
        </AppBarSection> */}
        <AppBarSection></AppBarSection>
        <AppBarSection>
          <Tooltip
            openDelay={0}
            tooltipStyle={{
              width: "100px",
              textAlign: "center",
            }}
            anchorElement="target"
            position="bottom"
          >
            <Button
              onClick={() =>
                ViewUserSetting
                  ? props.history.push("/settings")
                  : props.history.push("/nopermission")
              }
              className="btn-custom-topbar buttonsave"
            >
              <i
                title={localizationService.toLanguageString("custom.settings")}
                className="k-icon k-i-gear app-top-bar-customicons"
              ></i>
            </Button>
          </Tooltip>
          {/* <Tooltip
            openDelay={0}
            tooltipStyle={{
              width: "100px",
              textAlign: "center",
            }}
            anchorElement="target"
            position="bottom"
          >
            <HeaderTranslate />
          </Tooltip> */}
          <Tooltip
            openDelay={0}
            tooltipStyle={{
              width: "100px",
              textAlign: "center",
            }}
            anchorElement="target"
            position="bottom"
          >
            <Button
              className="btn-custom-topbar"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <i
                title={localizationService.toLanguageString(
                  "custom.userprofile"
                )}
                className="k-icon k-i-user app-top-bar-customicons"
              ></i>
            </Button>
          </Tooltip>
        </AppBarSection>
      </AppBar>

      <Drawer
        expanded={drawerExpanded}
        mode="push"
        width={200}
        items={data.map((d) => ({ ...d, selected: d.text === selected }))}
        item={CustomItem}
        onSelect={onSelect}
      >
        <DrawerContent style={{ padding: "4px" }}>
          {props.children}
        </DrawerContent>
      </Drawer>

      {/* Bootstrap Side Bar Content */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <UserSideBar />
      </div>
    </div>
  );
}

export default withRouter(AppTopBar);
