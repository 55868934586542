//utilities
import React, { useEffect, useRef } from "react";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";

//access
import { useHistory } from "react-router-dom";

//localization
import { Loader } from "@progress/kendo-react-indicators";
import { useLocalization } from "@progress/kendo-react-intl";

//kendo component
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

export function FollowupPopup(props) {
  //localization
  const localizationService = useLocalization();

  //access
  const history = useHistory();
  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          section_title: props.section_title,
        },
      })
      .then((res) => res.data);

  const { data: sections, error } = useSWR(
    [APIURL + "/common/listsection/", props.section_title],
    fetcher1
  );

  const handleRoute = (dataItem) => {
    console.log("dataItemdataItem follow", dataItem);
    let sectionName = dataItem.section_name.replace(/\s/g, "").toLowerCase();

    let sectionTitle = dataItem.title.toLowerCase();

    axios
      .get(APIURL + "/common/sectiondetection/", {
        params: {
          section: dataItem.section_name,
          subject: props.data.id,
        },
      })
      .then((res) => {
        if (res.data.message === "approve") {
          history.push({
            pathname: `/crf/${sectionTitle}/${sectionName}/add`,
            state: { data: props.data, section: dataItem },
          });
        } else {
          history.push({
            pathname: `/sectionlocked`,
            state: {
              data: props.data,
              section: dataItem,
              reason: res.data.reason,
            },
          });
        }
      });
  };

  //if the user click outside the component , the pop up will close

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        let element = document.getElementsByClassName(`followup-popup`)[0];
        let id = document.getElementById(`followup${props.data.crf_id}`)["id"];

        if (
          ref.current &&
          id &&
          !ref.current.contains(event.target) &&
          event.srcElement.id !== id
        ) {
          props.parentCallback();
        }
      }

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const ActionTemplate = (dataItem) => {
    let sectionName = dataItem.dataItem.section_name
      .replace(/\s/g, "")
      .toLowerCase();

    let sectionTitle = dataItem.dataItem.title.toLowerCase();
    return (
      <td>
        <i
          className="k-icon k-i-track-changes-enable DocumentActionButtons"
          onClick={() => handleRoute(dataItem.dataItem)}
        ></i>
      </td>
    );
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (error)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <>
      <div ref={wrapperRef}>
        {!sections && loadingPanel}
        <Grid data={sections}>
          <GridToolbar>
            <div className="col pages-medium-title">
              <strong>
                {localizationService.toLanguageString("custom.subject")}
                {" : "}
                {props.data.crf_id}
              </strong>
            </div>
          </GridToolbar>
          <GridToolbar>
            <div className="col pages-medium-title">
              <strong>
                {localizationService.toLanguageString("custom.section")}
                {" : "}
                {props.section_title}
              </strong>
            </div>
          </GridToolbar>
          <Column
            title={localizationService.toLanguageString("custom.crfname")}
            field={"section_name"}
          ></Column>
          <Column
            title={localizationService.toLanguageString("custom.actions")}
            cell={ActionTemplate}
          ></Column>
        </Grid>
      </div>
    </>
  );
}
export default FollowupPopup;
