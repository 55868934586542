const UrinaryProtein = [
  {
    value: "Negative",
    name: "Negative",
  },
  {
    value: "1+",
    name: "1+",
  },
  {
    value: "2+ *",
    name: "2+ *",
  },
  {
    value: "3+ *",
    name: "3+ *",
  },
  {
    value: "4+ * ",
    name: "4+ * ",
  },

  {
    value: "Trace",
    name: "Trace",
  },
];

export default UrinaryProtein;
