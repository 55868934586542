//UTILITIES
import { Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SWRConfig } from "swr";
import APIURL from "./APIURL";
import axios from "axios";
//Component
import Login from "./authentication/Login";
import AppRouter from "./AppRouter";

//default theme kendo css
import "moment-timezone";
import "@progress/kendo-theme-default/dist/all.css";
import "./dist_css/all.min.css";
import "./assets/styling/all.scss";

//redux
import {
  edit_subject,
  approve_subject,
  view_all_subject,
  view_subject,
  add_subject,
  add_study,
  edit_study,
  view_study,
  view_user_setting,
  add_user_setting,
  edit_user_setting,
  user_detail,
  islogged,
  receive_email,
} from "./redux/action";
import { useSelector, useDispatch } from "react-redux";

//localization
import { AppContext } from "./AppContext";

//kendo component
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
  useLocalization,
} from "@progress/kendo-react-intl";

import { countries } from "./resources/countries";

import { enMessages } from "./messages/en-US";
import { frMessages } from "./messages/fr";

import { locales } from "./resources/locales";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import frNumbers from "cldr-numbers-full/main/fr/numbers.json";
import frLocalCurrency from "cldr-numbers-full/main/fr/currencies.json";
import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";
import usNumbers from "cldr-numbers-full/main/en/numbers.json";
import usLocalCurrency from "cldr-numbers-full/main/en/currencies.json";
import usCaGregorian from "cldr-dates-full/main/en/ca-gregorian.json";
import usDateFields from "cldr-dates-full/main/en/dateFields.json";
import esNumbers from "cldr-numbers-full/main/es/numbers.json";
import esLocalCurrency from "cldr-numbers-full/main/es/currencies.json";
import esCaGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import esDateFields from "cldr-dates-full/main/es/dateFields.json";

//localization
load(
  likelySubtags,
  currencyData,
  weekData,
  frNumbers,
  frLocalCurrency,
  frCaGregorian,
  frDateFields,
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
  esNumbers,
  esLocalCurrency,
  esCaGregorian,
  esDateFields
);
loadMessages(frMessages, "fr");
loadMessages(enMessages, "en-US");

function App() {
  //localization
  const localizationService = useLocalization();
  const UserDetail = useSelector((state) => state.UserDetail);
  const [test, setTest] = useState(false);
  const { localeId } = React.useContext(AppContext);
  const currentLanguage = locales.find((item) => item.localeId === localeId);
  const [contextState, setContextState] = React.useState({
    localeId: "en-US",
    firstName: "Peter",
    lastName: "Douglas",
    middleName: "",
    email: "peter.douglas@progress.com",
    phoneNumber: "(+1) 8373-837-93-02",
    avatar: null,
    country: countries[33].name,
    isInPublicDirectory: true,
    biography: "",
    teamId: 1,
  });

  const onLanguageChange = React.useCallback(
    (event) => {
      setContextState({ ...contextState, localeId: event.item.localeId });
    },
    [contextState, setContextState]
  );

  //redux
  const isLogged = useSelector((state) => state.isLogged);
  const dispatch = useDispatch();

  if (sessionStorage.getItem("reloaded") != null) {
  } else {
    dispatch(islogged(false));

    dispatch(add_subject(false));
    dispatch(edit_subject(false));
    dispatch(approve_subject(false));
    dispatch(view_subject(false));
    dispatch(view_all_subject(false));
    dispatch(view_study(false));
    dispatch(add_study(false));
    dispatch(edit_study(false));
    dispatch(view_user_setting(false));
    dispatch(add_user_setting(false));
    dispatch(edit_user_setting(false));

    dispatch(receive_email(false));
    dispatch(
      user_detail({
        id: "",
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        role: "",
        sub_investigator_fn: "",
        sub_investigator_ln: "",
        is_assistant: false,
        is_active: "",
        role_id: "",
        country: "",
        center: "",
        study_id: "",
        study_name: "",
      })
    );
  }

  sessionStorage.setItem("reloaded", "yes");

  return (
    <div>
      <SWRConfig
        value={{
          refreshInterval: 50000,
          // refreshInterval: 500,
          fetcher: (resource, init) =>
            fetch(resource, init).then((res) => res.json()),
        }}
      >
        <LocalizationProvider language={contextState.localeId}>
          <IntlProvider locale={contextState.localeId}>
            <AppContext.Provider value={{ ...contextState, onLanguageChange }}>
              {isLogged ? "" : <Route path="" component={Login} />}
              {isLogged ? (
                <>
                  <div>
                    <AppRouter />
                  </div>
                </>
              ) : (
                ""
              )}
              {localizationService.toLanguageString("custom.language")}
            </AppContext.Provider>
          </IntlProvider>
        </LocalizationProvider>
      </SWRConfig>
    </div>
  );
}

export default App;
