//utilities
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import APIURL from "../../APIURL";
import axios from "axios";
//localization
import { useLocalization } from "@progress/kendo-react-intl";

//access
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Redirect } from 'react-router-dom';

//react icons
import { BiUser, BiLogOut } from "react-icons/bi";
import {
  edit_subject,
  approve_subject,
  view_all_subject,
  view_subject,
  add_subject,
  add_study,
  edit_study,
  view_study,
  view_user_setting,
  add_user_setting,
  edit_user_setting,
  user_detail,
  islogged,
  receive_email,
} from "../../redux/action";

export function UserSideBar(props) {
  //localization
  const localizationService = useLocalization();
  const UserDetail = useSelector((state) => state.UserDetail);
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const history = useHistory();

  const logout = () => {
    console.log("logout")
    dispatch(islogged(false));
    dispatch(add_subject(false));
    dispatch(edit_subject(false));
    dispatch(approve_subject(false));
    dispatch(view_subject(false));
    dispatch(view_all_subject(false));

    dispatch(view_study(false));
    dispatch(add_study(false));
    dispatch(edit_study(false));

    dispatch(view_user_setting(false));
    dispatch(add_user_setting(false));
    dispatch(edit_user_setting(false));

    dispatch(receive_email(false));
    dispatch(
      user_detail({
        id: "",
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        role: "",
        sub_investigator_fn: "",
        sub_investigator_ln: "",
        is_assistant: false,
        is_active: "",
        role_id: "",
        country: "",
        center: "",
        study_id: "",
        study_name: "",
      })
    );
    history.push('/login');
  };

  //access

  //date
  var today = new Date();
  const all = moment(today).format("MMMM,ddd D");

  return (
    <>
      <div className="CloseRow">
        <div className="col">
          <div className="float-right">
            <button
              type="button"
              className="btn-close text-reset bootstrap-close-button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      <div className="ClockRow">
        <div className="row CloseRow"></div>
        <div className="row UserProfileRow DateRow">
          <div className="col">{all}</div>
          <div className="col"></div>
        </div>
      </div>
      <div className="row UserRow">
        <div className="col">
          <div className="row UserProfileRow NameRow">
            <strong>
              {localizationService.toLanguageString("custom.username")}
              {" : "}
              {UserDetail.username}
            </strong>
          </div>
          <div className="row UserProfileRow NameRow">
            <strong>
              {localizationService.toLanguageString("custom.email")} {" : "}
              {UserDetail.email}
            </strong>
          </div>
        </div>
      </div>
      <div className="IconsRow">
        <div className="row UserProfileRow">
          <div className="col">
            <span>
              <BiUser className="ProfileIconStyle" />
            </span>
            <span
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="LinkClickable"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/authentication/user/userprofile")}
            >
              {localizationService.toLanguageString("custom.userprofile")}
            </span>
          </div>
        </div>

        <div className="row UserProfileRow">
          <div className="col">
            <span>
              {" "}
              <BiLogOut className="LogoutIconStyle" />
            </span>
            <span
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="LinkClickable"
              style={{ cursor: "pointer" }}
              onClick={logout}
            >
              {" "}
              {localizationService.toLanguageString("custom.logout")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserSideBar;
