import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
export const DateFilterCell = (props) => {
  let hasValue = (value) => Boolean(value && value !== props.defaultItem);

  const onChange = (event) => {
    hasValue = hasValue(event.target.value);

    props.onChange({
      value: hasValue ? event.target.value : "",
      operator: hasValue ? "eq" : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    props.onChange({
      value: "",
      operator: "",
      syntheticEvent: event,
    });
  };

  return (
    <div className="k-filtercell">
      <DatePicker
        onChange={onChange}
        // value={props.value || props.defaultItem} before
        value={props.value === "" ? props.defaultItem : props.value}
        format={"dd/MMM/yyyy"}
      />
      <Button
        title="Clear"
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};
