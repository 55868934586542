const MetastaticMethods = [
  {
    value: "CT Scan",
    name: "CT Scan",
  },
  {
    value: "MRI",
    name: "MRI",
  },
  {
    value: "PET CT Scan",
    name: "PET CT Scan",
  },
  {
    value: "Other",
    name: "Other",
  },
];

export default MetastaticMethods;
