//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AppBar, AppBarSection } from "@progress/kendo-react-layout";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement, FieldArray} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { useLocalization } from "@progress/kendo-react-intl";
import {
  FormDateTimePicker,
  RequiredFormDatePicker,
  FormCheckbox,
  RequiredFormTextArea,
  RequiredFormNumericTextBox,
  RequiredDropDownList,
  FormDropDownList,
  FormMultiSelect,
  RequiredFormInput,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax50Validator,
  requiredMax255Validator,
} from "../common/Kendo/validators";

//component
import ParamTestOptions from "../common/commoncomponents/ParamTestOptions";
import UrinaryProtein from "../common/commoncomponents/UrinaryProtein";
import karnoskiValues from "../common/commoncomponents/KarnofskiValues";
import EcgValues from "../common/commoncomponents/EcgValues";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { clone } from "@progress/kendo-react-common";
import { trashIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { Error } from "@progress/kendo-react-labels";


export const FormGridEditContext = React.createContext({});
const FORM_DATA_INDEX = "formDataIndex";
const DATA_ITEM_KEY = "ProductID";
const onRemoveContext = React.createContext((options) => null);
// Create context for your state
const stateContext = React.createContext([{}, () => {}]);
const MyContext = React.createContext({
  deletearray: [],
});
export function OtherTest(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);
  const [check, setCheck] = React.useState(false)
  const [eventId, setEventId] = React.useState()
  //localization
  const localizationService = useLocalization();

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  const onRemoveContext = React.createContext((options) => null);

const otherNameForm =(formRenderProps) =>{
  return(
    <td>
    <Field
      // id={"other_param_test"}
      // name={"other_param_test"}
      name={`other_tests[${formRenderProps.dataIndex}].${formRenderProps.field}`}
      component={RequiredDropDownList}
      // label={localizationService.toLanguageString(
      //   "custom.name"
      // )}
      data={ParamTestOptions}
      validator={requiredValidator}
      onChange={(e) =>
        onOtherParamChange(e, formRenderProps)
      }
    />
  </td>
  )
}
async function deleteAdverseEventById(id) {
  console.log("id")
  try {
      const response = await axios.post(APIURL + '/common/deleteotherlaboratorytest/',{
        id: id
      } );
      if (response.status === 200) {
        // if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
        //   onRemove({ index: cellProps.dataIndex });
        // } else {
        //   currentContext.deletearray.push(cellProps.dataItem.id);
    
        //   onRemove({ index: cellProps.dataIndex });
        // }

          console.log('Resource deleted successfully');
          
      } else {
          console.error('Failed to delete the resource');
      }
  } catch (error) {
      console.error('Error:', error);
  }
}


const toggleDelete = () => {
  setCheck(!check);
};
const handleDeleteClick =(id) => {
  deleteAdverseEventById(id);
  toggleDelete()
  props.refresh()
}

const testtoggleDelete = (id) => {
setEventId(id);
toggleDelete()
};
const otherDateForm =(formRenderProps) =>{
  return(
    <td >
      <Field
        // id={"other_param_date"}
        // name={"other_param_date"}
        name={`other_tests[${formRenderProps.dataIndex}].${formRenderProps.field}`}
        component={RequiredFormDatePicker}
        // label={localizationService.toLanguageString(
        //   "custom.date"
        // )}
        validator={requiredValidator}
      />
   
  </td>
  )
}

const otheroptionForm =(formRenderProps) =>{
  return(
    <td >
      <Field
        // id={"other_param_date"}
        // name={"other_param_date"}
        name={`other_tests[${formRenderProps.dataIndex}].${formRenderProps.field}`}
      component={RequiredFormInput}
      disabled={formRenderProps.dataItem.other_param_test?.value === 'Other' ? false : true}
        validator={formRenderProps.dataItem.other_param_test?.value === 'Other'?requiredValidator: ''}
      />
   
  </td>
  )
}

const CommandCell = (cellProps) => {
  const onRemove = React.useContext(onRemoveContext);
  const currentContext = React.useContext(MyContext);

  const onClick = (e) => {
    e.preventDefault();
    console.log("formDataIndex:", cellProps.formDataIndex);
    
    if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
      console.log("Removing new item at index:", cellProps.formDataIndex);
      onRemove({ index: cellProps.formDataIndex });
    } else {
      console.log("Deleting item with id:", cellProps.dataItem.id);
      currentContext.deletearray.push(cellProps.dataItem.id);
      onRemove({ index: cellProps.formDataIndex });
    }
  };

  return (
    <td>
    {cellProps.dataItem.id ? (
             <SvgIcon
             icon={trashIcon}
             style={{ color: "red", cursor: "pointer" }}
             onClick={() => testtoggleDelete(cellProps.dataItem.id)}
           />
        ) : (
          <SvgIcon
            icon={trashIcon}
            style={{ color: "red", cursor: "pointer" }}
            onClick={onClick}
          />
        )}
    </td>
  );
};

// const CommandCell = (cellProps) => {
//   const onRemove = React.useContext(onRemoveContext);
//   // Consume the state context, which will give you both the state and the setter.
//   const [state, setState] = React.useContext(stateContext);
//   const currentContext = React.useContext(MyContext);

//   const onClick = (e) => {
//     console.log("dddddddddddddddddddddddddd",cellProps.dataItem)
//     e.preventDefault();
//     if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
//       onRemove({ index: cellProps.formDataIndex });
//     } else {
//       currentContext.deletearray.push(cellProps.dataItem.id);

//       onRemove({ index: cellProps.formDataIndex });
//     }
//   };

//   return (
//     <td>
//       {cellProps.dataItem.id ? (
//            <SvgIcon
//            icon={trashIcon}
//            style={{ color: "red", cursor: "pointer" }}
//            onClick={onClick}
//           //  onClick={() => testtoggleDelete(cellProps.dataItem.id)}
//          />
//       ) : (
//         <SvgIcon
//           icon={trashIcon}
//           style={{ color: "red", cursor: "pointer" }}
//           onClick={onClick}
//         />
//       )}
//     </td>
//   );
// };
  const FormGrid = (fieldArrayRenderProps) => {
    const { validationMessage, visited, name, dataItemKey } =
      fieldArrayRenderProps;
    const [editIndex, setEditIndex] = React.useState();
    const editItemCloneRef = React.useRef();
  
  

  
    // Add a new item to the Form FieldArray that will be shown in the Grid
    const onAdd = React.useCallback(
      (e) => {
        e.preventDefault();
        fieldArrayRenderProps.onUnshift({
          value: {
            other_param_test: "",
            other_param_date: "",
          },
        });
        setEditIndex(0);
      },
      [fieldArrayRenderProps]
    );

    // Remove a new item to the Form FieldArray that will be removed from the Grid
    const onRemove = React.useCallback(
      (dataItem) => {
        fieldArrayRenderProps.onRemove({
          index: dataItem[FORM_DATA_INDEX],
        });
        setEditIndex(undefined);
      },
      [fieldArrayRenderProps]
    );
  
    // Update an item from the Grid and update the index of the edited item
    const onEdit = React.useCallback((dataItem, isNewItem) => {

      if (!isNewItem) {
        editItemCloneRef.current = clone(dataItem);
      }
      setEditIndex(dataItem[FORM_DATA_INDEX]);
    }, []);
  
    // Cancel the editing of an item and return its initial value
    const onCancel = React.useCallback(() => {
      if (editItemCloneRef.current) {
        fieldArrayRenderProps.onReplace({
          index: editItemCloneRef.current[FORM_DATA_INDEX],
          value: editItemCloneRef.current,
        });
      }
      editItemCloneRef.current = undefined;
      setEditIndex(undefined);
    }, [fieldArrayRenderProps]);
  
    // Save the changes
    const onSave = React.useCallback(() => {
      console.log(fieldArrayRenderProps);
      setEditIndex(undefined);
    }, [fieldArrayRenderProps]);
    const dataWithIndexes = fieldArrayRenderProps?.value?.map((item, index) => {
      return {
        ...item,
        [FORM_DATA_INDEX]: index,
      };
    });
    return (
      // <FormGridEditContext.Provider
      //   value={{
      //     onCancel,
      //     onEdit,
      //     onRemove,
      //     onSave,
      //     editIndex,
      //     parentField: name,
      //   }}
      // >
      <div>
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      <onRemoveContext.Provider value={fieldArrayRenderProps.onRemove}>
        <Grid data={dataWithIndexes} dataItemKey={dataItemKey}>
          <GridToolbar>
            <button
              title="Add new"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={onAdd}
            >
              Add new
            </button>
          </GridToolbar>
          <GridColumn field="other_param_test" title="Name" cell={otherNameForm} />
          <GridColumn field="other_param_date" title="Date" cell={otherDateForm} />
          <GridColumn field="other_param_option" title="Please Specify" cell={otheroptionForm} />
          <GridColumn cell={CommandCell} filterable={false} width="50px" />

        </Grid>
        </onRemoveContext.Provider>   
        </div>
           // </FormGridEditContext.Provider>
    );
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //variables

  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //handle form modification
  const [radioValue, setradioValue] = useState("no");
  const [paramdradioValue, setparamdradioValue] = useState("no");
  const [karnofskiradioValue, setkarnofskiradioValue] = useState("no");
  const [urinalysisradioValue, seturinalysisradioValue] = useState("no");

  const [ModifiedData, setModifiedData] = useState();
  const AllData = props.data;
console.log("Alllllllllllllllllllllllllll",AllData)
  //useEffect
  useEffect(() => {
    if (AllData !== null) {
      setradioValue(AllData?.laboratorytest?.creatinine_test_missing === true ? "yes" : "no");
      // setradioValue();
      setparamdradioValue(
        AllData?.laboratorytest?.paramedical_test_missing === true ? "yes" : "no"
      );
      setkarnofskiradioValue(AllData?.laboratorytest?.karnofski_missing === true ? "yes" : "no");
      seturinalysisradioValue(
        AllData?.laboratorytest?.urinary_test_missing === true ? "yes" : "no"
      );
    }
  }, [AllData]);

  setInterval(function () {
    props.parentCallBack({ ref: MyForm.current });
  }, 500);

  //Radio Button Handle Change
  const handleCreantinineRadio1Change = (e, formRenderProps) => {
    setradioValue(e.value);
    formRenderProps.onChange("date_creantininetest", { value: null });
    formRenderProps.onChange("is_serum_creatinine", { value: false });
    formRenderProps.onChange("creatinine_clearance_unit", { value: "" });
    formRenderProps.onChange("creatinine_clearance_value", { value: null });
    formRenderProps.onChange("gfr_unit", { value: "" });
    formRenderProps.onChange("gfr_value", { value: null });
  };

  const handleCreantinineRadio2Change = (e, formRenderProps) => {
    setradioValue(e.value);
    formRenderProps.onChange("creatinine_missing_reason", { value: "" });
  };

  const handleParamdRadio1Change = (e, formRenderProps) => {
    setparamdradioValue(e.value);
    formRenderProps.onChange("electrocardiogram", { value: false });
    formRenderProps.onChange("date_paramedicaltest", { value: null });
    formRenderProps.onChange("ecg_status", { value: null });
    formRenderProps.onChange("other_paramedical_test_presence", {
      value: false,
    });
    formRenderProps.onChange("other_paramedical_test", { value: "" });
  };

  const handleParamdRadio2Change = (e, formRenderProps) => {
    setparamdradioValue(e.value);
    formRenderProps.onChange("paramedical_missing_reason", { value: "" });
  };

  const handleKarnofskiRadio1Change = (e, formRenderProps) => {
    setkarnofskiradioValue(e.value);
    formRenderProps.onChange("karnofski_status", { value: null });
  };

  const handleKarnofskiRadio2Change = (e, formRenderProps) => {
    setkarnofskiradioValue(e.value);
    formRenderProps.onChange("karnofski_missing_reason", { value: "" });
  };
  const handleUrinalysisRadio1Change = (e, formRenderProps) => {
    formRenderProps.onChange("urinary_protein", { value: null });
    formRenderProps.onChange("date_urinarytest", { value: null });
    seturinalysisradioValue(e.value);
  };
  const handleUrinalysisRadio2Change = (e, formRenderProps) => {
    seturinalysisradioValue(e.value);
    formRenderProps.onChange("urinary_missing_reason", { value: "" });
  };

  const onSerumCreantinineChange = (e, formRenderProps) => {
    formRenderProps.onChange("date_creantininetest", { value: null });
    formRenderProps.onChange("creatinine_clearance_value", { value: "" });
    formRenderProps.onChange("creatinine_clearance_unit", { value: "" });
    formRenderProps.onChange("gfr_value", { value: "" });
    formRenderProps.onChange("gfr_unit", { value: "" });
  };

  const handleOtherTest = (e, formRenderProps) => {
    formRenderProps.onChange("other_param_test", { value: null });
    formRenderProps.onChange("other_param_option", { value: "" });
    formRenderProps.onChange("other_param_date", { value: null });
  };

  const onOtherParamChange = (e, formRenderProps) => {
    formRenderProps.onChange("other_param_option", { value: "" });
    formRenderProps.onChange("other_param_date", { value: null });
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  //create function

  const CreateOtherTest = (dataItem) => {
    console.log("dataItemposttttt", AllData,)
    if (dataItem.isValid) {
      axios
        .get(APIURL + "/common/laboratorydetect/", {
          params: {
            study_subject: props.subjectdata.data.id,
            section: props.subjectdata.section.id,
          },
        })
        .then((res) => {
          if (res.data.message === "notestdata") {
            setLoading(true);
            axios
              .post(APIURL + "/common/listlaboratoryvalues/", {
                created_by: UserDetail.id,
                done_other_test: true,
                study_subject: props.subjectdata.data.id,
                section: props.subjectdata.section.id,
                date_urinarytest: dataItem.values.date_urinarytest
                  ? moment(dataItem.values.date_urinarytest).format(
                      "YYYY-MM-DD"
                    )
                  : null,
                date_creantininetest: dataItem.values.date_creantininetest
                  ? moment(dataItem.values.date_creantininetest).format(
                      "YYYY-MM-DD"
                    )
                  : null,
                date_paramedicaltest: dataItem.values.date_paramedicaltest
                  ? moment(dataItem.values.date_paramedicaltest).format(
                      "YYYY-MM-DD"
                    )
                  : null,
                urinary_test_missing: urinalysisradioValue,
                urinary_missing_reason: dataItem.values.urinary_missing_reason
                  ? dataItem.values.urinary_missing_reason
                  : "",
                urinary_protein: dataItem.values.urinary_protein
                  ? dataItem.values.urinary_protein.value
                  : null,
                is_serum_creatinine: dataItem.values.is_serum_creatinine,
                creatinine_test_missing: radioValue,
                paramedical_test_missing: paramdradioValue,
                paramedical_missing_reason: dataItem.values
                  .paramedical_missing_reason
                  ? dataItem.values.paramedical_missing_reason
                  : "",
                creatinine_missing_reason: dataItem.values
                  .creatinine_missing_reason
                  ? dataItem.values.creatinine_missing_reason
                  : "",
                creatinine_clearance_value: dataItem.values
                  .creatinine_clearance_value
                  ? dataItem.values.creatinine_clearance_value
                  : null,
                creatinine_clearance_unit: dataItem.values
                  .creatinine_clearance_unit
                  ? dataItem.values.creatinine_clearance_unit
                  : "",
                gfr_value: dataItem.values.gfr_value
                  ? dataItem.values.gfr_value
                  : null,
                gfr_unit: dataItem.values.gfr_unit
                  ? dataItem.values.gfr_unit
                  : "",
                electrocardiogram: dataItem.values.electrocardiogram,
                ecg_status: dataItem.values.ecg_status
                  ? dataItem.values.ecg_status.value
                  : null,
                other_param_test_presence:
                  dataItem.values.other_param_test_presence,
                other_param_test: dataItem.values.other_param_test
                  ? dataItem.values.other_param_test.value
                  : null,
                other_param_option: dataItem.values.other_param_option,
                other_param_date: dataItem.values.other_param_date
                  ? moment(dataItem.values.other_param_date).format(
                      "YYYY-MM-DD"
                    )
                  : null,
                karnofski_status: dataItem.values.karnofski_status
                  ? dataItem.values.karnofski_status.value
                  : null,
                karnofski_missing: karnofskiradioValue,
                karnofski_missing_reason: dataItem.values
                  .karnofski_missing_reason
                  ? dataItem.values.karnofski_missing_reason
                  : "",
                  other_tests: dataItem.values.other_tests?.map(d => ({
                    other_param_date: moment(d.other_param_date).format("YYYY-MM-DD"),
                    other_param_test: d.other_param_test.value,
                    other_param_option: d.other_param_option
                }))
                
              })
              .then((res) => {
                onToggle("success");
                props.refresh()
                setTimeout(() => {
                  setState({ ...state, success: false });
                  setLoading(false);
                }, GlobalNotificationTimer);
              })
              .catch((error) => {
                setErrorNotification(error.response.status);
                onToggle("nosave");
                setTimeout(() => {
                  setState({ ...state, nosave: false });
                  setLoading(false);
                }, GlobalNotificationTimer);
              });
          } else if (res.data.message === "yesdata") {
            if (dataItem.isModified) {
              setModifiedData(dataItem.values);
              toggleDModifyreason();
            } else {
              onToggle("nomodify");
              props.refresh()
              setLoading(false);
              setTimeout(() => {
                setState({ ...state, nomodify: false });
              }, GlobalNotificationTimer);
            }
          } else if (res.data.message === "noothertestdata") {
            setLoading(true);
       
            axios
              .put(
                APIURL + "/common/updatelaboratoryvalues/" + AllData.laboratorytest.id + "/",
                {
                  other_tests: dataItem.values.other_tests?.map(d => ({
                    other_param_date: moment(d.other_param_date).format("YYYY-MM-DD"),
                    other_param_test: d.other_param_test.value,
                    other_param_option: d.other_param_option
                })),
                  modified_by: UserDetail.id,
                  done_other_test: true,
                  study_subject: props.subjectdata.data.id,
                  section: props.subjectdata.section.id,
                  date_urinarytest: dataItem.values.date_urinarytest
                    ? moment(dataItem.values.date_urinarytest).format(
                        "YYYY-MM-DD"
                      )
                    : null,
                  date_creantininetest: dataItem.values.date_creantininetest
                    ? moment(dataItem.values.date_creantininetest).format(
                        "YYYY-MM-DD"
                      )
                    : null,
                  date_paramedicaltest: dataItem.values.date_paramedicaltest
                    ? moment(dataItem.values.date_paramedicaltest).format(
                        "YYYY-MM-DD"
                      )
                    : null,
                  urinary_test_missing: urinalysisradioValue,
                  urinary_missing_reason: dataItem.values.urinary_missing_reason
                    ? dataItem.values.urinary_missing_reason
                    : "",
                  urinary_protein: dataItem.values.urinary_protein
                    ? dataItem.values.urinary_protein.value
                    : "",
                  is_serum_creatinine: dataItem.values.is_serum_creatinine,
                  creatinine_test_missing: radioValue,
                  paramedical_test_missing: paramdradioValue,
                  paramedical_missing_reason: dataItem.values
                    .paramedical_missing_reason
                    ? dataItem.values.paramedical_missing_reason
                    : "",
                  creatinine_missing_reason: dataItem.values
                    .creatinine_missing_reason
                    ? dataItem.values.creatinine_missing_reason
                    : "",
                  creatinine_clearance_value: dataItem.values
                    .creatinine_clearance_value
                    ? dataItem.values.creatinine_clearance_value
                    : null,
                  creatinine_clearance_unit: dataItem.values
                    .creatinine_clearance_unit
                    ? dataItem.values.creatinine_clearance_unit
                    : "",
                  gfr_value: dataItem.values.gfr_value
                    ? dataItem.values.gfr_value
                    : null,
                  gfr_unit: dataItem.values.gfr_unit
                    ? dataItem.values.gfr_unit
                    : "",
                  electrocardiogram: dataItem.values.electrocardiogram,
                  ecg_status: dataItem.values.ecg_status
                    ? dataItem.values.ecg_status.value
                    : "",
                  other_param_test_presence:
                    dataItem.values.other_param_test_presence,
                  other_param_test: dataItem.values.other_param_test
                    ? dataItem.values.other_param_test.value
                    : null,
                  other_param_option: dataItem.values.other_param_option,
                  other_param_date: dataItem.values.other_param_date
                    ? moment(dataItem.values.other_param_date).format(
                        "YYYY-MM-DD"
                      )
                    : null,
                  karnofski_status: dataItem.values.karnofski_status
                    ? dataItem.values.karnofski_status.value
                    : "",
                  karnofski_missing: karnofskiradioValue,
                  karnofski_missing_reason: dataItem.values
                    .karnofski_missing_reason
                    ? dataItem.values.karnofski_missing_reason
                    : "",
                }
              )
              .then((res) => {
                onToggle("success");
                props.refresh()
                setTimeout(() => {
                  setState({ ...state, success: false });
                  setLoading(false);
                }, GlobalNotificationTimer);
              })
              .catch((error) => {
                setErrorNotification(error.response.status);
                onToggle("nosave");
                setTimeout(() => {
                  setState({ ...state, nosave: false });
                  setLoading(false);
                }, GlobalNotificationTimer);
              });
          }
        });
    }
  };


  const CreateModification = (dataItem) => {
    toggleDModifyreason(false);
    setLoading(true);
    axios
      .put(APIURL + "/common/updatelaboratoryvalues/" + AllData.laboratorytest.id + "/", {
        other_tests: ModifiedData.other_tests?.map(d => ({
          id: d.id,
          other_param_date: moment(d.other_param_date).format("YYYY-MM-DD"),
          other_param_test: d.other_param_test.value,
          other_param_option: d.other_param_option
      })),
        created_by: AllData.laboratorytest.created_by,
        modified_by: UserDetail.id,
        done_other_test: AllData.laboratorytest.done_other_test,
        study_subject: props.subjectdata.data.id,
        section: props.subjectdata.section.id,
        date_urinarytest: ModifiedData.date_urinarytest
          ? moment(ModifiedData.date_urinarytest).format("YYYY-MM-DD")
          : null,
        date_creantininetest: ModifiedData.date_creantininetest
          ? moment(ModifiedData.date_creantininetest).format("YYYY-MM-DD")
          : null,
        date_paramedicaltest: ModifiedData.date_paramedicaltest
          ? moment(ModifiedData.date_paramedicaltest).format("YYYY-MM-DD")
          : null,
        urinary_test_missing: urinalysisradioValue,
        urinary_missing_reason: ModifiedData.urinary_missing_reason
          ? ModifiedData.urinary_missing_reason
          : "",
        urinary_protein: ModifiedData.urinary_protein
          ? ModifiedData.urinary_protein.value
          : null,
        is_serum_creatinine: ModifiedData.is_serum_creatinine,
        creatinine_test_missing: radioValue,
        paramedical_test_missing: paramdradioValue,
        paramedical_missing_reason: ModifiedData.paramedical_missing_reason
          ? ModifiedData.paramedical_missing_reason
          : "",
        creatinine_missing_reason: ModifiedData.creatinine_missing_reason
          ? ModifiedData.creatinine_missing_reason
          : "",
        creatinine_clearance_value: ModifiedData.creatinine_clearance_value
          ? ModifiedData.creatinine_clearance_value
          : null,
        creatinine_clearance_unit: ModifiedData.creatinine_clearance_unit
          ? ModifiedData.creatinine_clearance_unit
          : "",
        gfr_value: ModifiedData.gfr_value ? ModifiedData.gfr_value : null,
        gfr_unit: ModifiedData.gfr_unit ? ModifiedData.gfr_unit : "",
        electrocardiogram: ModifiedData.electrocardiogram,
        ecg_status: ModifiedData.ecg_status
          ? ModifiedData.ecg_status.value
          : null,
        other_param_test_presence: ModifiedData.other_param_test_presence,
        other_param_test: ModifiedData.other_param_test
          ? ModifiedData.other_param_test.value
          : null,
        other_param_option: ModifiedData.other_param_option,
        other_param_date: ModifiedData.other_param_date
          ? moment(ModifiedData.other_param_date).format("YYYY-MM-DD")
          : null,
        karnofski_status: ModifiedData.karnofski_status
          ? ModifiedData.karnofski_status.value
          : null,
        karnofski_missing: karnofskiradioValue,
        karnofski_missing_reason: ModifiedData.karnofski_missing_reason
          ? ModifiedData.karnofski_missing_reason
          : "",
        modification_reason: dataItem.modification_reason,
      })
      .then((res) => {
        onToggle("success");
        props.refresh()
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      });
  };
  const arrayLengthValidator = (value) =>
  value && value.length ? "" : "Please add at least one record.";


  const disabletitle = () => {
    let title = "";
    if (!EditSubject && props.approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (props.approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || props.approved) {
      boolean = true;
    }
    return boolean;
  };

  return (
    <>
      <Form
        initialValues={{
          date_urinarytest:
            AllData && AllData?.laboratorytest?.date_urinarytest
              ? new Date(AllData?.laboratorytest?.date_urinarytest)
              : null,
          date_creantininetest:
            AllData && AllData?.laboratorytest?.date_creantininetest
              ? new Date(AllData?.laboratorytest?.date_creantininetest)
              : null,
          date_paramedicaltest:
            AllData && AllData?.laboratorytest?.date_paramedicaltest
              ? new Date(AllData?.laboratorytest?.date_paramedicaltest)
              : null,
          urinary_missing_reason:
            AllData && AllData?.laboratorytest?.urinary_missing_reason
              ? AllData?.laboratorytest?.urinary_missing_reason
              : "",
          urinary_protein:
            AllData && AllData?.laboratorytest?.urinary_protein
              ? {
                  name: AllData?.laboratorytest?.urinary_protein,
                  value: AllData?.laboratorytest?.urinary_protein,
                }
              : null,
          is_serum_creatinine: AllData ? AllData?.laboratorytest?.is_serum_creatinine : false,
          creatinine_missing_reason:
            AllData && AllData?.laboratorytest?.creatinine_missing_reason
              ? AllData?.laboratorytest?.creatinine_missing_reason
              : "",

          creatinine_clearance_value: AllData
            ? AllData?.laboratorytest?.creatinine_clearance_value
            : null,
          creatinine_clearance_unit: AllData
            ? AllData?.laboratorytest?.creatinine_clearance_unit
            : "",
          paramedical_missing_reason:
            AllData && AllData?.laboratorytest?.paramedical_missing_reason
              ? AllData?.laboratorytest?.paramedical_missing_reason
              : "",
          gfr_value: AllData ? AllData?.laboratorytest?.gfr_value : null,
          gfr_unit: AllData ? AllData?.laboratorytest?.gfr_unit : "",
          electrocardiogram: AllData ? AllData?.laboratorytest?.electrocardiogram : false,
          ecg_status:
            AllData && AllData?.laboratorytest?.ecg_status
              ? { name: AllData?.laboratorytest?.ecg_status, value: AllData?.laboratorytest?.ecg_status }
              : null,
          other_param_test_presence: AllData
            ? AllData?.laboratorytest?.other_param_test_presence
            : false,
          other_param_test:
            AllData && AllData?.laboratorytest?.other_param_test
              ? {
                  name: AllData?.laboratorytest?.other_param_test,
                  value: AllData?.laboratorytest?.other_param_test,
                }
              : null,
          other_param_option: AllData ? AllData?.laboratorytest?.other_param_option : "",
          other_param_date:
            AllData && AllData?.laboratorytest?.other_param_date
              ? new Date(AllData?.laboratorytest?.other_param_date)
              : null,

          karnofski_status: AllData
            ? {
                name: AllData?.laboratorytest?.karnofski_status,
                value: AllData?.laboratorytest?.karnofski_status,
              }
            : null,
          karnofski_missing_reason: AllData
            ? AllData?.laboratorytest?.karnofski_missing_reason
            : "",
            other_tests: AllData?.laboratorytest?.other_tests?.map(d => ({
              other_param_date: new Date (d.other_param_date),
              other_param_test: {name: d.other_param_test , value:d.other_param_test},
              other_param_option: d.other_param_option,
              id: d.id
          }))
        }}
        ref={MyForm}
        onSubmitClick={CreateOtherTest}
        key={JSON.stringify(AllData)}
        render={(formRenderProps) => (
          <FormElement className="">
            <fieldset className={"k-form-fieldset"}>
              <Tooltip
                openDelay={0}
                anchorElement="target"
                parentTitle={true}
                position={"top"}
              >
                <Prompt
                  when={PromptFunction(formRenderProps)}
                  message={localizationService.toLanguageString(
                    "custom.promptmessage"
                  )}
                />

                <AppBar className="FormAppBar">
                  <AppBarSection>
                    <div>
                      <strong>
                        {localizationService.toLanguageString(
                          "custom.urinalysis"
                        )}
                      </strong>
                    </div>
                  </AppBarSection>
                </AppBar>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <strong>
                        {localizationService.toLanguageString(
                          "custom.informationstatusquestion"
                        )}
                        ?
                      </strong>
                    </div>
                    <div className="col-3">
                      {" "}
                      <RadioButton
                        name="urinalysisradio1"
                        value="yes"
                        checked={urinalysisradioValue === "yes"}
                        label={localizationService.toLanguageString(
                          "custom.yes"
                        )}
                        onChange={(e) =>
                          handleUrinalysisRadio1Change(e, formRenderProps)
                        }
                        // disabled={
                        //   AllData &&
                        //   AllData.laboratorytest.done_other_test === true &&
                        //   AllData.laboratorytest.urinary_test_missing === false
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>
                    <div className="col-3">
                      {" "}
                      <RadioButton
                        name="urinalysisradio2"
                        value="no"
                        checked={urinalysisradioValue === "no"}
                        label={localizationService.toLanguageString(
                          "custom.no"
                        )}
                        onChange={(e) =>
                          handleUrinalysisRadio2Change(e, formRenderProps)
                        }
                        // disabled={
                        //   AllData &&
                        //   AllData.laboratorytest.done_other_test === true &&
                        //   AllData.laboratorytest.urinary_test_missing === false
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>
                  </div>
                  {urinalysisradioValue === "no" ? (
                    <div className="row">
                      <div className="col-4">
                        <Field
                          id={"date_urinarytest"}
                          name={"date_urinarytest"}
                          label={localizationService.toLanguageString(
                            "custom.sampledate"
                          )}
                          component={RequiredFormDatePicker}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="col-4">
                        <Field
                          id={"urinary_protein"}
                          name={"urinary_protein"}
                          label={localizationService.toLanguageString(
                            "custom.urinaryprotein"
                          )}
                          data={UrinaryProtein}
                          component={RequiredDropDownList}
                          validator={requiredValidator}
                        />
                      </div>
                    </div>
                  ) : (
                    <Field
                      name={"urinary_missing_reason"}
                      label={localizationService.toLanguageString(
                        "custom.missingreason"
                      )}
                      // validator={requiredValidator}
                      component={RequiredFormTextArea}
                    />
                  )}
                </div>
                <AppBar className="FormAppBar">
                  <AppBarSection>
                    <div>
                      <strong>
                        {localizationService.toLanguageString(
                          "custom.creatinineclearance"
                        )}
                      </strong>
                    </div>
                  </AppBarSection>
                </AppBar>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <strong>
                        {localizationService.toLanguageString(
                          "custom.informationstatusquestion"
                        )}
                        ?
                      </strong>
                    </div>
                    <div className="col-3">
                      {" "}
                      <RadioButton
                        name="creantineradio1"
                        value="yes"
                        checked={radioValue === "yes"}
                        label={localizationService.toLanguageString(
                          "custom.yes"
                        )}
                        onChange={(e) =>
                          handleCreantinineRadio1Change(e, formRenderProps)
                        }
                        // disabled={
                        //   AllData &&
                        //   AllData.laboratorytest.done_other_test === true 
                        //   &&
                        //   AllData.laboratorytest.creatinine_test_missing === false
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>
                    <div className="col-3">
                      {" "}
                      <RadioButton
                        name="creantineradio2"
                        value="no"
                        checked={radioValue === "no"}
                        label={localizationService.toLanguageString(
                          "custom.no"
                        )}
                        onChange={(e) =>
                          handleCreantinineRadio2Change(e, formRenderProps)
                        }
                        // disabled={
                        //   AllData &&
                        //   AllData.laboratorytest.done_other_test === true &&
                        //   AllData.laboratorytest.creatinine_test_missing === false
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>
                  </div>

                  {radioValue === "no" ? (
                    <>
                      <div className="row">
                        <div className="col-6">
                          <Field
                            id={"is_serum_creatinine"}
                            name={"is_serum_creatinine"}
                            component={FormCheckbox}
                            label={localizationService.toLanguageString(
                              "custom.serumquestion"
                            )}
                            onChange={(e) =>
                              onSerumCreantinineChange(e, formRenderProps)
                            }
                          />
                        </div>
                      </div>
                      {formRenderProps.valueGetter("is_serum_creatinine") ===
                      true ? (
                        <>
                          <div className="row mt-2">
                            <div className="col-6">
                              <div className="row">
                                <Field
                                  id={"date_creantininetest"}
                                  name={"date_creantininetest"}
                                  label={localizationService.toLanguageString(
                                    "custom.sampledate"
                                  )}
                                  component={RequiredFormDatePicker}
                                  validator={requiredValidator}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="row mt-2">
                                <div className="col-4">
                                  {localizationService.toLanguageString(
                                    "custom.creatinineclearance"
                                  )}
                                </div>

                                <div className="col-4">
                                  <Field
                                    id={"creatinine_clearance_value"}
                                    name={"creatinine_clearance_value"}
                                    component={RequiredFormNumericTextBox}
                                    label={localizationService.toLanguageString(
                                      "custom.value"
                                    )}
                                    validator={requiredValidator}
                                    format={"n0"}
                                  />
                                </div>
                                <div className="col-4">
                                  <Field
                                    id={"creatinine_clearance_unit"}
                                    name={"creatinine_clearance_unit"}
                                    component={RequiredFormInput}
                                    label={localizationService.toLanguageString(
                                      "custom.unit"
                                    )}
                                    validator={requiredMax50Validator}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-4">
                                  {localizationService.toLanguageString(
                                    "custom.gfr"
                                  )}
                                </div>

                                <div className="col-4">
                                  <Field
                                    id={"gfr_value"}
                                    name={"gfr_value"}
                                    component={RequiredFormNumericTextBox}
                                    label={localizationService.toLanguageString(
                                      "custom.value"
                                    )}
                                    format={"n0"}
                                    validator={requiredValidator}
                                  />
                                </div>
                                <div className="col-4">
                                  <Field
                                    id={"gfr_unit"}
                                    name={"gfr_unit"}
                                    component={RequiredFormInput}
                                    label={localizationService.toLanguageString(
                                      "custom.unit"
                                    )}
                                    validator={requiredMax50Validator}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <Field
                      name={"creatinine_missing_reason"}
                      label={localizationService.toLanguageString(
                        "custom.missingreason"
                      )}
                      // validator={requiredValidator}
                      component={RequiredFormTextArea}
                    />
                  )}
                </div>
                <AppBar className="FormAppBar">
                  <AppBarSection>
                    <div>
                      <strong>
                        {localizationService.toLanguageString(
                          "custom.paramedicaltests"
                        )}
                      </strong>
                    </div>
                  </AppBarSection>
                </AppBar>
                <div>
                  <div className="row">
                    <div className="col-6">
                      <strong>
                        {" "}
                        {localizationService.toLanguageString(
                          "custom.informationstatusquestion"
                        )}
                        ?
                      </strong>
                    </div>
                    <div className="col-3">
                      {" "}
                      <RadioButton
                        name="paramdradio1"
                        value="yes"
                        checked={paramdradioValue === "yes"}
                        label={localizationService.toLanguageString(
                          "custom.yes"
                        )}
                        onChange={(e) =>
                          handleParamdRadio1Change(e, formRenderProps)
                        }
                        // disabled={
                        //   AllData &&
                        //   AllData.laboratorytest.done_other_test === true &&
                        //   AllData.laboratorytest.paramedical_test_missing === false
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>
                    <div className="col-3">
                      {" "}
                      <RadioButton
                        name="paramdradio2"
                        value="no"
                        checked={paramdradioValue === "no"}
                        label={localizationService.toLanguageString(
                          "custom.no"
                        )}
                        onChange={(e) =>
                          handleParamdRadio2Change(e, formRenderProps)
                        }
                        // disabled={
                        //   AllData &&
                        //   AllData.laboratorytest.done_other_test === true &&
                        //   AllData.laboratorytest.paramedical_test_missing === false
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>
                  </div>

                  {paramdradioValue === "no" ? (
                    <>
                      <div className="row">
                        <div className="col-3">
                          <Field
                            id={"electrocardiogram"}
                            name={"electrocardiogram"}
                            component={FormCheckbox}
                            label={localizationService.toLanguageString(
                              "custom.electrocardiogram"
                            )}
                            // validator={requiredValidator}
                          />
                        </div>
                        <div className="col-3">
                          <Field
                            id={"date_paramedicaltest"}
                            name={"date_paramedicaltest"}
                            label={localizationService.toLanguageString(
                              "custom.dateecg"
                            )}
                            component={RequiredFormDatePicker}
                            validator={formRenderProps.valueGetter("electrocardiogram") ?requiredValidator : ''}
                          />
                        </div>
                        <div className="col-3">
                          <Field
                            id={"ecg_status"}
                            name={"ecg_status"}
                            label={localizationService.toLanguageString(
                              "custom.ecg"
                            )}
                            data={EcgValues}
                            component={RequiredDropDownList}
                            validator={formRenderProps.valueGetter("electrocardiogram") ?requiredValidator : ''}                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <Field
                            id={"other_param_test_presence"}
                            name={"other_param_test_presence"}
                            component={FormCheckbox}
                            label={localizationService.toLanguageString(
                              "custom.ifother"
                            )}
                            onChange={(e) =>
                              handleOtherTest(e, formRenderProps)
                            }
                          />
                        </div>
                        {formRenderProps.valueGetter(
                          "other_param_test_presence"
                        ) === true ? (
                          <FieldArray
                          name="other_tests"
                          dataItemKey={DATA_ITEM_KEY}
                          component={FormGrid}
                          validator={arrayLengthValidator}
                        />
                        ): ''
                        //   <>
                        //     <div className="col-3">
                        //       <Field
                        //         id={"other_param_test"}
                        //         name={"other_param_test"}
                        //         component={RequiredDropDownList}
                        //         label={localizationService.toLanguageString(
                        //           "custom.name"
                        //         )}
                        //         data={ParamTestOptions}
                        //         validator={requiredValidator}
                        //         onChange={(e) =>
                        //           onOtherParamChange(e, formRenderProps)
                        //         }
                        //       />
                        //     </div>

                        //     {formRenderProps.valueGetter("other_param_test") &&
                        //     formRenderProps.valueGetter("other_param_test")
                        //       .value === "Other" ? (
                        //       <>
                        //         <div className="col-3">
                        //           <Field
                        //             id={"other_param_option"}
                        //             name={"other_param_option"}
                        //             component={RequiredFormInput}
                        //             label={localizationService.toLanguageString(
                        //               "custom.pleasespecify"
                        //             )}
                        //             validator={requiredMax255Validator}
                        //           />
                        //         </div>
                        //       </>
                        //     ) : (
                        //       ""
                        //     )}
                        //     <div className="col-3">
                        //       <Field
                        //         id={"other_param_date"}
                        //         name={"other_param_date"}
                        //         component={RequiredFormDatePicker}
                        //         label={localizationService.toLanguageString(
                        //           "custom.date"
                        //         )}
                        //         validator={requiredValidator}
                        //       />
                        //     </div>
                        //   </>
                        // ) : (
                        //   ""
                        }
                      </div>
                    </>
                  ) : (
                    <Field
                      name={"paramedical_missing_reason"}
                      label={localizationService.toLanguageString(
                        "custom.missingreason"
                      )}
                      // validator={requiredValidator}
                      component={RequiredFormTextArea}
                    />
                  )}
                </div>
                <AppBar className="FormAppBar">
                  <AppBarSection>
                    <div>
                      <strong>
                        <strong>
                          {" "}
                          {localizationService.toLanguageString(
                            "custom.additionalinformation"
                          )}
                        </strong>
                      </strong>
                    </div>
                  </AppBarSection>
                </AppBar>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <strong>
                        {localizationService.toLanguageString(
                          "custom.informationstatusquestion"
                        )}
                        ?
                      </strong>
                    </div>
                    <div className="col-3">
                      {" "}
                      <RadioButton
                        name="karnosfkiradio1"
                        value="yes"
                        checked={karnofskiradioValue === "yes"}
                        label={localizationService.toLanguageString(
                          "custom.yes"
                        )}
                        onChange={(e) =>
                          handleKarnofskiRadio1Change(e, formRenderProps)
                        }
                        // disabled={
                        //   AllData &&
                        //   AllData.laboratorytest.done_other_test === true &&
                        //   AllData.laboratorytest.karnofski_missing === false
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>
                    <div className="col-3">
                      {" "}
                      <RadioButton
                        name="karnosfkiradio2"
                        value="no"
                        checked={karnofskiradioValue === "no"}
                        label={localizationService.toLanguageString(
                          "custom.no"
                        )}
                        onChange={(e) =>
                          handleKarnofskiRadio2Change(e, formRenderProps)
                        }
                        // disabled={
                        //   AllData &&
                        //   AllData.laboratorytest.done_other_test === true &&
                        //   AllData.laboratorytest.karnofski_missing === false
                        //     ? true
                        //     : false
                        // }
                      />
                    </div>
                  </div>
                  {karnofskiradioValue === "no" ? (
                    <div className="row">
                      <div className="col-4">
                        <Field
                          id={"karnofski_status"}
                          name={"karnofski_status"}
                          label={localizationService.toLanguageString(
                            "custom.karnofskistatus"
                          )}
                          data={karnoskiValues}
                          component={RequiredDropDownList}
                          validator={requiredValidator}
                        />
                      </div>
                    </div>
                  ) : (
                    <Field
                      name={"karnofski_missing_reason"}
                      label={localizationService.toLanguageString(
                        "custom.missingreason"
                      )}
                      // validator={requiredValidator}
                      component={RequiredFormTextArea}
                    />
                  )}
                </div>
              </Tooltip>
            </fieldset>
            <Tooltip
              openDelay={0}
              anchorElement="target"
              parentTitle={true}
              position={"left"}
            >
              <div className="float-right">
                <div title={disabletitle()}>
                  <Button
                    primary={true}
                    type={"submit"}
                    className="mr-2em"
                    disabled={disablefunction()}
                  >
                    {loading
                      ? localizationService.toLanguageString("custom.loading")
                      : localizationService.toLanguageString("custom.save")}
                  </Button>
                </div>
              </div>
            </Tooltip>
          </FormElement>
        )}
      />

      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}
          {check && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDelete}
          width={"700px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.deleteconfirmation"
              )}
            </p>

          </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDelete}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => handleDeleteClick(eventId)}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  );
}

export default OtherTest;
