const KarnofskiValues = [
  {
    value: "<60",
    name: "<60",
  },
  {
    value: "60-80",
    name: "60-80",
  },
  {
    value: ">80",
    name: ">80",
  },
];

export default KarnofskiValues;
