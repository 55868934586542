const host = "http://localhost:";

const port = "8000";

var APIURL = "";

if (process.env.NODE_ENV === "production") {
  APIURL = "https://ecrf.galoper.net";
} else {
  APIURL = host + port;
}

export default APIURL;
