//utilities
import React from "react";
import axios from "axios";

import APIURL from "../../APIURL";

//kendo component
import { requiredValidator } from "../Kendo/validators";
import { RequiredFormTextArea } from "../Kendo/form-components";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

export function AddNote(props) {
  //localization
  const localizationService = useLocalization();

  //CREATE NOTE
  const createNote = (dataItem) => {
    if (props.data) {
      axios
        .put(APIURL + `/common/updatenote/${props.data.id}/`, {
          note: dataItem.note,
          study_subject: props.subject,
          section: props.section,
        })
        .then((res) => {
          props.onClose();
        });
    } else {
      axios
        .post(APIURL + "/common/newnote/", {
          note: dataItem.note,
          study_subject: props.subject,
          section: props.section,
        })
        .then((res) => {
          props.onClose();
        });
    }
  };
  return (
    <>
      <Form
        initialValues={{
          note: props.data ? props.data.note : "",
        }}
        onSubmit={createNote}
        render={(formRenderProps) => (
          <>
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                <Field
                  name={"note"}
                  label={localizationService.toLanguageString("custom.note")}
                  component={RequiredFormTextArea}
                  validator={requiredValidator}
                />
                <div className="row mt-2">
                  <div className="col-12">
                    <div className="float-right">
                      <Button primary={true} type={"submit"} className="mr-2em">
                        {localizationService.toLanguageString("custom.save")}
                      </Button>

                      <Button onClick={props.onClose}>
                        {localizationService.toLanguageString("custom.cancel")}
                      </Button>
                    </div>
                  </div>
                </div>
              </fieldset>
            </FormElement>
          </>
        )}
      />
    </>
  );
}

export default AddNote;
