//utilities
import React, { useEffect, useState } from "react";
import APIURL from "../APIURL";
import useSWR from "swr";
import { useSelector } from "react-redux";

//kendo component
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { SvgIcon } from "@progress/kendo-react-common";
import { pencilIcon } from "@progress/kendo-svg-icons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process } from "@progress/kendo-data-query";
import { DropDownButton, Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//access
import { useHistory } from "react-router-dom";

//Component
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import { CustomColumnMenu } from "../common/Kendo/CustomColumnMenu";

export function ListStudy(props) {
  //data lead fetching
  const { data: studies, error } = useSWR(APIURL + "/common/liststudy/");

  //REDUX
  const EditStudy = useSelector((state) => state.EditStudy);
  const AddStudy = useSelector((state) => state.AddStudy);

  //PERMISSION DIALOG
  const [NoPermission, setNoPermission] = useState("");
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //localization
  const localizationService = useLocalization();

  //NOTIFICATION
  const [state, setState] = React.useState({
    success: false,
    added: false,
    edited: false,
    error: false,
    warning: false,
    info: false,
    none: false,
  });
  const { success, added, edited } = state;
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  //access
  const history = useHistory();

  //multiple selection
  const [selected, setselected] = useState([]);
  const selectField = "selected";
  // FILTER PART
  const [filter, setFilter] = useState();

  let _pdfExport;
  let _export;

  const excelExport = () => {
    _export.save();
  };

  const exportPDF = () => {
    _pdfExport.save();
  };

  const createDataState = (dataState) => {
    return {
      result: studies ? process(studies.slice(0), dataState) : [],
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 8,
    skip: 0,
  });
  const columns = [
    {
      title: localizationService.toLanguageString("custom.name"),
      field: "study_name",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.protocolid"),
      field: "protocol_id",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.therapeuticarea"),
      field: "therapeutic_area",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString(
        "custom.principalinvestigator"
      ),
      field: "principal_investigator",
      show: true,
      filter: "text",
    },
  ];
  const [data, setData] = useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(columns);

  useEffect(() => {
    if (studies) setData(process(studies ? studies.slice(0) : [], dataState));
    if (props.location.add && props.location.add === "edited")
      setState({ edited: true });
    if (props.location.add && props.location.add === "added")
      setState({ added: true });
    setTimeout(() => {
      setState({ added: false, edited: false });
    }, 3000);
  }, [studies]);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setData(updatedState.result);
    setDataState(updatedState.dataState);
  };

  //action button

  const ActionTemplate = (props) => {
    return (
      <td>
        <span title={localizationService.toLanguageString("custom.edit")}>
          <SvgIcon
            className="mr-2em EditActionButtons"
            icon={pencilIcon}
            onClick={() => {
              setNoPermission("editnoaccess");
              history.push({
                pathname: "/crf/study/edit",
                state: props.dataItem,
              });
            }}
          />
        </span>
      </td>
    );
  };

  // FILTER PART
  const filterChange = (event) => {
    setData(
      process(
        studies ? filterBy(studies.slice(0), event.filter) : [],
        dataState
      )
    );

    setFilter(event.filter);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <>
      {!studies && loadingPanel}
      <Tooltip
        openDelay={0}
        anchorElement="target"
        position="bottom"
        parentTitle="true"
      >
        <ExcelExport
          data={data}
          ref={(exporter) => {
            _export = exporter;
          }}
        >
          <Grid
            data={data}
            {...dataState}
            onDataStateChange={dataStateChange}
            sortable={true}
            pageable={true}
            pageSize={8}
            filterable={true}
            filter={filter}
            onFilterChange={filterChange}
          >
            <GridToolbar>
              <div className="col pages-big-title">
                <strong>
                  {localizationService.toLanguageString("custom.study")}
                </strong>
              </div>
              <div className="float-right">
                <Button
                  primary={true}
                  className="mr-2"
                  onClick={() => {
                    setNoPermission("addnoaccess");
                    AddStudy
                      ? props.history.push("/crf/study/add")
                      : toggleDPermission();
                  }}
                >
                  {localizationService.toLanguageString("custom.new")}
                </Button>
              </div>
            </GridToolbar>
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    cell={column.cell}
                    columnMenu={(props) => (
                      <CustomColumnMenu
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  />
                )
            )}
            <Column
              className="ActionColumn"
              title={localizationService.toLanguageString("custom.actions")}
              cell={(props) => ActionTemplate(props)}
              filterable={false}
              width="100px"
            />
          </Grid>
        </ExcelExport>

        <GridPDFExport
          ref={(element) => {
            _pdfExport = element;
          }}
          margin="1cm"
        >
          <Grid data={data}>
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    cell={column.cell}
                    columnMenu={(props) => (
                      <CustomColumnMenu
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  />
                )
            )}
            <Column
              className="ActionColumn"
              title={localizationService.toLanguageString("custom.actions")}
              cell={(props) => ActionTemplate(props)}
              filterable={false}
              width="100px"
            />
          </Grid>
        </GridPDFExport>

        {/* PERMISSION DIALOG */}
        {VPermission && (
          <Dialog
            title={localizationService.toLanguageString(
              "custom.permissiondenied"
            )}
            onClose={toggleDPermission}
          >
            <PermissionDenied
              onClose={toggleDPermission}
              NoPermission={NoPermission}
            />
          </Dialog>
        )}
      </Tooltip>
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.studyadded")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {edited && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, edited: false })}
            >
              <span>
                {" "}
                {localizationService.toLanguageString("custom.studyedited")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  );
}

export default ListStudy;
