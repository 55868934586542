//utilities
import React, { useEffect, useState } from "react";
import APIURL from "../APIURL";
import useSWR from "swr";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
//react icons
import { VscRequestChanges } from "react-icons/vsc";
//kendo component
import { Tooltip } from "@progress/kendo-react-tooltip";
import { process } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";

import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//Component
import SeeModifications from "./SeeModifications";
import { CustomColumnMenu } from "../common/Kendo/CustomColumnMenu";

export function ListModifications(props) {
  //REDUX
  const UserDetail = useSelector((state) => state.UserDetail);
  const ViewAllSubject = useSelector((state) => state.ViewAllSubject);

  //data fetching by filter
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          userloggedIn: UserDetail.id,
          permission: ViewAllSubject,
        },
      })
      .then((res) => res.data);

  const { data: modifications, error } = useSWR(
    [APIURL + "/common/listmodification/", UserDetail.id, ViewAllSubject],
    fetcher1
  );

  //localization
  const localizationService = useLocalization();

  // FILTER PART
  const [filter, setFilter] = useState();

  const createDataState = (dataState) => {
    return {
      result: modifications ? process(modifications.slice(0), dataState) : [],
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 25,
    skip: 0,
  });
  const columns = [
    {
      title: localizationService.toLanguageString("custom.studysubjectid"),
      field: "studyCrfId",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.section"),
      field: "sectionName",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.subsection"),
      field: "table",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.type"),
      field: "type_log",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.modifiedby"),
      field: "modifiedBy",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.instance"),
      field: "code",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.modificationdate"),
      field: "Stringdate",
      show: true,
      filter: "date",
      cell: (props) => {
        return (
          <td>
            {moment(props.dataItem.modified_date).format("YYYY-MM-DD HH:mm")}
          </td>
        );
      },
    },
  ];

  const [InsertedModifications, setInsertedModification] = useState([]);
  const [ReasonModification, setReasonModification] = useState([]);
  const [AllModifications, setAllModifications] = useState([]);
  const [data, setData] = useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(columns);

  useEffect(() => {
    if (modifications)
      setData(process(modifications ? modifications.slice(0) : [], dataState));
  }, [modifications]);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setData(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  //Modification Dialog
  const [visibleModification, setVisibleModification] = useState(false);
  const toggleDialogModification = () => {
    setVisibleModification(!visibleModification);
  };

  //Modification Dialog
  const [visibleInsertModification, setVisibleInsertModification] =
    useState(false);
  const toggleDialogInsertModification = () => {
    setVisibleInsertModification(!visibleInsertModification);
  };

  //alter dialogs
  const handleModificationChanges = (e, props) => {
    if (props.dataItem.type_log === "insert") {
      setReasonModification(props.dataItem.modification_reason);
      setInsertedModification(props.dataItem.inserted_code);
      toggleDialogInsertModification();
    } else if (props.dataItem.type_log === "update") {
      setReasonModification(props.dataItem.modification_reason);
      setAllModifications(props.dataItem.modifications);
      toggleDialogModification();
    }
  };
  //modification template

  const ModificationCell = (props) => {
    return (
      <td>
        {props.dataItem.type_log === "insert" &&
        !props.dataItem.inserted_code ? (
          ""
        ) : (
          <span
            title={localizationService.toLanguageString("custom.modifications")}
          >
            <VscRequestChanges
              className="mr-2em DocumentActionButtons"
              style={{ textAlign: "center" }}
              onClick={(e) => handleModificationChanges(e, props)}
            />
          </span>
        )}
      </td>
    );
  };

  //Date Filter Change
  const filterChange = (event) => {
    if (event.filter != null) {
      let newfilter = event.filter.filters;

      event.filter.filters.map((i) => {
        if (i.field !== "Stringdate") {
          setFilter(event.filter);
        }
        if (i.field === "Stringdate") {
          newfilter = event.filter.filters.filter(
            (j) => j.field !== "Stringdate"
          );
          newfilter.push({
            field: "Stringdate",
            operator: i.operator,
            value: moment(i.value).format("YYYY-MM-DD"),
          });
        }

        setData(
          process(
            modifications ? filterBy(modifications.slice(0), newfilter) : [],
            dataState
          )
        );
        setFilter(event.filter);
      });
    } else if (event.filter === null) {
      setData(
        process(
          modifications ? filterBy(modifications.slice(0), event.filter) : [],
          dataState
        )
      );
      setFilter(event.filter);
    }
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <>
      {" "}
      {!modifications && loadingPanel}
      <Tooltip
        openDelay={0}
        anchorElement="target"
        position="top"
        parentTitle="true"
      >
        <Grid
          className="full-page-style"
          data={data}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          pageable={true}
          pageSize={8}
          filterable={true}
          filter={filter}
          onFilterChange={filterChange}
        >
          <GridToolbar>
            <div className="col GridListTitle">
              <strong>
                {localizationService.toLanguageString("custom.logs")}
              </strong>
            </div>
          </GridToolbar>
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <Column
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filter={column.filter}
                  cell={column.cell}
                  columnMenu={(props) => (
                    <CustomColumnMenu
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
          <Column
            title={localizationService.toLanguageString(
              "custom.seeallmodifications"
            )}
            cell={(props) => ModificationCell(props)}
            filterable={false}
          />
        </Grid>
      </Tooltip>
      {/* See All Modifications */}
      {visibleModification && (
        <Dialog
          title={localizationService.toLanguageString("custom.modifications")}
          onClose={toggleDialogModification}
          height={"600px"}
          width={"700px"}
        >
          <SeeModifications
            onClose={toggleDialogModification}
            data={AllModifications}
            reason={ReasonModification}
            log="update"
          />
        </Dialog>
      )}
      {/* See All Modifications */}
      {visibleInsertModification && (
        <Dialog
          title={localizationService.toLanguageString("custom.modifications")}
          onClose={toggleDialogInsertModification}
          height={"600px"}
          width={"700px"}
        >
          <SeeModifications
            onClose={toggleDialogInsertModification}
            data={InsertedModifications}
            reason={ReasonModification}
            log="insert"
          />
        </Dialog>
      )}
    </>
  );
}

export default ListModifications;
