const SmokingType = [
  { value: "Cigarettes", name: "Cigarettes" },
  { value: "Cigars", name: "Cigars" },
  { value: "Hookahs", name: "Hookahs" },
  { value: "Pipe", name: "Pipe" },
  { value: "Tobacco", name: "Tobacco" },
  { value: "Vaping Devices", name: "Vaping Devices" },
];

export default SmokingType;
