export const user_detail = (state = false, action, data) => {
  switch (action.type) {
    case "USER_DETAIL":
      return action.payload;

    default:
      return state;
  }
};

//receive email
export const receive_emailReducer = (state = false, action) => {
  switch (action.type) {
    case "RECEIVE_EMAIL":
      return action.payload;

    default:
      return state;
  }
};

export const view_all_subjectReducer = (state = false, action) => {
  switch (action.type) {
    case "VIEWALLSUBJECT":
      return action.payload;

    default:
      return state;
  }
};

export const view_subjectReducer = (state = false, action) => {
  switch (action.type) {
    case "VIEWSUBJECT":
      return action.payload;

    default:
      return state;
  }
};

export const add_subjectReducer = (state = false, action) => {
  switch (action.type) {
    case "ADDSUBJECT":
      return action.payload;

    default:
      return state;
  }
};

export const edit_subjectReducer = (state = false, action) => {
  switch (action.type) {
    case "EDITSUBJECT":
      return action.payload;

    default:
      return state;
  }
};

export const approve_subjectReducer = (state = false, action) => {
  switch (action.type) {
    case "APPROVESUBJECT":
      return action.payload;

    default:
      return state;
  }
};

export const view_studyReducer = (state = false, action) => {
  switch (action.type) {
    case "VIEWSTUDY":
      return action.payload;

    default:
      return state;
  }
};

export const add_studyReducer = (state = false, action) => {
  switch (action.type) {
    case "ADDSTUDY":
      return action.payload;

    default:
      return state;
  }
};

export const edit_studyReducer = (state = false, action) => {
  switch (action.type) {
    case "EDITSTUDY":
      return action.payload;

    default:
      return state;
  }
};

export const view_user_settingReducer = (state = false, action) => {
  switch (action.type) {
    case "VIEWUSERSETTING":
      return action.payload;

    default:
      return state;
  }
};

export const add_user_settingReducer = (state = false, action) => {
  switch (action.type) {
    case "ADDUSERSETTING":
      return action.payload;

    default:
      return state;
  }
};

export const edit_user_settingReducer = (state = false, action) => {
  switch (action.type) {
    case "EDITUSERSETTING":
      return action.payload;

    default:
      return state;
  }
};
