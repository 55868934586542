const SurgeryType = [
  {
    value: "Partial nephrectomy",
    name: "Partial nephrectomy",
  },
  {
    value: "Radical nephrectomy",
    name: "Radical nephrectomy",
  },
  {
    value: "Other",
    name: "Other",
  },
];

export default SurgeryType;
