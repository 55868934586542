//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";

import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { trashIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  FormCheckbox,
  FormInput,
  FormDropDownList,
  FormDateTimePicker,
  RequiredFormTextArea,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax255Validator,
} from "../common/Kendo/validators";

//component
import SeverityValues from "../common/commoncomponents/SeverityValues";
import ActionTakenValues from "../common/commoncomponents/ActionTakenValues";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";

const languageContext = React.createContext({
  intl: null,
});

const MyContext = React.createContext({
  deletearray: [],
});

const arrayLengthValidator = (value) =>
  value && value.length ? "" : "Please add at least one record.";

const onRemoveContext = React.createContext((options) => null);
// Create context for your state
const stateContext = React.createContext([{}, () => {}]);

const AdvserseEventCustomCell = (props) => {
  if (props.field === "adverse_event") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`advserse_events[${props.dataIndex}].${props.field}`}
          validator={requiredMax255Validator}
        />
      </td>
    );
  }
  if (props.field === "adverse_serious") {
    return (
      <td>
        <Field
          component={FormCheckbox}
          name={`advserse_events[${props.dataIndex}].${props.field}`}
        />
      </td>
    );
  }
  if (props.field === "adverse_severity") {
    return (
      <td>
        <Field
          component={FormDropDownList}
          name={`advserse_events[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
          data={SeverityValues}
        />
      </td>
    );
  } else if (props.field === "studydrug_relationship") {
    return (
      <td>
        <Field
          component={FormCheckbox}
          name={`advserse_events[${props.dataIndex}].${props.field}`}
        />
      </td>
    );
  } else if (props.field === "action_taken") {
    return (
      <td>
        <Field
          component={FormDropDownList}
          name={`advserse_events[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
          data={ActionTakenValues}
        />
      </td>
    );
  } else if (props.field === "start_date") {
    return (
      <td>
        <Field
          component={FormDateTimePicker}
          name={`advserse_events[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "end_date") {
    return (
      <td>
        <Field
          component={FormDateTimePicker}
          name={`advserse_events[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "cont_atfinalexam") {
    return (
      <td>
        <Field
          component={FormCheckbox}
          name={`advserse_events[${props.dataIndex}].${props.field}`}
        />
      </td>
    );
  }
};



async function deleteAdverseEventById(id) {
  try {
      const response = await axios.post(APIURL + '/opera/treatment/deleteadverseevent/',{
        id: id
      } );
      if (response.status === 200) {
        // if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
        //   onRemove({ index: cellProps.dataIndex });
        // } else {
        //   currentContext.deletearray.push(cellProps.dataItem.id);
    
        //   onRemove({ index: cellProps.dataIndex });
        // }

          console.log('Resource deleted successfully');
          
      } else {
          console.error('Failed to delete the resource');
      }
  } catch (error) {
      console.error('Error:', error);
  }
}


export function AdverseEvent(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();
  languageContext.intl = useLocalization();

  //variables
  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //form ref to detect modification of the form
  const [deletearray, setdeletearray] = useState([]); //to check what are the deleted items
  const [check, setCheck] = React.useState(false)
  const [eventId, setEventId] = React.useState()
  const [refresh, setRefresh] = React.useState(false)


  const toggleDelete = () => {
    setCheck(!check);
 };

 const toggleRefresh = () => {
  console.log("before",refresh)
  setRefresh(!refresh);
  console.log("after",refresh)

};
 const testtoggleDelete = (id) => {
  setEventId(id);
  toggleDelete()
  toggleRefresh()
};

  const AdvserseEventFormGrid = (fieldArrayRenderProps) => {
    const { validationMessage, visited } = fieldArrayRenderProps;
    const [state, setState] = React.useState({});
    const onAdd = React.useCallback(
      (e) => {
        e.preventDefault();
        fieldArrayRenderProps.onUnshift({
          value: {
            status: "new",
            adverse_event: "",
            adverse_serious: false,
            adverse_severity: "",
            studydrug_relationship: false,
            action_taken: "",
            start_date: null,
            end_date: null,
            cont_atfinalexam: false,
          },
        });
      },
      [fieldArrayRenderProps.onUnshift]
    );
  
    return (
      <div>
        {visited && validationMessage && <Error>{validationMessage}</Error>}
  
        <stateContext.Provider value={[state, setState]}>
          <onRemoveContext.Provider value={fieldArrayRenderProps.onRemove}>
            <GridTelerik
              style={{
                maxHeight: "500px",
              }}
              data={fieldArrayRenderProps.value}
            >
              <GridToolbar>
                <div className="float-right">
                  <Button primary={true} type={"button"} onClick={onAdd}>
                    {languageContext.intl.toLanguageString("custom.new")}
                  </Button>
                </div>
              </GridToolbar>
              <Column
                field="code"
                title={
                  <>
                    <span>
                      {languageContext.intl.toLanguageString("custom.id")}
                    </span>
                  </>
                }
              />
              <Column
                field="adverse_event"
                cell={AdvserseEventCustomCell}
                title={
                  <>
                    <span
                      title={languageContext.intl.toLanguageString(
                        "custom.adverseevent"
                      )}
                      className="text-responsive-overflow"
                    >
                      {languageContext.intl.toLanguageString(
                        "custom.adverseevent"
                      )}
                    </span>
                    <span style={{ color: "red" }}>{"*"}</span>
                  </>
                }
              />
  
              <Column
                field="adverse_serious"
                title={
                  <>
                    <span
                      title={languageContext.intl.toLanguageString(
                        "custom.adverseserious"
                      )}
                      className="text-responsive-overflow"
                    >
                      {languageContext.intl.toLanguageString(
                        "custom.adverseserious"
                      )}
                    </span>
                    <span style={{ color: "red" }}>{"*"}</span>
                  </>
                }
                cell={AdvserseEventCustomCell}
              />
              <Column
                field="adverse_severity"
                title={
                  <>
                    <span
                      title={languageContext.intl.toLanguageString(
                        "custom.severity"
                      )}
                      className="text-responsive-overflow"
                    >
                      {languageContext.intl.toLanguageString("custom.severity")}
                    </span>
                    <span style={{ color: "red" }}>{"*"}</span>
                  </>
                }
                cell={AdvserseEventCustomCell}
              />
              <Column
                field="studydrug_relationship"
                title={
                  <>
                    <span
                      title={languageContext.intl.toLanguageString(
                        "custom.relationshipstudydrug"
                      )}
                      className="text-responsive-overflow"
                    >
                      {languageContext.intl.toLanguageString(
                        "custom.relationshipstudydrug"
                      )}
                    </span>
                    <span style={{ color: "red" }}>{"*"}</span>
                  </>
                }
                cell={AdvserseEventCustomCell}
              />
              <Column
                field="action_taken"
                title={
                  <>
                    <span
                      title={languageContext.intl.toLanguageString(
                        "custom.actiontaken"
                      )}
                      className="text-responsive-overflow"
                    >
                      {languageContext.intl.toLanguageString(
                        "custom.actiontaken"
                      )}
                    </span>
                    <span style={{ color: "red" }}>{"*"}</span>
                  </>
                }
                cell={AdvserseEventCustomCell}
              />
              <Column
                field="start_date"
                title={
                  <>
                    <span
                      title={languageContext.intl.toLanguageString(
                        "custom.startdate"
                      )}
                      className="text-responsive-overflow"
                    >
                      {languageContext.intl.toLanguageString("custom.startdate")}
                    </span>
                    <span style={{ color: "red" }}>{"*"}</span>
                  </>
                }
                cell={AdvserseEventCustomCell}
              />
              <Column
                field="end_date"
                title={
                  <>
                    <span
                      title={languageContext.intl.toLanguageString(
                        "custom.enddate"
                      )}
                      className="text-responsive-overflow"
                    >
                      {languageContext.intl.toLanguageString("custom.enddate")}
                    </span>
                    <span style={{ color: "red" }}>{"*"}</span>
                  </>
                }
                cell={AdvserseEventCustomCell}
              />
              <Column
                field="cont_atfinalexam"
                title={
                  <>
                    <span
                      title={languageContext.intl.toLanguageString(
                        "custom.continuousatfinal"
                      )}
                      className="text-responsive-overflow"
                    >
                      {languageContext.intl.toLanguageString(
                        "custom.continuousatfinal"
                      )}
                    </span>
                    <span style={{ color: "red" }}>{"*"}</span>
                  </>
                }
                cell={AdvserseEventCustomCell}
              />
  
              <Column cell={CommandCell} filterable={false} width="50px" />
            </GridTelerik>
          </onRemoveContext.Provider>
        </stateContext.Provider>
      </div>
    );
  };
  
  const CommandCell = (cellProps) => {
    const onRemove = React.useContext(onRemoveContext);
    // Consume the state context, which will give you both the state and the setter.
    const [state, setState] = React.useContext(stateContext);
    const currentContext = React.useContext(MyContext);
  
    const onClick = (e) => {
      e.preventDefault();
      if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
        onRemove({ index: cellProps.dataIndex });
      } else {
        currentContext.deletearray.push(cellProps.dataItem.id);
  
        onRemove({ index: cellProps.dataIndex });
      }
    };
  
    return (
      <td>
        {cellProps.dataItem.id ? (
             <SvgIcon
             icon={trashIcon}
             style={{ color: "red", cursor: "pointer" }}
             onClick={() => testtoggleDelete(cellProps.dataItem.id)}
           />
        ) : (
          <SvgIcon
            icon={trashIcon}
            style={{ color: "red", cursor: "pointer" }}
            onClick={onClick}
          />
        )}
      </td>
    );
  };

  const handleDeleteClick =(id) => {
    deleteAdverseEventById(id);
    toggleDelete()
  }
  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.subjectdata.data.id,
          section: props.subjectdata.section.id,
        },
      })
      .then((res) => res.data);

  const { data, error } = useSWR(
    [
      APIURL + "/opera/treatment/addadverseevent/",
      props.subjectdata.data.id,
      props.subjectdata.section.id,
    ],
    fetcher1
  );

  const DateTime = (TheDate) => {
    let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
    let regExTimeArr = regExTime.exec(TheDate);
    var database_from = new Date(TheDate);
    database_from.setHours(regExTimeArr[1]);
    database_from.setMinutes(regExTimeArr[2]);
    database_from.setSeconds(regExTimeArr[3]);
    database_from.setMilliseconds(0);

    return database_from;
  };
  // useEffect(() => {
  //   // This effect will run on the initial component render
  //   // You can update the 'refresh' state if needed to trigger a re-render
  // }, [refresh]);
  //USE EFFECT
  useEffect(() => {
    let array = [];

    if (data) {
      data.map((d) => {
        array.push({
          id: d.id,
          study_subject: d.study_subject,
          section: d.section,
          adverse_event: d.adverse_event,
          adverse_serious: d.adverse_serious,
          adverse_severity: d.adverse_severity
            ? { name: d.adverse_severity, value: d.adverse_severity }
            : "",
          studydrug_relationship: d.studydrug_relationship,
          cont_atfinalexam: d.cont_atfinalexam,
          action_taken: d.action_taken
            ? { name: d.action_taken, value: d.action_taken }
            : "",
          start_date: d.start_date ? DateTime(d.start_date) : null,
          end_date: d.end_date ? DateTime(d.end_date) : null,
          code: d.code,
        });
      });
    }
    setAllData(array);
  }, [data,refresh]);

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //variable

  const [ModifiedData, setModifiedData] = useState();
  const [AllData, setAllData] = useState([]);

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  var checkmodification = setInterval(function () {
    props.parentCallBack({ ref: MyForm.current });
  }, 500);

  const CreateAdvserseEvent = (dataItem) => {
    if (dataItem.isValid) {
      if (AllData.length > 0) {
        if (dataItem.isModified) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let array = [];
        if (dataItem.values.advserse_events) {
          dataItem.values.advserse_events.map((d) => {
            array.push({
              created_by: UserDetail.id,
              modified_by: null,
              study_subject: props.subjectdata.data.id,
              section: props.subjectdata.section.id,
              status: d.status ? d.status : "edit",
              adverse_event: d.adverse_event,
              adverse_serious: d.adverse_serious,
              adverse_severity: d.adverse_severity
                ? d.adverse_severity.value
                : "",
              studydrug_relationship: d.studydrug_relationship,
              action_taken: d.action_taken ? d.action_taken.value : "",
              start_date: moment(d.start_date).format("YYYY-MM-DD HH:mm"),
              end_date: moment(d.end_date).format("YYYY-MM-DD HH:mm"),
              cont_atfinalexam: d.cont_atfinalexam,
            });
          });
        }
        setLoading(true);
        axios
          .post(APIURL + "/opera/treatment/addadverseevent/", {
            section: props.subjectdata.section.id,
            modified_by: UserDetail.id,
            study_subject: props.subjectdata.data.id,
            advserse_events: array,
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
          });
      }
    }
  };

  const CreateModification = (dataItem) => {
    toggleDModifyreason(false);
    let array = [];
    if (ModifiedData.advserse_events) {
      ModifiedData.advserse_events.map((d) => {
        array.push({
          id: d.id,
          created_by: d.status === "new" ? UserDetail.id : d.created_by,
          modified_by: d.status === "new" ? null : UserDetail.id,
          study_subject: props.subjectdata.data.id,
          section: props.subjectdata.section.id,
          status: d.status ? d.status : "edit",
          adverse_event: d.adverse_event,
          adverse_serious: d.adverse_serious,
          adverse_severity: d.adverse_severity ? d.adverse_severity.value : "",
          studydrug_relationship: d.studydrug_relationship,
          action_taken: d.action_taken ? d.action_taken.value : "",
          start_date: moment(d.start_date).format("YYYY-MM-DD HH:mm"),
          end_date: moment(d.end_date).format("YYYY-MM-DD HH:mm"),
          cont_atfinalexam: d.cont_atfinalexam,
        });
      });
    }
    setLoading(true);
    axios
      .put(APIURL + "/opera/treatment/updateadverseevent/", {
        study_subject: props.subjectdata.data.id,
        section: props.subjectdata.section.id,
        modified_by: UserDetail.id,
        advserse_events: array,
        modification_reason: dataItem.modification_reason,
      })
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      });
  };

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && props.approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (props.approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || props.approved) {
      boolean = true;
    }
    return boolean;
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <>
      {!data && loadingPanel}
      <MyContext.Provider
        value={{
          deletearray: deletearray,
        }}
      >
        <Form
          initialValues={{
            advserse_events: AllData.length > 0 ? AllData : [],
          }}
          ref={MyForm}
          onSubmitClick={CreateAdvserseEvent}
          key={JSON.stringify(AllData)}
          render={(formRenderProps) => (
            <FormElement className="">
              <fieldset className={"k-form-fieldset"}>
                <Tooltip
                  openDelay={0}
                  anchorElement="target"
                  parentTitle={true}
                  position={"top"}
                >
                  <Prompt
                    when={PromptFunction(formRenderProps)}
                    message={localizationService.toLanguageString(
                      "custom.promptmessage"
                    )}
                  />

                  <div className="row mt-2">
                    <FieldArray
                      name="advserse_events"
                      component={AdvserseEventFormGrid}
                      validator={arrayLengthValidator}
                    />
                  </div>
                </Tooltip>
              </fieldset>

              <Tooltip
                openDelay={0}
                anchorElement="target"
                parentTitle={true}
                position={"left"}
              >
                <div className="float-right">
                  <div title={disabletitle()}>
                    <Button
                      primary={true}
                      type={"submit"}
                      className="mr-2em"
                      disabled={disablefunction()}
                    >
                      {loading
                        ? localizationService.toLanguageString("custom.loading")
                        : localizationService.toLanguageString("custom.save")}
                    </Button>
                  </div>
                </div>
              </Tooltip>
            </FormElement>
          )}
        />
      </MyContext.Provider>

      {check && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDelete}
          width={"700px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.deleteconfirmation"
              )}
            </p>

          </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDelete}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => handleDeleteClick(eventId)}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  );
}

export default AdverseEvent;
