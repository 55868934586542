//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";

//kendo Component

import { Button } from "@progress/kendo-react-buttons";

import { Card, CardHeader } from "@progress/kendo-react-layout";

import { RequiredFormNumericTextBox } from "../common/Kendo/form-components";
import { requiredValidator } from "../common/Kendo/validators";
import { Form, Field, FormElement } from "@progress/kendo-react-form";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

export function OverallRes(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();

  //variables
  const [ErrorNotification, setErrorNotification] = useState("");

  const disableFunction = () => {
    let boolean = false;
    if (!EditSubject) {
      boolean = true;
    }
    return boolean;
  };

  const disabletitle = () => {
    let title = "";
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  //function
  const UpdateSubject = (dataItem) => {
    if (dataItem.isValid) {
      if (dataItem.isModified) {
        axios
          .put(APIURL + "/common/overallsubjectupdate/" + props.data.id + "/", {
            overall_survival_os: dataItem.values.overall_survival_os,
            time_to_response: dataItem.values.time_to_response,
            progressive_free_survival:
              dataItem.values.progressive_free_survival,
            modified_by: UserDetail.id,
          })
          .then((res) => {
            setErrorNotification("");
            props.onClose();
          })
          .catch((error) => {
            setErrorNotification(
              error.response.status +
                " " +
                localizationService.toLanguageString("custom.errornosave")
            );
          });
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader className="DetailFormHeader">
              <div className="row">
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.subjectid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString(
                    "custom.subjectinitials"
                  )}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.study")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.protocolid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.sitenumber")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.createdby")}
                </div>
              </div>
              <div className="row">
                <div className="col text-responsive-overflow subject-id-style">
                  {props.data.crf_id}
                </div>
                <div className="col text-responsive-overflow">
                  {props.data.subject_initial}
                </div>
                <div className="col text-responsive-overflow">
                  {props.data.study_name}
                </div>
                <div className="col text-responsive-overflow">
                  {props.data.study_protocol}
                </div>
                <div className="col text-responsive-overflow">
                  {props.data.subject_center}
                </div>
                <div className="col text-responsive-overflow">
                  {props.data.createdBy}
                </div>
              </div>
              <hr className="RowRoleHr" />
            </CardHeader>
          </Card>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <Form
            initialValues={{
              progressive_free_survival: props.data
                ? props.data.progressive_free_survival
                : null,
              overall_survival_os: props.data
                ? props.data.overall_survival_os
                : null,
              time_to_response: props.data ? props.data.time_to_response : null,
            }}
            onSubmitClick={UpdateSubject}
            render={(formRenderProps) => (
              <FormElement>
                <fieldset className={"k-form-fieldset"}>
                  <div className="row">
                    <div className="col-4">
                      <Field
                        id={"progressive_free_survival"}
                        name={"progressive_free_survival"}
                        label={localizationService.toLanguageString(
                          "custom.progressivefreesurvival"
                        )}
                        validator={requiredValidator}
                        component={RequiredFormNumericTextBox}
                        format={"n0"}
                        hint={localizationService.toLanguageString(
                          "custom.nbmonth"
                        )}
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        id={"overall_survival_os"}
                        name={"overall_survival_os"}
                        label={localizationService.toLanguageString(
                          "custom.overallsurvivalos"
                        )}
                        validator={requiredValidator}
                        component={RequiredFormNumericTextBox}
                        format={"n0"}
                        hint={localizationService.toLanguageString(
                          "custom.nbmonth"
                        )}
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        id={"time_to_response"}
                        name={"time_to_response"}
                        label={localizationService.toLanguageString(
                          "custom.timetoresponse"
                        )}
                        validator={requiredValidator}
                        component={RequiredFormNumericTextBox}
                        format={"n0"}
                        hint={localizationService.toLanguageString(
                          "custom.nbmonth"
                        )}
                      />
                    </div>
                  </div>
                </fieldset>
                <div className="row">
                  <div className="col">
                    <span className="k-form-error">{ErrorNotification}</span>
                  </div>
                </div>
                <div className="float-right">
                  <div title={disabletitle()}>
                    <Button
                      className="k-button mr-2em"
                      primary={true}
                      disabled={disableFunction()}
                    >
                      {localizationService.toLanguageString("custom.save")}
                    </Button>
                  </div>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </>
  );
}

export default OverallRes;
