//utilities
import React, { useEffect, useState } from "react";

//kendo component

import { process } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//Component
import { CustomColumnMenu } from "../common/Kendo/CustomColumnMenu";
import { CardBody, CardHeader, Card } from "@progress/kendo-react-layout";
export function SeeModifications(props) {
  //localization
  const localizationService = useLocalization();

  // FILTER PART
  const [filter, setFilter] = useState();

  const createDataState = (dataState) => {
    return {
      result: props.data ? process(props.data.slice(0), dataState) : [],
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 8,
    skip: 0,
  });
  const columns = [
    {
      title: localizationService.toLanguageString("custom.field"),
      field: "fields",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.oldvalue"),
      field: "old_value",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.newvalue"),
      field: "new_value",
      show: true,
      filter: "text",
    },
  ];

  const insertcolumns = [
    {
      title: localizationService.toLanguageString("custom.insertedcodes"),
      field: "name",
      show: true,
      filter: "text",
    },
  ];

  const [data, setData] = useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(
    props.log === "insert" ? insertcolumns : columns
  );

  useEffect(() => {
    let array = [];
    if (props.data) {
      if (props.log == "update") {
        setData(process(props.data ? props.data.slice(0) : [], dataState));
      } else if (props.log === "insert") {
        props.data.map((d) => {
          array.push({
            name: d,
          });
        });
        setData(process(props.data ? array.slice(0) : [], dataState));
      }
    }
  }, [props.data]);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setData(updatedState.result);
    setDataState(updatedState.dataState);
  };

  // FILTER PART
  const filterChange = (event) => {
    setData(
      process(
        props.data ? filterBy(props.data.slice(0), event.filter) : [],
        dataState
      )
    );

    setFilter(event.filter);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  return (
    <>
      {props.log === "update" ? (
        <>
          <div className="col-12 mb-2">
            {props.reason ? (
              <Card>
                <CardHeader className="DetailFormHeader">
                  <strong>
                    {localizationService.toLanguageString(
                      "custom.reasonformodification"
                    )}
                  </strong>
                </CardHeader>
                <CardBody>
                  <div className="row">
                    <div className="col-12">{props.reason}</div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              ""
            )}
          </div>
          <div className="col-12 mt-2">
            <Grid
              data={data}
              {...dataState}
              onDataStateChange={dataStateChange}
              sortable={true}
              pageable={true}
              pageSize={8}
              filterable={true}
              filter={filter}
              onFilterChange={filterChange}
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      cell={column.cell}
                      columnMenu={(props) => (
                        <CustomColumnMenu
                          {...props}
                          columns={stateColumns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                    />
                  )
              )}
            </Grid>
          </div>
        </>
      ) : (
        <div className="col-12 mt-2">
          <Grid
            data={data}
            {...dataState}
            onDataStateChange={dataStateChange}
            sortable={true}
            pageable={true}
            pageSize={8}
            filterable={true}
            filter={filter}
            onFilterChange={filterChange}
          >
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    cell={column.cell}
                    columnMenu={(props) => (
                      <CustomColumnMenu
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  />
                )
            )}
          </Grid>
        </div>
      )}
    </>
  );
}

export default SeeModifications;
