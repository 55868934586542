const Countries = [
  { name: "Egypt", value: "EG" },
  { name: "Jordan", value: "JO" },
  { name: "Kuwait", value: "KW" },
  { name: "Lebanon", value: "LB" },
  { name: "Saudi Arabia", value: "SA" },
  { name: "United Arab Emirates", value: "AE" },
];

export default Countries;
