//utilities
import React, { useEffect, useState } from "react";
import APIURL from "../../APIURL";
import useSWR from "swr";
import { useSelector } from "react-redux";

//kendo component
import { RiLockPasswordLine } from "react-icons/ri";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { SvgIcon } from "@progress/kendo-react-common";
import { pencilIcon } from "@progress/kendo-svg-icons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//access
import { useHistory } from "react-router-dom";

//Component
import PermissionDenied from "../../common/commoncomponents/PermissionDenied";
import { CustomColumnMenu } from "../../common/Kendo/CustomColumnMenu";
import Settings from "../../setting/Settings";
import UpdatePassword from "./UpdatePassword";
export function ListUser(props) {
  //data lead fetching
  const { data: users, error } = useSWR(APIURL + "/auth/allusers/");

  //REDUX
  const EditUserSetting = useSelector((state) => state.EditUserSetting);
  const AddUserSetting = useSelector((state) => state.AddUserSetting);

  //PERMISSION DIALOG
  const [NoPermission, setNoPermission] = useState("");
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //Change Password DIALOG

  const [VPasswordChange, setVPasswordChange] = useState(false);

  const toggleDPasswordChange = () => {
    setVPasswordChange(!VPasswordChange);
  };

  //localization
  const localizationService = useLocalization();

  //NOTIFICATION
  const [state, setState] = React.useState({
    success: false,
    added: false,
    edited: false,
    error: false,
    warning: false,
    info: false,
    none: false,
  });
  const { success, added, edited } = state;
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  //access
  const history = useHistory();

  //multiple selection
  const [selected, setselected] = useState([]);
  const selectField = "selected";
  // FILTER PART
  const [filter, setFilter] = useState();

  let _pdfExport;
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(newArray);
    }
  };

  // const excelExport = () => {
  //   _export.save();
  // };

  const exportPDF = () => {
    _pdfExport.save();
  };

  const createDataState = (dataState) => {
    return {
      result: users ? process(users.slice(0), dataState) : [],
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 50,
    skip: 0,
  });
  const columns = [
    {
      title: localizationService.toLanguageString("custom.username"),
      field: "username",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.name"),
      field: "fullname",
      show: true,
      filter: "text",
      cell: (props) => {
        return (
          <td>
            {props.dataItem.first_name} {props.dataItem.last_name}
          </td>
        );
      },
    },
    {
      title: localizationService.toLanguageString("custom.email"),
      field: "email",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.rolename"),
      field: "role_name",
      show: true,
      filter: "text",
    },
    {
      title: localizationService.toLanguageString("custom.isactive"),
      field: "is_active",
      show: true,
      filter: "boolean",
      cell: (props) => {
        return props.dataItem.is_active === false ? (
          <td>
            <span className="inactive-user-status">
              {localizationService.toLanguageString("custom.inactive")}
            </span>
          </td>
        ) : (
          <td>
            <span className="active-user-status">
              {localizationService.toLanguageString("custom.active")}
            </span>
          </td>
        );
      },
    },
  ];

  const [selectedData, setSelectedData] = useState();
  const [data, setData] = useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(columns);
  const [newArray, setNewArray] = React.useState([]);

  useEffect(() => {
    if (users) setData(process(users ? users.slice(0) : [], dataState));
    if (props.location.add && props.location.add === "edited")
      setState({ edited: true });
    if (props.location.add && props.location.add === "added")
      setState({ added: true });
    setTimeout(() => {
      setState({ added: false, edited: false });
    }, 3000);
  }, [users]);

  useEffect(() => {
    const tempArray = data ? data?.data?.map(item => ({
      username: item.username,
      fullname: item.first_name + ' ' + item.last_name,
      email: item.email,
      role_name: item.role_name,
      is_active: item.is_active ? 'Active' : 'Inactive'
    })) : []
    setNewArray(tempArray)
  }, [data]);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setData(updatedState.result);
    setDataState(updatedState.dataState);
  };

  //action button

  const ActionTemplate = (props) => {
    return (
      <td>
        <span title={localizationService.toLanguageString("custom.edit")}>
          <SvgIcon
            className="mr-2em EditActionButtons"
            icon={pencilIcon}
            onClick={() => {
              setNoPermission("editnoaccess");
              EditUserSetting
                ? history.push({
                  pathname: "/settings/authentication/user/edit",
                  state: props.dataItem,
                })
                : toggleDPermission();
            }}
          />
        </span>
        <span
          title={localizationService.toLanguageString("custom.changepassword")}
        >
          <RiLockPasswordLine
            className="mr-2em ViewActionButtons"
            onClick={() => {
              setNoPermission("editnoaccess");
              setSelectedData(props.dataItem);
              EditUserSetting ? toggleDPasswordChange() : toggleDPermission();
            }}
          />
        </span>
      </td>
    );
  };

  // FILTER PART
  const filterChange = (event) => {
    setData(
      process(users ? filterBy(users.slice(0), event.filter) : [], dataState)
    );

    setFilter(event.filter);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  return (
    <Settings>
      {!users && loadingPanel}
      <Tooltip
        openDelay={0}
        anchorElement="target"
        position="bottom"
        parentTitle="true"
      >
        <NotificationGroup style={position.topRight}>
          <Fade>
            {success && (
              <Notification
                type={{
                  style: "success",
                  icon: true,
                }}
                closable={true}
                onClose={() => setState({ ...state, success: false })}
              >
                <span>
                  {localizationService.toLanguageString("custom.useradded")}
                </span>
              </Notification>
            )}
          </Fade>
          <Fade>
            {edited && (
              <Notification
                type={{
                  style: "success",
                  icon: true,
                }}
                closable={true}
                onClose={() => setState({ ...state, edited: false })}
              >
                <span>
                  {localizationService.toLanguageString("custom.useredited")}
                </span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup>

        <Grid
        className="users-full-page-style"
          data={data}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          pageable={true}
          pageSize={50}
          filterable={true}
          filter={filter}
          // scrollable="virtual"
          onFilterChange={filterChange}
        >
          <GridToolbar>
            <div className="col h5 titles-all-page">
              {localizationService.toLanguageString("custom.users")}
            </div>
            <div className="float-right">
              <Button
                primary={true}
                className="mr-2"
                onClick={() => {
                  setNoPermission("addnoaccess");
                  AddUserSetting
                    ? props.history.push("/settings/authentication/user/add")
                    : toggleDPermission();
                }}
              >
                {localizationService.toLanguageString("custom.new")}
              </Button>
            </div>
            <div>
              <button
                title="Export Excel"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={excelExport}
              >
                Export to Excel
              </button>

            </div>
          </GridToolbar>
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <Column
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filter={column.filter}
                  cell={column.cell}
                  columnMenu={(props) => (
                    <CustomColumnMenu
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
          <Column
            className="ActionColumn"
            title={localizationService.toLanguageString("custom.actions")}
            cell={(props) => ActionTemplate(props)}
            filterable={false}
            width="100px"
          />
        </Grid>
        <ExcelExport
          data={newArray}
          fileName="users.xlsx"
          ref={_export}
        >
           {stateColumns.map(
            (column, idx) =>
              column.show && (
                <ExcelExportColumn
                  key={idx}
                  field={column.field}
                  title={column.title}
               
                />
              )
          )}
        </ExcelExport>
        <GridPDFExport
          ref={(element) => {
            _pdfExport = element;
          }}
          margin="1cm"
        >
          {
            <Grid data={data}>
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      cell={column.cell}
                      columnMenu={(props) => (
                        <CustomColumnMenu
                          {...props}
                          columns={stateColumns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                    />
                  )
              )}
              <Column
                className="ActionColumn"
                title={localizationService.toLanguageString("custom.actions")}
                cell={(props) => ActionTemplate(props)}
                filterable={false}
                width="100px"
              />
            </Grid>
          }
        </GridPDFExport>

        {/* PERMISSION DIALOG */}
        {VPermission && (
          <Dialog
            title={localizationService.toLanguageString(
              "custom.permissiondenied"
            )}
            onClose={toggleDPermission}
          >
            <PermissionDenied
              onClose={toggleDPermission}
              NoPermission={NoPermission}
            />
          </Dialog>
        )}

        {/*Password change DIALOG */}
        {VPasswordChange && (
          <Dialog
            title={localizationService.toLanguageString(
              "custom.updateuserpassword"
            )}
            onClose={toggleDPasswordChange}
            width={"500px"}
          >
            <UpdatePassword
              onClose={toggleDPasswordChange}
              data={selectedData}
            />
          </Dialog>
        )}
      </Tooltip>

      <NotificationGroup style={position.topCenter}>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.useradded")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {edited && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, edited: false })}
            >
              <span>
                {" "}
                {localizationService.toLanguageString("custom.useredited")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </Settings>
  );
}

export default ListUser;
