//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { Error } from "@progress/kendo-react-labels";
import { AppBar, AppBarSection } from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { FieldArray } from "@progress/kendo-react-form";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Card, CardHeader } from "@progress/kendo-react-layout";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  RequiredFormDatePicker,
  FormNumericTextBox,
  FormInput,
  FormDatePicker,
  RequiredFormTextArea,
  RequiredFormNumericTextBox,
  RequiredDropDownList,
  FormDropDownList,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax50Validator,
} from "../common/Kendo/validators";

//component
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
import TrialMap from "../studysubject/TrialMap";
import AddFile from "../common/commoncomponents/AddFile";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import SmokingType from "../common/commoncomponents/SmokingType";
import SmokingStatus from "../common/commoncomponents/SmokingStatus";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
import FrequencyValues from "../common/commoncomponents/FrequencyValues";
import TabChange from "../common/commoncomponents/TabChange";
const MyContext = React.createContext({
  deletearray: [],
});

const languageContext = React.createContext({
  new: null,
});

const arrayLengthValidator = (value) =>
  value && value.length ? "" : "Please add at least one record.";

const onRemoveContext = React.createContext((options) => null);
// Create context for your state
const stateContext = React.createContext([{}, () => {}]);

const CustomCell = (props) => {
  if (props.field === "start_date") {
    return (
      <td>
        <Field
          component={FormDatePicker}
          name={`forms_of_smoking[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "smoking_form") {
    return (
      <td>
        <Field
          component={FormDropDownList}
          name={`forms_of_smoking[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
          data={SmokingType}
        />
      </td>
    );
  } else if (props.field === "frequency") {
    return (
      <td>
        <Field
          component={FormDropDownList}
          data={FrequencyValues}
          name={`forms_of_smoking[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "occurence") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`forms_of_smoking[${props.dataIndex}].${props.field}`}
          validator={requiredMax50Validator}
        />
      </td>
    );
  } else if (props.field === "nb_months") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`forms_of_smoking[${props.dataIndex}].${props.field}`}
          format={"n0"}
          min={1}
          validator={requiredValidator}
        />
      </td>
    );
  }
};

const CommandCell = (cellProps) => {
  const onRemove = React.useContext(onRemoveContext);
  // Consume the state context, which will give you both the state and the setter.
  const [state, setState] = React.useContext(stateContext);
  const currentContext = React.useContext(MyContext);
  const onClick = (e) => {
    e.preventDefault();
    if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
      onRemove({ index: cellProps.dataIndex });
    } else {
      currentContext.deletearray.push(cellProps.dataItem.id);

      onRemove({ index: cellProps.dataIndex });
    }
  };

  return (
    <td>
      {cellProps.dataItem.id ? (
        ""
      ) : (
        <SvgIcon
          icon={trashIcon}
          style={{ color: "red", cursor: "pointer" }}
          onClick={onClick}
        />
      )}
    </td>
  );
};

const FormGrid = (fieldArrayRenderProps) => {
  const { validationMessage, visited } = fieldArrayRenderProps;
  const [state, setState] = React.useState({});
  const onAdd = React.useCallback(
    (e) => {
      e.preventDefault();
      fieldArrayRenderProps.onUnshift({
        value: {
          status: "new",
          start_date: "",
          smoking_form: "",
          frequency: null,
          nb_months: null,
          occurence: "",
        },
      });
    },
    [fieldArrayRenderProps.onUnshift]
  );

  return (
    <div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}

      <stateContext.Provider value={[state, setState]}>
        <onRemoveContext.Provider value={fieldArrayRenderProps.onRemove}>
          <GridTelerik
            style={{
              maxHeight: "500px",
            }}
            data={fieldArrayRenderProps.value}
          >
            <GridToolbar>
              <div className="float-right">
                <Button primary={true} type={"button"} onClick={onAdd}>
                  {languageContext.new.toLanguageString("custom.new")}
                </Button>
              </div>
            </GridToolbar>
            <Column
              field="code"
              title={
                <>
                  <span>
                    {languageContext.new.toLanguageString("custom.id")}
                  </span>
                </>
              }
            />

            <Column
              field="start_date"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.startdate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.startdate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column
              field="smoking_form"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.formofsmoking"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString(
                      "custom.formofsmoking"
                    )}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="frequency"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.frequency"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.frequency")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column
              field="occurence"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.occurence"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.occurence")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column
              field="nb_months"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.numberofmonthssmoking"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString(
                      "custom.numberofmonthssmoking"
                    )}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column cell={CommandCell} filterable={false} width="50px" />
          </GridTelerik>
        </onRemoveContext.Provider>
      </stateContext.Provider>
    </div>
  );
};

export function ListSmokingHistory(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();
  languageContext.new = useLocalization();

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  //variables
  const [approved, setApproved] = useState(false);
  const [approvedformchanged, setapprovedformchanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //form ref to detect modification of the form
  const [deletearray, setdeletearray] = useState([]);
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  const [formradioValue, setformradioValue] = useState("no");
  const [radioValue, setradioValue] = useState("no");
  const [AllData, setAllData] = useState(null);
  const [ModifiedData, setModifiedData] = useState();

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setSelected(value);
    setapprovedformchanged(false);
  };

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);
  const { data: notes, Noteserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  const { data: checkapprove, checkapproveerror } = useSWR(
    props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  //attachement data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attchementserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    props.location.state
      ? [
          APIURL + "/opera/baseline/addsmokinghistory/",
          props.location.state.data.id,
        ]
      : null,
    fetcher1
  );

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                toggleDeditNote();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                toggleDdelete();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  useEffect(() => {
    let array = [];

    if (data) {
      if (data.message === "no data") {
        setAllData(null);
      } else {
        if (data.SmokingForms) {
          data.SmokingForms.map((d) => {
            array.push({
              id: d.id,
              start_date: new Date(d.start_date),
              smoking_form: { name: d.smoking_form, value: d.smoking_form },
              frequency: { name: d.frequency, value: d.frequency },
              nb_months: d.nb_months,
              occurence: d.occurence,
              smoking_history: d.smoking_history,
              code: d.code,
            });
          });
        }

        setAllData({
          created_by: data.created_by,
          createdBy: data.createdBy,
          id: data.id,
          missing_information: data.missing_information,
          missing_information_reason: data.missing_information_reason,
          modified_by: data.modified_by,
          modifiedBy: data.modifiedBy,
          nb_pack: data.nb_pack,
          presenceof_other_form: data.presenceof_other_form,
          section: data.section,
          smoking_status: data.smoking_status
            ? { name: data.smoking_status, value: data.smoking_status }
            : null,
          stop_date: data.stop_date ? new Date(data.stop_date) : null,
          study_subject: data.study_subject,
          forms_of_smoking: array,
        });

        setradioValue(data.missing_information === true ? "yes" : "no");
        setformradioValue(data.presenceof_other_form === true ? "yes" : "no");
      }
    }

    if (checkapprove) {
      setApproved(checkapprove);
    }
  }, [data, checkapprove]);

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  const CreateSmokingHistory = (dataItem) => {
    if (dataItem.isValid) {
      if (AllData) {
        if (dataItem.isModified) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let array = [];
        if (dataItem.values.forms_of_smoking) {
          dataItem.values.forms_of_smoking.map((d) => {
            array.push({
              status: d.status ? d.status : "edit",
              start_date: moment(d.start_date).format("YYYY-MM-DD"),
              frequency: d.frequency.value,
              nb_months: d.nb_months,
              occurence: d.occurence,
              smoking_form: d.smoking_form.value,
              study_subject: props.location.state.data.id,
            });
          });
        }
        setLoading(true);
        axios
          .post(APIURL + "/opera/baseline/addsmokinghistory/", {
            created_by: UserDetail.id,
            modified_by: null,
            study_subject: props.location.state.data.id,
            section: props.location.state.section.id,
            smoking_status: dataItem.values.smoking_status
              ? dataItem.values.smoking_status.value
              : "",
            stop_date: dataItem.values.stop_date
              ? moment(dataItem.values.stop_date).format("YYYY-MM-DD")
              : null,

            nb_pack: dataItem.values.nb_pack,
            presenceof_other_form: formradioValue,
            missing_information: radioValue,
            missing_information_reason:
              dataItem.values.missing_information_reason,
            forms_of_smoking: array,
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
          });
      }
    }
  };

  //TAB STRIP
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    if (selected === 1) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setSelected(e.selected);
      }
    } else if (
      MyForm.current &&
      Object.keys(MyForm.current.modified).length !== 0
    ) {
      setSelectedTab(e.selected);
      toggleDTabStripChange();
    } else {
      setSelected(e.selected);
    }
  };

  const [Subselected, setSubSelected] = React.useState(0);

  const handleSubSelect = (e) => {
    setSubSelected(e.selected);
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const CreateModification = (dataItem) => {
    let array = [];
    if (ModifiedData.forms_of_smoking) {
      ModifiedData.forms_of_smoking.map((d) => {
        array.push({
          id: d.id,
          status: d.status ? d.status : "edit",
          start_date: moment(d.start_date).format("YYYY-MM-DD"),
          frequency: d.frequency.value,
          nb_months: d.nb_months,
          occurence: d.occurence,
          smoking_form: d.smoking_form.value,
          study_subject: props.location.state.data.id,
        });
      });
    }
    toggleDModifyreason(false);
    setLoading(true);
    axios
      .put(
        APIURL + "/opera/baseline/updatesmokinghistory/" + AllData.id + "/",
        {
          created_by: AllData.created_by,
          modified_by: UserDetail.id,
          study_subject: props.location.state.data.id,
          section: props.location.state.section.id,
          smoking_status: ModifiedData.smoking_status
            ? ModifiedData.smoking_status.value
            : "",
          stop_date: ModifiedData.stop_date
            ? moment(ModifiedData.stop_date).format("YYYY-MM-DD")
            : null,
          nb_pack: ModifiedData.nb_pack,
          presenceof_other_form: formradioValue,
          missing_information: radioValue,
          missing_information_reason: ModifiedData.missing_information_reason,
          forms_of_smoking: array,
          modification_reason: dataItem.modification_reason,
        }
      )
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
        setdeletearray([]);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
        setdeletearray([]);
      });
  };

  //Radio Missing CHanging State
  const handleRadio1Change = (e, formRenderProps) => {
    setradioValue(e.value);
    formRenderProps.onChange("missing_information_reason", { value: "" });
  };

  const handleRadio2Change = (e, formRenderProps) => {
    setradioValue(e.value);
    formRenderProps.onChange("smoking_status", { value: null });
    formRenderProps.onChange("stop_date", { value: null });
    formRenderProps.onChange("nb_pack", { value: null });
    formRenderProps.onChange("forms_of_smoking", { value: [] });
  };

  //Radio Form Smoking Radio
  const handleRadio3Change = (e, formRenderProps) => {
    setformradioValue(e.value);
    formRenderProps.onChange("forms_of_smoking", { value: [] });
  };
  const handleRadio4Change = (e, formRenderProps) => {
    setformradioValue(e.value);
  };

  const handleSmokingStatusChange = (e, formRenderProps) => {
    formRenderProps.onChange("nb_pack", { value: null });
    formRenderProps.onChange("stop_date", { value: null });
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };

  //SWR ESSENTIAL

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (error && Noteserror && Attchementserror)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || approved) {
      boolean = true;
    }
    return boolean;
  };

  return (
    <TrialMap>
      {(!data || !notes || !attachements) && loadingPanel}
      <MyContext.Provider
        value={{
          deletearray: deletearray,
        }}
      >
        <div className="row">
          <div className="col-12">
            <Card>
              <CardHeader className="DetailFormHeader">
                <div className="row">
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.subjectid")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString(
                      "custom.subjectinitials"
                    )}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.study")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.protocolid")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.sitenumber")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.createdby")}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-responsive-overflow subject-id-style">
                    {props.location.state
                      ? props.location.state.data.crf_id
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.subject_initial
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.study_name
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.study_protocol
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.subject_center
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.createdBy
                      : ""}
                  </div>
                </div>
                <hr className="RowRoleHr" />
                <div className="row">
                  <div className="col pages-big-title">
                    <strong>
                      {localizationService.toLanguageString(
                        "custom.smokinghistory"
                      )}
                    </strong>
                  </div>
                  <div className="col">
                    <div className="float-right">
                      <Button onClick={goBack} className="back-button-style">
                        {localizationService.toLanguageString("custom.back")}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col">
                    <span className="k-form-error">
                      *
                      {localizationService.toLanguageString(
                        "custom.pleasefillmedicalhistoryrelated"
                      )}
                    </span>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <TabStrip selected={selected} onSelect={handleSelect}>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.smokinghistory"
                )}
              >
                <Form
                  initialValues={{
                    smoking_status: AllData ? AllData.smoking_status : null,
                    stop_date: AllData ? AllData.stop_date : null,
                    nb_pack: AllData ? AllData.nb_pack : null,
                    presenceof_other_form: AllData
                      ? AllData.presenceof_other_form
                      : "",
                    missing_information_reason: AllData
                      ? AllData.missing_information_reason
                      : "",
                    forms_of_smoking: AllData ? AllData.forms_of_smoking : [],
                  }}
                  ref={MyForm}
                  onSubmitClick={CreateSmokingHistory}
                  key={JSON.stringify(AllData)}
                  render={(formRenderProps) => (
                    <FormElement className="">
                      <fieldset className={"k-form-fieldset"}>
                        <Tooltip
                          openDelay={0}
                          anchorElement="target"
                          parentTitle={true}
                          position={"top"}
                        >
                          <Prompt
                            when={PromptFunction(formRenderProps)}
                            message={localizationService.toLanguageString(
                              "custom.promptmessage"
                            )}
                          />

                          <div className="row">
                            <div className="col-6">
                              <strong>
                                {localizationService.toLanguageString(
                                  "custom.informationstatusquestion"
                                )}
                                ?
                              </strong>
                            </div>
                            <div className="col-3">
                              {" "}
                              <RadioButton
                                name="radio1"
                                value="yes"
                                checked={radioValue === "yes"}
                                label={localizationService.toLanguageString(
                                  "custom.yes"
                                )}
                                onChange={(e) =>
                                  handleRadio1Change(e, formRenderProps)
                                }
                                // disabled={
                                //   AllData &&
                                //   AllData.missing_information === false
                                //     ? true
                                //     : false
                                // }
                              />
                            </div>
                            <div className="col-3">
                              {" "}
                              <RadioButton
                                name="radio2"
                                value="no"
                                checked={radioValue === "no"}
                                label={localizationService.toLanguageString(
                                  "custom.no"
                                )}
                                onChange={(e) =>
                                  handleRadio2Change(e, formRenderProps)
                                }
                                // disabled={
                                //   AllData &&
                                //   AllData.missing_information === false
                                //     ? true
                                //     : false
                                // }
                              />
                            </div>
                            {radioValue === "yes" ? (
                              <div className="row">
                                <div className="col">
                                  <Field
                                    name={"missing_information_reason"}
                                    label={localizationService.toLanguageString(
                                      "custom.missingreason"
                                    )}
                                    validator={requiredValidator}
                                    component={RequiredFormTextArea}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {radioValue === "no" ? (
                            <>
                              <AppBar className="FormAppBar">
                                <AppBarSection>
                                  <div>
                                    <strong>
                                      {localizationService.toLanguageString(
                                        "custom.cigarettessmoking"
                                      )}
                                    </strong>
                                  </div>
                                </AppBarSection>
                              </AppBar>
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-6">
                                    <Field
                                      id={"smoking_status"}
                                      name={"smoking_status"}
                                      label={localizationService.toLanguageString(
                                        "custom.smokingstatus"
                                      )}
                                      data={SmokingStatus}
                                      component={RequiredDropDownList}
                                      onChange={(e) =>
                                        handleSmokingStatusChange(
                                          e,
                                          formRenderProps
                                        )
                                      }
                                      validator={requiredValidator}
                                    />
                                  </div>
                                  <div className="col-6">
                                    {formRenderProps.valueGetter(
                                      "smoking_status"
                                    ) &&
                                    (formRenderProps.valueGetter(
                                      "smoking_status"
                                    ).value === "Current Smoker" ||
                                      formRenderProps.valueGetter(
                                        "smoking_status"
                                      ).value === "Former Smoker") ? (
                                      <Field
                                        id={"nb_pack"}
                                        name={"nb_pack"}
                                        label={localizationService.toLanguageString(
                                          "custom.nbpacksmonths"
                                        )}
                                        component={RequiredFormNumericTextBox}
                                        min={0}
                                        format={"n0"}
                                        validator={requiredValidator}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-6">
                                    {formRenderProps.valueGetter(
                                      "smoking_status"
                                    ) &&
                                    formRenderProps.valueGetter(
                                      "smoking_status"
                                    ).value === "Former Smoker" ? (
                                      <Field
                                        id={"stop_date"}
                                        name={"stop_date"}
                                        label={localizationService.toLanguageString(
                                          "custom.datesmokingstopped"
                                        )}
                                        component={RequiredFormDatePicker}
                                        validator={requiredValidator}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>

                              <AppBar className="FormAppBar">
                                <AppBarSection>
                                  <div>
                                    <strong>
                                      {localizationService.toLanguageString(
                                        "custom.otherformofsmoking"
                                      )}
                                    </strong>
                                  </div>
                                </AppBarSection>
                              </AppBar>
                              <div className="row">
                                <div className="col-6">
                                  {localizationService.toLanguageString(
                                    "custom.haveothersmokingform"
                                  )}
                                </div>
                                <div className="col-3">
                                  {" "}
                                  <RadioButton
                                    name="radio3"
                                    value="yes"
                                    checked={formradioValue === "yes"}
                                    label={localizationService.toLanguageString(
                                      "custom.yes"
                                    )}
                                    onChange={(e) =>
                                      handleRadio3Change(e, formRenderProps)
                                    }
                                    // disabled={
                                    //   AllData &&
                                    //   AllData.presenceof_other_form === true
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                </div>
                                <div className="col-3">
                                  {" "}
                                  <RadioButton
                                    name="radio4"
                                    value="no"
                                    checked={formradioValue === "no"}
                                    label={localizationService.toLanguageString(
                                      "custom.no"
                                    )}
                                    onChange={(e) =>
                                      handleRadio4Change(e, formRenderProps)
                                    }
                                    // disabled={
                                    //   AllData &&
                                    //   AllData.presenceof_other_form === true
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                </div>
                              </div>
                              {formradioValue === "yes" ? (
                                <div className="row mt-4">
                                  <div className="col">
                                    <FieldArray
                                      name="forms_of_smoking"
                                      component={FormGrid}
                                      validator={arrayLengthValidator}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </Tooltip>
                      </fieldset>
                      <Tooltip
                        openDelay={0}
                        anchorElement="target"
                        parentTitle={true}
                        position={"left"}
                      >
                        <div className="float-right">
                          <div title={disabletitle()}>
                            <Button
                              primary={true}
                              type={"submit"}
                              className="mr-2em"
                              disabled={disablefunction()}
                            >
                              {loading
                                ? localizationService.toLanguageString(
                                    "custom.loading"
                                  )
                                : localizationService.toLanguageString(
                                    "custom.save"
                                  )}
                            </Button>
                          </div>
                        </div>
                      </Tooltip>
                    </FormElement>
                  )}
                />
              </TabStripTab>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.approvesection"
                )}
              >
                <ApproveStudySubject
                  study_subject={props.location.state.data.id}
                  section={props.location.state.section.id}
                  data={props.location.state.data}
                  parentCallBack={ChangeApproveSection}
                />
              </TabStripTab>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.notesnattachements"
                )}
              >
                <TabStrip selected={Subselected} onSelect={handleSubSelect}>
                  <TabStripTab
                    title={localizationService.toLanguageString("custom.notes")}
                  >
                    <GridTelerik data={notes}>
                      <GridToolbar>
                        <Button
                          primary={true}
                          onClick={() => {
                            {
                              EditSubject
                                ? toggleDaddNote()
                                : toggleDPermission();
                            }
                          }}
                        >
                          {localizationService.toLanguageString("custom.new")}
                        </Button>
                      </GridToolbar>

                      <Column
                        field={"name"}
                        title={localizationService.toLanguageString(
                          "custom.name"
                        )}
                        cell={NoteNameTemplate}
                      />
                      <Column
                        field={"date_created"}
                        title={localizationService.toLanguageString(
                          "custom.datecreated"
                        )}
                        cell={DateCreatedTemplate}
                      />
                      <Column
                        title={localizationService.toLanguageString(
                          "custom.actions"
                        )}
                        width="100px"
                        cell={Noteactions}
                      />
                    </GridTelerik>
                  </TabStripTab>

                  <TabStripTab
                    title={localizationService.toLanguageString(
                      "custom.attachements"
                    )}
                  >
                    <GridTelerik data={attachements}>
                      <GridToolbar>
                        <Button
                          primary={true}
                          onClick={() => {
                            {
                              EditSubject
                                ? toggleDaddFile()
                                : toggleDPermission();
                            }
                          }}
                        >
                          {localizationService.toLanguageString("custom.new")}
                        </Button>
                      </GridToolbar>
                      <Column
                        field={"title"}
                        title={localizationService.toLanguageString(
                          "custom.title"
                        )}
                      />

                      <Column
                        field={"date_created"}
                        title={localizationService.toLanguageString(
                          "custom.dateadded"
                        )}
                        cell={DateCreatedTemplate}
                      />

                      <Column
                        title={localizationService.toLanguageString(
                          "custom.actions"
                        )}
                        width="100px"
                        cell={Fileactions}
                      />
                    </GridTelerik>
                  </TabStripTab>
                </TabStrip>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      </MyContext.Provider>
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
          height={"400px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}
      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}

      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}

      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
    </TrialMap>
  );
}

export default ListSmokingHistory;
