
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import axios from "axios";
import APIURL from "../APIURL";

export default class Excel extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      demography: [],
      InclusionExclusion: [],
      vitalsigns: [],
      smokingHistory: [],
      medicalHistory: [],
      mRCCHistory: [],
      metastaticHistory: [],
      PrognosticFactors: [],
      CurrentTherapy: [],
      LaboratoryTests: [],
      BloodChemistryTest: [],
      ConcomitantMedications: [],
      Month_3: [],
      Month_6: [],
      Month_12: [],
      Month_36: [],
  
    }
  }

  componentDidMount(){
   
    this.fetchData()

  }
  

  addCode = ({ value, dataItem }) => {
    return <td>{value}{dataItem.dataItem.fields.number}</td>;
  }

   fetchData(){
    // let SID = window.location.href.split('/')[5]
    axios.get(APIURL + "/common/getall/", {
      params: {
        // section: ev.itemTarget.props.section_name,
        pk: this.props.SID,
        // pk:32
      },
    })
      .then((res) => {
        // let response = JSON.parse(res.data.message[0])
        // 13
       
        let datas = []
        let demography = []
        let vitalsigns = []
        let smokingHistory = []
        let medicalHistory = []
        let metastaticHistory = []
        let PrognosticFactors = []
        let CurrentTherapy = []
        let LaboratoryTests = []
        let BloodChemistryTest = []
        let HematologyTest = []
        let ConcomitantMedications = []
        let InclusionExclusion = []
        let mRCCHistory = []


        const test1 = res.data.message.map((todo, index) =>{
          let result = JSON.parse(todo)
          if(todo[0].model === "screening.demography"){
          }

          this.setState(prevState => ({
            data: [...prevState.data, JSON.parse(todo)]
          }))
     
        if (res.data.message[14]) {
          datas = JSON.parse(res.data.message[14])
        }
        if (result[0].model=== "screening.demography") {
          demography = result
        }
        if (result[0].model=== "baseline.vitalsigns") {
          vitalsigns = result
        }
        if (result[0].model=== "baseline.smokinghistory") {
          smokingHistory = result
        }
        if (result[0].model=== "baseline.medicalhistory") {
          medicalHistory = result
        }
        if (result[0].model=== "baseline.metastaticsite") {
          metastaticHistory = result
        }
        if (result[0].model=== "baseline.prognosticfactors") {
          PrognosticFactors = result
        }
        if (result[0].model=== "baseline.currenttherapy") {
          CurrentTherapy = result
        }
        if (result[0].model=== "common.laboratorytest") {
          LaboratoryTests = result
        }
        if (result[0].model=== "common.bloodchemistrytest") {
          BloodChemistryTest = result
        }
        if (result[0].model=== "common.hematologytest") {
          HematologyTest = result
        }
        if (result[0].model=== "baseline.concomitantmedication") {
          ConcomitantMedications = result
        }
        if (result[0].model=== "screening.inclusionexclusion") {
          InclusionExclusion = result
        }
        if (result[0].model=== "baseline.cancerhistory") {
          mRCCHistory = result
        }
      })
        this.setState({ data: datas })
        this.setState({ demography: demography })
        this.setState({ vitalsigns: vitalsigns })
        this.setState({ smokingHistory: smokingHistory })
        this.setState({ medicalHistory: medicalHistory })
        this.setState({ metastaticHistory: metastaticHistory })
        this.setState({ PrognosticFactors: PrognosticFactors })
        this.setState({ CurrentTherapy: CurrentTherapy })
        this.setState({ LaboratoryTests: LaboratoryTests })
        this.setState({ BloodChemistryTest: BloodChemistryTest })
        this.setState({ HematologyTest: HematologyTest })
        this.setState({ ConcomitantMedications: ConcomitantMedications })
        this.setState({ InclusionExclusion: InclusionExclusion })
        this.setState({ mRCCHistory: mRCCHistory })


        console.log("SSSSSSS", this.state.demography)
        console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBB", res.data)
        // console.log("FFFFFF",res.data.message[5])
        this.state.BloodChemistryTest.forEach((number) => {
          number.model = "Blood Chemistry Test"
          if (number.fields.section === 5) {

            this.setState(prevState => ({
              Month_3: [...prevState.Month_3, number]
            }))
          }
          if (number.fields.section === 4) {
            this.setState(prevState => ({
              Month_6: [...prevState.Month_6, number]
            }))
          }
          if (number.fields.section === 3) {
            this.setState(prevState => ({
              Month_12: [...prevState.Month_12, number]
            }))
          }

          if (number.fields.section === 2) {
            this.setState(prevState => ({
              Month_36: [...prevState.Month_36, number]
            }))
          }
        })

        this.state.HematologyTest.forEach((number) => {
          number.model = "Hematology Chemistry Test"
          if (number.fields.section === 5) {


            this.setState(prevState => ({
              Month_3: [...prevState.Month_3, number]
            }))
          }
          if (number.fields.section === 4) {
            this.setState(prevState => ({
              Month_6: [...prevState.Month_6, number]
            }))
          }
          if (number.fields.section === 3) {
            this.setState(prevState => ({
              Month_12: [...prevState.Month_12, number]
            }))
          }

          if (number.fields.section === 2) {
            this.setState(prevState => ({
              Month_36: [...prevState.Month_36, number]
            }))
          }
        })
      });

  }


  _export;
  export = () => {
    const optionsGridOne = this._exportGridOne.workbookOptions();
    const optionsGridSixteen = this._exportGridSixteen.workbookOptions();
    const optionsGridTwo = this._exportGridTwo.workbookOptions();
    const optionsGridThree = this._exportGridThree.workbookOptions();
    const optionsGridFour = this._exportGridFour.workbookOptions();
    const optionsGridFive = this._exportGridFive.workbookOptions();
    const optionsGridSix = this._exportGridSix.workbookOptions();
    const optionsGridSeven = this._exportGridSeven.workbookOptions();
    const optionsGridEight = this._exportGridEight.workbookOptions();
    const optionsGridNine = this._exportGridNine.workbookOptions();
    const optionsGridTen = this._exportGridTen.workbookOptions();
    const optionsGridEleven = this._exportGridEleven.workbookOptions();
    const optionsGridTwelve = this._exportGridTwelve.workbookOptions();
    const optionsGridThirteen = this._exportGridThirteen.workbookOptions();
    const optionsGridFourteen = this._exportGridFourteen.workbookOptions();
    const optionsGridFifteen = this._exportGridFifteen.workbookOptions();
    const optionsGridSeventeen = this._exportGridSeventeen.workbookOptions();

    optionsGridOne.sheets[1] = optionsGridSixteen.sheets[0];
    optionsGridOne.sheets[2] = optionsGridTwo.sheets[0];
    optionsGridOne.sheets[3] = optionsGridThree.sheets[0];
    optionsGridOne.sheets[4] = optionsGridFour.sheets[0];
    optionsGridOne.sheets[5] = optionsGridFive.sheets[0];
    optionsGridOne.sheets[6] = optionsGridSeventeen.sheets[0];
    optionsGridOne.sheets[7] = optionsGridSix.sheets[0];
    optionsGridOne.sheets[8] = optionsGridSeven.sheets[0];
    optionsGridOne.sheets[9] = optionsGridEight.sheets[0];
    optionsGridOne.sheets[10] = optionsGridNine.sheets[0];
    optionsGridOne.sheets[11] = optionsGridTen.sheets[0];
    optionsGridOne.sheets[12] = optionsGridEleven.sheets[0];
    optionsGridOne.sheets[13] = optionsGridTwelve.sheets[0];
    optionsGridOne.sheets[14] = optionsGridThirteen.sheets[0];
    optionsGridOne.sheets[15] = optionsGridFourteen.sheets[0];
    optionsGridOne.sheets[16] = optionsGridFifteen.sheets[0];


    optionsGridOne.sheets[0].title = "Demography"
    optionsGridOne.sheets[1].title = "Inclusion Exclusion"
    optionsGridOne.sheets[2].title = "Vital Signs"
    optionsGridOne.sheets[3].title = "Smoking History"
    optionsGridOne.sheets[4].title = "Medical History"
    optionsGridOne.sheets[5].title = "Metastatic History"
    optionsGridOne.sheets[6].title = "Metastatic Renal Cell Carcinoma (mRCC) History"
    optionsGridOne.sheets[7].title = "Prognostic Factors"
    optionsGridOne.sheets[8].title = "Current Therapy"
    optionsGridOne.sheets[9].title = "Laboratory Tests"
    optionsGridOne.sheets[10].title = "Blood Chemistry Test"
    optionsGridOne.sheets[11].title = "Hematology Test"
    optionsGridOne.sheets[12].title = "Concomitant Medications"
    optionsGridOne.sheets[13].title = "Month-3"
    optionsGridOne.sheets[14].title = "Month-6"
    optionsGridOne.sheets[15].title = "Month-12"
    optionsGridOne.sheets[16].title = "Month-36"

    this._exportGridOne.save(optionsGridOne);
  }
  render() {
return (
      <div>
        {/* style={{ display: "none" }} */}
        <div style={{ display: "none" }}>

          <ExcelExport
            data={this.state.demography}
            ref={(exporter) => { this._exportGridOne = exporter; }}
          >
            <Grid data={this.state.demography} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.birthdate" title="Birth Date" />
              <GridColumn field="fields.country.0.name" title="Country" />
              <GridColumn field="fields.gender" title="Gender" />
              <GridColumn field="fields.nationality" title="Nationality" />
            </Grid>
          </ExcelExport>
          
          <ExcelExport
            data={this.state.InclusionExclusion}
            ref={(exporter) => { this._exportGridSixteen = exporter; }}
          >
            <Grid data={this.state.InclusionExclusion} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.in_above_eighteen" title="Patients ≥ 18 years old" />
              <GridColumn field="fields.in_historical_cancer" title="Histologically confirmed metastatic renal cell cancer" />
              <GridColumn field="fields.in_required_treatement" title="Patients treated by immunotherapy and/or TKI" />
              <GridColumn field="fields.in_informed_signed" title="Signed informed consent form obtained prior to study entry only for living patients" />
              <GridColumn field="fields.ex_another_malignancy" title="History of another malignancy within the past 5 years except cured basal cell carcinoma of the skin or excised carcinoma in situ of the cervix" />
              <GridColumn field="fields.ex_pregnant" title="Pregnancy" />
              <GridColumn field="fields.ex_another_trial" title="Current participation in another clinical trial" />
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={this.state.vitalsigns}
            ref={(exporter) => { this._exportGridTwo = exporter; }}
          >
            <Grid data={this.state.vitalsigns} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.assessment_date" title="Date Of Assessement" />
              <GridColumn field="fields.height" title="Height(CM)" />
              <GridColumn field="fields.weight" title="Weight(KG)" />
              <GridColumn field="fields.pulse" title="Pulse(Beats/min)" />
              <GridColumn field="fields.respiratory_rate" title="Respiratory Rate" />
              <GridColumn field="fields.body_temperature" title="Body Temperature(°C)" />
              <GridColumn field="fields.blood_pressure" title="Blood Pressure(mmHg/mmHg)" />
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={this.state.smokingHistory}
            ref={(exporter) => { this._exportGridThree = exporter; }}
          >
            <Grid data={this.state.smokingHistory} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.smoking_status" title="subject’s smoking status" />
              <GridColumn field="fields.presenceof_other_form" title="Presence of other smoking forms?" />
              <GridColumn field="fields.date_created" title="Date Created" />
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={this.state.medicalHistory}
            ref={(exporter) => { this._exportGridFour = exporter; }}
          >
            <Grid data={this.state.medicalHistory} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.number" title="Code" cell={(dataItem) => this.addCode({ value: "MH-", dataItem })} />
              <GridColumn field="fields.history" title="History/Condition/Surgery" />
              <GridColumn field="fields.date_diagnosis" title="Diagnosis/Surgery Date" />
              <GridColumn field="fields.active_problem" title="Active problem at start of study drug" />
            </Grid>
          </ExcelExport>
          {/* metastatic site */}
          <ExcelExport
            data={this.state.metastaticHistory}
            ref={(exporter) => { this._exportGridFive = exporter; }}
          >
            <Grid data={this.state.metastaticHistory} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.number" title="Code" cell={(dataItem) => this.addCode({ value: "MS-", dataItem })} />
              <GridColumn field="fields.spread_site" title="Presence" />
              <GridColumn field="fields.site" title="Site" />
              <GridColumn field="fields.date_assessment" title="Date Of Assessement" />
              <GridColumn field="fields.method" title="Method" />
              <GridColumn field="fields.other_method" title="Other Method" />
            </Grid>
          </ExcelExport>
          <ExcelExport
            data={this.state.PrognosticFactors}
            ref={(exporter) => { this._exportGridSix = exporter; }}
          >
            <Grid data={this.state.PrognosticFactors} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.prognostic_factors" title="MSK Poor Prognostic Factors (Memorial Sloan-kettering)" />
              <GridColumn field="fields.risk_group" title="Patient Risk Group" />
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={this.state.CurrentTherapy}
            ref={(exporter) => { this._exportGridSeven = exporter; }}
          >
            <Grid data={this.state.CurrentTherapy} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.number" title="Code" cell={(dataItem) => this.addCode({ value: "CT-", dataItem })} />
              <GridColumn field="fields.Section" title="Section" />
              <GridColumn field="fields.treatment_line" title="Treatment Line" />
              <GridColumn field="fields.therapy" title="Therapy" />
              <GridColumn field="fields.dose" title="Dose" />
              <GridColumn field="fields.unit" title="Unit" />
              <GridColumn field="fields.frequency" title="Frequency" />
              <GridColumn field="fields.start_date" title="Start Date" />
              <GridColumn field="fields.until_present" title="Until Present" />
              <GridColumn field="fields.end_date" title="End Date" />
              <GridColumn field="fields.reasone" title="Reason" />
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={this.state.LaboratoryTests}
            ref={(exporter) => { this._exportGridEight = exporter; }}
          >
            <Grid data={this.state.LaboratoryTests} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.date_urinarytest" title="Urinary Sample Date" />
              <GridColumn field="fields.urinary_protein" title="Urinary Protein" />
              <GridColumn field="fields.date_creantininetest" title="Creatinine Sample Date" />
              <GridColumn field="fields.creatinine_clearance_value" title="Creatinine Clearance" />
              <GridColumn field="fields.gfr_value" title="Glomerular filtration rate" />
              <GridColumn field="fields.date_paramedicaltest" title="Date of ECG" />
              <GridColumn field="fields.ecg_status" title="ECG" />
              <GridColumn field="fields.karnofski_status" title="Karnofski Performance Status" />
            </Grid>
          </ExcelExport>
          <ExcelExport
            data={this.state.BloodChemistryTest}
            ref={(exporter) => { this._exportGridNine = exporter; }}
          >
            <Grid data={this.state.BloodChemistryTest} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.test_present" title="Presence" />
              <GridColumn field="fields.notpresent_reason" title="Missing Reason" />
              <GridColumn field="fields.test_name" title="Test" />
              <GridColumn field="fields.result" title="Result" />
              <GridColumn field="fields.lower_limit" title="Lower limit of normal" />
              <GridColumn field="fields.upper_limit" title="Upper limit of normal*" />
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={this.state.HematologyTest}
            ref={(exporter) => { this._exportGridTen = exporter; }}
          >
            <Grid data={this.state.HematologyTest} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.test_present" title="Presence" />
              <GridColumn field="fields.notpresent_reason" title="Missing Reason" />
              <GridColumn field="fields.test_name" title="Test" />
              <GridColumn field="fields.result" title="Result" />
              <GridColumn field="fields.lower_limit" title="Lower limit of normal" />
              <GridColumn field="fields.upper_limit" title="Upper limit of normal*" />
            </Grid>
          </ExcelExport>
          <ExcelExport
            data={this.state.ConcomitantMedications}
            ref={(exporter) => { this._exportGridEleven = exporter; }}
          >
            <Grid data={this.state.ConcomitantMedications} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
                {/* cell={(dataItem) => this.addCode( "CM-",dataItem)}  */}
              </GridToolbar>
              <GridColumn field="fields.number" title="Code" cell={(dataItem) => this.addCode({ value: "CM-", dataItem })} />
              <GridColumn field="fields.medication" title="Medication(Trade Name if Possible)" />
              <GridColumn field="fields.reason" title="Reason" />
              <GridColumn field="fields.single_dose" title="Single dose" />
              <GridColumn field="fields.unit" title="Unit" />
              <GridColumn field="fields.upper_limit" title="Other Unit" />
              <GridColumn field="fields.frequency" title="Frequency" />
              <GridColumn field="fields.other_frequency" title="Other Frequency" />
              <GridColumn field="fields.start_date" title="Start Date" />
              <GridColumn field="fields.end_date" title="End Date" />
              <GridColumn field="fields.continuous_atfinal" title="True if continuous at final exam" />
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={this.state.Month_3}
            ref={(exporter) => { this._exportGridTwelve = exporter; }}
          >
            <Grid data={this.state.Month_3} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="model" title="Test" />
              <GridColumn field="fields.test_present" title="Presence" />
              <GridColumn field="fields.notpresent_reason" title="Missing Reason" />
              <GridColumn field="fields.test_name" title="Test" />
              <GridColumn field="fields.result" title="Result" />
              <GridColumn field="fields.lower_limit" title="Lower limit of normal" />
              <GridColumn field="fields.upper_limit" title="Upper limit of normal*" />
            </Grid>
          </ExcelExport>
          <ExcelExport
            data={this.state.Month_6}
            ref={(exporter) => { this._exportGridThirteen = exporter; }}
          >
            <Grid data={this.state.Month_6} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="model" title="Test" />
              <GridColumn field="fields.test_present" title="Presence" />
              <GridColumn field="fields.notpresent_reason" title="Missing Reason" />
              <GridColumn field="fields.test_name" title="Test" />
              <GridColumn field="fields.result" title="Result" />
              <GridColumn field="fields.lower_limit" title="Lower limit of normal" />
              <GridColumn field="fields.upper_limit" title="Upper limit of normal*" />
            </Grid>
          </ExcelExport>
          <ExcelExport
            data={this.state.Month_12}
            ref={(exporter) => { this._exportGridFourteen = exporter; }}
          >
            <Grid data={this.state.Month_12} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="model" title="Test" />
              <GridColumn field="fields.test_present" title="Presence" />
              <GridColumn field="fields.notpresent_reason" title="Missing Reason" />
              <GridColumn field="fields.test_name" title="Test" />
              <GridColumn field="fields.result" title="Result" />
              <GridColumn field="fields.lower_limit" title="Lower limit of normal" />
              <GridColumn field="fields.upper_limit" title="Upper limit of normal*" />
            </Grid>
          </ExcelExport>
          <ExcelExport
            data={this.state.Month_36}
            ref={(exporter) => { this._exportGridFifteen = exporter; }}
          >
            <Grid data={this.state.Month_36} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="model" title="Test" />
              <GridColumn field="fields.test_present" title="Presence" />
              <GridColumn field="fields.notpresent_reason" title="Missing Reason" />
              <GridColumn field="fields.test_name" title="Test" />
              <GridColumn field="fields.result" title="Result" />
              <GridColumn field="fields.lower_limit" title="Lower limit of normal" />
              <GridColumn field="fields.upper_limit" title="Upper limit of normal*" />
            </Grid>
          </ExcelExport>


          <ExcelExport
            data={this.state.mRCCHistory}
            ref={(exporter) => { this._exportGridSeventeen = exporter; }}
          >
            <Grid data={this.state.mRCCHistory} >
              <GridToolbar>
                <button
                  title="Export PDF"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn field="fields.diagnosis_stage" title="Stage of RCC at initial diagnosis" />
              <GridColumn field="fields.diagnosis_date" title="Date of initial diagnosis of RCC" />
              <GridColumn field="fields.location" title="Location" />
              <GridColumn field="fields.pathology" title="Pathology" />
              <GridColumn field="fields.neoadjuvant_therapy" title="Neoadjuvant Therapy" />
              <GridColumn field="fields.ex_pregnant" title="Adjuvant Therapy" />
              <GridColumn field="fields.surgery" title="Surgery" />
              <GridColumn field="fields.other" title="Other" />

            </Grid>
          </ExcelExport>

        
        </div>
        <button
          title="Export PDF"
          className="k-button k-primary"
          onClick={this.export}
        >
          Export to Excel
        </button>
      </div>
    );
  }
}

