const Units = [
  {
    value: "Milligrams",
    name: "Milligrams",
  },
  {
    value: "Micrograms",
    name: "Micrograms",
  },
  {
    value: "Milliliters",
    name: "Milliliters",
  },
  {
    value: "Other",
    name: "Other",
  },
];

export default Units;
