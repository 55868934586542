const TreatmentLines = [
  {
    value: "First Line",
    name: "First Line",
  },
  {
    value: "Second Line",
    name: "Second Line",
  },
  {
    value: "Third Line",
    name: "Third Line",
  },
];

export default TreatmentLines;
