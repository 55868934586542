//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { Error } from "@progress/kendo-react-labels";
import { AppBar, AppBarSection } from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { FieldArray } from "@progress/kendo-react-form";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  FormNumericTextBox,
  FormInput,
  FormDatePicker,
  RequiredFormTextArea,
  RequiredDropDownList,
  FormMultiSelect,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax50Validator,
} from "../common/Kendo/validators";

//component
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
import TrialMap from "../studysubject/TrialMap";
import AddFile from "../common/commoncomponents/AddFile";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import MskPoorPrognostic from "../common/commoncomponents/MskPoorPrognostic";
import PatientRiskGroup from "../common/commoncomponents/PatientRiskGroup";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
import TabChange from "../common/commoncomponents/TabChange";

export function ListPrognosticFactors(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();

  //variables
  const [approved, setApproved] = useState(false);
  const [approvedformchanged, setapprovedformchanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //form ref to detect modification of the form
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  const [radioValue, setradioValue] = useState("no");
  const [AllData, setAllData] = useState(null);
  const [mskPoorFactors, setmskPoorFactors] = useState([]);
  const [ModifiedData, setModifiedData] = useState();

  //attachement data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attchementserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setSelected(value);
    setapprovedformchanged(false);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                toggleDeditNote();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                toggleDdelete();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);
  const { data: notes, Noteserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  const { data: checkapprove, checkapproveerror } = useSWR(
    props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    props.location.state
      ? [
          APIURL + "/opera/baseline/addprognosticfactors/",
          props.location.state.data.id,
        ]
      : null,
    fetcher1
  );

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //USE EFFECT
  useEffect(() => {
    let array = [];
    let array2 = [];
    if (data) {
      if (data.message === "no data") {
        setAllData(null);
      } else {
        setAllData({
          created_by: data.created_by,
          createdBy: data.createdBy,
          id: data.id,
          prog_factors_missing: data.prog_factors_missing,
          prog_factors_missing_reason: data.prog_factors_missing_reason,
          modified_by: data.modified_by,
          modifiedBy: data.modifiedBy,
          risk_group: data.risk_group
            ? { name: data.risk_group, value: data.risk_group }
            : null,
          section: data.section,
        });
        if (data.prognostic_factors) {
          data.prognostic_factors.map((val) => {
            array2.push({
              name: val,
              value: val,
            });
          });
        }

        setmskPoorFactors(array2);
        setradioValue(data.prog_factors_missing === true ? "yes" : "no");
      }
    }
    if (checkapprove) {
      setApproved(checkapprove);
    }
  }, [data, checkapprove]);

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  //TAB STRIP
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    if (selected === 1) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setSelected(e.selected);
      }
    } else if (
      MyForm.current &&
      Object.keys(MyForm.current.modified).length !== 0
    ) {
      setSelectedTab(e.selected);
      toggleDTabStripChange();
    } else {
      setSelected(e.selected);
    }
  };
  //TAB STRIP
  const [Subselected, setSubSelected] = React.useState(0);

  const handleSubSelect = (e) => {
    setSubSelected(e.selected);
  };

  //radio  handle change
  const handleRadio1Change = (e, formRenderProps) => {
    setradioValue(e.value);
    formRenderProps.onChange("prognostic_factors", { value: [] });
    formRenderProps.onChange("risk_group", { value: null });
  };

  const handleRadio2Change = (e, formRenderProps) => {
    formRenderProps.onChange("prog_factors_missing_reason", { value: "" });
    setradioValue(e.value);
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const CreatePrognosticFactors = (dataItem) => {
    if (dataItem.isValid) {
      if (AllData) {
        if (dataItem.isModified) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let array2 = [];

        if (dataItem.values.prognostic_factors) {
          dataItem.values.prognostic_factors.map((d) => {
            array2.push(d.value);
          });
        }
        setLoading(true);
        axios
          .post(APIURL + "/opera/baseline/addprognosticfactors/", {
            created_by: UserDetail.id,
            modified_by: null,
            study_subject: props.location.state.data.id,
            section: props.location.state.section.id,
            prognostic_factors: dataItem.values.prognostic_factors
              ? array2
              : [],
            risk_group: dataItem.values.risk_group
              ? dataItem.values.risk_group.value
              : null,
            prog_factors_missing: radioValue,
            prog_factors_missing_reason: dataItem.values
              .prog_factors_missing_reason
              ? dataItem.values.prog_factors_missing_reason
              : "",
            prog_factors_missing_reason: dataItem.values
              .prog_factors_missing_reason
              ? dataItem.values.prog_factors_missing_reason
              : "",
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          });
      }
    }
  };

  const CreateModification = (dataItem) => {
    let array = [];

    if (ModifiedData.prognostic_factors) {
      ModifiedData.prognostic_factors.map((d) => {
        array.push(d.value);
      });
    }

    toggleDModifyreason(false);
    setLoading(true);
    axios
      .put(
        APIURL + "/opera/baseline/updateprognosticfactors/" + AllData.id + "/",
        {
          created_by: AllData.created_by,
          modified_by: UserDetail.id,
          study_subject: props.location.state.data.id,
          section: props.location.state.section.id,
          prognostic_factors: ModifiedData.prognostic_factors ? array : [],
          risk_group: ModifiedData.risk_group
            ? ModifiedData.risk_group.value
            : null,
          prog_factors_missing: radioValue,
          prog_factors_missing_reason: ModifiedData.prog_factors_missing_reason
            ? ModifiedData.prog_factors_missing_reason
            : "",
          prog_factors_missing_reason: ModifiedData.prog_factors_missing_reason
            ? ModifiedData.prog_factors_missing_reason
            : "",

          modification_reason: dataItem.modification_reason,
        }
      )
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      });
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || approved) {
      boolean = true;
    }
    return boolean;
  };

  if (error && Noteserror && Attchementserror)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <TrialMap>
      {(!data || !notes || !attachements) && loadingPanel}
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader className="DetailFormHeader">
              <div className="row">
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.subjectid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString(
                    "custom.subjectinitials"
                  )}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.study")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.protocolid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.sitenumber")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.createdby")}
                </div>
              </div>
              <div className="row">
                <div className="col text-responsive-overflow subject-id-style">
                  {props.location.state ? props.location.state.data.crf_id : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_initial
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_name
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_protocol
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_center
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.createdBy
                    : ""}
                </div>
              </div>
              <hr className="RowRoleHr" />
              <div className="row">
                <div className="col pages-big-title">
                  <strong>
                    {localizationService.toLanguageString(
                      "custom.prognosticfactors"
                    )}
                  </strong>
                </div>
                <div className="col">
                  <div className="float-right">
                    <Button onClick={goBack} className="back-button-style">
                      {localizationService.toLanguageString("custom.back")}
                    </Button>
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.prognosticfactors"
              )}
            >
              <Form
                initialValues={{
                  prognostic_factors: mskPoorFactors,
                  prog_factors_missing_reason: AllData
                    ? AllData.prog_factors_missing_reason
                    : "",
                  risk_group: AllData ? AllData.risk_group : null,
                }}
                ref={MyForm}
                onSubmitClick={CreatePrognosticFactors}
                key={JSON.stringify(AllData)}
                render={(formRenderProps) => (
                  <FormElement className="">
                    <fieldset className={"k-form-fieldset"}>
                      <Tooltip
                        openDelay={0}
                        anchorElement="target"
                        parentTitle={true}
                        position={"top"}
                      >
                        <Prompt
                          when={PromptFunction(formRenderProps)}
                          message={localizationService.toLanguageString(
                            "custom.promptmessage"
                          )}
                        />

                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <strong>
                                {" "}
                                {localizationService.toLanguageString(
                                  "custom.informationstatusquestion"
                                )}
                                ?
                              </strong>
                            </div>
                            <div className="col-3">
                              {" "}
                              <RadioButton
                                name="radio1"
                                value="yes"
                                checked={radioValue === "yes"}
                                label={localizationService.toLanguageString(
                                  "custom.yes"
                                )}
                                onChange={(e) =>
                                  handleRadio1Change(e, formRenderProps)
                                }
                                // disabled={
                                //   AllData &&
                                //   AllData.prog_factors_missing === false
                                //     ? true
                                //     : false
                                // }
                              />
                            </div>
                            <div className="col-3">
                              {" "}
                              <RadioButton
                                name="radio2"
                                value="no"
                                checked={radioValue === "no"}
                                label={localizationService.toLanguageString(
                                  "custom.no"
                                )}
                                onChange={(e) =>
                                  handleRadio2Change(e, formRenderProps)
                                }
                                // disabled={
                                //   AllData &&
                                //   AllData.prog_factors_missing === false
                                //     ? true
                                //     : false
                                // }
                              />
                            </div>
                            {radioValue === "yes" ? (
                              <div className="row">
                                <div className="col">
                                  <Field
                                    name={"prog_factors_missing_reason"}
                                    label={localizationService.toLanguageString(
                                      "custom.missingreason"
                                    )}
                                    validator={requiredValidator}
                                    component={RequiredFormTextArea}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {radioValue === "no" ? (
                            <>
                              <div className="row">
                                <div className="col-12">
                                  <Field
                                    id={"prognostic_factors"}
                                    name={"prognostic_factors"}
                                    label={localizationService.toLanguageString(
                                      "custom.mskpoorprognostic"
                                    )}
                                    data={MskPoorPrognostic}
                                    component={FormMultiSelect}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <Field
                                    id={"risk_group"}
                                    name={"risk_group"}
                                    label={localizationService.toLanguageString(
                                      "custom.patientriskgroup"
                                    )}
                                    data={PatientRiskGroup}
                                    component={RequiredDropDownList}
                                    validator={requiredValidator}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </Tooltip>
                    </fieldset>

                    <Tooltip
                      openDelay={0}
                      anchorElement="target"
                      parentTitle={true}
                      position={"left"}
                    >
                      <div className="float-right">
                        <div title={disabletitle()}>
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                            disabled={disablefunction()}
                          >
                            {loading
                              ? localizationService.toLanguageString(
                                  "custom.loading"
                                )
                              : localizationService.toLanguageString(
                                  "custom.save"
                                )}
                          </Button>
                        </div>
                      </div>
                    </Tooltip>
                  </FormElement>
                )}
              />
            </TabStripTab>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.approvesection"
              )}
            >
              <ApproveStudySubject
                study_subject={props.location.state.data.id}
                section={props.location.state.section.id}
                data={props.location.state.data}
                parentCallBack={ChangeApproveSection}
              />
            </TabStripTab>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.notesnattachements"
              )}
            >
              <TabStrip selected={Subselected} onSelect={handleSubSelect}>
                <TabStripTab
                  title={localizationService.toLanguageString("custom.notes")}
                >
                  <GridTelerik data={notes}>
                    <GridToolbar>
                      <Button
                        primary={true}
                        onClick={() => {
                          {
                            EditSubject
                              ? toggleDaddNote()
                              : toggleDPermission();
                          }
                        }}
                      >
                        {localizationService.toLanguageString("custom.new")}
                      </Button>
                    </GridToolbar>

                    <Column
                      field={"note"}
                      title={localizationService.toLanguageString(
                        "custom.note"
                      )}
                      cell={NoteNameTemplate}
                    />
                    <Column
                      field={"date_created"}
                      title={localizationService.toLanguageString(
                        "custom.datecreated"
                      )}
                      cell={DateCreatedTemplate}
                    />
                    <Column
                      title={localizationService.toLanguageString(
                        "custom.actions"
                      )}
                      width="100px"
                      cell={Noteactions}
                    />
                  </GridTelerik>
                </TabStripTab>

                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.attachements"
                  )}
                >
                  <GridTelerik data={attachements}>
                    <GridToolbar>
                      <Button
                        primary={true}
                        onClick={() => {
                          {
                            EditSubject
                              ? toggleDaddFile()
                              : toggleDPermission();
                          }
                        }}
                      >
                        {localizationService.toLanguageString("custom.new")}
                      </Button>
                    </GridToolbar>
                    <Column
                      field={"title"}
                      title={localizationService.toLanguageString(
                        "custom.title"
                      )}
                    />

                    <Column
                      field={"date_created"}
                      title={localizationService.toLanguageString(
                        "custom.dateadded"
                      )}
                      cell={DateCreatedTemplate}
                    />

                    <Column
                      title={localizationService.toLanguageString(
                        "custom.actions"
                      )}
                      width="100px"
                      cell={Fileactions}
                    />
                  </GridTelerik>
                </TabStripTab>
              </TabStrip>
            </TabStripTab>
          </TabStrip>
        </div>
      </div>

      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
          height={"400px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}

      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}

      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}

      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
    </TrialMap>
  );
}

export default ListPrognosticFactors;
