//is logged
export const islogged = (data) => {
  return {
    type: "SIGN_IN",
    payload: data,
  };
};

//receive email
export const receive_email = (data) => {
  return {
    type: "RECEIVE_EMAIL",
    payload: data,
  };
};
//contain user detail
export const user_detail = (data) => {
  return {
    type: "USER_DETAIL",
    payload: data,
  };
};

//roles
export const view_all_subject = (data) => {
  return {
    type: "VIEWALLSUBJECT",
    payload: data,
  };
};

export const view_subject = (data) => {
  return {
    type: "VIEWSUBJECT",
    payload: data,
  };
};

export const add_subject = (data) => {
  return {
    type: "ADDSUBJECT",
    payload: data,
  };
};

export const edit_subject = (data) => {
  return {
    type: "EDITSUBJECT",
    payload: data,
  };
};

export const approve_subject = (data) => {
  return {
    type: "APPROVESUBJECT",
    payload: data,
  };
};

//STUDY
export const add_study = (data) => {
  return {
    type: "ADDSTUDY",
    payload: data,
  };
};

export const edit_study = (data) => {
  return {
    type: "EDITSTUDY",
    payload: data,
  };
};

export const view_study = (data) => {
  return {
    type: "VIEWSTUDY",
    payload: data,
  };
};

//USER SETTING
export const add_user_setting = (data) => {
  return {
    type: "ADDUSERSETTING",
    payload: data,
  };
};

export const edit_user_setting = (data) => {
  return {
    type: "EDITUSERSETTING",
    payload: data,
  };
};

export const view_user_setting = (data) => {
  return {
    type: "VIEWUSERSETTING",
    payload: data,
  };
};
