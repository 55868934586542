//utilities
import React, { useState, useEffect } from "react";
import axios from "axios";
import APIURL from "../../APIURL";

import { Prompt } from "react-router-dom";
import { useSelector } from "react-redux";

//kendo

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import {
  RequiredFormInput,
  RequiredDropDownList,
  FormCheckbox,
} from "../../common/Kendo/form-components";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  passwordValidator,
  requiredValidator,
} from "../../common/Kendo/validators";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//component
import GlobalNotificationTimer from "../../common/commoncomponents/GlobalNotificationTimer";

export function UpdatePassword(props) {
  //localization
  const localizationService = useLocalization();

  //redux
  const UserDetail = useSelector((state) => state.UserDetail);

  //variables
  const [oldpassworderror, setOldPasswordError] = useState("");
  const [newpassworderror, setNewPasswordError] = useState("");

  const UpdatePersonalInfo = (dataItem) => {
    if (dataItem.isValid) {
      axios
        .put(APIURL + `/auth/updatepassword/${props.data.id}/`, {
          firstname: props.data.first_name,
          lastname: props.data.last_name,
          username: props.data.username,
          confirm_password: dataItem.values.confirm_password,
          old_password: dataItem.values.old_password,
          new_password: dataItem.values.new_password,
        })
        .then((res) => {
          if (res.data.oldpassword == "incorrectpassword") {
            setOldPasswordError(
              localizationService.toLanguageString("custom.incorrectpassword")
            );
          } else if (res.data.newpasswordmatch == "passwordsnotmatching") {
            setNewPasswordError(
              localizationService.toLanguageString("custom.passwordnotmatching")
            );
          } else if (res.data.newpasswordstructure == "passwordnotallowed") {
            setNewPasswordError(
              localizationService.toLanguageString(
                "custom.yourpasswordnotpersonalinformation"
              )
            );
          } else if (res.data.message === "success") {
            props.onClose();
          }
        });
    }
  };

  return (
    <div className="grid-layout-container">
      <Form
        onSubmitClick={UpdatePersonalInfo}
        render={(formRenderProps) => (
          <FormElement>
            <fieldset>
              <GridLayout
                gap={{
                  rows: 1,
                  cols: 0,
                }}
              >
                <GridLayoutItem row={1} col={1} colSpan={8}>
                  <Card>
                    <CardHeader>
                      <strong>{props.data.username}</strong>
                    </CardHeader>
                    <CardBody>
                      <GridLayout
                        gap={{
                          rows: 2,
                          cols: 2,
                        }}
                      >
                        <GridLayoutItem row={1} col={1}>
                          <Field
                            name={"old_password"}
                            type="password"
                            component={RequiredFormInput}
                            label={localizationService.toLanguageString(
                              "custom.currentpassword"
                            )}
                            validator={requiredValidator}
                            onChange={() => setOldPasswordError("")}
                          />
                          <span className={"k-form-error"}>
                            {" "}
                            {oldpassworderror}
                          </span>
                        </GridLayoutItem>
                        <GridLayoutItem row={2} col={1}>
                          <Field
                            name={"new_password"}
                            type="password"
                            component={RequiredFormInput}
                            label={localizationService.toLanguageString(
                              "custom.newpassword"
                            )}
                            validator={passwordValidator}
                            onChange={() => setNewPasswordError("")}
                          />
                          <span className={"k-form-error"}>
                            {" "}
                            {newpassworderror}
                          </span>
                        </GridLayoutItem>
                        <GridLayoutItem row={2} col={2}>
                          <Field
                            type="password"
                            name={"confirm_password"}
                            component={RequiredFormInput}
                            label={localizationService.toLanguageString(
                              "custom.verifypassword"
                            )}
                            validator={passwordValidator}
                            onChange={() => setNewPasswordError("")}
                          />
                        </GridLayoutItem>
                      </GridLayout>
                    </CardBody>
                  </Card>
                </GridLayoutItem>
              </GridLayout>
            </fieldset>
            <div className="float-right mt-2 mb-2">
              <div>
                <Button primary={true} type={"submit"} className="mr-2em">
                  {localizationService.toLanguageString("custom.save")}
                </Button>
              </div>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
}

export default UpdatePassword;
