//utilities
import React from "react";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

export function PermissionDenied(props) {
  //localization
  const localizationService = useLocalization();

  if (props.NoPermission) {
    return (
      <>
        <p style={{ margin: "25px", textAlign: "center" }}>
          {localizationService.toLanguageString(`custom.${props.NoPermission}`)}
        </p>
      </>
    );
  } else {
    return (
      <>
        <p style={{ margin: "25px", textAlign: "center" }}>
          {localizationService.toLanguageString("custom.noaccess")}
        </p>
      </>
    );
  }
}
export default PermissionDenied;
