//utilities
import React, { useEffect, useState, useRef } from "react";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
//access
import { useHistory } from "react-router-dom";

//react icons
import { VscFeedback } from "react-icons/vsc";
//localization
import { useLocalization } from "@progress/kendo-react-intl";

//kendo component
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

//component
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import ResponseTreatment from "../treatment/ResponseTreatment";

export function TreatmentPopup(props) {
  //localization
  const localizationService = useLocalization();

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          section_title: props.section_title,
        },
      })
      .then((res) => res.data);

  const { data: sections, error } = useSWR(
    [APIURL + "/common/listsection/", props.section_title],
    fetcher1
  );

  //access
  const history = useHistory();

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for adding and updating and deleting notes
  const [VaddResponse, setVaddResponse] = useState(false);

  const toggleDaddResponse = () => {
    setVaddResponse(!VaddResponse);
  };

  const handleRoute = (dataItem) => {
    console.log("dataItemdataItem tratment", dataItem);
    let sectionName = dataItem.section_name.replace(/\s/g, "").toLowerCase();

    let sectionTitle = dataItem.title.toLowerCase();

    axios
      .get(APIURL + "/common/sectiondetection/", {
        params: {
          section: dataItem.section_name,
          subject: props.data.id,
        },
      })
      .then((res) => {
        if (res.data.message === "approve") {
          history.push({
            pathname: `/crf/${sectionTitle}/${sectionName}/add`,
            state: { data: props.data, section: dataItem },
          });
        } else {
          history.push({
            pathname: `/sectionlocked`,
            state: {
              data: props.data,
              section: dataItem,
              reason: res.data.reason,
            },
          });
        }
      });
  };
  //if the user click outside the component , the pop up will close

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        let element = document.getElementsByClassName(`treatment-popup`)[0];
        let id = document.getElementById(`treatment${props.data.crf_id}`)["id"];

        if (
          ref.current &&
          id &&
          !ref.current.contains(event.target) &&
          event.srcElement.id !== id
        ) {
          props.parentCallback();
        }
      }

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const ActionTemplate = (dataItem) => {
    let sectionName = dataItem.dataItem.section_name
      .replace(/\s/g, "")
      .toLowerCase();

    let sectionTitle = dataItem.dataItem.title.toLowerCase();

    return (
      <td>
        <i
          className="k-icon k-i-track-changes-enable DocumentActionButtons mr-6em"
          onClick={() => handleRoute(dataItem.dataItem)}
        ></i>
      </td>
    );
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (error)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <>
      <div ref={wrapperRef}>
        {!sections && loadingPanel}
        <Grid data={sections}>
          <GridToolbar>
            <div className="col pages-medium-title">
              <strong>
                {localizationService.toLanguageString("custom.subject")}
                {" : "}
                {props.data.crf_id}
              </strong>
            </div>
          </GridToolbar>
          <GridToolbar>
            <div className="col pages-medium-title">
              <strong>
                {localizationService.toLanguageString("custom.section")}
                {" : "}
                {props.section_title}
              </strong>
            </div>
          </GridToolbar>
          <Column
            title={localizationService.toLanguageString("custom.crfname")}
            field={"section_name"}
          ></Column>
          <Column
            title={localizationService.toLanguageString("custom.actions")}
            cell={ActionTemplate}
          ></Column>
        </Grid>
      </div>

      <>
        {/* PERMISSION DIALOG */}
        {VPermission && (
          <Dialog
            title={localizationService.toLanguageString(
              "custom.permissiondenied"
            )}
            onClose={toggleDPermission}
          >
            <PermissionDenied onClose={toggleDPermission} />
          </Dialog>
        )}
        {VaddResponse && (
          <Dialog
            width="700px"
            title={localizationService.toLanguageString(
              "custom.responsetotreatment"
            )}
            onClose={toggleDaddResponse}
          >
            <ResponseTreatment onClose={toggleDaddResponse} />
          </Dialog>
        )}
      </>
    </>
  );
}
export default TreatmentPopup;
