export const locales = [
    {
        locale: 'English',
        localeId: 'en-US'
    },
    {
        locale: 'French',
        localeId: 'fr'
    },
    {
        locale: 'Spanish',
        localeId: 'es'
    }
];