//utilities import
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import APIURL from "../APIURL";
import useSWR, { SWRConfig } from "swr";
import { useHistory } from "react-router-dom";
import axios from "axios";

//kendo elements

import { Button } from "@progress/kendo-react-buttons";

import { Form, Field, FormElement } from "@progress/kendo-react-form";

import { FormInput } from "../common/Kendo/form-components";

import { requiredValidator } from "../common/Kendo/validators";

//localization

//redux
import {
  edit_subject,
  approve_subject,
  view_all_subject,
  view_subject,
  add_subject,
  add_study,
  edit_study,
  view_study,
  view_user_setting,
  add_user_setting,
  edit_user_setting,
  user_detail,
  islogged,
  receive_email,
} from "../redux/action";

//localization

import { useLocalization } from "@progress/kendo-react-intl";

export function Login(props) {
  //localization
  const localizationService = useLocalization();

  //Redux
  const DashboardList = useSelector((state) => state.DashboardList);

  //variables
  const [error, seterror] = useState(null);
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  //useEffect
  useEffect(() => {
    //set the language to en-US on the first render
  }, []);

  //User Detail Function

  const UserDetail = (e) => {
    axios.get(APIURL + "/auth/detailuser/", {}).then((res) => {
      dispatch(
        user_detail({
          id: res.data.id,
          username: res.data.username,
          email: res.data.email,
          country: res.data.country,
          center: res.data.center,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          role: res.data.role,
          sub_investigator_fn: res.data.sub_investigator_fn,
          sub_investigator_ln: res.data.sub_investigator_ln,
          is_assistant: res.data.is_assistant,
          is_active: res.data.is_active,
          role_id: res.data.role_id,
          study_id: res.data.study_id,
          study_name: res.data.study_name,
        })
      );

      axios
        .get(APIURL + "/auth/checkroles/", {
          params: {
            key: res.data.id,
          },
        })
        .then((res) => {
          if (res.data.message === "no role")
            alert(
              "The User does not have any Role! Unfortunately you cannot use the software"
            );
          //ROLES
          else {
            if (res.data.view_all_subject === "access") {
              dispatch(view_all_subject(true));
            } else {
              dispatch(view_all_subject(false));
            }
            if (res.data.view_subject === "access") {
              dispatch(view_subject(true));
            } else {
              dispatch(view_subject(false));
            }
            if (res.data.edit_subject === "access") {
              dispatch(edit_subject(true));
            } else {
              dispatch(edit_subject(false));
            }
            if (res.data.add_subject === "access") {
              dispatch(add_subject(true));
            } else {
              dispatch(add_subject(false));
            }
            if (res.data.approve_subject === "access") {
              dispatch(approve_subject(true));
            } else {
              dispatch(approve_subject(false));
            }

            if (res.data.view_study === "access") {
              dispatch(view_study(true));
            } else {
              dispatch(view_study(false));
            }
            if (res.data.edit_study === "access") {
              dispatch(edit_study(true));
            } else {
              dispatch(edit_study(false));
            }
            if (res.data.add_study === "access") {
              dispatch(add_study(true));
            } else {
              dispatch(add_study(false));
            }

            if (res.data.view_user_setting === "access") {
              dispatch(view_user_setting(true));
            } else {
              dispatch(view_user_setting(false));
            }
            if (res.data.edit_user_setting === "access") {
              dispatch(edit_user_setting(true));
            } else {
              dispatch(edit_user_setting(false));
            }
            if (res.data.add_user_setting === "access") {
              dispatch(add_user_setting(true));
            } else {
              dispatch(add_user_setting(false));
            }

            if (res.data.receive_email === "access") {
              dispatch(receive_email(true));
            } else {
              dispatch(receive_email(false));
            }
          }
          dispatch(islogged(true));
        });
    });
  };

  //Login Function
  const Login = (dataItem) => {
    axios
      .post(APIURL + "/auth/", {
        username: dataItem.username,
        password: dataItem.password,
      })
      .then((res) => {
        if (res.data.message === "success") {
          UserDetail();
          props.history.push("/crf/studysubject/list");
        }
        if (res.data.message === "alreadyloggedin") {
          seterror(
            localizationService.toLanguageString("custom.alreadyloggedin")
          );
          dispatch(islogged(false));
        }

        if (res.data.message === "fail") {
          seterror(
            localizationService.toLanguageString("custom.incorrectlogin")
          );
          dispatch(islogged(false));
        }
        if (res.data.message === "inactiveuser") {
          seterror(localizationService.toLanguageString("custom.inactiveuser"));
          dispatch(islogged(false));
        }
      });
  };

  return (
    <div className="LoginPageContainer">
      <div className="row">
        <div className="col-9">
          <div className="SVGImageSpace">
            {/* <ERP class="image-login" /> */}
            {/* <img src={svgImage} class="image-login" alt="" /> */}
          </div>
        </div>
        <div className="col-3 SignIn-Form">
          <div className="row">
            <div className="col-6">
              <div class="content-login" style={{ textAlign: "center" }}>
                <h3>
                  {" "}
                  {localizationService.toLanguageString("custom.galoperecf")}
                </h3>
                <h3>
                  {" "}
                  {localizationService.toLanguageString("custom.welcome")}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <Form
              initialValues={{
                username: "",
                password: "",
              }}
              onSubmit={Login}
              render={(formRenderProps) => (
                <FormElement>
                  <div>
                    <div className="row">
                      <div className="col">
                        <Field
                          style={{
                            width: "50%",
                          }}
                          className="LoginInput"
                          name={"username"}
                          component={FormInput}
                          placeholder={localizationService.toLanguageString(
                            "custom.username"
                          )}
                          validator={requiredValidator}
                          onChange={() => seterror("")}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Field
                          style={{
                            width: "50%",
                          }}
                          className="LoginInput"
                          name={"password"}
                          type="password"
                          placeholder={localizationService.toLanguageString(
                            "custom.password"
                          )}
                          component={FormInput}
                          validator={requiredValidator}
                          onChange={() => seterror("")}
                        />
                      </div>
                    </div>
                    <span className="k-form-error">{error}</span>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div style={{ textAlign: "center" }}>
                        <Button primary={true} className="sign-in-button">
                          {localizationService.toLanguageString("custom.login")}
                        </Button>
                     
                      </div>
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
