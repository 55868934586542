//UTILITIES
import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";
import { confirmAlert } from "react-confirm-alert";

import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

//REDUX
import { createStore } from "redux";
import allReducres from "./redux/reducer";
import { Provider } from "react-redux";

import reportWebVitals from "./reportWebVitals";

//css
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
}
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}
const persistedState = loadFromLocalStorage();
const store = createStore(
  allReducres,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

//Prompt dialog

const CustomConfirmationPrompt = (message, callback) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <Dialog title={"Please Confirm"} onClose={onClose}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            {message}
          </p>
          <div className="row">
            <div className="col">
              <Button
                style={{ width: "230px" }}
                className="k-button mr-2em"
                primary={true}
                onClick={() => {
                  callback(true);
                  onClose();
                }}
              >
                Yes
              </Button>
              <Button
                style={{ width: "230px" }}
                className="k-button"
                onClick={() => {
                  callback(false);
                  onClose();
                }}
              >
                No
              </Button>
            </div>
          </div>
        </Dialog>
      );
    },
  });
};

store.subscribe(() => saveToLocalStorage(store.getState()));
ReactDOM.render(
  <BrowserRouter
    getUserConfirmation={(message, callback) =>
      CustomConfirmationPrompt(message, callback)
    }
  >
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
