//utilities import
import React, { useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import moment from "moment";
import axios from "axios";
import { Prompt } from "react-router-dom";

//kendo Component
import { Tooltip } from "@progress/kendo-react-tooltip";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { filterBy } from "@progress/kendo-data-query";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import {
  requiredValidator,
  requiredMax50Validator,
  requiredMax255Validator,
} from "../common/Kendo/validators";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import {
  FormTextArea,
  RequiredFormInput,
  RequiredDropDownList,
  RequiredFormDatePicker,
  RequiredFormNumericTextBox,
} from "../common/Kendo/form-components";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//Component
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
import TherapeuticAreas from "../common/commoncomponents/TherapeuticAreas";
import StudyTypes from "../common/commoncomponents/StudyTypes";

export function AddStudyy(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditStudy = useSelector((state) => state.EditStudy);

  //localization
  const localizationService = useLocalization();

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted } = state;

  //save variable for the prompt function functionality
  const [Promptsave, setPromptsave] = useState(false);

  //date of now for date picker limits
  const now = new Date();
  const min = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  //Therapeutic filter dropdownlist
  const TherapeuticDropDownList = (fieldRenderProps) => {
    const [data, setData] = React.useState(TherapeuticAreas.slice());
    const filterData = (filter) => {
      const data = TherapeuticAreas.slice();
      return filterBy(data, filter);
    };
    const filterChange = (event) => {
      setData(filterData(event.filter));
    };
    const handleChange = (e) => {
      fieldRenderProps.onChange({
        dataItem: fieldRenderProps.dataItem,
        field: fieldRenderProps.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value ? e.target.value.value : null,
      });
    };
    const { dataItem, field } = fieldRenderProps;
    const dataValue = fieldRenderProps;
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      wrapperStyle,
      ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    return (
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
          <span style={{ color: "red" }}>*</span>
        </Label>
        <ComboBox
          onChange={handleChange}
          value={data.find((c) => c.value === dataValue)}
          data={data}
          textField="name"
          dataItemKey="value"
          filterable={true}
          onFilterChange={filterChange}
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    );
  };

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/study/list");
    }
  };

  const OnTherapeuticChange = (e, formRenderProps) => {
    formRenderProps.onChange("other_therapeutic_area", { value: "" });
  };
  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (!Promptsave && formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const createStudy = (dataItem) => {
    if (dataItem.isValid) {
      setPromptsave(true);
      if (props.location.state) {
        axios
          .put(
            APIURL + "/common/updatestudy/" + props.location.state.id + "/",
            {
              created_by: props.location.state.created_by,
              modified_by: UserDetail.id,
              study_name: dataItem.values.study_name,
              protocol_name: dataItem.values.protocol_name,
              protocol_id: dataItem.values.protocol_id,
              start_date: moment(dataItem.values.start_date).format(
                "YYYY-MM-DD"
              ),
              study_type: dataItem.values.study_type.value,
              estimated_enrollement: dataItem.values.estimated_enrollement,
              principal_investigator: dataItem.values.principal_investigator,
              study_sponsor: dataItem.values.study_sponsor,
              therapeutic_area: dataItem.values.therapeutic_area.value,
              other_therapeutic_area: dataItem.values.other_therapeutic_area,
              description: dataItem.values.description,
            }
          )
          .then((res) => {
            props.history.push({
              pathname: "/crf/study/list",
              add: "edited",
            });
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
            }, GlobalNotificationTimer);
          });
      } else {
        axios
          .post(APIURL + "/common/liststudy/", {
            created_by: UserDetail.id,
            study_name: dataItem.values.study_name,
            protocol_id: dataItem.values.protocol_id,
            protocol_name: dataItem.values.protocol_name,
            start_date: moment(dataItem.values.start_date).format("YYYY-MM-DD"),
            study_type: dataItem.values.study_type.value,
            estimated_enrollement: dataItem.values.estimated_enrollement,
            principal_investigator: dataItem.values.principal_investigator,
            study_sponsor: dataItem.values.study_sponsor,
            therapeutic_area: dataItem.values.therapeutic_area.value,
            other_therapeutic_area: dataItem.values.other_therapeutic_area,
            description: dataItem.values.description,
          })
          .then((res) => {
            props.history.push({
              pathname: "/crf/study/list",
              add: "added",
            });
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
            }, GlobalNotificationTimer);
          });
      }
    }
  };

  //disable function
  const disablefunction = () => {
    let boolean = false;
    if (props.location.state && !EditStudy) {
      boolean = true;
    }
    return boolean;
  };

  const disabletitle = () => {
    let title = localizationService.toLanguageString("custom.savechanges");
    if (!EditStudy && props.location.state) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }

    return title;
  };

  return (
    <>
      <Tooltip
        openDelay={0}
        anchorElement="target"
        parentTitle={true}
        position={"left"}
      >
        <Card>
          <Form
            initialValues={{
              study_name: props.location.state
                ? props.location.state.study_name
                : "",
              protocol_name: props.location.state
                ? props.location.state.protocol_name
                : "",
              protocol_id: props.location.state
                ? props.location.state.protocol_id
                : "",
              start_date: props.location.state
                ? new Date(props.location.state.start_date)
                : null,
              study_type: props.location.state
                ? {
                    name: props.location.state.study_type,
                    value: props.location.state.study_type,
                  }
                : "",
              estimated_enrollement: props.location.state
                ? props.location.state.estimated_enrollement
                : 0,
              principal_investigator: props.location.state
                ? props.location.state.principal_investigator
                : "",
              study_sponsor: props.location.state
                ? props.location.state.study_sponsor
                : "",
              therapeutic_area: props.location.state
                ? {
                    name: props.location.state.therapeutic_area,
                    value: props.location.state.therapeutic_area,
                  }
                : "",
              other_therapeutic_area: props.location.state
                ? props.location.state.other_therapeutic_area
                : "",
              description: props.location.state
                ? props.location.state.description
                : "",
            }}
            onSubmitClick={createStudy}
            render={(formRenderProps) => (
              <FormElement>
                <fieldset className={"k-form-fieldset"}>
                  <Prompt
                    when={PromptFunction(formRenderProps)}
                    message={localizationService.toLanguageString(
                      "custom.promptmessage"
                    )}
                  />
                  <CardHeader className="FormHeader">
                    <CardTitle className="pages-big-title">
                      <strong>
                        {props.location.state
                          ? localizationService.toLanguageString(
                              "custom.editstudy"
                            )
                          : localizationService.toLanguageString(
                              "custom.newstudy"
                            )}
                      </strong>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="col">
                        <Field
                          name={"study_name"}
                          component={RequiredFormInput}
                          label={localizationService.toLanguageString(
                            "custom.studyname"
                          )}
                          validator={requiredMax50Validator}
                        />
                      </div>
                      <div className="col">
                        <Field
                          name={"start_date"}
                          component={RequiredFormDatePicker}
                          label={localizationService.toLanguageString(
                            "custom.startdate"
                          )}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="col">
                        <Field
                          name={"protocol_name"}
                          component={RequiredFormInput}
                          label={localizationService.toLanguageString(
                            "custom.protocolname"
                          )}
                          validator={requiredMax255Validator}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <Field
                          name={"protocol_id"}
                          component={RequiredFormInput}
                          label={localizationService.toLanguageString(
                            "custom.protocolid"
                          )}
                          validator={requiredMax50Validator}
                        />
                      </div>
                      <div className="col">
                        <Field
                          data={StudyTypes}
                          name={"study_type"}
                          component={RequiredDropDownList}
                          label={localizationService.toLanguageString(
                            "custom.studytype"
                          )}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="col">
                        <Field
                          name={"estimated_enrollement"}
                          component={RequiredFormNumericTextBox}
                          label={localizationService.toLanguageString(
                            "custom.estimatedenrollement"
                          )}
                          min={0}
                          format={"n0"}
                          validator={requiredValidator}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Field
                          name={"principal_investigator"}
                          component={RequiredFormInput}
                          label={localizationService.toLanguageString(
                            "custom.principalinvestigator"
                          )}
                          validator={requiredMax50Validator}
                        />
                      </div>

                      <div className="col">
                        <Field
                          name={"study_sponsor"}
                          component={RequiredFormInput}
                          label={localizationService.toLanguageString(
                            "custom.studysponsor"
                          )}
                          validator={requiredMax50Validator}
                        />
                      </div>
                      <div className="col">
                        <Field
                          name={"therapeutic_area"}
                          component={TherapeuticDropDownList}
                          label={localizationService.toLanguageString(
                            "custom.therapeuticarea"
                          )}
                          validator={requiredValidator}
                          onChange={(e) =>
                            OnTherapeuticChange(e, formRenderProps)
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        {formRenderProps.valueGetter("therapeutic_area") &&
                        formRenderProps.valueGetter("therapeutic_area")
                          .value === "Other" ? (
                          <Field
                            name={"other_therapeutic_area"}
                            component={RequiredFormInput}
                            label={localizationService.toLanguageString(
                              "custom.othertherapeuticarea"
                            )}
                            validator={requiredMax50Validator}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <Field
                          name={"description"}
                          label={localizationService.toLanguageString(
                            "custom.description"
                          )}
                          component={FormTextArea}
                          validator={requiredValidator}
                        />
                      </div>
                    </div>
                  </CardBody>
                </fieldset>

                <div className="float-right">
                  <span title={disabletitle()}>
                    <Button
                      primary={true}
                      type={"submit"}
                      className="mr-2em"
                      disabled={disablefunction()}
                    >
                      {localizationService.toLanguageString("custom.save")}
                    </Button>
                  </span>
                  <Button type={"button"} onClick={(event) => goBack(event)}>
                    {localizationService.toLanguageString("custom.cancel")}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </Card>
      </Tooltip>
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, error: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  );
}

export default AddStudyy;
