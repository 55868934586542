//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { FieldArray } from "@progress/kendo-react-form";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Card, CardHeader } from "@progress/kendo-react-layout";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  FormInput,
  FormDatePicker,
  RequiredFormTextArea,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax255Validator,
} from "../common/Kendo/validators";

//component
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
import TrialMap from "../studysubject/TrialMap";
import AddFile from "../common/commoncomponents/AddFile";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import MetastaticMethods from "../common/commoncomponents/MetastaticMethods";
import MetastaticSites from "../common/commoncomponents/MestaticSites";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
import TabChange from "../common/commoncomponents/TabChange";
const languageContext = React.createContext({
  intl: null,
});

const MyContext = React.createContext({
  deletearray: [],
  FormRef: {},
});

const arrayLengthValidator = (value) =>
  value && value.length ? "" : "Please add at least one record.";

const onRemoveContext = React.createContext((options) => null);
// Create context for your state
const stateContext = React.createContext([{}, () => {}]);

export const FormCheckbox = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const currentContext = React.useContext(MyContext);
  return (
    <FieldWrapper>
      <Checkbox
        ariaDescribedBy={`${hintId} ${errorId}`}
        label={label}
        labelOptional={optional}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
        onChange={(e) => {
          fieldRenderProps.onChange(e);
          let fieldValue = fieldRenderProps.name.replace(
            "spread_site",
            "other_method"
          );
          let fieldValue1 = fieldRenderProps.name.replace(
            "spread_site",
            "date_assessment"
          );
          let fieldValue2 = fieldRenderProps.name.replace(
            "spread_site",
            "method"
          );

          currentContext.FormRef.current.valueSetter(fieldValue, "");
          currentContext.FormRef.current.valueSetter(fieldValue1, null);
          currentContext.FormRef.current.valueSetter(fieldValue2, null);
        }}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDropDownList = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  const currentContext = React.useContext(MyContext);
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DropDownList
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        textField={"name"}
        dataItemKey={"value"}
        id={id}
        disabled={disabled}
        {...others}
        onChange={(e) => {
          fieldRenderProps.onChange(e);
          let fieldValue = fieldRenderProps.name.replace(
            "method",
            "other_method"
          );

          currentContext.FormRef.current.valueSetter(fieldValue, "");
        }}
      />

      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

const CustomCell = (props) => {
  if (props.field === "spread_site") {
    return (
      <td>
        <Field
          component={FormCheckbox}
          name={`metastatic_sites[${props.dataIndex}].${props.field}`}
          // onChange={(e) => OnSpreadChange(e, props.dataItem)}
        />
      </td>
    );
  }
  if (props.field === "site") {
    return (
      <td>
        {props.dataItem.initial_data ? (
          props.dataItem.site
        ) : (
          <Field
            component={FormInput}
            name={`metastatic_sites[${props.dataIndex}].${props.field}`}
            validator={requiredValidator}
            maxLength={255}
            disabled={props.dataItem.initial_data ? true : false}
          />
        )}
      </td>
    );
  }
  if (props.field === "other_method") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`metastatic_sites[${props.dataIndex}].${props.field}`}
          validator={
            props.dataItem.method && props.dataItem.method.value === "Other"
              ? requiredMax255Validator
              : ""
          }
          maxLength={255}
          disabled={
            (props.dataItem.method &&
              props.dataItem.method.value !== "Other") ||
            !props.dataItem.spread_site
              ? true
              : false
          }
        />
      </td>
    );
  } else if (props.field === "date_assessment") {
    return (
      <td>
        <Field
          component={FormDatePicker}
          name={`metastatic_sites[${props.dataIndex}].${props.field}`}
          validator={props.dataItem.spread_site ? requiredValidator : ""}
          disabled={props.dataItem.spread_site ? false : true}
        />
      </td>
    );
  } else if (props.field === "method") {
    return (
      <td>
        <Field
          component={FormDropDownList}
          name={`metastatic_sites[${props.dataIndex}].${props.field}`}
          validator={props.dataItem.spread_site ? requiredValidator : ""}
          data={MetastaticMethods}
          disabled={props.dataItem.spread_site ? false : true}
        />
      </td>
    );
  }
};

const CommandCell = (cellProps) => {
  const onRemove = React.useContext(onRemoveContext);
  // Consume the state context, which will give you both the state and the setter.
  const [state, setState] = React.useContext(stateContext);
  const currentContext = React.useContext(MyContext);

  const onClick = (e) => {
    e.preventDefault();
    if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
      onRemove({ index: cellProps.dataIndex });
    } else {
      currentContext.deletearray.push(cellProps.dataItem.id);

      onRemove({ index: cellProps.dataIndex });
    }
  };

  return (
    <td>
      {cellProps.dataItem.initial_data || cellProps.dataItem.id ? (
        ""
      ) : (
        <SvgIcon
          icon={trashIcon}
          style={{ color: "red", cursor: "pointer" }}
          onClick={onClick}
        />
      )}
    </td>
  );
};

const FormGrid = (fieldArrayRenderProps) => {
  const { validationMessage, visited } = fieldArrayRenderProps;
  const [state, setState] = React.useState({});
  const onAdd = React.useCallback(
    (e) => {
      e.preventDefault();
      fieldArrayRenderProps.onUnshift({
        value: {
          status: "new",
          initial_data: false,
          spread_site: false,
          site: "",
          date_assessment: null,
          method: null,
          other_method: "",
        },
      });
    },
    [fieldArrayRenderProps.onUnshift]
  );

  return (
    <div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}

      <stateContext.Provider value={[state, setState]}>
        <onRemoveContext.Provider value={fieldArrayRenderProps.onRemove}>
          <GridTelerik
            style={{
              maxHeight: "500px",
            }}
            data={fieldArrayRenderProps.value}
          >
            <GridToolbar>
              <div className="float-right">
                <Button primary={true} type={"button"} onClick={onAdd}>
                  {languageContext.intl.toLanguageString("custom.new")}
                </Button>
              </div>
            </GridToolbar>
            <Column
              field="code"
              title={
                <>
                  <span>
                    {languageContext.intl.toLanguageString("custom.id")}
                  </span>
                </>
              }
            />

            <Column
              field="spread_site"
              cell={CustomCell}
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.presence"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.presence")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
            />

            <Column
              field="site"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString("custom.site")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.site")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="date_assessment"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.dateofassessment"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString(
                      "custom.dateofassessment"
                    )}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="method"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.method"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.method")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="other_method"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.othermethod"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString(
                      "custom.othermethod"
                    )}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column cell={CommandCell} filterable={false} width="50px" />
          </GridTelerik>
        </onRemoveContext.Provider>
      </stateContext.Provider>
    </div>
  );
};

export function ListMetastaticSites(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();
  languageContext.intl = useLocalization();

  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);
  const { data: notes, Noteserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  //attachement data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attchementserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  const { data: checkapprove, checkapproveerror } = useSWR(
    props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  //variables
  const [approved, setApproved] = useState(false);
  const [approvedformchanged, setapprovedformchanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //for handling checkbox change and form modification
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  const [ModifiedData, setModifiedData] = useState();
  const [deletearray, setdeletearray] = useState([]); //to check what are the deleted items
  const [radioValue, setradioValue] = useState(
    props.location.state.data.metastatic_site_missing === true ? "yes" : "no"
  );
  const [AllData, setAllData] = useState([]);

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    props.location.state
      ? [
          APIURL + "/opera/baseline/addmetastaticsites/",
          props.location.state.data.id,
        ]
      : null,
    fetcher1
  );

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setSelected(value);
    setapprovedformchanged(false);
  };

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                EditSubject ? toggleDeditNote() : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  useEffect(() => {
    let array = [];
    if (data) {
      data.map((d) => {
        array.push({
          id: d.id,
          created_by: d.created_by,
          modified_by: d.modified_by,
          createdBy: d.createdBy,
          modifiedBy: d.modifiedBy,
          section: d.section,
          study_subject: d.study_subject,
          initial_data: d.initial_data,
          spread_site: d.spread_site,
          site: d.site,
          method: d.method ? { name: d.method, value: d.method } : "",
          other_method: d.other_method,
          date_assessment:
            d.date_assessment !== null ? new Date(d.date_assessment) : null,
          code: d.code,
        });
      });
      setAllData(array);
    }
    if (checkapprove) {
      setApproved(checkapprove);
    }
  }, [data, checkapprove]);

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  const CreateMetastaticSites = (dataItem) => {
    if (dataItem.isValid) {
      if (Object.keys(AllData).length !== 0) {
        if (dataItem.isModified) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let array = [];
        if (dataItem.values.metastatic_sites) {
          dataItem.values.metastatic_sites.map((d) => {
            array.push({
              created_by: UserDetail.id,
              modified_by: null,
              study_subject: props.location.state.data.id,
              section: props.location.state.section.id,
              status: d.status ? d.status : "edit",
              spread_site: d.spread_site,
              initial_data: d.initial_data,
              site: d.site,
              method: d.method ? d.method.value : "",
              other_method: d.other_method ? d.other_method : "",
              date_assessment: d.date_assessment
                ? moment(d.date_assessment).format("YYYY-MM-DD")
                : null,
            });
          });
        }
        setLoading(true);
        axios
          .post(APIURL + "/opera/baseline/addmetastaticsites/", {
            section: props.location.state.section.id,
            modified_by: UserDetail.id,
            study_subject: props.location.state.data.id,
            metastatic_sites: radioValue === "yes" ? [] : array,
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
          });
      }
    }
  };

  //TAB STRIP
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    if (selected === 1) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setSelected(e.selected);
      }
    } else if (
      MyForm.current &&
      Object.keys(MyForm.current.modified).length !== 0
    ) {
      setSelectedTab(e.selected);
      toggleDTabStripChange();
    } else {
      setSelected(e.selected);
    }
  };

  const [Subselected, setSubSelected] = React.useState(0);

  const handleSubSelect = (e) => {
    setSubSelected(e.selected);
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const CreateModification = (dataItem) => {
    toggleDModifyreason(false);
    setLoading(true);
    let array = [];
    if (ModifiedData.metastatic_sites) {
      ModifiedData.metastatic_sites.map((d) => {
        array.push({
          id: d.id,
          created_by: d.status == "new" ? UserDetail.id : d.created_by,
          modified_by: d.status == "new" ? null : UserDetail.id,
          study_subject: props.location.state.data.id,
          section: props.location.state.section.id,
          status: d.status ? d.status : "edit",
          spread_site: d.spread_site,
          initial_data: d.initial_data,
          site: d.site,
          method: d.method ? d.method.value : "",
          other_method: d.other_method,
          date_assessment: d.date_assessment
            ? moment(d.date_assessment).format("YYYY-MM-DD")
            : null,
        });
      });
    }
    axios
      .put(APIURL + "/opera/baseline/updatemetastaticsites/", {
        study_subject: props.location.state.data.id,
        section: props.location.state.section.id,
        modified_by: UserDetail.id,
        metastatic_sites: array,
        modification_reason: dataItem.modification_reason,
      })
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
        setdeletearray([]);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
        setdeletearray([]);
      });
  };

  //SWR ESSENTIAL
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (error && Noteserror && Attchementserror)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || approved) {
      boolean = true;
    }
    return boolean;
  };

  return (
    <TrialMap>
      {(!data || !notes || !attachements) && loadingPanel}
      <MyContext.Provider
        value={{
          deletearray: deletearray,
        }}
      >
        <div className="row">
          <div className="col-12">
            <Card>
              <CardHeader className="DetailFormHeader">
                <div className="row">
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.subjectid")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString(
                      "custom.subjectinitials"
                    )}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.study")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.protocolid")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.sitenumber")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.createdby")}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-responsive-overflow subject-id-style">
                    {props.location.state
                      ? props.location.state.data.crf_id
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.subject_initial
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.study_name
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.study_protocol
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.subject_center
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.createdBy
                      : ""}
                  </div>
                </div>
                <hr className="RowRoleHr" />
                <div className="row">
                  <div className="col pages-big-title">
                    <strong>
                      {localizationService.toLanguageString(
                        "custom.metastaticsites"
                      )}
                    </strong>
                  </div>
                  <div className="col">
                    <div className="float-right">
                      <Button onClick={goBack} className="back-button-style">
                        {localizationService.toLanguageString("custom.back")}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <TabStrip selected={selected} onSelect={handleSelect}>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.metastaticsites"
                )}
              >
                <MyContext.Provider value={{ FormRef: MyForm }}>
                  <Form
                    initialValues={{
                      metastatic_sites:
                        data && data.length > 0 ? AllData : MetastaticSites,
                    }}
                    ref={MyForm}
                    onSubmitClick={CreateMetastaticSites}
                    key={JSON.stringify(AllData)}
                    render={(formRenderProps: FormRenderProps) => (
                      <FormElement className="">
                        <fieldset className={"k-form-fieldset"}>
                          <Tooltip
                            openDelay={0}
                            anchorElement="target"
                            parentTitle={true}
                            position={"top"}
                          >
                            <Prompt
                              when={PromptFunction(formRenderProps)}
                              message={localizationService.toLanguageString(
                                "custom.promptmessage"
                              )}
                            />

                            <>
                              <div className="row mt-4">
                                <div className="col">
                                  <FieldArray
                                    name="metastatic_sites"
                                    component={FormGrid}
                                    validator={arrayLengthValidator}
                                  />
                                </div>
                              </div>
                            </>
                          </Tooltip>
                        </fieldset>
                        <Tooltip
                          openDelay={0}
                          anchorElement="target"
                          parentTitle={true}
                          position={"left"}
                        >
                          <div className="float-right">
                            <div title={disabletitle()}>
                              <Button
                                primary={true}
                                type={"submit"}
                                className="mr-2em"
                                disabled={disablefunction()}
                              >
                                {loading
                                  ? localizationService.toLanguageString(
                                      "custom.loading"
                                    )
                                  : localizationService.toLanguageString(
                                      "custom.save"
                                    )}
                              </Button>
                            </div>
                          </div>
                        </Tooltip>
                      </FormElement>
                    )}
                  />
                </MyContext.Provider>
              </TabStripTab>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.approvesection"
                )}
              >
                <ApproveStudySubject
                  study_subject={props.location.state.data.id}
                  section={props.location.state.section.id}
                  data={props.location.state.data}
                  parentCallBack={ChangeApproveSection}
                />
              </TabStripTab>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.notesnattachements"
                )}
              >
                <TabStrip selected={Subselected} onSelect={handleSubSelect}>
                  <TabStripTab
                    title={localizationService.toLanguageString("custom.notes")}
                  >
                    <GridTelerik data={notes}>
                      <GridToolbar>
                        <Button
                          primary={true}
                          onClick={() => {
                            {
                              EditSubject
                                ? toggleDaddNote()
                                : toggleDPermission();
                            }
                          }}
                        >
                          {localizationService.toLanguageString("custom.new")}
                        </Button>
                      </GridToolbar>

                      <Column
                        field={"note"}
                        title={localizationService.toLanguageString(
                          "custom.note"
                        )}
                        cell={NoteNameTemplate}
                      />
                      <Column
                        field={"date_created"}
                        title={localizationService.toLanguageString(
                          "custom.datecreated"
                        )}
                        cell={DateCreatedTemplate}
                      />
                      <Column
                        title={localizationService.toLanguageString(
                          "custom.actions"
                        )}
                        width="100px"
                        cell={Noteactions}
                      />
                    </GridTelerik>
                  </TabStripTab>

                  <TabStripTab
                    title={localizationService.toLanguageString(
                      "custom.attachements"
                    )}
                  >
                    <GridTelerik data={attachements}>
                      <GridToolbar>
                        <Button
                          primary={true}
                          onClick={() => {
                            {
                              EditSubject
                                ? toggleDaddFile()
                                : toggleDPermission();
                            }
                          }}
                        >
                          {localizationService.toLanguageString("custom.new")}
                        </Button>
                      </GridToolbar>
                      <Column
                        field={"title"}
                        title={localizationService.toLanguageString(
                          "custom.title"
                        )}
                      />

                      <Column
                        field={"date_created"}
                        title={localizationService.toLanguageString(
                          "custom.dateadded"
                        )}
                        cell={DateCreatedTemplate}
                      />

                      <Column
                        title={localizationService.toLanguageString(
                          "custom.actions"
                        )}
                        width="100px"
                        cell={Fileactions}
                      />
                    </GridTelerik>
                  </TabStripTab>
                </TabStrip>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      </MyContext.Provider>
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
          height={"400px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}
      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}

      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}

      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
    </TrialMap>
  );
}
export default ListMetastaticSites;
