const Frequencies = [
  {
    value: "1x daily",
    name: "1x daily",
  },
  {
    value: "2x daily",
    name: "2x daily",
  },
  {
    value: "3x daily",
    name: "3x daily",
  },
  {
    value: "4x daily",
    name: "4x daily",
  },
  {
    value: "On demand",
    name: "On demand",
  },
  {
    value: "Other",
    name: "Other",
  },
];

export default Frequencies;
