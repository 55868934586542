//utilities
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import APIURL from "../APIURL";
//kendo component
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
//localization
import { useLocalization } from "@progress/kendo-react-intl";

//access
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";

export function TrialMap(props) {
  //localization
  const localizationService = useLocalization();

  const [items, setItems] = React.useState([
    {
      text: localizationService.toLanguageString("custom.studyoverview"),
      route: "/crf/study/detail",
      selected: true,
      className: "expanded-title",
      id: 1,
    },
    {
      text: localizationService.toLanguageString("custom.drawerscreening"),
      className: "expanded-title",
      id: 2,
    },
    {
      text: localizationService.toLanguageString("custom.demography"),
      route: "/crf/screening/demography/add",
      className: "indent-title",
      id: 3,
      section_name: "Demography",
    },

    {
      text: localizationService.toLanguageString(
        "custom.inclusionexclusioncriteria"
      ),
      route: "/crf/screening/inclusionexclusioncriteria/add",
      className: "indent-title",
      id: 4,
      section_name: "Inclusion/Exclusion Criteria",
    },

    {
      text: localizationService.toLanguageString("custom.informedconsent"),
      route: "/crf/screening/informedconsent/add",
      className: "indent-title",
      id: 5,
      section_name: "Informed Consent",
    },
    // {
    //   text: localizationService.toLanguageString("custom.approvetrial"),
    //   route: "/crf/screening/approvetrial/add",
    //   className: "indent-title",
    //   id: 6,
    //   section_name: "Approve Trial",
    // },
    // {
    //   separator: true,
    // },
    {
      text: localizationService.toLanguageString("custom.drawerbaseline"),
      selected: true,
      className: "expanded-title",
      id: 6,
    },
    {
      text: localizationService.toLanguageString("custom.vitalsigns"),
      route: "/crf/baseline/vitalsigns/add",
      className: "indent-title",
      id: 7,
      section_name: "Vital Signs",
    },
    {
      text: localizationService.toLanguageString("custom.smokinghistory"),
      route: "/crf/baseline/smokinghistory/add",
      className: "indent-title",
      id: 8,
      section_name: "Smoking History",
    },
    {
      text: localizationService.toLanguageString("custom.medicalhistory"),
      route: "/crf/baseline/medicalhistory/add",
      className: "indent-title",
      id: 9,
      section_name: "Medical History",
    },
    {
      text: localizationService.toLanguageString("custom.mrcchistory"),
      route: "/crf/baseline/mrcchistory/add",
      className: "indent-title",
      id: 10,
      section_name: "mRCC History",
    },
    {
      text: localizationService.toLanguageString("custom.metastaticsites"),
      route: "/crf/baseline/metastaticsites/add",
      className: "indent-title",
      id: 11,
      section_name: "Metastatic Sites",
    },

    {
      text: localizationService.toLanguageString("custom.prognosticfactors"),
      route: "/crf/baseline/prognosticfactors/add",
      className: "indent-title",
      id: 12,
      section_name: "Prognostic Factors",
    },
    {
      text: localizationService.toLanguageString("custom.currenttherapy"),
      route: "/crf/baseline/currenttherapy/add",
      className: "indent-title",
      id: 13,
      section_name: "Current Therapy",
    },
    {
      text: localizationService.toLanguageString("custom.laboratorytests"),
      route: "/crf/baseline/laboratorytests/add",
      className: "indent-title",
      id: 14,
      section_name: "Laboratory Tests",
    },
    {
      text: localizationService.toLanguageString(
        "custom.concomitantmedications"
      ),
      route: "/crf/baseline/concomitantmedications/add",
      className: "indent-title",
      id: 15,
      section_name: "Concomitant Medications",
    },

    {
      separator: true,
    },
    {
      text: localizationService.toLanguageString("custom.drawertreatment"),
      selected: true,
      className: "expanded-title",
      id: 16,
    },

    {
      text: localizationService.toLanguageString("custom.monththree"),
      route: `/crf/treatment/month-3/add`,
      className: "indent-title",
      id: 17,
      section_name: "Month-3",
    },
    {
      text: localizationService.toLanguageString("custom.monthsix"),
      route: `/crf/treatment/month-6/add`,
      className: "indent-title",
      id: 18,
      section_name: "Month-6",
    },
    {
      text: localizationService.toLanguageString("custom.monthtwelve"),
      route: `/crf/treatment/month-12/add`,
      className: "indent-title",
      id: 19,
      section_name: "Month-12",
    },
    {
      text: localizationService.toLanguageString("custom.monththirtysix"),
      route: `/crf/treatment/month-36/add`,
      className: "indent-title",
      id: 20,
      section_name: "Month-36",
    },
    {
      separator: true,
    },
    {
      text: localizationService.toLanguageString("custom.drawerfollowup"),
      className: "expanded-title",
      id: 21,
    },
    {
      text: localizationService.toLanguageString("custom.followupone"),
      route: `/crf/followup/followup-1/add`,
      className: "indent-title",
      id: 22,
      section_name: "Followup-1",
    },
  ]);

  //we make this function : so if I am in the edit page : it will understand the props even if the seleted element has no route
  const location = useLocation();

  useEffect(() => {
    //remove title from the drawer : telerik are giving title to this class
    let x = document.getElementsByClassName("k-drawer-items")[1];
    x.removeAttribute("title");
  }, []);


  const onSelect = (ev) => {
    
    if (
      location.pathname !== ev.itemTarget.props.route &&
      ev.itemTarget.props.route !== undefined &&
      ev.itemTarget.props.route !== "/crf/study/detail"
    ) {
 
      axios
        .get(APIURL + "/common/sectiondetection/", {
          params: {
            section: ev.itemTarget.props.section_name,
            subject: props.location.state.data.id,
          },
        })
        .then((res) => {
          
          if (res.data.message === "approve") {
            props.history.push({
              pathname: ev.itemTarget.props.route,
              state: {
                data: props.location.state.data,
                section: res.data.section,
              },
            });
          } else {
            props.history.push({
              pathname: "/sectionlocked",
              state: {
                data: props.location.state.data,
                section: res.data.section,
                reason: res.data.reason,
              },
            });
          }
        });
    } else if (ev.itemTarget.props.route === "/crf/study/detail") {
      props.history.push({
        pathname: ev.itemTarget.props.route,
        state: { data: props.location.state.data },
      });
    }

    const currentItem = ev.itemTarget.props;
    const isParent = currentItem["data-expanded"] !== undefined;
    const nextExpanded = !currentItem["data-expanded"];
    const newData = items.map((item) => {
      const {
        selected,
        ["data-expanded"]: currentExpanded,
        id,
        ...others
      } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        selected: isCurrentItem,
        ["data-expanded"]:
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });
    setItems(newData);
  };

  const data = items.map((item) => {
    const { parentId, ...others } = item;

    if (parentId !== undefined) {
      const parent = items.find((parent) => parent.id === parentId);
      return { ...others, visible: parent["data-expanded"] };
    }

    return item;
  });
  const setSelectedItem = (pathName) => {
    let currentPath = items.find((item) => item.route === pathName);

    if (pathName === "/trialmap") {
      return "";
    }

    if (pathName === "/crf/studysubject/list") {
      return "";
    }

    if (pathName === "/sectionlocked") {
      return "";
    }

    if (currentPath.text) {
      return currentPath.text;
    }
  };

  let selected = setSelectedItem(props.location.pathname);


 
  return (
    <>
      {props.condition && props.condition === "dialog" ? (
        props.children
      ) : (
        <div>
          <Drawer
            className="SettingsDrawer"
            expanded={true}
            position={"start"}
            mode={"push"}
            mini={true}
            items={data.map((d) => ({ ...d, selected: d.text === selected }))}
            //items={items}
            onSelect={onSelect}
          >
            <DrawerContent className="drawer-content">
              {props.children}
            </DrawerContent>
          </Drawer>
        </div>
      )}
    </>
  );
}

export default withRouter(TrialMap);
