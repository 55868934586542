//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { AppBar, AppBarSection } from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { FieldArray } from "@progress/kendo-react-form";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { DatePicker } from "@progress/kendo-react-dateinputs";
//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  RequiredFormDatePicker,
  FormCheckbox,
  FormNumericTextBox,
  FormInput,
  FormDatePicker,
  RequiredFormTextArea,
  RequiredDropDownList,
  RequiredFormInput,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax255Validator,
  requiredMax50Validator,
} from "../common/Kendo/validators";
import { v4 as uuidv4 } from "uuid";
//component
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
import TrialMap from "../studysubject/TrialMap";
import TabChange from "../common/commoncomponents/TabChange";
import AddFile from "../common/commoncomponents/AddFile";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import CancerLocation from "../common/commoncomponents/CancerLoaction";
import PathologyType from "../common/commoncomponents/PathologyType";
import SurgerySite from "../common/commoncomponents/SurgerySite";
import SurgeryType from "../common/commoncomponents/SurgeryType";
import RccStages from "../common/commoncomponents/RccStages";
import ClearCellType from "../common/commoncomponents/ClearCellType";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import TreatmentLines from "../common/commoncomponents/TreatmentLines";
const MyContext = React.createContext({
  deletearray: [],
  deleteadjarray: [],
});

const languageContext = React.createContext({
  new: null,
});

const FormTreatmentLineDropDownList = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  const currentContext = React.useContext(MyContext);
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DropDownList
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        textField={"name"}
        dataItemKey={"value"}
        id={id}
        disabled={disabled}
        {...others}
      />

      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
const arrayLengthValidator = (value) =>
  value && value.length ? "" : "Please add at least one record.";

const onRemoveContext = React.createContext((options) => null);
// Create context for your state
const stateContext = React.createContext([{}, () => {}]);

const CustomCell = (props) => {
  if (props.field === "start_date") {
    return (
      <td>
        <Field
          component={FormDatePicker}
          name={`cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "end_date") {
    return (
      <td>
        <Field
          component={FormDatePicker}
          name={`cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "frequency") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredMax50Validator}
        />
      </td>
    );
  } else if (props.field === "dose") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`cancer_therapy[${props.dataIndex}].${props.field}`}
          format={"n0"}
          min={1}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "unit") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredMax50Validator}
        />
      </td>
    );
  } else if (props.field === "drug") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredMax50Validator}
        />
      </td>
    );
  } else if (props.field === "nb_cycle") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`cancer_therapy[${props.dataIndex}].${props.field}`}
          format={"n0"}
          min={1}
          validator={requiredValidator}
        />
      </td>
    );
  }
};

const AdjCustomCell = (props) => {
  if (props.field === "start_date") {
    return (
      <td>
        <Field
          component={FormDatePicker}
          name={`adjuvant_cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "end_date") {
    return (
      <td>
        <Field
          component={FormDatePicker}
          name={`adjuvant_cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "frequency") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`adjuvant_cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredMax50Validator}
        />
      </td>
    );
  } else if (props.field === "dose") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`adjuvant_cancer_therapy[${props.dataIndex}].${props.field}`}
          format={"n0"}
          min={1}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "unit") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`adjuvant_cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredMax50Validator}
        />
      </td>
    );
  } else if (props.field === "drug") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`adjuvant_cancer_therapy[${props.dataIndex}].${props.field}`}
          validator={requiredMax50Validator}
        />
      </td>
    );
  } else if (props.field === "nb_cycle") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`adjuvant_cancer_therapy[${props.dataIndex}].${props.field}`}
          format={"n0"}
          min={1}
          validator={requiredValidator}
        />
      </td>
    );
  }
};

const CommandCell = (cellProps) => {
  const onRemove = React.useContext(onRemoveContext);
  // Consume the state context, which will give you both the state and the setter.
  const [state, setState] = React.useContext(stateContext);
  const currentContext = React.useContext(MyContext);
  const onClick = (e) => {
    e.preventDefault();
    if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
      onRemove({ index: cellProps.dataIndex });
    } else {
      currentContext.deletearray.push(cellProps.dataItem.id);

      onRemove({ index: cellProps.dataIndex });
    }
  };

  return (
    <td>
      {cellProps.dataItem.id ? (
        ""
      ) : (
        <SvgIcon
          icon={trashIcon}
          style={{ color: "red", cursor: "pointer" }}
          onClick={onClick}
        />
      )}
    </td>
  );
};

const AdjCommandCell = (cellProps) => {
  const onRemove = React.useContext(onRemoveContext);
  // Consume the state context, which will give you both the state and the setter.
  const [state, setState] = React.useContext(stateContext);
  const currentContext = React.useContext(MyContext);
  const onClick = (e) => {
    e.preventDefault();
    if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
      onRemove({ index: cellProps.dataIndex });
    } else {
      currentContext.deleteadjarray.push(cellProps.dataItem.id);

      onRemove({ index: cellProps.dataIndex });
    }
  };

  return (
    <td>
      {cellProps.dataItem.id ? (
        ""
      ) : (
        <SvgIcon
          icon={trashIcon}
          style={{ color: "red", cursor: "pointer" }}
          onClick={onClick}
        />
      )}
    </td>
  );
};

const FormGrid = (fieldArrayRenderProps) => {
  const { validationMessage, visited } = fieldArrayRenderProps;
  const [state, setState] = React.useState({});
  const onAdd = React.useCallback(
    (e) => {
      e.preventDefault();
      fieldArrayRenderProps.onUnshift({
        value: {
          status: "new",
          drug: "",
          dose: null,
          unit: "",
          frequency: "",
          nb_cycle: null,
          end_date: null,
          start_date: null,
        },
      });
    },
    [fieldArrayRenderProps.onUnshift]
  );

  return (
    <div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}

      <stateContext.Provider value={[state, setState]}>
        <onRemoveContext.Provider value={fieldArrayRenderProps.onRemove}>
          <GridTelerik
            style={{
              maxHeight: "500px",
            }}
            data={fieldArrayRenderProps.value}
          >
            <GridToolbar>
              <div className="float-right">
                <Button primary={true} type={"button"} onClick={onAdd}>
                  {languageContext.new.toLanguageString("custom.new")}
                </Button>
              </div>
            </GridToolbar>
            <Column
              field="code"
              title={
                <>
                  <span>
                    {languageContext.new.toLanguageString("custom.id")}
                  </span>
                </>
              }
            />

            <Column
              field="drug"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString("custom.drug")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.drug")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="dose"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString("custom.dose")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.dose")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column
              field="unit"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString("custom.unit")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.unit")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="frequency"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.frequency"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.frequency")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="nb_cycle"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.nbcycles"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.nbcycles")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="start_date"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.startdate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.startdate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="end_date"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.enddate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.enddate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column cell={CommandCell} filterable={false} width="50px" />
          </GridTelerik>
        </onRemoveContext.Provider>
      </stateContext.Provider>
    </div>
  );
};

const AdjuvantFormGrid = (fieldArrayRenderProps) => {
  const { validationMessage, visited } = fieldArrayRenderProps;
  const [state, setState] = React.useState({});
  const onAdd = React.useCallback(
    (e) => {
      e.preventDefault();
      fieldArrayRenderProps.onUnshift({
        value: {
          status: "new",
          drug: "",
          dose: null,
          unit: "",
          frequency: "",
          nb_cycle: null,
          end_date: null,
          start_date: null,
        },
      });
    },
    [fieldArrayRenderProps.onUnshift]
  );

  return (
    <div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}

      <stateContext.Provider value={[state, setState]}>
        <onRemoveContext.Provider value={fieldArrayRenderProps.onRemove}>
          <GridTelerik
            style={{
              maxHeight: "500px",
            }}
            data={fieldArrayRenderProps.value}
          >
            <GridToolbar>
              <div className="float-right">
                <Button primary={true} type={"button"} onClick={onAdd}>
                  {languageContext.new.toLanguageString("custom.new")}
                </Button>
              </div>
            </GridToolbar>
            <Column
              field="code"
              title={
                <>
                  <span>
                    {languageContext.new.toLanguageString("custom.id")}
                  </span>
                </>
              }
            />

            <Column
              field="drug"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString("custom.drug")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.drug")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={AdjCustomCell}
            />
            <Column
              field="dose"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString("custom.dose")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.dose")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={AdjCustomCell}
            />

            <Column
              field="unit"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString("custom.unit")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.unit")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={AdjCustomCell}
            />
            <Column
              field="frequency"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.frequency"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.frequency")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={AdjCustomCell}
            />
            <Column
              field="nb_cycle"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.nbcycles"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.nbcycles")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={AdjCustomCell}
            />
            <Column
              field="start_date"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.startdate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.startdate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={AdjCustomCell}
            />
            <Column
              field="end_date"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.enddate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.enddate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={AdjCustomCell}
            />

            <Column cell={AdjCommandCell} filterable={false} width="50px" />
          </GridTelerik>
        </onRemoveContext.Provider>
      </stateContext.Provider>
    </div>
  );
};

export function ListCancerHistory(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();
  languageContext.new = useLocalization();

  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);
  const { data: notes, Noteserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  //notes data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attchementserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  const { data: checkapprove, checkapproveerror } = useSWR(
    props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  //variables
  const [approved, setApproved] = useState(false);
  const [approvedformchanged, setapprovedformchanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //form ref to detect modification of the form
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  const [ModifiedData, setModifiedData] = useState();
  const [rcctreatmentradioValue, setrcctreatmentradioValue] = useState("no");
  const [rccstageradioValue, setrccstageradioValue] = useState("no");
  const [radioValue, setradioValue] = useState("no");
  const [AllData, setAllData] = useState(null);
  const [deletearray, setdeletearray] = useState([]);
  const [deleteadjarray, setdeleteadjarray] = useState([]);
  const [OtherArray, setOtherArray] = useState([]);
  const [editID, setEditID] = React.useState(null);

  const rowClick = (event) => {
    setEditID(event.dataItem.id);
  };
  const [changed, setChanged] = useState(false);
  const itemChange = (event) => {
    setChanged(true);
    const inEditID = event.dataItem.id;
    const field = event.field || "";
    const newData = OtherArray.map((item) =>
      item.id === inEditID ? { ...item, [field]: event.value } : item
    );
    setOtherArray(newData);
  };

  const removeOtherRecord = (index) => {
    let values = [...OtherArray];
    values.splice(index, 1);
    setOtherArray(values);
  };
  const ActionTemplate = (cellProps) => {
    return (
      <td>
        {cellProps.dataItem.pk ? (
          ""
        ) : (
          <SvgIcon
            icon={trashIcon}
            style={{ color: "red", cursor: "pointer" }}
            onClick={() =>
              removeOtherRecord(
                OtherArray.findIndex(
                  (item) => item.id === cellProps.dataItem.id
                )
              )
            }
          />
        )}
      </td>
    );
  };
  const DateTemplate = (props) => {
    const { dataItem, field } = props;
    const editorRef = React.useRef(null);
    const dataValue = props.dataItem[props.field];
    const handleChange = (e) => {
      props.onChange({
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    };

    return (
      <td>
        {dataItem.inEdit ? (
          <DatePicker
          format={"dd/MMMM/yyyy "}
            value={props.dataItem[props.field]}
            ref={editorRef}
            onChange={handleChange}
          />
        ) : dataValue ? (
          moment(dataValue).format("DD/MM/YYYY")
        ) : (
          dataValue
        )}
      </td>
    );
  };

  const OtherTherapyCodeTemplate = (cellProps) => {
    return (
      <td>
        {cellProps.dataItem.inEdit ? "" : "OT- " + cellProps.dataItem.number}
      </td>
    );
  };
  const OtherTherapyTreatmentLineTemplate = (props) => {
   

    
  
  
  
    const handleChange = (e) => {
      props.onChange({
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
    };
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      wrapperStyle,
  
      ...others
    } = props;
    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    const { dataItem, field } = props;
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
  
    return (
      <td>
        {dataItem.inEdit ? (
          <DropDownList
            onChange={handleChange}
            value={dataValue}
            data={TreatmentLines}
            textField="name"
            dataItemKey="value"
            filterable={true}
       
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hintId} ${errorId}`}
            ref={editorRef}
            valid={valid}
            id={id}
            disabled={disabled}
          
          />
        ) : dataValue ? (
          dataValue.name
        ) : (
          dataValue
        )}
      </td>
    );
  };
  console.log("otherrrrrrrrrrrrrrs", OtherArray);
  const addOtherRecord = () => {
    const newRecord = {
      id: uuidv4(),

      code: null,
      drug: null,
      dose: null,
      unit: null,
      frequency: null,
      nb_cycle: null,
      start_date: "",
      end_date: "",
      treatment_line: "First Line",
    };
    setOtherArray([newRecord, ...OtherArray]);
    setEditID(newRecord.id);
  };
  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setSelected(value);
    setapprovedformchanged(false);
  };

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    props.location.state
      ? [
          APIURL + "/opera/baseline/addcancerhistory/",
          props.location.state.data.id,
        ]
      : null,
    fetcher1
  );

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                EditSubject ? toggleDeditNote() : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //USE EFFECT
  useEffect(() => {
    let array = [];
    let adjuvantarray = [];

    if (data) {
      if (data.message === "no data") {
        setAllData(null);
      } else {
        if (data.CancerTherapy) {
          data.CancerTherapy.map((d) => {
            array.push({
              id: d.id,
              start_date: new Date(d.start_date),
              end_date: new Date(d.end_date),
              frequency: d.frequency,
              dose: d.dose,
              nb_cycle: d.nb_cycle,
              unit: d.unit,
              drug: d.drug,
              code: d.code,
            });
          });
        }
        if (data.AdjuvantTherapy) {
          data.AdjuvantTherapy.map((d) => {
            adjuvantarray.push({
              id: d.id,
              start_date: new Date(d.start_date),
              end_date: new Date(d.end_date),
              frequency: d.frequency,
              dose: d.dose,
              nb_cycle: d.nb_cycle,
              unit: d.unit,
              drug: d.drug,
              code: d.code,
            });
          });
        }

        if (data.OtherTherapy) {
          let array = data.OtherTherapy.map((d) => ({
            id: d.id,
            pk: d.id,
            start_date: new Date(d.start_date),
            end_date: new Date(d.end_date),
            frequency: d.frequency,
            dose: d.dose,
            nb_cycle: d.nb_cycle,
            unit: d.unit,
            drug: d.drug,
            code: d.code,
            study_subject: d.study_subject,
            cancer_history: d.cancer_history,
            number: d.number,
            treatment_line: { name: d.treatment_line, value: d.treatment_line },
          }));
          setOtherArray(array);
        }

        setAllData({
          created_by: data.created_by,
          createdBy: data.createdBy,
          modified_by: data.modified_by,
          modifiedBy: data.modifiedBy,
          id: data.id,
          diagnosis_date: data.diagnosis_date
            ? new Date(data.diagnosis_date)
            : null,
          stage_date_diagnosis: data.stage_date_diagnosis
            ? new Date(data.stage_date_diagnosis)
            : null,

          pathology: data.pathology
            ? { name: data.pathology, value: data.pathology }
            : null,
          mixed_pathology: data.mixed_pathology,
          clear_cell_type: data.clear_cell_type
            ? { name: data.clear_cell_type, value: data.clear_cell_type }
            : null,
          other_clear_cell_type: data.other_clear_cell_type,
          stage_pathology: data.stage_pathology
            ? { name: data.stage_pathology, value: data.stage_pathology }
            : null,
          stage_mixed_pathology: data.stage_mixed_pathology,
          stage_clearcell_type: data.stage_clearcell_type
            ? {
                name: data.stage_clearcell_type,
                value: data.stage_clearcell_type,
              }
            : null,
          stage_otherclearcell_type: data.stage_otherclearcell_type,
          location: data.location
            ? { name: data.location, value: data.location }
            : null,
          diagnosis_stage: data.diagnosis_stage
            ? { name: data.diagnosis_stage, value: data.diagnosis_stage }
            : null,
          previous_treatment: data.previous_treatment,
          neoadjuvant_therapy: data.neoadjuvant_therapy,
          adjuvant_therapy: data.adjuvant_therapy,
          surgery: data.surgery,
          laparoscopic_surgery: data.laparoscopic_surgery,
          surgery_type: data.surgery_type
            ? { name: data.surgery_type, value: data.surgery_type }
            : null,
          other_surgery_type: data.other_surgery_type,
          surgery_date: data.surgery_date ? new Date(data.surgery_date) : null,
          surgery_site: data.surgery_site
            ? { name: data.surgery_site, value: data.surgery_site }
            : null,
          mrcc_stagehistory_reason: data.mrcc_stagehistory_reason,

          mrcc_history_reason: data.mrcc_history_reason,
          mrcc_treatment_reason: data.mrcc_treatment_reason,
          cancer_therapy: array,
          adjuvant_cancer_therapy: adjuvantarray,
          mrcc_history_missing: data.mrcc_history_missing,
          mrcc_treatment_missing: data.mrcc_treatment_missing,
          mrcc_stagehistory_missing: data.mrcc_stagehistory_missing,
          other: data.other,
        });
        setrccstageradioValue(
          data.mrcc_stagehistory_missing === true ? "yes" : "no"
        );
        setradioValue(data.mrcc_history_missing === true ? "yes" : "no");
        setrcctreatmentradioValue(
          data.mrcc_treatment_missing === true ? "yes" : "no"
        );
      }
    }

    if (checkapprove) {
      setApproved(checkapprove);
    }
  }, [data, checkapprove]);

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  //TAB STRIP
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    if (selected === 1) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setSelected(e.selected);
      }
    } else if (
      MyForm.current &&
      Object.keys(MyForm.current.modified).length !== 0
    ) {
      setSelectedTab(e.selected);
      toggleDTabStripChange();
    } else {
      setSelected(e.selected);
    }
  };

  const [Subselected, setSubSelected] = React.useState(0);

  const handleSubSelect = (e) => {
    setSubSelected(e.selected);
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  //handle radio stage rcc
  const handleRadioStageRcc1Change = (e, formRenderProps) => {
    setrccstageradioValue(e.value);
    formRenderProps.onChange("diagnosis_stage", {
      value: null,
    });
    formRenderProps.onChange("stage_date_diagnosis", {
      value: null,
    });
    formRenderProps.onChange("stage_pathology", {
      value: null,
    });
    formRenderProps.onChange("stage_mixed_pathology", {
      value: "",
    });
    formRenderProps.onChange("stage_clearcell_type", {
      value: null,
    });
    formRenderProps.onChange("stage_otherclearcell_type", {
      value: "",
    });
  };

  const handleRadioStageRcc2Change = (e, formRenderProps) => {
    setrccstageradioValue(e.value);
    formRenderProps.onChange("mrcc_stagehistory_reason", { value: "" });
  };

  //radio Cancer History handle change
  const handleRadio1Change = (e, formRenderProps) => {
    setradioValue(e.value);
    formRenderProps.onChange("diagnosis_date", { value: null });
    formRenderProps.onChange("pathology", { value: null });
    formRenderProps.onChange("clear_cell_type", {
      value: null,
    });
    formRenderProps.onChange("other_clear_cell_type", {
      value: "",
    });
    formRenderProps.onChange("mixed_pathology", { value: "" });
    formRenderProps.onChange("location", { value: null });
  };

  const handleRadio2Change = (e, formRenderProps) => {
    setradioValue(e.value);
    formRenderProps.onChange("mrcc_history_reason", { value: "" });
  };

  //handle stage change

  const handleStageChange = (e, formRenderProps) => {
    formRenderProps.onChange("stage_pathology", {
      value: null,
    });
    formRenderProps.onChange("stage_date_diagnosis", {
      value: null,
    });

    formRenderProps.onChange("stage_mixed_pathology", {
      value: "",
    });
    formRenderProps.onChange("stage_clearcell_type", {
      value: null,
    });
    formRenderProps.onChange("stage_otherclearcell_type", {
      value: "",
    });
  };

  //radio Previous treatment section
  const handleRadio3Change = (e, formRenderProps) => {
    setrcctreatmentradioValue(e.value);
    formRenderProps.onChange("previous_treatment", { value: false });
    formRenderProps.onChange("neoadjuvant_therapy", { value: false });
    formRenderProps.onChange("surgery", { value: false });
    formRenderProps.onChange("laparoscopic_surgery", {
      value: false,
    });
    formRenderProps.onChange("surgery_type", {
      value: null,
    });
    formRenderProps.onChange("other_surgery_type", { value: "" });
    formRenderProps.onChange("surgery_date", { value: null });
    formRenderProps.onChange("surgery_site", {
      value: null,
    });
    formRenderProps.onChange("cancer_history", {
      value: [],
    });
  };

  const handleRadio4Change = (e, formRenderProps) => {
    setrcctreatmentradioValue(e.value);
    formRenderProps.onChange("mrcc_treatment_reason", { value: "" });
  };

  //Checkbox handle change
  const onNeoTherapyChange = (e, formRenderProps) => {
    formRenderProps.onChange("cancer_therapy", { value: [] });
  };

  const onAdjTherapyChange = (e, formRenderProps) => {
    formRenderProps.onChange("adjuvant_cancer_therapy", { value: [] });
  };

  const onSurgeryChange = (e, formRenderProps) => {
    formRenderProps.onChange("laparoscopic_surgery", {
      value: false,
    });
    formRenderProps.onChange("surgery_type", {
      value: null,
    });
    formRenderProps.onChange("other_surgery_type", { value: "" });
    formRenderProps.onChange("surgery_date", { value: null });
    formRenderProps.onChange("surgery_site", {
      value: null,
    });
  };

  const onPreviousTreatmentChange = (e, formRenderProps) => {
    formRenderProps.onChange("mrcc_treatment_missing", {
      value: false,
    });
    formRenderProps.onChange("surgery", {
      value: false,
    });
    formRenderProps.onChange("neoadjuvant_therapy", {
      value: false,
    });
    formRenderProps.onChange("surgery_date", {
      value: null,
    });
    formRenderProps.onChange("surgery_site", {
      value: null,
    });
    formRenderProps.onChange("surgery_type", {
      value: null,
    });
    formRenderProps.onChange("other_surgery_type", {
      value: "",
    });
    formRenderProps.onChange("laparoscopic_surgery", {
      value: false,
    });
    formRenderProps.onChange("cancer_therapy", {
      value: [],
    });
  };

  const handlePathologyChange = (e, formRenderProps) => {
    formRenderProps.onChange("mixed_pathology", {
      value: "",
    });
    formRenderProps.onChange("clear_cell_type", {
      value: null,
    });
    formRenderProps.onChange("other_clear_cell_type", {
      value: "",
    });
  };

  const handleStagePathologyChange = (e, formRenderProps) => {
    formRenderProps.onChange("stage_mixed_pathology", {
      value: "",
    });
    formRenderProps.onChange("stage_clearcell_type", {
      value: null,
    });
    formRenderProps.onChange("stage_otherclearcell_type", {
      value: "",
    });
  };
  const OnStageClearCellChange = (e, formRenderProps) => {
    formRenderProps.onChange("stage_otherclearcell_type", {
      value: "",
    });
  };

  const OnClearCellChange = (e, formRenderProps) => {
    formRenderProps.onChange("other_clear_cell_type", {
      value: "",
    });
  };

  const OnSurgeryTypeChange = (e, formRenderProps) => {
    formRenderProps.onChange("other_surgery_type", {
      value: "",
    });
  };
  console.log("ddddddddddddddddd", ModifiedData);
  const CreateCancerHistory = (dataItem) => {
    let submitdata = dataItem.values;

    if (dataItem.isValid) {
      if (AllData) {
        if (dataItem.isModified || changed) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let array = [];
        let adjuvantarray = [];
        let others = [];
        if (dataItem.values.cancer_therapy) {
          dataItem.values.cancer_therapy.map((d) => {
            array.push({
              status: d.status ? d.status : "edit",
              start_date: moment(d.start_date).format("YYYY-MM-DD"),
              end_date: moment(d.end_date).format("YYYY-MM-DD"),
              frequency: d.frequency,
              dose: d.dose,
              unit: d.unit,
              drug: d.drug,
              nb_cycle: d.nb_cycle,
              study_subject: props.location.state.data.id,
            });
          });
        }
        if (dataItem.values.adjuvant_cancer_therapy) {
          dataItem.values.adjuvant_cancer_therapy.map((d) => {
            adjuvantarray.push({
              status: d.status ? d.status : "edit",
              start_date: moment(d.start_date).format("YYYY-MM-DD"),
              end_date: moment(d.end_date).format("YYYY-MM-DD"),
              frequency: d.frequency,
              dose: d.dose,
              unit: d.unit,
              drug: d.drug,
              nb_cycle: d.nb_cycle,
              study_subject: props.location.state.data.id,
            });
          });
        }

        if (dataItem.values.other) {
          others = OtherArray.map((d) => ({
            ...d,
            start_date: moment(d.start_date).format("YYYY-MM-DD"),
            end_date: moment(d.end_date).format("YYYY-MM-DD"),
          }));
        }
        setLoading(true);

        axios
          .post(APIURL + "/opera/baseline/addcancerhistory/", {
            created_by: UserDetail.id,
            modified_by: null,

            study_subject: props.location.state.data.id,
            section: props.location.state.section.id,
            diagnosis_date: submitdata.diagnosis_date
              ? moment(submitdata.diagnosis_date).format("YYYY-MM-DD")
              : null,
            stage_date_diagnosis: submitdata.stage_date_diagnosis
              ? moment(submitdata.stage_date_diagnosis).format("YYYY-MM-DD")
              : null,
            stage_pathology: submitdata.stage_pathology
              ? submitdata.stage_pathology.value
              : "",
            stage_mixed_pathology: submitdata.stage_mixed_pathology
              ? submitdata.stage_mixed_pathology
              : "",
            stage_clearcell_type: submitdata.stage_clearcell_type
              ? submitdata.stage_clearcell_type.value
              : "",
            stage_otherclearcell_type: submitdata.stage_otherclearcell_type
              ? submitdata.stage_otherclearcell_type
              : "",

            pathology: submitdata.pathology ? submitdata.pathology.value : "",
            mixed_pathology: submitdata.mixed_pathology
              ? submitdata.mixed_pathology
              : "",
            clear_cell_type: submitdata.clear_cell_type
              ? submitdata.clear_cell_type.value
              : "",
            other_clear_cell_type: submitdata.other_clear_cell_type
              ? submitdata.other_clear_cell_type
              : "",
            location: submitdata.location ? submitdata.location.value : "",
            diagnosis_stage: submitdata.diagnosis_stage
              ? submitdata.diagnosis_stage.value
              : "",
            previous_treatment: submitdata.previous_treatment,
            neoadjuvant_therapy: submitdata.neoadjuvant_therapy,
            adjuvant_therapy: submitdata.adjuvant_therapy,
            surgery: submitdata.surgery,
            laparoscopic_surgery: submitdata.laparoscopic_surgery,

            surgery_type: submitdata.surgery_type
              ? submitdata.surgery_type.value
              : "",
            other_surgery_type: submitdata.other_surgery_type
              ? submitdata.other_surgery_type
              : "",
            surgery_date: submitdata.surgery_date
              ? moment(submitdata.surgery_date).format("YYYY-MM-DD")
              : null,
            surgery_site: submitdata.surgery_site
              ? submitdata.surgery_site.value
              : "",
            mrcc_stagehistory_missing:
              rccstageradioValue === "yes" ? true : false,
            mrcc_history_missing: radioValue === "yes" ? true : false,
            mrcc_treatment_missing:
              rcctreatmentradioValue === "yes" ? true : false,
            mrcc_stagehistory_reason: submitdata.mrcc_stagehistory_reason
              ? submitdata.mrcc_stagehistory_reason
              : "",
            mrcc_history_reason: submitdata.mrcc_history_reason
              ? submitdata.mrcc_history_reason
              : "",
            mrcc_treatment_reason: submitdata.mrcc_treatment_reason
              ? submitdata.mrcc_treatment_reason
              : "",

            cancer_therapy: array,
            adjuvant_cancer_therapy: adjuvantarray,
            other: dataItem.values.other,
            other_therapy: others,
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
            setdeleteadjarray([]);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
            setdeleteadjarray([]);
          });
      }
    }
  };

  const CreateModification = (dataItem) => {
    ModifiedData["other_array"] = OtherArray;

    let array = [];
    let adjuvantarray = [];
    let others = [];

    if (ModifiedData.other) {
      console.log("ifffffffffffffffffffffffffffffffff", OtherArray);
      others = OtherArray.map((d) => ({
        ...d,
        start_date: moment(d.start_date).format("YYYY-MM-DD"),
        end_date: moment(d.end_date).format("YYYY-MM-DD"),
      }));
    }
    if (ModifiedData.cancer_therapy) {
      ModifiedData.cancer_therapy.map((d) => {
        array.push({
          id: d.id,
          status: d.status ? d.status : "edit",
          start_date: moment(d.start_date).format("YYYY-MM-DD"),
          end_date: moment(d.end_date).format("YYYY-MM-DD"),
          frequency: d.frequency,
          dose: d.dose,
          unit: d.unit,
          drug: d.drug,
          nb_cycle: d.nb_cycle,
          study_subject: props.location.state.data.id,
        });
      });
    }
    if (ModifiedData.adjuvant_cancer_therapy) {
      ModifiedData.adjuvant_cancer_therapy.map((d) => {
        adjuvantarray.push({
          id: d.id,
          status: d.status ? d.status : "edit",
          start_date: moment(d.start_date).format("YYYY-MM-DD"),
          end_date: moment(d.end_date).format("YYYY-MM-DD"),
          frequency: d.frequency,
          dose: d.dose,
          unit: d.unit,
          drug: d.drug,
          nb_cycle: d.nb_cycle,
          study_subject: props.location.state.data.id,
        });
      });
    }

    toggleDModifyreason(false);
    setLoading(true);

    axios
      .put(APIURL + "/opera/baseline/updatecancerhistory/" + AllData.id + "/", {
        created_by: AllData.created_by,
        modified_by: UserDetail.id,
        study_subject: props.location.state.data.id,
        section: props.location.state.section.id,
        diagnosis_date: ModifiedData.diagnosis_date
          ? moment(ModifiedData.diagnosis_date).format("YYYY-MM-DD")
          : null,
        pathology: ModifiedData.pathology ? ModifiedData.pathology.value : "",
        mixed_pathology: ModifiedData.mixed_pathology
          ? ModifiedData.mixed_pathology
          : "",
        clear_cell_type: ModifiedData.clear_cell_type
          ? ModifiedData.clear_cell_type.value
          : "",
        other_clear_cell_type: ModifiedData.other_clear_cell_type
          ? ModifiedData.other_clear_cell_type
          : "",
        stage_date_diagnosis: ModifiedData.stage_date_diagnosis
          ? moment(ModifiedData.stage_date_diagnosis).format("YYYY-MM-DD")
          : null,
        stage_pathology: ModifiedData.stage_pathology
          ? ModifiedData.stage_pathology.value
          : "",
        stage_mixed_pathology: ModifiedData.stage_mixed_pathology
          ? ModifiedData.stage_mixed_pathology
          : "",
        stage_clearcell_type: ModifiedData.stage_clearcell_type
          ? ModifiedData.stage_clearcell_type.value
          : "",
        stage_otherclearcell_type: ModifiedData.stage_otherclearcell_type
          ? ModifiedData.stage_otherclearcell_type
          : "",
        location: ModifiedData.location ? ModifiedData.location.value : "",
        diagnosis_stage: ModifiedData.diagnosis_stage
          ? ModifiedData.diagnosis_stage.value
          : "",
        previous_treatment: ModifiedData.previous_treatment,
        neoadjuvant_therapy: ModifiedData.neoadjuvant_therapy,
        adjuvant_therapy: ModifiedData.adjuvant_therapy,
        surgery: ModifiedData.surgery,
        laparoscopic_surgery: ModifiedData.laparoscopic_surgery,

        surgery_type: ModifiedData.surgery_type
          ? ModifiedData.surgery_type.value
          : "",
        other_surgery_type: ModifiedData.other_surgery_type
          ? ModifiedData.other_surgery_type
          : "",
        surgery_date: ModifiedData.surgery_date
          ? moment(ModifiedData.surgery_date).format("YYYY-MM-DD")
          : null,
        surgery_site: ModifiedData.surgery_site
          ? ModifiedData.surgery_site.value
          : "",
        mrcc_history_missing: radioValue === "yes" ? true : false,
        mrcc_stagehistory_missing: rccstageradioValue === "yes" ? true : false,
        mrcc_treatment_missing: rcctreatmentradioValue === "yes" ? true : false,
        mrcc_stagehistory_reason: ModifiedData.mrcc_stagehistory_reason
          ? ModifiedData.mrcc_stagehistory_reason
          : "",
        mrcc_history_reason: ModifiedData.mrcc_history_reason
          ? ModifiedData.mrcc_history_reason
          : "",
        mrcc_treatment_reason: ModifiedData.mrcc_treatment_reason
          ? ModifiedData.mrcc_treatment_reason
          : "",

        cancer_therapy: array,
        adjuvant_cancer_therapy: adjuvantarray,
        modification_reason: dataItem.values.modification_reason,
        other: ModifiedData.other,
        other_therapy: others,
      })
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
        setdeletearray([]);
        setdeleteadjarray([]);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
        setdeletearray([]);
        setdeleteadjarray([]);
      });
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };
  console.log("props.location.state", props.location.state);
  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || approved) {
      boolean = true;
    }
    return boolean;
  };

  if (error && Noteserror && Attchementserror)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <TrialMap>
      {(!data || !notes || !attachements) && loadingPanel}
      <MyContext.Provider
        value={{
          deletearray: deletearray,
          deleteadjarray: deleteadjarray,
        }}
      >
        <div className="row">
          <div className="col-12">
            <Card>
              <CardHeader className="DetailFormHeader">
                <div className="row">
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.subjectid")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString(
                      "custom.subjectinitials"
                    )}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.study")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.protocolid")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.sitenumber")}
                  </div>
                  <div className="col DetailCardElementTitle">
                    {localizationService.toLanguageString("custom.createdby")}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-responsive-overflow subject-id-style">
                    {props.location.state
                      ? props.location.state.data.crf_id
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.subject_initial
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.study_name
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.study_protocol
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.subject_center
                      : ""}
                  </div>
                  <div className="col text-responsive-overflow">
                    {props.location.state
                      ? props.location.state.data.createdBy
                      : ""}
                  </div>
                </div>
                <hr className="RowRoleHr" />
                <div className="row">
                  <div className="col pages-big-title">
                    <strong>
                      {localizationService.toLanguageString(
                        "custom.cancerhistory"
                      )}
                    </strong>
                  </div>
                  <div className="col">
                    <div className="float-right">
                      <Button onClick={goBack} className="back-button-style">
                        {localizationService.toLanguageString("custom.back")}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <TabStrip selected={selected} onSelect={handleSelect}>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.cancerhistory"
                )}
              >
                <Form
                  initialValues={{
                    diagnosis_date: AllData ? AllData.diagnosis_date : null,
                    pathology: AllData ? AllData.pathology : null,
                    mixed_pathology: AllData ? AllData.mixed_pathology : "",
                    clear_cell_type: AllData ? AllData.clear_cell_type : null,
                    other_clear_cell_type: AllData
                      ? AllData.other_clear_cell_type
                      : "",
                    stage_date_diagnosis: AllData
                      ? AllData.stage_date_diagnosis
                      : null,
                    stage_pathology: AllData ? AllData.stage_pathology : null,
                    stage_mixed_pathology: AllData
                      ? AllData.stage_mixed_pathology
                      : "",
                    stage_clearcell_type: AllData
                      ? AllData.stage_clearcell_type
                      : null,
                    stage_otherclearcell_type: AllData
                      ? AllData.stage_otherclearcell_type
                      : "",
                    location: AllData ? AllData.location : null,

                    diagnosis_stage: AllData ? AllData.diagnosis_stage : null,

                    previous_treatment: AllData
                      ? AllData.previous_treatment
                      : true,
                    neoadjuvant_therapy: AllData
                      ? AllData.neoadjuvant_therapy
                      : false,
                    adjuvant_therapy: AllData
                      ? AllData.adjuvant_therapy
                      : false,
                    surgery: AllData ? AllData.surgery : false,
                    surgery_type: AllData ? AllData.surgery_type : null,

                    surgery_site: AllData ? AllData.surgery_site : null,
                    laparoscopic_surgery: AllData
                      ? AllData.laparoscopic_surgery
                      : false,
                    surgery_date: AllData ? AllData.surgery_date : null,
                    other_surgery_type: AllData
                      ? AllData.other_surgery_type
                      : "",

                    mrcc_history_reason: AllData
                      ? AllData.mrcc_history_reason
                      : "",

                    mrcc_stagehistory_reason: AllData
                      ? AllData.mrcc_stagehistory_reason
                      : "",

                    mrcc_treatment_reason: AllData
                      ? AllData.mrcc_treatment_reason
                      : "",
                    cancer_therapy: AllData ? AllData.cancer_therapy : [],
                    adjuvant_cancer_therapy: AllData
                      ? AllData.adjuvant_cancer_therapy
                      : [],
                    other: AllData ? AllData.other : false,
                  }}
                  ref={MyForm}
                  onSubmitClick={CreateCancerHistory}
                  key={JSON.stringify(AllData)}
                  render={(formRenderProps) => (
                    <FormElement className="">
                      <fieldset className={"k-form-fieldset"}>
                        <Tooltip
                          openDelay={0}
                          anchorElement="target"
                          parentTitle={true}
                          position={"top"}
                        >
                          <Prompt
                            when={PromptFunction(formRenderProps)}
                            message={localizationService.toLanguageString(
                              "custom.promptmessage"
                            )}
                          />

                          <AppBar className="FormAppBar">
                            <AppBarSection>
                              <div>
                                <strong>
                                  {localizationService.toLanguageString(
                                    "custom.stageofrcc"
                                  )}
                                </strong>
                              </div>
                            </AppBarSection>
                          </AppBar>
                          <div className="col-10">
                            <div className="row">
                              <div className="col-6">
                                <strong>
                                  {" "}
                                  {localizationService.toLanguageString(
                                    "custom.informationstatusquestion"
                                  )}
                                  ?
                                </strong>
                              </div>
                              <div className="col-3">
                                {" "}
                                <RadioButton
                                  name="radio5"
                                  value="yes"
                                  checked={rccstageradioValue === "yes"}
                                  label={localizationService.toLanguageString(
                                    "custom.yes"
                                  )}
                                  onChange={(e) =>
                                    handleRadioStageRcc1Change(
                                      e,
                                      formRenderProps
                                    )
                                  }
                                  // disabled={
                                  //   AllData &&
                                  //   AllData.mrcc_stagehistory_missing === false
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </div>
                              <div className="col-3">
                                {" "}
                                <RadioButton
                                  name="radio6"
                                  value="no"
                                  checked={rccstageradioValue === "no"}
                                  label={localizationService.toLanguageString(
                                    "custom.no"
                                  )}
                                  onChange={(e) =>
                                    handleRadioStageRcc2Change(
                                      e,
                                      formRenderProps
                                    )
                                  }
                                  // disabled={
                                  //   AllData &&
                                  //   AllData.mrcc_stagehistory_missing === false
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </div>
                            </div>
                            {rccstageradioValue === "yes" ? (
                              <div className="row">
                                <div className="col">
                                  <Field
                                    name={"mrcc_stagehistory_reason"}
                                    label={localizationService.toLanguageString(
                                      "custom.missingreason"
                                    )}
                                    validator={requiredValidator}
                                    component={RequiredFormTextArea}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {rccstageradioValue === "no" ? (
                              <>
                                <div className="row">
                                  <div className="col-4">
                                    <Field
                                      id={"diagnosis_stage"}
                                      name={"diagnosis_stage"}
                                      label={localizationService.toLanguageString(
                                        "custom.stagercc"
                                      )}
                                      data={RccStages}
                                      component={RequiredDropDownList}
                                      validator={requiredValidator}
                                      onChange={(e) =>
                                        handleStageChange(e, formRenderProps)
                                      }
                                    />
                                  </div>
                                  <div className="col-4">
                                    {formRenderProps.valueGetter(
                                      "diagnosis_stage"
                                    ) &&
                                    formRenderProps.valueGetter(
                                      "diagnosis_stage"
                                    ).value !== "Stage 4(mRCC)" ? (
                                      <Field
                                        id={"stage_date_diagnosis"}
                                        name={"stage_date_diagnosis"}
                                        label={localizationService.toLanguageString(
                                          "custom.initialdiagnosisrcc"
                                        )}
                                        component={RequiredFormDatePicker}
                                        validator={requiredValidator}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                {formRenderProps.valueGetter(
                                  "diagnosis_stage"
                                ) &&
                                formRenderProps.valueGetter("diagnosis_stage")
                                  .value !== "Stage 4(mRCC)" ? (
                                  <>
                                    <div className="row">
                                      <div className="col-4">
                                        <Field
                                          id={"stage_pathology"}
                                          name={"stage_pathology"}
                                          label={localizationService.toLanguageString(
                                            "custom.pathology"
                                          )}
                                          data={PathologyType}
                                          component={RequiredDropDownList}
                                          validator={requiredValidator}
                                          onChange={(e) =>
                                            handleStagePathologyChange(
                                              e,
                                              formRenderProps
                                            )
                                          }
                                        />
                                      </div>

                                      {formRenderProps.valueGetter(
                                        "stage_pathology"
                                      ) &&
                                      formRenderProps.valueGetter(
                                        "stage_pathology"
                                      ).value === "Non clear cell" ? (
                                        <>
                                          <div className="col-4">
                                            <Field
                                              id={"stage_clearcell_type"}
                                              name={"stage_clearcell_type"}
                                              label={localizationService.toLanguageString(
                                                "custom.pleasemarkone"
                                              )}
                                              data={ClearCellType}
                                              component={RequiredDropDownList}
                                              validator={requiredValidator}
                                              onChange={(e) =>
                                                OnStageClearCellChange(
                                                  e,
                                                  formRenderProps
                                                )
                                              }
                                            />
                                          </div>
                                          {formRenderProps.valueGetter(
                                            "stage_clearcell_type"
                                          ) &&
                                          formRenderProps.valueGetter(
                                            "stage_clearcell_type"
                                          ).value === "Other, specify" ? (
                                            <div className="col-4">
                                              <Field
                                                id={"stage_otherclearcell_type"}
                                                name={
                                                  "stage_otherclearcell_type"
                                                }
                                                label={localizationService.toLanguageString(
                                                  "custom.specify"
                                                )}
                                                component={RequiredFormInput}
                                                validator={
                                                  requiredMax255Validator
                                                }
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : formRenderProps.valueGetter(
                                          "stage_pathology"
                                        ) &&
                                        formRenderProps.valueGetter(
                                          "stage_pathology"
                                        ).value === "Mixed, specify" ? (
                                        <div className="col-4">
                                          <Field
                                            id={"stage_mixed_pathology"}
                                            name={"stage_mixed_pathology"}
                                            label={localizationService.toLanguageString(
                                              "custom.mixedpathology"
                                            )}
                                            component={RequiredFormInput}
                                            validator={requiredMax255Validator}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <AppBar className="FormAppBar">
                            <AppBarSection>
                              <div>
                                <strong>
                                  {localizationService.toLanguageString(
                                    "custom.mrccsectiontitle"
                                  )}
                                </strong>
                              </div>
                            </AppBarSection>
                          </AppBar>
                          <div className="col-10">
                            <div className="row">
                              <div className="col-6">
                                <strong>
                                  {localizationService.toLanguageString(
                                    "custom.informationstatusquestion"
                                  )}
                                  ?
                                </strong>
                              </div>
                              <div className="col-3">
                                {" "}
                                <RadioButton
                                  name="radio1"
                                  value="yes"
                                  checked={radioValue === "yes"}
                                  label={localizationService.toLanguageString(
                                    "custom.yes"
                                  )}
                                  onChange={(e) =>
                                    handleRadio1Change(e, formRenderProps)
                                  }
                                  // disabled={
                                  //   AllData &&
                                  //   AllData.mrcc_history_missing === false
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </div>
                              <div className="col-3">
                                {" "}
                                <RadioButton
                                  name="radio2"
                                  value="no"
                                  checked={radioValue === "no"}
                                  label={localizationService.toLanguageString(
                                    "custom.no"
                                  )}
                                  onChange={(e) =>
                                    handleRadio2Change(e, formRenderProps)
                                  }
                                  // disabled={
                                  //   AllData &&
                                  //   AllData.mrcc_history_missing === false
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </div>
                            </div>
                            {radioValue === "yes" ? (
                              <div className="row">
                                <div className="col">
                                  <Field
                                    name={"mrcc_history_reason"}
                                    label={localizationService.toLanguageString(
                                      "custom.missingreason"
                                    )}
                                    validator={requiredValidator}
                                    component={RequiredFormTextArea}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {radioValue === "no" ? (
                              <>
                                <div className="row">
                                  <div className="col-4">
                                    {" "}
                                    <Field
                                      id={"diagnosis_date"}
                                      name={"diagnosis_date"}
                                      label={localizationService.toLanguageString(
                                        "custom.initialdiagnosisrcc"
                                      )}
                                      component={RequiredFormDatePicker}
                                      validator={requiredValidator}
                                    />
                                  </div>
                                  <div className="col-4">
                                    <Field
                                      id={"location"}
                                      name={"location"}
                                      label={localizationService.toLanguageString(
                                        "custom.location"
                                      )}
                                      data={CancerLocation}
                                      component={RequiredDropDownList}
                                      validator={requiredValidator}
                                    />
                                  </div>
                                  <div className="col-4"></div>
                                </div>

                                <div className="row">
                                  <div className="col-4">
                                    <Field
                                      id={"pathology"}
                                      name={"pathology"}
                                      label={localizationService.toLanguageString(
                                        "custom.pathology"
                                      )}
                                      data={PathologyType}
                                      component={RequiredDropDownList}
                                      validator={requiredValidator}
                                      onChange={(e) =>
                                        handlePathologyChange(
                                          e,
                                          formRenderProps
                                        )
                                      }
                                    />
                                  </div>

                                  {formRenderProps.valueGetter("pathology") &&
                                  formRenderProps.valueGetter("pathology")
                                    .value === "Non clear cell" ? (
                                    <>
                                      <div className="col-4">
                                        <Field
                                          id={"clear_cell_type"}
                                          name={"clear_cell_type"}
                                          label={localizationService.toLanguageString(
                                            "custom.pleasemarkone"
                                          )}
                                          data={ClearCellType}
                                          component={RequiredDropDownList}
                                          validator={requiredValidator}
                                          onChange={(e) =>
                                            OnClearCellChange(
                                              e,
                                              formRenderProps
                                            )
                                          }
                                        />
                                      </div>
                                      {formRenderProps.valueGetter(
                                        "clear_cell_type"
                                      ) &&
                                      formRenderProps.valueGetter(
                                        "clear_cell_type"
                                      ).value === "Other, specify" ? (
                                        <div className="col-4">
                                          <Field
                                            id={"other_clear_cell_type"}
                                            name={"other_clear_cell_type"}
                                            label={localizationService.toLanguageString(
                                              "custom.specify"
                                            )}
                                            component={RequiredFormInput}
                                            validator={requiredMax255Validator}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : formRenderProps.valueGetter(
                                      "pathology"
                                    ) &&
                                    formRenderProps.valueGetter("pathology")
                                      .value === "Mixed, specify" ? (
                                    <div className="col-4">
                                      <Field
                                        id={"mixed_pathology"}
                                        name={"mixed_pathology"}
                                        label={localizationService.toLanguageString(
                                          "custom.mixedpathology"
                                        )}
                                        component={RequiredFormInput}
                                        validator={requiredMax255Validator}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            <div className="row">
                              <div className="col-6"></div>
                              <div className="col-6"></div>
                            </div>
                          </div>
                          <AppBar className="FormAppBar">
                            <AppBarSection>
                              <div>
                                <strong>
                                  {localizationService.toLanguageString(
                                    "custom.previoustreatmentmrcc"
                                  )}
                                </strong>
                              </div>
                            </AppBarSection>
                          </AppBar>
                          <div className="col-10">
                            <div className="row">
                              <div className="col-6">
                                <strong>
                                  {" "}
                                  {localizationService.toLanguageString(
                                    "custom.informationstatusquestion"
                                  )}
                                  ?
                                </strong>
                              </div>
                              <div className="col-3">
                                {" "}
                                <RadioButton
                                  name="radio3"
                                  value="yes"
                                  checked={rcctreatmentradioValue === "yes"}
                                  label={localizationService.toLanguageString(
                                    "custom.yes"
                                  )}
                                  onChange={(e) =>
                                    handleRadio3Change(e, formRenderProps)
                                  }
                                  // disabled={
                                  //   AllData &&
                                  //   AllData.mrcc_treatment_missing === false
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </div>
                              <div className="col-3">
                                {" "}
                                <RadioButton
                                  name="radio4"
                                  value="no"
                                  checked={rcctreatmentradioValue === "no"}
                                  label={localizationService.toLanguageString(
                                    "custom.no"
                                  )}
                                  onChange={(e) =>
                                    handleRadio4Change(e, formRenderProps)
                                  }
                                  // disabled={
                                  //   AllData &&
                                  //   AllData.mrcc_treatment_missing === false
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </div>
                            </div>
                            {rcctreatmentradioValue === "no" ? (
                              <div className="row">
                                <Field
                                  id={"previous_treatment"}
                                  name={"previous_treatment"}
                                  component={FormCheckbox}
                                  label={localizationService.toLanguageString(
                                    "custom.previoussectionquestion"
                                  )}
                                  onChange={(e) =>
                                    onPreviousTreatmentChange(
                                      e,
                                      formRenderProps
                                    )
                                  }
                                  disabled={
                                    AllData &&
                                    AllData.previous_treatment === true
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col">
                                  <Field
                                    name={"mrcc_treatment_reason"}
                                    label={localizationService.toLanguageString(
                                      "custom.missingreason"
                                    )}
                                    validator={requiredValidator}
                                    component={RequiredFormTextArea}
                                  />
                                </div>
                              </div>
                            )}

                            {formRenderProps.valueGetter(
                              "previous_treatment"
                            ) === true ? (
                              <>
                                <div className="row mt-4">
                                  <div className="col-12">
                                    <Field
                                      id={"neoadjuvant_therapy"}
                                      name={"neoadjuvant_therapy"}
                                      component={FormCheckbox}
                                      label={localizationService.toLanguageString(
                                        "custom.neoadjuvanttherapy"
                                      )}
                                      onChange={(e) =>
                                        onNeoTherapyChange(e, formRenderProps)
                                      }
                                      disabled={
                                        AllData &&
                                        AllData.neoadjuvant_therapy === true
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                                {formRenderProps.valueGetter(
                                  "neoadjuvant_therapy"
                                ) === true ? (
                                  <>
                                    <div className="row mt-4">
                                      <div className="col-12">
                                        <FieldArray
                                          name="cancer_therapy"
                                          component={FormGrid}
                                          validator={arrayLengthValidator}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <div className="row mt-4">
                                  <div className="col-12">
                                    <Field
                                      id={"adjuvant_therapy"}
                                      name={"adjuvant_therapy"}
                                      component={FormCheckbox}
                                      label={localizationService.toLanguageString(
                                        "custom.adjuvanttherapy"
                                      )}
                                      onChange={(e) =>
                                        onAdjTherapyChange(e, formRenderProps)
                                      }
                                      disabled={
                                        AllData &&
                                        AllData.adjuvant_therapy === true
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                                {formRenderProps.valueGetter(
                                  "adjuvant_therapy"
                                ) === true ? (
                                  <>
                                    <div className="row mt-4">
                                      <div className="col-12">
                                        <FieldArray
                                          name="adjuvant_cancer_therapy"
                                          component={AdjuvantFormGrid}
                                          validator={arrayLengthValidator}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="row mt-4">
                                  <div className="col-6">
                                    <Field
                                      id={"surgery"}
                                      name={"surgery"}
                                      component={FormCheckbox}
                                      label={localizationService.toLanguageString(
                                        "custom.surgery"
                                      )}
                                      onChange={(e) =>
                                        onSurgeryChange(e, formRenderProps)
                                      }
                                      disabled={
                                        AllData && AllData.surgery === true
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                  {formRenderProps.valueGetter("surgery") ===
                                  true ? (
                                    <div className="col-6">
                                      <Field
                                        id={"laparoscopic_surgery"}
                                        name={"laparoscopic_surgery"}
                                        component={FormCheckbox}
                                        label={localizationService.toLanguageString(
                                          "custom.laparoscopicsurgery"
                                        )}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {formRenderProps.valueGetter("surgery") ===
                                true ? (
                                  <>
                                    <div className="row">
                                      <div className="col-6">
                                        <Field
                                          id={"surgery_date"}
                                          name={"surgery_date"}
                                          label={localizationService.toLanguageString(
                                            "custom.date"
                                          )}
                                          component={RequiredFormDatePicker}
                                          validator={requiredValidator}
                                        />
                                      </div>
                                      <div className="col-6">
                                        <Field
                                          id={"surgery_site"}
                                          name={"surgery_site"}
                                          label={localizationService.toLanguageString(
                                            "custom.site"
                                          )}
                                          data={SurgerySite}
                                          component={RequiredDropDownList}
                                          validator={requiredValidator}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-6">
                                        <Field
                                          id={"surgery_type"}
                                          name={"surgery_type"}
                                          label={localizationService.toLanguageString(
                                            "custom.type"
                                          )}
                                          data={SurgeryType}
                                          component={RequiredDropDownList}
                                          validator={requiredValidator}
                                          onChange={(e) =>
                                            OnSurgeryTypeChange(
                                              e,
                                              formRenderProps
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-6">
                                        {formRenderProps.valueGetter(
                                          "surgery_type"
                                        ) &&
                                        formRenderProps.valueGetter(
                                          "surgery_type"
                                        ).value === "Other" ? (
                                          <Field
                                            id={"other_surgery_type"}
                                            name={"other_surgery_type"}
                                            label={localizationService.toLanguageString(
                                              "custom.othertype"
                                            )}
                                            component={RequiredFormInput}
                                            validator={requiredMax255Validator}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {formRenderProps.valueGetter(
                                  "previous_treatment"
                                ) === true ? (
                                  <>
                                    <div className="row mt-4">
                                      <div className="col-12">
                                        <Field
                                          id={"other"}
                                          name={"other"}
                                          component={FormCheckbox}
                                          label={localizationService.toLanguageString(
                                            "custom.other"
                                          )}
                                          disabled={
                                            AllData && AllData.other === true
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {formRenderProps.valueGetter("other") ===
                                true ? (
                                  <>
                                    <div className="row mt-4">
                                      <div className="col-12">
                                        <GridTelerik
                                          data={OtherArray.map((item) => ({
                                            ...item,
                                            inEdit: item.id === editID,
                                          }))}
                                          editField="inEdit"
                                          onRowClick={rowClick}
                                          onItemChange={itemChange}
                                        >
                                          <GridToolbar>
                                            <div>
                                              <Button
                                                type="button"
                                                themeColor="primary"
                                                onClick={addOtherRecord}
                                              >
                                                {localizationService.toLanguageString(
                                                  "custom.new"
                                                )}
                                              </Button>
                                            </div>
                                          </GridToolbar>
                                          <Column
                                            field="code"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.id"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.id"
                                                  )}
                                                </span>
                                              </>
                                            }
                                            // width="50px"
                                            editable={false}
                                            cell={OtherTherapyCodeTemplate}
                                          />
                                          <Column
                                            field="treatment_line"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.treatmentline"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.treatmentline"
                                                  )}
                                                </span>
                                                <span style={{ color: "red" }}>
                                                  {"*"}
                                                </span>
                                              </>
                                            }
                                            cell={
                                              OtherTherapyTreatmentLineTemplate
                                            }
                                          />
                                          <Column
                                            field="drug"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.drug"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.drug"
                                                  )}
                                                </span>
                                                <span style={{ color: "red" }}>
                                                  {"*"}
                                                </span>
                                              </>
                                            }

                                            // width="50px"
                                          />
                                          <Column
                                            field="dose"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.dose"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.dose"
                                                  )}
                                                </span>
                                                <span style={{ color: "red" }}>
                                                  {"*"}
                                                </span>
                                              </>
                                            }
                                            editor="numeric"
                                            // width="50px"
                                          />
                                          <Column
                                            field="unit"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.unit"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.unit"
                                                  )}
                                                </span>
                                                <span style={{ color: "red" }}>
                                                  {"*"}
                                                </span>
                                              </>
                                            }
                                            // width="50px"
                                          />
                                          <Column
                                            field="frequency"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.frequency"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.frequency"
                                                  )}
                                                </span>
                                                <span style={{ color: "red" }}>
                                                  {"*"}
                                                </span>
                                              </>
                                            }
                                            // width="50px"
                                          />
                                          <Column
                                            field="nb_cycle"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.nbcycles"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.nbcycles"
                                                  )}
                                                </span>
                                                <span style={{ color: "red" }}>
                                                  {"*"}
                                                </span>
                                              </>
                                            }
                                            editor="numeric"
                                            // width="50px"
                                          />
                                          <Column
                                            field="start_date"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.startdate"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.startdate"
                                                  )}
                                                </span>
                                                <span style={{ color: "red" }}>
                                                  {"*"}
                                                </span>
                                              </>
                                            }
                                            editor="date"
                                            // width="50px"
                                            cell={DateTemplate}
                                          />
                                          <Column
                                            field="end_date"
                                            title={
                                              <>
                                                <span
                                                  title={languageContext.new.toLanguageString(
                                                    "custom.enddate"
                                                  )}
                                                  className="text-responsive-overflow"
                                                >
                                                  {languageContext.new.toLanguageString(
                                                    "custom.enddate"
                                                  )}
                                                </span>
                                                <span style={{ color: "red" }}>
                                                  {"*"}
                                                </span>
                                              </>
                                            }
                                            cell={DateTemplate}
                                            editor="date"
                                            // width="50px"
                                          />
                                          <Column
                                            title={""}
                                            cell={ActionTemplate}

                                            // width="50px"
                                          />
                                        </GridTelerik>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </Tooltip>
                      </fieldset>
                      <Tooltip
                        openDelay={0}
                        anchorElement="target"
                        parentTitle={true}
                        position={"left"}
                      >
                        <div className="float-right">
                          <div title={disabletitle()}>
                            <Button
                              primary={true}
                              type={"submit"}
                              className="mr-2em"
                              disabled={disablefunction()}
                            >
                              {loading
                                ? localizationService.toLanguageString(
                                    "custom.loading"
                                  )
                                : localizationService.toLanguageString(
                                    "custom.save"
                                  )}
                            </Button>
                          </div>
                        </div>
                      </Tooltip>
                    </FormElement>
                  )}
                />
              </TabStripTab>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.approvesection"
                )}
              >
                <ApproveStudySubject
                  study_subject={props.location.state.data.id}
                  section={props.location.state.section.id}
                  data={props.location.state.data}
                  parentCallBack={ChangeApproveSection}
                />
              </TabStripTab>
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.notesnattachements"
                )}
              >
                <TabStrip selected={Subselected} onSelect={handleSubSelect}>
                  <TabStripTab
                    title={localizationService.toLanguageString("custom.notes")}
                  >
                    <GridTelerik data={notes}>
                      <GridToolbar>
                        <Button
                          primary={true}
                          onClick={() => {
                            {
                              EditSubject
                                ? toggleDaddNote()
                                : toggleDPermission();
                            }
                          }}
                        >
                          {localizationService.toLanguageString("custom.new")}
                        </Button>
                      </GridToolbar>

                      <Column
                        field={"note"}
                        title={localizationService.toLanguageString(
                          "custom.note"
                        )}
                        cell={NoteNameTemplate}
                      />
                      <Column
                        field={"date_created"}
                        title={localizationService.toLanguageString(
                          "custom.datecreated"
                        )}
                        cell={DateCreatedTemplate}
                      />
                      <Column
                        title={localizationService.toLanguageString(
                          "custom.actions"
                        )}
                        width="100px"
                        cell={Noteactions}
                      />
                    </GridTelerik>
                  </TabStripTab>

                  <TabStripTab
                    title={localizationService.toLanguageString(
                      "custom.attachements"
                    )}
                  >
                    <GridTelerik data={attachements}>
                      <GridToolbar>
                        <Button
                          primary={true}
                          onClick={() => {
                            {
                              EditSubject
                                ? toggleDaddFile()
                                : toggleDPermission();
                            }
                          }}
                        >
                          {localizationService.toLanguageString("custom.new")}
                        </Button>
                      </GridToolbar>
                      <Column
                        field={"title"}
                        title={localizationService.toLanguageString(
                          "custom.title"
                        )}
                      />

                      <Column
                        field={"date_created"}
                        title={localizationService.toLanguageString(
                          "custom.dateadded"
                        )}
                        cell={DateCreatedTemplate}
                      />

                      <Column
                        title={localizationService.toLanguageString(
                          "custom.actions"
                        )}
                        width="100px"
                        cell={Fileactions}
                      />
                    </GridTelerik>
                  </TabStripTab>
                </TabStrip>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      </MyContext.Provider>
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
          height={"400px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmitClick={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}

      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}

      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}

      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
    </TrialMap>
  );
}

export default ListCancerHistory;
