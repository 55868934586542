const RccStages = [
  {
    value: "Stage 1",
    name: "Stage 1",
  },
  {
    value: "Stage 2",
    name: "Stage 2",
  },
  {
    value: "Stage 3",
    name: "Stage 3",
  },
  {
    value: "Stage 4(mRCC)",
    name: "Stage 4(mRCC)",
  },
];

export default RccStages;
