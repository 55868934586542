//utilities
import React from "react";
import { withRouter } from "react-router-dom";
//kendo component
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
//localization
import { useLocalization } from "@progress/kendo-react-intl";

//access
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

export function Settings(props) {
  //localization
  const localizationService = useLocalization();

  //access
  const history = useHistory();

  const [items, setItems] = React.useState([
    {
      text: localizationService.toLanguageString("custom.companysetting"),
      selected: true,
      className: "expanded-title",
      id: 1,
    },
    {
      text: localizationService.toLanguageString("custom.companyprofile"),
      route: "/settings/companyprofile",
      className: "indent-title",
      id: 2,
    },
    { separator: true },

    {
      text: localizationService.toLanguageString("custom.usersandroles"),
      selected: true,
      className: "expanded-title",
      id: 3,
    },
    {
      text: localizationService.toLanguageString("custom.users"),
      route: "/settings/authentication/user/list",
      className: "indent-title",
      id: 4,
    },

    {
      text: localizationService.toLanguageString("custom.rolesnpermission"),
      route: "/settings/authentication/role/list",
      className: "indent-title",
      id: 5,
    },
  ]);

  //we make this function : so if I am in the edit page : it will understand the props even if the seleted element has no route
  const location = useLocation();

  const onSelect = (ev) => {
    if (
      location.pathname !== ev.itemTarget.props.route &&
      ev.itemTarget.props.route !== undefined
    ) {
      props.history.push(ev.itemTarget.props.route);
    }

    const currentItem = ev.itemTarget.props;
    const isParent = currentItem["data-expanded"] !== undefined;
    const nextExpanded = !currentItem["data-expanded"];
    const newData = items.map((item) => {
      const {
        selected,
        ["data-expanded"]: currentExpanded,
        id,
        ...others
      } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        selected: isCurrentItem,
        ["data-expanded"]:
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });
    setItems(newData);
  };

  const data = items.map((item) => {
    const { parentId, ...others } = item;

    if (parentId !== undefined) {
      const parent = items.find((parent) => parent.id === parentId);
      return { ...others, visible: parent["data-expanded"] };
    }

    return item;
  });
  const setSelectedItem = (pathName) => {
    let currentPath = items.find((item) => item.route === pathName);

    if (pathName === "/settings") {
      return "";
    }
    if (
      pathName === "/settings/authentication/role/add" ||
      pathName === "/settings/authentication/role/edit"
    ) {
      return "Roles and Permissions";
    }

    if (
      pathName === "/settings/authentication/user/add" ||
      pathName === "/settings/authentication/user/edit"
    ) {
      return "Users";
    }

    if (currentPath.text) {
      return currentPath.text;
    }
  };

  let selected = setSelectedItem(props.location.pathname);

  return (
    <div>
      <div className="col pages-big-title">
        {localizationService.toLanguageString("custom.systemsettings")}
      </div>
      <hr />

      <Drawer
        className="SettingsDrawer"
        expanded={true}
        position={"start"}
        mode={"push"}
        mini={true}
        items={data.map((d) => ({ ...d, selected: d.text === selected }))}
        //items={items}
        onSelect={onSelect}
      >
        <DrawerContent className="drawer-content">
          {props.children}
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default withRouter(Settings);
