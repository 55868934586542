const PatientStatuses = [
  {
    value: "Alive",
    name: "Alive",
  },
  {
    value: "Dead",
    name: "Dead",
  },
  {
    value: "Lost to follow-up",
    name: "Lost to follow-up",
  },
];

export default PatientStatuses;
