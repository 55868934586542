//utilities
import React, { useEffect, useState } from "react";
import APIURL from "../../APIURL";
import useSWR from "swr";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";

//KENDO COMPONENT
import { BiUpload } from "react-icons/bi";
import { Fade } from "@progress/kendo-react-animation";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import {
  FormSingleUpload,
  FormUpload,
} from "../../common/Kendo/form-components";
import { requiredValidator } from "../../common/Kendo/validators";
//LOCALIZATION
import { useLocalization } from "@progress/kendo-react-intl";

//component
import GeneralSetting from "../../setting/Settings";
import GlobalNotificationTimer from "../../common/commoncomponents/GlobalNotificationTimer";

export function CompanyProfile(props) {
  //localization
  const localizationService = useLocalization();
  const dispatch = useDispatch();

  //redux
  const UserDetail = useSelector((state) => state.UserDetail);

  //DATA FETCHING
  const { data: company } = useSWR(APIURL + "/common/getcompany/");
  const { data: default_url } = useSWR(APIURL + "/common/getdefaulturl/");
  //PERMISSION DIALOG

  const [loading, setLoading] = useState(false);
  const [VPermission, setVPermission] = React.useState(false);
  const [NoPermission, setNoPermission] = React.useState(false);
  const [enablesave, setenavlesave] = useState(false);
  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //VARIABLES
  const [test, settest] = useState({
    image: [],
  });
  const [imageurl, setimageurl] = useState(null);

  // reset state function
  const resetState = () => {
    axios.get(APIURL + "/common/getcompany/").then((res) => {
      if (res.data.data) {
        if (res.data.data.image) {
          setimageurl(res.data.data.image);
        } else {
          setimageurl(default_url);
        }

        settest({
          image: res.data.data.image_json ? res.data.data.image_json : null,
        });
      } else {
        setimageurl(default_url);
        settest({
          image: null,
        });
      }
    });
  };

  useEffect(() => {
    resetState();
  }, []);

  //LOADING
  const loadingPanel = (
    <div className="k-loading-mask" style={{ color: "#EF476F" }}>
      <span className="k-loading-text">
        {" "}
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
    </div>
  );

  //NOTIFICATION
  const [ErrorNotification, setErrorNotification] = useState("");
  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });
  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  //HANDLE IMAGE URL
  const handleimageurl = (e, formRenderProps) => {
    let uploadValue;
    let fileValue;
    uploadValue = formRenderProps.valueGetter("image");
    var reader = new FileReader();
    if (uploadValue.length !== 0) {
      fileValue = uploadValue[0].getRawFile();
      reader.readAsDataURL(fileValue);
      reader.onloadend = function () {
        setimageurl(reader.result);
      };
    } else {
      setimageurl(null);
    }
  };

  const onProgress = (event, formRenderProps) => {
    formRenderProps.onChange("image", { value: event.newState });
  };

  const onStatusChange = (event, formRenderProps) => {
    formRenderProps.onChange("image", { value: event.newState });
  };

  const editCompany = (dataItem) => {
    if (dataItem.isValid && !dataItem.values.image[0].validationErrors) {
      setLoading(true);

      if (company && company.data && Object.keys(company.data).length !== 0) {
        if (dataItem.isModified) {
          let logo = dataItem.values.image;

          const formData = new FormData();
          formData.append(
            "image",
            logo[0].hasOwnProperty("getRawFile")
              ? logo[0].getRawFile()
              : logo[0]
          );

          formData.append("modified_by", UserDetail.id);
          formData.append("i_extension", dataItem.values.image[0].extension);
          formData.append("i_name", dataItem.values.image[0].name);
          formData.append("i_progress", dataItem.values.image[0].progress);
          formData.append("i_size", dataItem.values.image[0].size);

          formData.append("i_status", dataItem.values.image[0].status);
          formData.append("i_uuid", dataItem.values.image[0].uid);

          axios
            .put(APIURL + `/common/editcompany/${company.data.id}/`, formData)
            .then((res) => {
              onToggle("success");
              setTimeout(() => {
                setState({ ...state, success: false });
                setLoading(false);
              }, GlobalNotificationTimer);
            })
            .catch((error) => {
              setErrorNotification(error.response.status);
              onToggle("nosave");
              setTimeout(() => {
                setState({ ...state, nosave: false });
                setLoading(false);
              }, GlobalNotificationTimer);
            });
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let logo = dataItem.values.image;

        const formData = new FormData();
        formData.append(
          "image",
          logo[0].hasOwnProperty("getRawFile") ? logo[0].getRawFile() : logo[0]
        );
        formData.append("created_by", UserDetail.id);
        formData.append("i_extension", dataItem.values.image[0].extension);
        formData.append("i_name", dataItem.values.image[0].name);
        formData.append("i_progress", dataItem.values.image[0].progress);
        formData.append("i_size", dataItem.values.image[0].size);

        formData.append("i_status", dataItem.values.image[0].status);
        formData.append("i_uuid", dataItem.values.image[0].uid);

        axios
          .post(APIURL + `/common/addcompany/`, formData)
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          });
      }
    }
  };

  //COMPANY LOGO
  const CustomListItemUI = (props) => {
    return (
      <ul>
        {props.files.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </ul>
    );
  };
  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  return (
    <GeneralSetting>
      {!company && loadingPanel}
      <Form
        initialValues={test}
        key={JSON.stringify(test)}
        onSubmitClick={editCompany}
        render={(formRenderProps) => (
          <FormElement>
            <fieldset className={"k-form-fieldset"}>
              <Prompt
                when={PromptFunction(formRenderProps)}
                message={localizationService.toLanguageString(
                  "custom.promptmessage"
                )}
              />

              <div className="row">
                <div className="col-4">
                  <img src={imageurl} width="400" height="200"></img>

                  <Field
                    id={"image"}
                    name={"image"}
                    selectMessageUI={() => (
                      <>
                        <BiUpload
                          className="UploadActionButtons"
                          width={"10px"}
                        />
                        {localizationService.toLanguageString("custom.logo")}
                      </>
                    )}
                    listItemUI={CustomListItemUI}
                    onProgress={(e) => onProgress(e, formRenderProps)}
                    onStatusChange={(e) => onStatusChange(e, formRenderProps)}
                    component={FormSingleUpload}
                    restrictions={{
                      allowedExtensions: [".jpg", ".png"],
                      maxFileSize: 400000, //telerik is taking byte as unit , here 400 Kb is allowed
                    }}
                    validator={requiredValidator}
                    onChange={(e) => handleimageurl(e, formRenderProps)}
                  />
                </div>
              </div>
              <div className="float-right">
                <Button primary={true} className={"mr-2 "}>
                  {localizationService.toLanguageString("custom.save")}
                </Button>
              </div>
            </fieldset>
          </FormElement>
        )}
      />
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {" "}
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </GeneralSetting>
  );
}
export default CompanyProfile;
