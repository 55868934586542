//utilities import
//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";
//kendo Component
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import { Fade } from "@progress/kendo-react-animation";

import { Dialog } from "@progress/kendo-react-dialogs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Card, CardHeader } from "@progress/kendo-react-layout";
import { RadioButton } from "@progress/kendo-react-inputs";

import {
  RequiredFormTextArea,
  RequiredFormDatePicker,
} from "../common/Kendo/form-components";
import { requiredValidator } from "../common/Kendo/validators";
import { Form, Field, FormElement } from "@progress/kendo-react-form";

import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
//component
import TrialMap from "../studysubject/TrialMap";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
//localization
import { useLocalization } from "@progress/kendo-react-intl";

export function ApproveStudySubject(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const ApproveSubject = useSelector((state) => state.ApproveSubject);

  //localization
  const localizationService = useLocalization();

  //variables
  const [loading, setLoading] = useState(false);
  const MyForm = React.createRef(); //form ref to detect modification of the form
  const [AllData, setAllData] = useState(null);
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  const [radioValue, setradioValue] = useState("no");
  const [aproved, setAproved] = useState(false);
const [refresh, setRefresh] = useState(false)

  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.study_subject,
          section: props.section,
          refresh: refresh
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    [
      APIURL + "/common/approvesectionstudy/",
      props.study_subject,
      props.section,
      refresh
    ],
    fetcher2
  );



  console.log(data, "sssssssssssssssssssss");

  useEffect(() => {
    if (data) {
      if (data.message === "nodata") {
        setAllData(null);
      } else {
        setAllData(data);
      }

      setradioValue(data.approved === false ? "no" : "yes");
    }
  }, [data]);

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
    checkapproved: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify, checkapproved } = state;

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  //radios
  const OnNoChange = (e, formRenderProps) => {
    props.parentCallBack(true);
    setradioValue(e.value);
    formRenderProps.onChange("approved_date", { value: null });
  };
  const OnYesChange = (e, formRenderProps) => {
    props.parentCallBack(true);
    setradioValue(e.value);
    formRenderProps.onChange("notapproved_reason", { value: "" });
  };

  console.log(AllData, "alllldataa");
  const fetcher5 = (url) =>
  axios
    .get(url, {
      params: {
        subject: props.subject,
      },
    })
    .then((res) =>
    {
      // if(res.data.message === "cant"){
        setAproved(res.data.message)
        // setState({ ...state, nosave: true });
        console.log("OOOOOOOOOOOOOOOOOOO$444",res.data)

      // }
    }
      );
  const { data: checkfilldata, checkerror } = useSWR(

     [APIURL + `/common/checkfilldataapproved/?subject=${ props.study_subject}&section_id=${props.section}`]
    ,
    fetcher5
  );
   console.log("OOOOOOOOOOOOOOOOOOO$444111", props.study_subject,props.section,checkfilldata, checkerror,data)

// if(checkapprove){
//   console.log("tttttttttttttttttttttttt111", props.study_subject,props.section,checkapprove, checkapproveerror,data)

//   setState({ ...state, nosave: checkapprove });

// }


async function deleteApprovedById(id) {

  try {
      // Make a DELETE request to your API endpoint with the resource ID
      const response = await axios.post(APIURL + '/common/deleteaprovedsection/',{
        id: id
      } );

      // Check the response status and handle accordingly
      if (response.status === 200) {
          // Resource deleted successfully
          console.log('Resource deleted successfully');
          setRefresh(!refresh)

      } else {
          // Handle other status codes or errors
          console.error('Failed to delete the resource');
      }
  } catch (error) {
      // Handle any network or request errors
      console.error('Error:', error);
  }
}
function handleDeleteClick(id) {
  deleteApprovedById(id);
}
  //function to update subject
  const UpdateApproval = (dataItem) => {
    // if
    if(aproved){
    if (dataItem.isValid) {
      if (AllData) {
        setLoading(true);
        axios
          .put(
            APIURL + "/common/updateapprovesectionstudy/" + AllData.id + "/",
            {
              approved: radioValue === "no" ? false : true,
              approved_date: dataItem.values.approved_date
                ? moment(dataItem.values.approved_date).format("YYYY-MM-DD")
                : null,
              notapproved_reason: dataItem.values.notapproved_reason,
              section: props.section,
              study_subject: props.study_subject,
              modified_by: UserDetail.id,
            }
          )
          .then((res) => {
            setRefresh(!refresh)
            onToggle("success");
            props.parentCallBack(false);
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          });
      } else {
        setLoading(true);
        axios
          .post(APIURL + "/common/approvesectionstudy/", {
            approved: radioValue === "no" ? false : true,
            approved_date: dataItem.values.approved_date
              ? moment(dataItem.values.approved_date).format("YYYY-MM-DD")
              : null,
            notapproved_reason: dataItem.values.notapproved_reason,
            section: props.section,
            study_subject: props.study_subject,
            created_by: UserDetail.id,
          })
          .then((res) => {
            onToggle("success");
            setRefresh(!refresh)
            props.parentCallBack(false);
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          });
      }
    }}else{
      setState({ ...state, checkapproved: true });

    }
  };

  //SWR ESSENTIAL
  if (!data)
    return (
      <>
        <Loader type="converging-spinner" />
      </>
    );

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (error)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <div className="row mt-2">
      <div className="col-12">


        <Form
          initialValues={{
            approved_date: AllData
              ? AllData.approved_date
                ? new Date(AllData.approved_date)
                : null
              : null,
            notapproved_reason: AllData
              ? AllData.notapproved_reason
                ? AllData.notapproved_reason
                : ""
              : "",
          }}
          key={JSON.stringify(AllData)}
          onSubmitClick={UpdateApproval}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                <Tooltip
                  openDelay={0}
                  anchorElement="target"
                  parentTitle={true}
                  position={"top"}
                >
                  <Prompt
                    when={PromptFunction(formRenderProps)}
                    message={localizationService.toLanguageString(
                      "custom.promptmessage"
                    )}
                  />
                  <div className="col-6">
                    <div className="row mt-4">
                      <div className="col-4">
                        <strong>
                          {localizationService.toLanguageString(
                            "custom.itisapprovedsection"
                          )}
                          ?
                        </strong>
                      </div>
                      <div className="col-4">
                        <RadioButton
                          name="radio1"
                          value="yes"
                          onChange={(e) => OnYesChange(e, formRenderProps)}
                          checked={radioValue === "yes"}
                          label={localizationService.toLanguageString(
                            "custom.yes"
                          )}
                          // disabled={
                          //   AllData && AllData.approved === true ? true : false
                          // }
                        />
                      </div>
                      <div className="col-4">
                        <RadioButton
                          name="radio1"
                          value="no"
                          checked={radioValue === "no"}
                          label={localizationService.toLanguageString(
                            "custom.no"
                          )}
                          onChange={(e) => OnNoChange(e, formRenderProps)}
                          // disabled={
                          //   AllData && AllData.approved === true ? true : false
                          // }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {radioValue === "no" ? (
                          <div>
                          <Field
                            id={"notapproved_reason"}
                            name={"notapproved_reason"}
                            label={localizationService.toLanguageString(
                              "custom.reason"
                            )}
                            optional={true}
                            validator={requiredValidator}
                            component={RequiredFormTextArea}
                            onChange={() => props.parentCallBack(true)}
                          />
                    {(UserDetail.role ==='CRA' || UserDetail.role==='administrator' || UserDetail.role==='admin') && data?.notapproved_reason ?
                    <Button className="k-button mr-2em back-button-style" type='button'
                    onClick={() => handleDeleteClick(data.id)}>{localizationService.toLanguageString("custom.disapprove")}</Button>: ''}</div>
                        ) : (
                          <Field
                            id={"approved_date"}
                            name={"approved_date"}
                            label={localizationService.toLanguageString(
                              "custom.approvaldate"
                            )}
                            validator={requiredValidator}
                            component={RequiredFormDatePicker}
                            onChange={() => props.parentCallBack(true)}
                          />
                        )}
                      </div>
                    </div>
                    {/* <div className="row mt-2">
                      <div className="col">
                        <span className="k-form-error">
                          {ErrorNotification}
                        </span>
                      </div>
                    </div> */}
                  </div>
                </Tooltip>
              </fieldset>
              <Tooltip
                openDelay={0}
                anchorElement="target"
                parentTitle={true}
                position={"left"}
              >
                <div className="float-right">
                {data.approved? "":
                  <Button
                    className="k-button mr-2em"
                    primary={true}
                    disabled={loading ? true : ApproveSubject ? false : false}
                  >
                    {loading
                      ? localizationService.toLanguageString("custom.loading")
                      : localizationService.toLanguageString("custom.save")}
                  </Button>
          }
                </div>
              </Tooltip>
            </FormElement>
          )}
        />
        {data.approved? 
<div style={{margin:'15px'}}>
{UserDetail.role ==='CRA' || UserDetail.role==='administrator' || UserDetail.role==='admin'?
                    <Button className="k-button mr-2em back-button-style" type='button'
                    onClick={() => handleDeleteClick(data.id)}>{localizationService.toLanguageString("custom.disapprove")}</Button>: ''}</div>: ''}

        <NotificationGroup style={position.topCenter}>
          <Fade>
            {nosave && (
              <Notification
                type={{
                  style: "error",
                  icon: true,
                }}
                closable={true}
                onClose={() => setState({ ...state, nosave: false })}
              >
                <span>
                  {ErrorNotification}{" "}
                  {localizationService.toLanguageString("custom.errornosave")}
                </span>
              </Notification>
            )}
          </Fade>


          <Fade>
            {checkapproved && (
              <Notification
                type={{
                  style: "error",
                  icon: true,
                }}
                closable={true}
                onClose={() => setState({ ...state, checkapproved: false })}
              >
                <span>
                  {ErrorNotification}{" "}
                  {/* fill all required fileds */}
                  {localizationService.toLanguageString("custom.fillallrequired")}
                </span>
              </Notification>
            )}
          </Fade>
          <Fade>
            {nomodify && (
              <Notification
                type={{
                  style: "warning",
                  icon: true,
                }}
                closable={true}
                onClose={() => setState({ ...state, nomodify: false })}
              >
                <span>
                  {localizationService.toLanguageString(
                    "custom.nomodificationchange"
                  )}
                </span>
              </Notification>
            )}
          </Fade>
          <Fade>
            {success && (
              <Notification
                type={{
                  style: "success",
                  icon: true,
                }}
                closable={true}
                onClose={() => setState({ ...state, success: false })}
              >
                <span>
                  {localizationService.toLanguageString("custom.datasaved")}
                </span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
        {/* PERMISSION DIALOG */}
        {VPermission && (
          <Dialog
            title={localizationService.toLanguageString(
              "custom.permissiondenied"
            )}
            onClose={toggleDPermission}
          >
            <PermissionDenied onClose={toggleDPermission} />
          </Dialog>
        )}
      </div>
    </div>
  );
}
export default ApproveStudySubject;
