//utilities
import React from "react";
import axios from "axios";

import APIURL from "../../APIURL";

//kendo component

import { Button } from "@progress/kendo-react-buttons";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

export function TabChange(props) {
  //localization
  const localizationService = useLocalization();

  //FUNCTION

  const ChangeTab = (e) => {
    props.parentCallBack(props.selected);
    props.onClose();
  };
  return (
    <div>
      <p style={{ margin: "25px", textAlign: "center" }}>
        {localizationService.toLanguageString("custom.unsaveddatalost")}?
      </p>
      <div className="row">
        <div className="col">
          <Button
            className="k-button mr-2em"
            style={{ width: "230px" }}
            primary={true}
            onClick={ChangeTab}
          >
            {localizationService.toLanguageString("custom.yes")}
          </Button>
          <Button
            className="k-button"
            onClick={props.onClose}
            style={{ width: "230px" }}
          >
            {localizationService.toLanguageString("custom.no")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TabChange;
