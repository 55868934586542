const FrequencyValues = [
  {
    value: "Per Month",
    name: "Per Month",
  },
  {
    value: "Per Day",
    name: "Per Day",
  },
];

export default FrequencyValues;
