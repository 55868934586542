//utilities
import React from "react";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//component
import TrialMap from "../../studysubject/TrialMap";
import { FaLock } from "react-icons/fa";
import { FcLock } from "react-icons/fc";
export function SectionLocked(props) {
  //localization
  const localizationService = useLocalization();
  console.log("PPPPPPPPPP",props.location.state.reason)

  return (
    <TrialMap>
      <div className="section-locked-container">
        <div className="row">
          <FcLock size={"100px"} />
        </div>
        <div className="row">
          <span style={{ color: "red", fontSize: "30px" }}>
            {localizationService.toLanguageString("custom.sectionlocked")}
          </span>
          <span style={{ color: "red", fontSize: "30px" }}>
            {localizationService.toLanguageString(
              `custom.${props.location.state.reason}`
            )}
          </span>
        </div>
      </div>
    </TrialMap>
  );
}

export default SectionLocked;
