//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { Dialog } from "@progress/kendo-react-dialogs";
import { FieldArray } from "@progress/kendo-react-form";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Card, CardHeader } from "@progress/kendo-react-layout";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  FormNumericTextBox,
  FormInput,
  FormDatePicker,
  RequiredFormTextArea,
  FormDropDownList,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax50Validator,
} from "../common/Kendo/validators";

//component
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
import TreatmentLines from "../common/commoncomponents/TreatmentLines";
import TrialMap from "../studysubject/TrialMap";
import AddFile from "../common/commoncomponents/AddFile";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
import TabChange from "../common/commoncomponents/TabChange";

const languageContext = React.createContext({
  new: null,
});

const FORM_DATA_INDEX = "formDataIndex";

const MyContext = React.createContext({
  FormRef: {},
  MaxSection: null,
  PropsState: "",
  section: {},
  filter: [],
  props: null,
  opendialog: false,
  toggleDialog: (e) => {},
  ModifiedData: null,
  check: [],
});
const propsContext = React.createContext({ props: null });
const arrayLengthValidator = (value) =>
  value && value.length ? "" : "Please add at least one record.";

const onRemoveContext = React.createContext((options) => null);
// Create context for your state
const stateContext = React.createContext([{}, () => {}]);

export const FormCheckbox = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const currentContext = React.useContext(MyContext);
  return (
    <FieldWrapper>
      <Checkbox
        ariaDescribedBy={`${hintId} ${errorId}`}
        label={label}
        labelOptional={optional}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
        onChange={(e) => {
          fieldRenderProps.onChange(e);
          let fieldValue = fieldRenderProps.name.replace(
            "until_present",
            "end_date"
          );
          let fieldValue1 = fieldRenderProps.name.replace(
            "until_present",
            "reason"
          );

          currentContext.FormRef.current.valueSetter(fieldValue, null);
          currentContext.FormRef.current.valueSetter(fieldValue1, "");
        }}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormTreatmentLineDropDownList = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  const currentContext = React.useContext(MyContext);
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DropDownList
        title={fieldRenderProps?.value?.name}
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        textField={"name"}
        dataItemKey={"value"}
        id={id}
        disabled={disabled}
        {...others}
      />

      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
const OnChange = (e) => {
  console.log(MyContext.ModifiedData);
  // e.dataItem.status = "edit";
  // console.log(" MyContext.ModifiedData", currentContext);
  // currentContext.ModifiedData.currenct_therapy[e.dataIndex]["status"] = "edit";
  return 0;
};
const CustomCell = (props) => {
  const currentContext = React.useContext(MyContext);

  if (props.field === "section_name") {
    if (props.dataItem.status) {
      return (
        <td>
          {props.dataItem.section_name}
          {/* baseline-Current Therapy */}
        </td>
      );
    } else {
      return (
        // <td title="baseline-Current Therapy">baseline-Current Therapy</td>
        <td title={props.dataItem.section_title + '-' + props.dataItem.section_name } >
          {props.dataItem.section_title}
          {"-"}
          {props.dataItem.section_name}
        </td>
      );
    }
  }
  if (props.field === "start_date") {
    if (currentContext.props.dialog)
      return (
        <td>
          <Field
            component={FormDatePicker}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredValidator}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        </td>
      );
    else
      return (
        <td>
          {(currentContext.PropsState === "dialog" && !props.dataItem.status) ||
          (currentContext.PropsState === "state" &&
            props.dataItem.section !== currentContext.MaxSection &&
            !props.dataItem.status) ? (
            moment(props.dataItem.start_date).format("YYYY-MM-DD")
          ) : (
            <Field
              component={FormDatePicker}
              name={`current_therapy[${props.dataIndex}].${props.field}`}
              validator={requiredValidator}
              onChange={() => {
                if (currentContext.props.dialog)
                  currentContext.check.push(props.dataIndex);
              }}
            />
          )}
        </td>
      );
  }
  if (props.field === "therapy") {
    if (currentContext.props.dialog)
      return (
        <td>
          <Field
            component={FormInput}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredValidator}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
            
          />
        </td>
      );
    return (
      <td>
        {(currentContext.PropsState === "dialog" && !props.dataItem.status) ||
        (currentContext.PropsState === "state" &&
          props.dataItem.section !== currentContext.MaxSection &&
          !props.dataItem.status) ? (
          props.dataItem.therapy
        ) : (
          <Field
            component={FormInput}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredValidator}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        )}
      </td>
    );
  }
  if (props.field === "treatment_line") {
    if (currentContext.props.dialog)
      return (
        <td>
          <Field
            component={FormTreatmentLineDropDownList}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredValidator}
            data={TreatmentLines}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        </td>
      );
    return (
      <td>
        {(currentContext.PropsState === "dialog" && !props.dataItem.status) ||
        (currentContext.PropsState === "state" &&
          props.dataItem.section !== currentContext.MaxSection &&
          !props.dataItem.status) ? (
          props.dataItem.therapy
        ) : (
          <Field
            component={FormTreatmentLineDropDownList}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredValidator}
            data={TreatmentLines}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        )}
      </td>
    );
  } else if (props.field === "end_date") {
    if (currentContext.props.dialog)
      return (
        <td>
          {" "}
          <Field
            component={FormDatePicker}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={props.dataItem.until_present ? "" : requiredValidator}
            disabled={props.dataItem.until_present ? true : false}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        </td>
      );
    return (
      <td>
        {(currentContext.PropsState === "dialog" && !props.dataItem.status) ||
        (currentContext.PropsState === "state" &&
          props.dataItem.section !== currentContext.MaxSection &&
          !props.dataItem.status) ? (
          props.dataItem.end_date ? (
            moment(props.dataItem.end_date).format("YYYY-MM-DD")
          ) : (
            ""
          )
        ) : (
          <Field
            component={FormDatePicker}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={props.dataItem.until_present ? "" : requiredValidator}
            disabled={props.dataItem.until_present ? true : false}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        )}
      </td>
    );
  }
  if (props.field === "reason") {
    if (currentContext.props.dialog)
      return (
        <td>
          {" "}
          <Field
            component={FormInput}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={props.dataItem.until_present ? "" : requiredValidator}
            disabled={props.dataItem.until_present ? true : false}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        </td>
      );
    return (
      <td>
        {(currentContext.PropsState === "dialog" && !props.dataItem.status) ||
        (currentContext.PropsState === "state" &&
          props.dataItem.section !== currentContext.MaxSection &&
          !props.dataItem.status) ? (
          props.dataItem.reason
        ) : (
          <Field
            component={FormInput}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={props.dataItem.until_present ? "" : requiredValidator}
            disabled={props.dataItem.until_present ? true : false}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        )}
      </td>
    );
  } else if (props.field === "frequency") {
    if (currentContext.props.dialog)
      return (
        <td>
          {" "}
          <Field
            component={FormInput}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredMax50Validator}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        </td>
      );
    return (
      <td>
        {(currentContext.PropsState === "dialog" && !props.dataItem.status) ||
        (currentContext.PropsState === "state" &&
          props.dataItem.section !== currentContext.MaxSection &&
          !props.dataItem.status) ? (
          props.dataItem.frequency
        ) : (
          <Field
            component={FormInput}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredMax50Validator}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        )}
      </td>
    );
  } else if (props.field === "dose") {
    if (currentContext.props.dialog)
      return (
        <td>
          {" "}
          <Field
            component={FormNumericTextBox}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            format={"n0"}
            min={1}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
            validator={requiredValidator}
          />
        </td>
      );
    return (
      <td>
        {(currentContext.PropsState === "dialog" && !props.dataItem.status) ||
        (currentContext.PropsState === "state" &&
          props.dataItem.section !== currentContext.MaxSection &&
          !props.dataItem.status) ? (
          props.dataItem.dose
        ) : (
          <Field
            component={FormNumericTextBox}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            format={"n0"}
            min={1}
            validator={requiredValidator}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        )}
      </td>
    );
  } else if (props.field === "unit") {
    if (currentContext.props.dialog)
      return (
        <td>
          {" "}
          <Field
            component={FormInput}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredMax50Validator}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        </td>
      );
    return (
      <td>
        {(currentContext.PropsState === "dialog" && !props.dataItem.status) ||
        (currentContext.PropsState === "state" &&
          props.dataItem.section !== currentContext.MaxSection &&
          !props.dataItem.status) ? (
          props.dataItem.unit
        ) : (
          <Field
            component={FormInput}
            name={`current_therapy[${props.dataIndex}].${props.field}`}
            validator={requiredMax50Validator}
            onChange={() => {
              if (currentContext.props.dialog)
                currentContext.check.push(props.dataIndex);
            }}
          />
        )}
      </td>
    );
  } else if (props.field === "until_present") {
    return (
      <td>
        <Field
          component={FormCheckbox}
          name={`current_therapy[${props.dataIndex}].${props.field}`}
          disabled={
            (currentContext.PropsState === "dialog" &&
              !props.dataItem.status) ||
            (currentContext.PropsState === "state" &&
              props.dataItem.section !== currentContext.MaxSection &&
              !props.dataItem.status)
              ? true
              : false
          }
          onChange={() => {
            if (currentContext.props.dialog)
              currentContext.check.push(props.dataIndex);
          }}
        />
      </td>
    );
  }
};

const CommandCell = (cellProps) => {
  const onRemove = React.useContext(onRemoveContext);
  // Consume the state context, which will give you both the state and the setter.
  const [state, setState] = React.useContext(stateContext);

  const onClick = (e) => {
    e.preventDefault();
    onRemove({ index: cellProps.dataIndex });
  };

  return (
    <td>
      {cellProps.dataItem.id ? (
        ""
      ) : (
        <SvgIcon
          icon={trashIcon}
          style={{ color: "red", cursor: "pointer" }}
          onClick={onClick}
        />
      )}
    </td>
  );
};

const FormGrid = (fieldArrayRenderProps) => {
  const [filter, setFilter] = React.useState([]);
  const currentContext = React.useContext(MyContext);
  const { validationMessage, visited } = fieldArrayRenderProps;
  const [state, setState] = React.useState({});
  console.log("ooooooooooooooooooo", currentContext)
  const onAdd = React.useCallback(
    (e) => {
      e.preventDefault();
      fieldArrayRenderProps.onUnshift({
        value: {
          status: "new",
          treatment_line: { name: "First Line", value: "First Line" },
          therapy: "",
          dose: null,
          unit: "",
          frequency: "",
          end_date: null,
          start_date: null,
          until_present: false,
          reason: "",
          section_name:  currentContext?.section?.section_name + " - " + currentContext?.section?.title
          // props.dataItem.section_title + "-" + props.dataItem.section_name
        
        },
      });
    },
    [fieldArrayRenderProps.onUnshift,currentContext]
  );

  // In case the data is processed before passing it to the Grid (e.g.: paging, filtering, sorting)
  // we need to add custom index prop to make sure the Field component is always bind to the correct dataitem
  const dataWithIndexes = fieldArrayRenderProps.value.map((item, index) => {
    return {
      ...item,
      [FORM_DATA_INDEX]: index,
    };
  });

  return (
    <div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}

      <stateContext.Provider value={[state, setState]}>
        <onRemoveContext.Provider value={fieldArrayRenderProps.onRemove}>
          <GridTelerik
            style={{
              maxHeight: "500px",
            }}
            data={filterBy(dataWithIndexes, filter)}
            filterable
            onFilterChange={(e) => setFilter(e.filter)}
            filter={filter}
          >
            <GridToolbar>
              <div className="float-right">
                <Button
                  primary={true}
                  type={"button"}
                  onClick={onAdd}
                  className="mr-2"
                >
                  {languageContext.new.toLanguageString("custom.new")}
                </Button>

                {/*   <Button
                  type="button"
                  style={{
                    display: currentContext.props.dialog ? "" : "none",
                  }}
                  onClick={() =>
                    currentContext.toggleDialog(!currentContext.opendialog)
                  }
                >
                  {languageContext.new.toLanguageString(
                    "custom.copyfrompreviuostherapy"
                  )}
                </Button> */}
              </div>
            </GridToolbar>
            <Column
              field="code"
              title={
                <>
                  <span>
                    {languageContext.new.toLanguageString("custom.id")}
                  </span>
                </>
              }
            />
            <Column
              field="section_name"
              width= "190"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.section"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.section")}
                  </span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="treatment_line"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.treatmentline"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString(
                      "custom.treatmentline"
                    )}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="therapy"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.therapy"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.therapy")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column
              field="dose"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString("custom.dose")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.dose")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column
              field="unit"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString("custom.unit")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.unit")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="frequency"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.frequency"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.frequency")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="start_date"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.startdate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.startdate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="until_present"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.untilpresent"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString(
                      "custom.untilpresent"
                    )}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="end_date"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.enddate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.enddate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="reason"
              title={
                <>
                  <span
                    title={languageContext.new.toLanguageString(
                      "custom.reason"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.new.toLanguageString("custom.reason")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column cell={CommandCell} filterable={false} width="50px" />
          </GridTelerik>
        </onRemoveContext.Provider>
      </stateContext.Provider>
    </div>
  );
};

export function CurrentTherapy(props) {
  console.log("propsssssssssssss", props)
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);
  const [openDialog, setOpenDialog] = useState(false);
  //localization
  const localizationService = useLocalization();
  languageContext.new = useLocalization();

  //variables
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = React.useState();
  const [PropsState, setPropsState] = useState(
    props.dialog ? "dialog" : "state"
  );
  const [approved, setApproved] = useState(false);
  const [sectiondata, setSectionData] = useState();
  const MyForm = React.createRef(); //form ref to detect modification of the form
  const [MaxSection, setMaxSection] = useState(); // state to save the max section saved of current therapy
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  const [AllData, setAllData] = useState([]);
  const [deletearray, setdeletearray] = useState([]);
  const [ModifiedData, setModifiedData] = useState();
  const [approvedformchanged, setapprovedformchanged] = useState(false);
  const [check, setCheck] = useState([]);
  console.log("checkcheck", check);
  //attachement data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.dialog ? props.data.id : props.location.state.data.id,
          section: props.dialog
            ? props.section.id
            : props.location.state.section.id,
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attachementserror } = useSWR(
    !props.dialog && props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : props.dialog
      ? [APIURL + "/common/listfile/", props.data.id, props.section.id]
      : null,
    fetcher4
  );

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setSelected(value);
    setapprovedformchanged(false);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                toggleDeditNote();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                toggleDdelete();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.dialog ? props.data.id : props.location.state.data.id,
          section: props.dialog
            ? props.section.id
            : props.location.state.section.id,
        },
      })
      .then((res) => res.data);
  const { data: notes, Noteserror } = useSWR(
    !props.dialog && props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : props.dialog
      ? [APIURL + "/common/listnote/", props.data.id, props.section.id]
      : null,
    fetcher2
  );

  const { data: checkapprove, checkapproveerror } = useSWR(
    !props.dialog && props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : props.dialog
      ? [APIURL + `/common/checkapprove/`, props.data.id, props.section.id]
      : null,
    fetcher2
  );

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.dialog ? props.data.id : props.location.state.data.id,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    !props.dialog && props.location.state
      ? [
          APIURL + "/opera/baseline/addcurrenttherapy/",
          props.location.state.data.id,
        ]
      : props.dialog
      ? [APIURL + "/opera/baseline/addcurrenttherapy/", props.data.id]
      : null,
    fetcher1
  );
  const { data: therapy } = useSWR(
    !props.dialog && props.location.state
      ? [
          APIURL + "/opera/baseline/currenttherapylist/",
          props.location.state.data.id,
        ]
      : props.dialog
      ? [APIURL + "/opera/baseline/currenttherapylist/", props.data.id]
      : null,
    fetcher1
  );
  console.log("therapyyyyyyyyyyyyyyyyyyyyyyyyy", therapy);
  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //USE EFFECT

  useEffect(() => {
    let array = [];

    if (data) {
      data.data.map((d) => {
        array.push({
          id: d.id,
          section_title: d.section_title,
          section_name: d.section_name,
          created_by: d.created_by,
          modified_by: d.modified_by,
          section: d.section,
          study_subject: d.study_subject,
          therapy: d.therapy,
          treatment_line: { name: d.treatment_line, value: d.treatment_line },
          dose: d.dose,
          unit: d.unit,
          frequency: d.frequency,
          start_date: new Date(d.start_date),
          until_present: d.until_present,
          end_date: d.end_date ? new Date(d.end_date) : null,
          reason: d.reason,
          code: d.code,
        });
      });
      setAllData(array);
      setMaxSection(data.maxsection);
      setSectionData(data.sectiondata);
    }

    if (checkapprove) {
      setApproved(checkapprove);
    }
  }, [data, checkapprove]);

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  //TAB STRIP
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    if (selected === 1) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setSelected(e.selected);
      }
    } else if (
      MyForm.current &&
      Object.keys(MyForm.current.modified).length !== 0
    ) {
      setSelectedTab(e.selected);
      toggleDTabStripChange();
    } else {
      setSelected(e.selected);
    }
  };
  //TAB STRIP
  const [Subselected, setSubSelected] = React.useState(0);

  const handleSubSelect = (e) => {
    setSubSelected(e.selected);
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const CreateCurrentTherapy = (dataItem) => {
    if (dataItem.isValid) {
      if (data && data.data.length > 0) {
        if (dataItem.isModified) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let array = [];
        if (dataItem.values.current_therapy) {
          dataItem.values.current_therapy.map((d) => {
            array.push({
              created_by: UserDetail.id,
              modified_by: null,
              study_subject: props.location
                ? props.location.state.data.id
                : props.data.id,
              section:
                d.status === "new" && props.location
                  ? sectiondata.id
                  : d.status === "new" && !props.location
                  ? props.section.id
                  : d.section,
              status: d.status ? d.status : "edit",
              therapy: d.therapy,
              treatment_line: d.treatment_line.value,
              dose: d.dose,
              unit: d.unit,
              frequency: d.frequency,
              start_date: moment(d.start_date).format("YYYY-MM-DD"),
              end_date: d.end_date
                ? moment(d.end_date).format("YYYY-MM-DD")
                : null,
              until_present: d.until_present,
              reason: d.reason,
            });
          });
        }
        setLoading(true);
        axios
          .post(APIURL + "/opera/baseline/addcurrenttherapy/", {
            modified_by: UserDetail.id,
            section: props.dialog ? props.section.id : sectiondata.id,
            study_subject: props.dialog
              ? props.data.id
              : props.location.state.data.id,
            current_therapy: array,
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
            if (props.onClose) {
              setLoading(false);
              props.TreatmentCallBack();
              props.onClose();
            }
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
            setdeletearray([]);
          });
      }
    }
  };

  const CreateModification = (dataItem) => {
    toggleDModifyreason(false);
    let array = [];
    if (ModifiedData.current_therapy) {
      ModifiedData.current_therapy.map((d, index) => {
 
        array.push({
          id: d.id,
          created_by: d.status === "new" ? UserDetail.id : d.created_by,
          modified_by: d.status === "new" ? null : UserDetail.id,
          study_subject: props.dialog
            ? props.data.id
            : props.location.state.data.id,

          section:
            d.status === "new" && !props.dialog
              ? sectiondata.id
              : d.status === "new" && props.dialog
              ? props.section.id
              : !d.status && props.dialog && check.includes(index)
              ? props.section.id
              : d.section,
          status: d.status
            ? d.status
            : props.dialog && check.includes(index)
            ? "new"
            : "edit",
          therapy: d.therapy,
          treatment_line: d.treatment_line.value,
          dose: d.dose,
          unit: d.unit,
          frequency: d.frequency,
          start_date: moment(d.start_date).format("YYYY-MM-DD"),
          end_date: d.end_date ? moment(d.end_date).format("YYYY-MM-DD") : null,
          until_present: d.until_present,
          reason: d.reason,
        });
      });
    }
    setLoading(true);
    axios
      .put(APIURL + "/opera/baseline/updatecurrenttherapy/", {
        modified_by: UserDetail.id,
        study_subject: props.dialog
          ? props.data.id
          : props.location.state.data.id,

        section: props.dialog ? props.section.id : sectiondata.id,
        current_therapy: array,
        modification_reason: dataItem.modification_reason,
      })
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
        if (props.onClose) {
          props.TreatmentCallBack();
          props.onClose();
        }
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      });
  };

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || approved === true) {
      boolean = true;
    }
    return boolean;
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  const toggleDialog = (e) => {
    setOpenDialog(e);
  };
  // const MyContext = React.createContext({
  //   section: {},
  // });
  return (
    <TrialMap condition={props.dialog ? "dialog" : "state"}>
      {(!data || !notes || !attachements) && loadingPanel}
      {/* <MyContext.Provider value={{ section : props.location.state.section }}> */}
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader className="DetailFormHeader">
              <div className="row">
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.subjectid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString(
                    "custom.subjectinitials"
                  )}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.study")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.protocolid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.sitenumber")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.createdby")}
                </div>
              </div>
              <div className="row">
                <div className="col text-responsive-overflow subject-id-style">
                  {props.dialog
                    ? props.data.crf_id
                    : props.location.state
                    ? props.location.state.data.crf_id
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.dialog
                    ? props.data.subject_initial
                    : props.location.state
                    ? props.location.state.data.subject_initial
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.dialog
                    ? props.data.study_name
                    : props.location.state
                    ? props.location.state.data.study_name
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.dialog
                    ? props.data.study_protocol
                    : props.location.state
                    ? props.location.state.data.study_protocol
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.dialog
                    ? props.data.subject_center
                    : props.location.state
                    ? props.location.state.data.subject_center
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.dialog
                    ? props.data.createdBy
                    : props.location.state
                    ? props.location.state.data.createdBy
                    : ""}
                </div>
              </div>
              <hr className="RowRoleHr" />
              <div className="row">
                <div className="col pages-big-title">
                  <strong>
                    {localizationService.toLanguageString(
                      "custom.currenttherapy"
                    )}
                  </strong>
                </div>
                <div className="col">
                  <div className="float-right">
                    <Button onClick={goBack} className="back-button-style">
                      {localizationService.toLanguageString("custom.back")}
                    </Button>
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>
      {/* </MyContext.Provider> */}

      <div className="row mt-2">
        <div className="col-12">
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.currenttherapy"
              )}
            >
              <MyContext.Provider
                value={{
                  FormRef: MyForm,
                  MaxSection: MaxSection,
                  PropsState: PropsState,
                  section: props.dialog ? props.section : sectiondata,
                  filter: filter,
                  props: props,
                  opendialog: openDialog,
                  toggleDialog: toggleDialog,
                  ModifiedData: ModifiedData,
                  check: check,
              
                }}
              >
                <Form
                  initialValues={{
                    current_therapy: data ? AllData : [],
                  }}
                  ref={MyForm}
                  onSubmitClick={CreateCurrentTherapy}
                  key={JSON.stringify(AllData)}
                  render={(formRenderProps) => (
                    <FormElement className="">
                      <fieldset className={"k-form-fieldset"}>
                        <Tooltip
                          openDelay={0}
                          anchorElement="target"
                          parentTitle={true}
                          position={"top"}
                        >
                          <Prompt
                            when={PromptFunction(formRenderProps)}
                            message={localizationService.toLanguageString(
                              "custom.promptmessage"
                            )}
                          />

                          <div className="row mt-4">
                            <div className="col-12">
                              <FieldArray
                                name="current_therapy"
                                component={FormGrid}
                                validator={arrayLengthValidator}
                              />
                            </div>
                          </div>
                        </Tooltip>
                      </fieldset>
                      <Tooltip
                        openDelay={0}
                        anchorElement="target"
                        parentTitle={true}
                        position={"left"}
                      >
                        <div className="float-right">
                          <div title={disabletitle()}>
                            <Button
                              primary={true}
                              type={"submit"}
                              className="mr-2em"
                              disabled={disablefunction()}
                            >
                              {loading
                                ? localizationService.toLanguageString(
                                    "custom.loading"
                                  )
                                : localizationService.toLanguageString(
                                    "custom.save"
                                  )}
                            </Button>
                          </div>
                        </div>
                      </Tooltip>
                    </FormElement>
                  )}
                />
              </MyContext.Provider>
            </TabStripTab>
            {!props.dialog ? (
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.approvesection"
                )}
              >
                <ApproveStudySubject
                  study_subject={props.location.state.data.id}
                  section={props.location.state.section.id}
                  data={props.location.state.data}
                  parentCallBack={ChangeApproveSection}
                />
              </TabStripTab>
            ) : (
              ""
            )}
            {!props.dialog ? (
              <TabStripTab
                title={localizationService.toLanguageString(
                  "custom.notesnattachements"
                )}
              >
                <TabStrip selected={Subselected} onSelect={handleSubSelect}>
                  <TabStripTab
                    title={localizationService.toLanguageString("custom.notes")}
                  >
                    <GridTelerik data={notes}>
                      <GridToolbar>
                        <Button
                          primary={true}
                          onClick={() => {
                            EditSubject
                              ? toggleDaddNote()
                              : toggleDPermission();
                          }}
                        >
                          {localizationService.toLanguageString("custom.new")}
                        </Button>
                      </GridToolbar>

                      <Column
                        field={"note"}
                        title={localizationService.toLanguageString(
                          "custom.note"
                        )}
                        cell={NoteNameTemplate}
                      />
                      <Column
                        field={"date_created"}
                        title={localizationService.toLanguageString(
                          "custom.datecreated"
                        )}
                        cell={DateCreatedTemplate}
                      />
                      <Column
                        title={localizationService.toLanguageString(
                          "custom.actions"
                        )}
                        width="100px"
                        cell={Noteactions}
                      />
                    </GridTelerik>
                  </TabStripTab>

                  <TabStripTab
                    title={localizationService.toLanguageString(
                      "custom.attachements"
                    )}
                  >
                    <GridTelerik data={attachements}>
                      <GridToolbar>
                        <Button
                          primary={true}
                          onClick={() => {
                            {
                              EditSubject
                                ? toggleDaddFile()
                                : toggleDPermission();
                            }
                          }}
                        >
                          {localizationService.toLanguageString("custom.new")}
                        </Button>
                      </GridToolbar>
                      <Column
                        field={"title"}
                        title={localizationService.toLanguageString(
                          "custom.title"
                        )}
                      />

                      <Column
                        field={"date_created"}
                        title={localizationService.toLanguageString(
                          "custom.dateadded"
                        )}
                        cell={DateCreatedTemplate}
                      />

                      <Column
                        title={localizationService.toLanguageString(
                          "custom.actions"
                        )}
                        width="100px"
                        cell={Fileactions}
                      />
                    </GridTelerik>
                  </TabStripTab>
                </TabStrip>
              </TabStripTab>
            ) : (
              ""
            )}
          </TabStrip>
        </div>
      </div>

      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
          height={"400px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}

      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}

      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={
              props.dialog
                ? props.data.section
                : props.location.state.section.id
            }
            subject={
              props.dialog ? props.data.id : props.location.state.data.id
            }
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}

      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={
              props.dialog
                ? props.data.section
                : props.location.state.section.id
            }
            subject={
              props.dialog ? props.data.id : props.location.state.data.id
            }
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
      {openDialog && (
        <Dialog
          title={"Please confirm"}
          onClose={() => setOpenDialog(!openDialog)}
        >
          <GridTelerik></GridTelerik>
        </Dialog>
      )}
    </TrialMap>
  );
}
export default CurrentTherapy;
