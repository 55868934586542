const Centers = [
  {
    value: "UAE",
    name: "Sheikh Khalifa Specialty Hospital ( SKSH )-Ras al Khaimah",
  },
  {
    value: "UAE",
    name: "Burjeel Medical City, Abu Dhabi",
  },
  { value: "KSA1", name: "King Faisal Specialty Hospital- Jeddah" },
  { value: "KSA2", name: "King Saud bin Abdul Aziz -Jeddah" },
  { value: "KWT", name: "Kuwait Cancer Control Center" },
  { value: "JOR", name: "King Hussein Cancer Center - KHCC" },
  { value: "EGY1", name: "Kasr Al Aini Hospital" },
  { value: "EGY2", name: "Cairo University Hospitals" },
  { value: "EGY3", name: "National Cancer Institute" },
];

export default Centers;
