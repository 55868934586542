const PathologyType = [
  {
    value: "Clear cell",
    name: "Clear cell",
  },
  {
    value: "Non clear cell",
    name: "Non clear cell",
  },
  {
    value: "Mixed, specify",
    name: "Mixed, specify",
  },
];

export default PathologyType;
