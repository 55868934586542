import { combineReducers } from "redux";
import loggedReducer from "./islogged";
import {
  user_detail,
  view_all_subjectReducer,
  view_subjectReducer,
  edit_subjectReducer,
  add_subjectReducer,
  approve_subjectReducer,
  view_studyReducer,
  edit_studyReducer,
  add_studyReducer,
  view_user_settingReducer,
  add_user_settingReducer,
  edit_user_settingReducer,
  receive_emailReducer,
} from "./access";

const allReducres = combineReducers({
  isLogged: loggedReducer,
  UserDetail: user_detail,

  ViewAllSubject: view_all_subjectReducer,
  ViewSubject: view_subjectReducer,
  AddSubject: add_subjectReducer,
  EditSubject: edit_subjectReducer,
  ApproveSubject: approve_subjectReducer,

  ViewStudy: view_studyReducer,
  AddStudy: add_studyReducer,
  EditStudy: edit_studyReducer,

  ViewUserSetting: view_user_settingReducer,
  AddUserSetting: add_user_settingReducer,
  EditUserSetting: edit_user_settingReducer,

  ReceiveEmail: receive_emailReducer,
});
export default allReducres;
