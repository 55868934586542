export const enMessages = {
  custom: {
    //software title
    crf: "CRF",
    ecrf: "E-CRF",
    //LOGIN
    login: "Login",
    welcome: "Welcome",
    galoperecf: "Galoper eCRF",
    submit : "Submit",
    enteremail : "Enter your email",
    forgotPassword : "Forgot password",
    //TimeOut
    stay: "Stay",
    timeout: "You Will Get Timed Out. You want to stay",
    idle: "You Have Been Idle!",

    //common
    approvesection: "Approve Section",
    signature: "Signature",
    formmustbesubmitted:
      "Form has been modified and not yet saved , please don't forget to save",
    sectionlocked: "Section Locked",
    approvesubjecttrial: "Approve Subject Trial",
    approvetrial: "Approve Trial",
    sampledate: "Date Of Sample",
    updateuserinfo: "Update Personal Information",
    loading: "Loading",
    insertedcodes: "Inserted Codes",
    id: "Code",
    inactive: "Inactive",
    crfidnotunique: "CRF Id not unique",
    receive: "Receive",
    table: "Table",
    modifiedby: "Modified By",
    isactive: "Status",
    savechanges: "Save Changes",
    nopermissiontoedit: "You don't have permission to edit subject information",
    instance: "Instance",
    unsaveddatalost:
      "All unsaved data will be lost , are you sure you want to proceed",
    deleteconfirmation: "Are you sure you want to delete",
    confirm: "Confirm",
    notesnattachements: "Notes and Attachements",
    value: "Value",
    filterbystudy: "Filter By Study",
    missingreason: "Missing Information Reason",
    informationstatusquestion: "Is this section information missing",
    nomodificationchange: "No Modification Change",
    pleasespecifymodifyreason: "Please specify a reason of this modification",
    modificationdate: "Modification Date",
    modificationreason: "Modification Reason",
    modificationinformation: "Modification Information",
    reason: "Reason",
    female: "Female",
    male: "Male",
    birthdate: "Birth Date",
    dateadded: "Date Added",
    title: "Title",
    datecreated: "Date Created",
    attachements: "Attachements",
    notes: "Notes",
    createdby: "Created By",
    lastmodifiedby: "Last Modified By",
    visitdate: "Visit Date",
    back: "Back",
    yes: "Yes",
    no: "No",
    description: "Description",
    startdate: "Start Date",
    confirmpassword: "Confirm Password",
    firstname: "First Name",
    lastname: "Last Name",
    email: "Email",
    role: "Role",
    country: "Country",
    center: "Center",
    roles: "Roles",
    chooseall: "Choose All",
    logout: "Logout",
    username: "Username",
    password: "Password",
    edit: "Edit",
    delete: "Delete",
    approve: "Approve",
    access: "Access",
    all: "All",
    view: "View",
    viewallrecord: "View All Record",
    new: "New",
    actions: "Actions",
    create: "Create",
    save: "Save",
    cancel: "Cancel",
    name: "Name",
    disapprove: "disapprove",

    //CompanyProfile
    selectlogo: "Select Logo",
    //roles
    hasassistant: "Has assistant",
    rolename: "Role Name",
    newrole: "New Role",
    editrole: "Edit Role",

    subsection: "Sub Section",
    //user
    isassistant: "Is an Assistant",
    subinvestigator: "Sub Investigator",
    active: "Active",
    newuser: "New User",
    edituser: "Edit User",

    //Notification
    datasaved: "Your Data has been saved",
    roleadded: "A role has been added",
    roleedited: "A role has been edited",
    useradded: "A user has been added",
    useredited: "A user has been edited",
    studyadded: "A study has been added",
    studyedited: "A study has been edited",

    //apptopbar
    study: "Study",
    studysubject: "Study Subject",

    //settings
    companysetting: "Company Setting",
    companyprofile: "Company Profile",
    yourpasswordnotpersonalinformation:
      "Your password can't contain personal information",
    incorrectpassword: "Incorrect Password",
    newpassword: "New Password",
    verifypassword: "Verify Password",
    currentpassword: "Current Password",
    updateuserpassword: "Update User Password",
    changepassword: "Change Password",
    settings: "Settings",
    systemsettings: "System Settings",
    usersandroles: "Users and Roles",
    users: "Users",
    rolesnpermission: "Roles and Permissions",

    //permission
    permissiondenied: "Permission Denied",
    viewnoaccess:
      "Permission Error , You don't have permission to view this page",
    addnoaccess: "Permission Error , You don't have permission to add record",
    deletenoaccess:
      "Permission Error , You don't have permission to delete record",
  
    editnoaccess: "Permission Error , You don't have permission to edit record",
    noaccess: "Oops you don't have access to view this page",
    nofinishtreatment: "The Subject Didn't Complete All Treatments Yet",
    noeligible: "Subject Is Not Eligible for This Trial",

    //password
    yourpasswordnotpersonalinformation:
      "Your password can't contain personal information",
    yourpassword: "Your password :",
    passwordnotpersonalinformation:
      "can't be too similar to your other personal information",
    passwordcharacternb: "must contain at least 8 characters",
    passwordnotnumeric: "can't be entirely numeric or includes spaces",

    //study

    studytype: "Study Type",
    studyoverview: "STUDY OVERVIEW",
    protocolname: "Protocol Name",
    studyname: "Study Name",
    study: "Study",
    studysponsor: "Study Sponsor",
    estimatedenrollement: "Estimated Enrollement",
    patientenrolmentdate: "patient Enrolment Date",
    studytype: "Study Type",
    newstudy: "New Study",
    editstudy: "Edit Study",
    studies: "Studies",
    othertherapeuticarea: "Other Therapeutic Area",
    therapeuticarea: "Therapeutic Area",
    protocolid: "Protocol Version and Date",
    principalinvestigator: "Principal Investigator",
    //erors
    pagenotfound: "404 ERROR , Page Not Found",
    errornosave: "ERROR , UNABLE TO SAVE THE FORM",

    //prompt
    navigationconfirmation: "Navigation Confirmation",
    promptmessage: "Are you sure you want to leave this page?",

    //subject subject
    subjectoverallresponse: "Subject Overall Response",
    overallresponse: "Overall Response",
    thesectionhasbeenapproved:
      "Section has been approved,you can no longer edit",
    filterbystudy: "Filter By Study",
    sitenumber: "Site Number",
    subjectinitials: "Subject Initials",
    studyrelatedto: "Study Related To",
    itisapprovedsection: "Is this section approved",
    approvaldate: "Approval Date",
    informeddate: "Informed Date",
    consentobtained: "Was informed consent obtained",
    informedconsent: "Informed Consent",
    approvestudySubject: "Study Subject Approval",
    approvestudysubject: "Approve Study Subject",
    informedconsent: "Informed Consent",
    subjectid: "Subject ID",
    crfname: "CRF Name",
    section: "Section",
    subject: "Subject",
    dataentry: "Start Data Entry",
    studysubjectidwillbe: "Study Subject ID will be",
    subjectfirstnameinitial: "Subject First Name Initial ",
    subjectlastnameinitial: "Subject Last Name Initial",
    subjectfilenumber: "Subject File Number",
    addstudysubject: "Add Study Subject",
    newstudysubject: "New Study Subject",
    studysubjects: "Study Subjects",
    studysubjectid: "Study Subject ID",
    screening: "Screening",
    baseline: "Baseline",
    treatment: "Treatment",
    followup: "FollowUp",

    //screening
    demography: "Demography",
    inclusionexclusioncriteria: "Inclusion/Exclusion Criteria",
    demographyinformation: "Demography Information",
    othernationality: "Other Nationality",

    //demography
    gender: "Gender",
    nationality: "Nationality",
    country: "Country",

    //inclusion/exclusion
    inclusionexclusioninformation: "Inclusion/Exclusion Criteria Information",
    inclusioncriteria: "Inclusion Criteria",
    exclusioncriteria: "Exclusion Criteria",
    agecondition: "Patients ≥ 18 years old",
    cancerhistorycondition:
      "Histologically confirmed metastatic renal cell cancer",
    treatmentcondition: "Patients treated by immunotherapy and/or TKI",
    consentcondition:
      "Signed informed consent form obtained prior to study entry only for living patients",
    pregnancycondition: "Pregnancy",
    otherdiseasecondition:
      "History of another malignancy within the past 5 years except cured basal cell carcinoma of the skin or excised carcinoma in situ of the cervix",
    othertrialcondition: "Current participation in another clinical trial",

    //vital signs
    vitalsigns: "Vital Signs",
    mmhg: "mmHg/mmHg",
    celcius: "°C",
    breathspermin: "Breaths per min",
    beatspermin: "Beats per min",
    kg: "Kg",
    cm: "Cm",
    respiratoryrate: "Respiratory Rate",
    vitalsignsinformation: "Vital Signs Information",
    dateofassessment: "Date Of Assessement",
    height: "Height",
    weight: "Weight",
    pulse: "Pulse",
    bodytemperature: "Body Temperature",
    bloodpressurelabel: "Seated Blood Pressure(Systolic/Diastolic)",
    bloodpressure: "Blood Pressure",

    //SmokingHistory :
    smokinghistory: "Smoking History",
    smokingnb: "Smoking Number",
    occurence: "Occurence",
    somkinginformationstatus: "Smoking History Information Status",
    smokinghistoryinformation: "Smoking History Information",
    cigarettessmoking: "Cigarettes Smoking",
    otherformofsmoking: "Other Forms Of Smoking",
    smokingstatus: "What is the subject’s smoking status?",
    datesmokingstopped: "Date smoking stopped",
    nbpacksmonths: "Number of packs per month",
    smokingstartdate: "Start Date",
    formofsmoking: "Smoking Forms",
    frequency: "Frequency",
    numberofmonths: "Number Of Months",
    numberofmonthssmoking: "Number of Months Smoking",
    pleasefillmedicalhistoryrelated:
      "Any significant findings associated with clinical signs or symptoms, or requiring therapy before the start of study drug should be recorded on the Relevant medical history/Current medical conditions page",
    haveothersmokingform: "Presence of other smoking forms?",
    //Medical history

    activeproblem: "Active problem at start of study drug",
    diagnosisdate: "Diagnosis/Surgery Date",
    history: "History/Condition/Surgery",
    subjecthasmedicalhistory: "Does the subject have medical history",
    medicalhistory: "Medical History",
    medicalhistoryinformation: "Medical History Information",
    medicalinformationstatus: "Medical History Information Status",

    //Prognostic factors
    therapy: "Therapy",
    untilpresent: "Until Present",
    prognosticfactorsinformation: "Prognostic Factors Information",
    prognosticfactors: "Prognostic Factors",
    currenttherapy: "Current Therapy",
    mskpoorprognostic: "MSK Poor Prognostic Factors (Memorial Sloan-kettering)",
    patientriskgroup: "Patient Risk Group",
    dose: "Dose",
    unit: "Unit",
    enddate: "End Date",
    startdate: "Start Date",

    //Cancer History

    stageofrcc: "Stage at initial diagnosis of RCC",
    mrcchistory: "mRCC History",
    cancerhistory: "Metastatic Renal Cell Carcinoma (mRCC) History",
    cancerhistoryinformation: "Cancer History Information",
    initialdiagnosisrcc: "Date of initial diagnosis of mRCC",
    pathology: "Pathology",
    location: "Location",
    specify: "Specify",
    stagercc: "Stage of RCC at initial diagnosis",
    previoustreatmentmrcc: "Previous Treatment of RCC or mRCC",
    neoadjuvanttherapy: "Neoadjuvant Therapy",
    adjuvanttherapy: "Adjuvant Therapy",
    nbcycles: "Number of cycles",
    drug: "Drug",
    surgerywithnote: "Surgery(Mark if laparoscopic surgery was used)",
    type: "Type",
    site: "Site",
    othertype: "Other Surgery Type",
    mrccsectiontitle: "Metastatic Renal Cell Carcinoma (mRCC) History",
    nonclearcelltype: "Non clear cell type",
    pleasemarkone: "Please , Choose one",
    mixedpathology: "Mixed Pathology",
    previoussectionquestion: "Presence of previous Rcc or mRCC treatment",
    surgery: "Surgery",
    date: "Date",
    laparoscopicsurgery: "Mark if laparoscopic surgery was used",

    //Concomitant Medication
    concomitantmedicationinformation:
      "Concomitant medications / Significant non-drug therapies",
    concomitantmedications: "Concomitant Medications",
    subjecthasconcommedication: "Does the subject have Concomitant Medications",
    medication: "Medication(Trade Name if Possible)",
    medicationreason: "Reason(including prophylaxis)",
    singledose: "Single dose",
    unit: "Unit",
    otherunit: "Other Unit",
    frequency: "Frequency",
    otherfrequency: "Other Frequency",
    continuousatfinal: "Mark if continuous at final exam",

    //METASTAIC SITE
    presence: "Presence",
    method: "Method",
    othermethod: "Other Method",
    metastaticsites: "Metastatic Sites",
    metastaticsitesinformation: "Metastatic Sites Information",
    metastaticsiteinformationstatus: "Metastatic Sites Information Status",

    //CURRENT THERAPY
    nodatayet: "No data yet,Continuity response is missing for this section",
    treatmentline: "Treatment Line",
    //LABORATORY VALUES
    normal: "Normal",
    problem: "Problem",
    missing: "Missing",
    status: "Status",
    lowerlimitnormal: "Lower limit of normal",
    upperlimitnormal: "Upper limit of normal",
    result: "Result",
    test: "Test",
    testmissingreason: "Missing Reason",
    laboratorytests: "Laboratory Tests",
    hematologytest: "Hematology Test",
    bloodchemistrytest: "Blood Chemistry Test",
    othertest: "Other Tests",
    creatinineclearance: "Creatinine Clearance",
    paramedicaltests: "Paramedical Tests",
    karnofskiperformancestatus: "Karnofski Performance Status",
    serumquestion: "Is serum creatinine > 1.5 ULN?",
    gfr: "Glomerular filtration rate (GFR)",
    electrocardiogram: "Electrocardiogram",
    dateecg: "Date of ECG",
    ecg: "ECG",
    additionalinformation: "Additional Information",
    karnofskistatus: "Karnofski Performance Status",
    sampledate: "Sample Date",
    urinalysis: "Urinalysis",
    urinaryprotein: "Urinary Protein",

    //Treatment
    ifother: "If other",
    pleasespecify: "Please Specify",
    addresponsetotreatment: "Add Response To Treatment",
    responsetotreatment: "Response to Treatment",
    subjectcontinuity: "Did the patient continue with current treatment?",
    dosechange:
      "Has any change occurred to dose or frequency of current treatment?",
    newdose: "New Dose",
    newlinetreatment: "New line treatment",
    subjectstop: "Did the patient stop current treatment for a period of time",
    from: "From",
    to: "To",

    //Adverse Event
    adverseevent: "Adverse Event",
    severity: "Severity",
    adverseserious: "Mark if AE meets definition of serious",
    relationshipstudydrug: "Relationship to study drug",
    actiontaken: "Action Taken",

    //Note
    addnote: "Add Note",
    editnote: "Edit Note",
    note: "Note",

    //Response Treatment
    deathdate: "Death Date",
    deathreason: "Death Reason",
    signaturedate: "Signature Date",
    relevantreason:
      "relevant information related to reason for discontinuation",
    discontreason: "Discontinuation Reason",
    lastdrugdate: "Last known date subject took study drug",
    evaluatedresponse: "Was the response to treatment evaluated?",
    progressioncase:
      "In case of Progression or recurrence,Specify Date and Site",
    patientstatus: "Patient status",
    response: "Response",
    drugdiscontinuation:
      "In case of loss to follow-up or death, please fill section study drug discontinuation",

    //files
    upload: "Upload Attchements",
    filenamealreadyexist: "This Name alreadyExist",
    accept: "Accept",
    fieldrequired: "This Field is required",
    failupload: "Fail To Upload Attachements",

    //Modification
    logs: "Logs",
    modifications: "Modifications",
    seeallmodifications: "See all Modifications",
    oldvalue: "Old Value",
    newvalue: "New Value",
    reasonformodification: "Reason For Modifications",
    modificationslogs: "Modifications Logs",

    //logins
    alreadyloggedin: "You are already logged in",
    incorrectlogin: "Incorrect username or password",
    inactiveuser:
      "Your account has been disabled , Please check with your system administrator",
    emailtaken: "Email Already Taken",
    usernametaken: "Username Already Taken",
    passwordnotmatching: "Passwords are not matching",

    //tooltip
    userprofile: "User Profile",

    //Drawer titles
    drawerscreening: "SCREENING",
    drawerbaseline: "BASELINE",
    drawertreatment: "TREATMENT",
    drawerfollowup: "Follow Up",
    monthone: "Month-1",
    monththree: "Month-3",
    monthsix: "Month-6",
    monthtwelve: "Month-12",
    monththirtysix: "Month-36",
    followupone: "Followup-1",

    //add study sbject
    subjectidstructure: "Subject ID Structure will be as follow",
    countrycharacter: "First and Second Letter for the Country ISO Code",
    centercharacter: "Third and Fourth Letter for the Site Number",
    doctorcharacter: "Fifth and Sixth Letter for the Doctor Initials",
    patientcharacter: "Seventh and Eighth Letter for the Patient Initials",
    othercharacters: "Subject Number",

    //Overall Response
    nbmonth: "Number of months",
    overallsurvivalos: "Overall survival OS",
    progressivefreesurvival: "Progression free survival",
    timetoresponse: "Time to response",

    //section locked
    subjectnoteligible: "Subject is not eligible for this trial",
    subjectlost: "We have lost follow up of this subject",
    fillsectionbefore: "Please fill all previous informations to unlock",
    other: "Other",
    copyfrompreviuostherapy: "Copy from previous therapy",
    fillallrequired:"Fill all required fileds in all Tabs"
  },
};
