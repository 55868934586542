const StaticHematologyTest = [
  {
    test_present: false,
    test_name: "Hemoglobin",
    notpresent_reason: "",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: "missing",
  },
  {
    test_present: false,
    test_name: "Hematocrit",
    notpresent_reason: "",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: "missing",
  },
  {
    test_present: false,
    test_name: "WBC",
    notpresent_reason: "",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: "missing",
  },
  {
    test_present: false,
    test_name: "ANC",
    notpresent_reason: "",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: "missing",
  },
  {
    test_present: false,
    test_name: "Platelets",
    notpresent_reason: "",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: "missing",
  },
  {
    test_present: false,
    test_name: "ESR",
    notpresent_reason: "",
    result: null,
    unit: "",
    lower_limit: null,
    upper_limit: null,
    normal: "missing",
  },
];

export default StaticHematologyTest;
