const ActionTakenValues = [
  { value: "No action taken", name: "No action taken" },
  {
    value: "Study treatment dosage adjusted/temporarily interrupted",
    name: "Study treatment dosage adjusted/temporarily interrupted",
  },
  {
    value: "Study treatment permanently discontinued due to this adverse event",
    name: "Study treatment permanently discontinued due to this adverse event",
  },
  {
    value: "Concomitant medication taken",
    name: "Concomitant medication taken",
  },
  { value: "Non-drug therapy given", name: "Non-drug therapy given" },
  {
    value: "Hospitalization/prolonged hospitalization",
    name: "Hospitalization/prolonged hospitalization",
  },
];

export default ActionTakenValues;
