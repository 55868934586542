//utilities
import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
//kendo component
import { Tooltip } from "@progress/kendo-react-tooltip";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { filterBy } from "@progress/kendo-data-query";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { requiredValidator } from "../common/Kendo/validators";
import {
  RequiredFormDatePicker,
  RequiredFormInput,
} from "../common/Kendo/form-components";
import {
  requiredMax1Validator,
  requiredMaxSubject20Validator,
} from "../common/Kendo/validators";
//localization
import { useLocalization } from "@progress/kendo-react-intl";
import moment from "moment";

export function AddStudySubject(props) {
  //localization
  const localizationService = useLocalization();

  //redux
  const UserDetail = useSelector((state) => state.UserDetail);

  //variables
  const [ErrorNotification, setErrorNotification] = useState("");
  console.log("UserDetailUserDetail",UserDetail)
  const doctfirstinitial = UserDetail.is_assistant
    ? UserDetail.sub_investigator_fn[0].toUpperCase()
    : UserDetail.first_name[0].toUpperCase();
  const doctlastinitial = UserDetail.is_assistant
    ? UserDetail.sub_investigator_ln[0].toUpperCase()
    : UserDetail.last_name[0].toUpperCase();
  const countryinitial = UserDetail.country[0]["value"].toUpperCase();
  console.log("testttt", UserDetail.center[0].toUpperCase() ,
  UserDetail.center.slice(-1).toUpperCase() , UserDetail.center)
  const centerinitial =
    UserDetail.center[0].toUpperCase() +
    UserDetail.center.slice(-1).toUpperCase();
  const [subjectfirstinitial, setsubjectfirstinitial] = useState("");
  const [subjectlastinitial, setsubjectlastinitial] = useState("");
  const [subjectdocumentnumber, setsubjectdocumentnumber] = useState("");

  //FUNCTION
  const handleDocumentchange = (e, formRenderProps) => {
    let ss = e.value.replace(/[a-zA-Z]*$/g, "");
    formRenderProps.onChange("subject_document_number", {
      value: ss,
    });
  };
  const handleFirstchange = (e, formRenderProps) => {
    let ss = e.value.replace(/[0-9]*$/g, "");
    formRenderProps.onChange("subject_firstname_initial", {
      value: ss,
    });
  };
  const handleLastchange = (e, formRenderProps) => {
    let ss = e.value.replace(/[0-9]*$/g, "");
    formRenderProps.onChange("subject_lastname_initial", {
      value: ss,
    });
  };

  //user data fetching
  const { data: studies } = useSWR(APIURL + "/common/liststudy/");
  const studyarray = studies
    ? studies.map((val) => ({ value: val.id, name: val.study_name }))
    : [];

  const StudyDropDownList = (fieldRenderProps) => {
    const [data, setData] = React.useState(studyarray.slice());
    const filterData = (filter) => {
      const data = studyarray.slice();
      return filterBy(data, filter);
    };
    const filterChange = (event) => {
      setData(filterData(event.filter));
    };
    const handleChange = (e) => {
      fieldRenderProps.onChange({
        dataItem: fieldRenderProps.dataItem,
        field: fieldRenderProps.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value ? e.target.value.value : null,
      });
    };
    const { dataItem, field } = fieldRenderProps;
    const dataValue = fieldRenderProps;
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      wrapperStyle,
      ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    return (
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
          <span style={{ color: "red" }}>*</span>
        </Label>
        <ComboBox
          onChange={handleChange}
          value={data.find((c) => c.value === dataValue)}
          data={data}
          textField="name"
          dataItemKey="value"
          filterable={true}
          onFilterChange={filterChange}
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    );
  };
console.log("ggggggggggggggggg" ,centerinitial )
  const CreateStudySubject = (dataItem) => {
    if (dataItem.isValid) {
      axios
        .post(APIURL + "/common/listsubject/", {
          crf_id:
            countryinitial +
            centerinitial +
            doctfirstinitial +
            doctlastinitial +
            "-" +
            subjectfirstinitial +
            subjectlastinitial +
            subjectdocumentnumber,
          subject_center: centerinitial,
          subject_initial: subjectfirstinitial + subjectlastinitial,
          user_responsable: UserDetail.id,
          study: dataItem.values.study.value,
          visit_date: moment(dataItem.values.visit_date).format("YYYY-MM-DD"),
          informed: false,
          created_by: UserDetail.id,
          approved_trial: false,
          missing_fields: false,
          eligible: false,
          subject_lost: false,
        })
        .then((res) => {
          if (res.data.message === "crfidnotunique") {
            setErrorNotification(
              localizationService.toLanguageString("custom.crfidnotunique")
            );
          } else {
            setErrorNotification("");
            props.onClose();
          }
        })
        .catch((error) => {
          setErrorNotification(
            error.response.status +
              " " +
              localizationService.toLanguageString("custom.errornosave")
          );
        });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="row mt-2 SubjectIdRecommendation1">
            <div className="col-12">
              {localizationService.toLanguageString(
                "custom.subjectidstructure"
              )}
              :
            </div>
          </div>
          <div className="row SubjectIdRecommendation2">
            <div>
              <li>
                {localizationService.toLanguageString(
                  "custom.countrycharacter"
                )}
              </li>
            </div>

            <div>
              <li>
                {" "}
                {localizationService.toLanguageString("custom.centercharacter")}
              </li>
            </div>
            <div>
              <li>
                {localizationService.toLanguageString("custom.doctorcharacter")}
              </li>
            </div>
            <div>
              <li>
                {localizationService.toLanguageString(
                  "custom.patientcharacter"
                )}
              </li>
            </div>
            <div>
              <li>
                {localizationService.toLanguageString(
                  "custom.subjectfilenumber"
                )}
              </li>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="subject-structure-style">
            {countryinitial +
              centerinitial +
              doctfirstinitial +
              doctlastinitial +
              "-" +
              subjectfirstinitial +
              subjectlastinitial +
              subjectdocumentnumber}
          </div>
        </div>
      </div>
      <Tooltip
        openDelay={0}
        anchorElement="target"
        parentTitle={true}
        position={"top"}
      >
        <Form
          initialValues={{
            subject_firstname_initial: "",
            subject_lastname_initial: "",
            subject_document_initial: "",
            visit_date: null,
            study: null,
          }}
          onSubmitClick={CreateStudySubject}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                <>
                  <div className="row mt-2">
                    <div className="col">
                      <Field
                        name={"subject_firstname_initial"}
                        component={RequiredFormInput}
                        label={localizationService.toLanguageString(
                          "custom.subjectfirstnameinitial"
                        )}
                        maxLength={1}
                        onChange={(e) => {
                          setErrorNotification("");
                          handleFirstchange(e, formRenderProps);
                          setsubjectfirstinitial(
                            formRenderProps
                              .valueGetter("subject_firstname_initial")
                              .toUpperCase()
                          );
                        }}
                        validator={requiredMax1Validator}
                      />
                    </div>
                    <div className="col">
                      <Field
                        name={"subject_lastname_initial"}
                        component={RequiredFormInput}
                        label={localizationService.toLanguageString(
                          "custom.subjectlastnameinitial"
                        )}
                        maxLength={1}
                        onChange={(e) => {
                          setErrorNotification("");
                          handleLastchange(e, formRenderProps);
                          setsubjectlastinitial(
                            formRenderProps
                              .valueGetter("subject_lastname_initial")
                              .toUpperCase()
                          );
                        }}
                        validator={requiredMax1Validator}
                      />
                    </div>
                    <div className="col">
                      <Field
                        name={"subject_document_number"}
                        component={RequiredFormInput}
                        label={localizationService.toLanguageString(
                          "custom.subjectfilenumber"
                        )}
                        onChange={(e) => {
                          setErrorNotification("");
                          handleDocumentchange(e, formRenderProps);
                          setsubjectdocumentnumber(
                            formRenderProps
                              .valueGetter("subject_document_number")
                              .toUpperCase()
                          );
                        }}
                        maxLength={20}
                        validator={requiredMaxSubject20Validator}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <Field
                        id={"visit_date"}
                        name={"visit_date"}
                        label={localizationService.toLanguageString(
                          "custom.visitdate"
                        )}
                        component={RequiredFormDatePicker}
                      />
                    </div>
                    <div className="col-6">
                      {" "}
                      <Field
                        id={"study"}
                        name={"study"}
                        component={StudyDropDownList}
                        label={localizationService.toLanguageString(
                          "custom.studyrelatedto"
                        )}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="k-form-error">{ErrorNotification}</span>
                    </div>
                  </div>
                </>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="float-right">
                      <Button
                        className="k-button mr-2em"
                        primary={true}
                        onClick={CreateStudySubject}
                      >
                        {localizationService.toLanguageString("custom.save")}
                      </Button>

                      <Button
                        className="k-button mr-2em"
                        onClick={props.onClose}
                        type="button"
                      >
                        {localizationService.toLanguageString("custom.cancel")}
                      </Button>
                    </div>
                  </div>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </Tooltip>
    </>
  );
}

export default AddStudySubject;
