//utilities import
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import moment from "moment";
import axios from "axios";
import { Prompt } from "react-router-dom";

//kendo
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import { Loader } from "@progress/kendo-react-indicators";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import { AppBar, AppBarSection } from "@progress/kendo-react-layout";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Card, CardHeader } from "@progress/kendo-react-layout";
import {
  FormCheckbox,
  RequiredFormTextArea,
} from "../common/Kendo/form-components";
import { requiredValidator } from "../common/Kendo/validators";
//localization
import { useLocalization } from "@progress/kendo-react-intl";

//component
import AddFile from "../common/commoncomponents/AddFile";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";
import TabChange from "../common/commoncomponents/TabChange";
import TrialMap from "../studysubject/TrialMap";
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
export function AddInclusionExlusion(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  const [approvedformchanged, setapprovedformchanged] = useState(false);

  //localization
  const localizationService = useLocalization();

  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);
  const { data: notes, Noteserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  //attachements data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attchementserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  //data

  const { data, error } = useSWR(
    props.location.state
      ? APIURL + `/common/detailsubject/${props.location.state.data.id}/`
      : null
  );

  //check if any section is filled
  const fetcher5 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
        },
      })
      .then((res) => res.data);

  const { data: checkfilldata, checkerror } = useSWR(
    props.location.state
      ? [APIURL + `/common/checkfilldata/`, props.location.state.data.id]
      : null,
    fetcher5
  );

  const { data: checkapprove, checkapproveerror } = useSWR(
    props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                EditSubject ? toggleDeditNote() : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  //variables
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const MyForm = React.createRef(); //form ref to detect modification of the form
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  const [AllData, setAllData] = useState(null);
  const [ModifiedData, setModifiedData] = useState();
  const [check, setcheck] = useState("");

  useEffect(() => {
    if (data) {
      if (data.SubjectCriteria) {
        setAllData(data.SubjectCriteria);
      } else {
        setAllData(null);
      }
    }
    if (checkapprove) {
      setApproved(checkapprove);
    }
  }, [data, checkapprove]);

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setSelected(value);
    setapprovedformchanged(false);
  };

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //TAB STRIP
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    if (selected === 1) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setSelected(e.selected);
      }
    } else if (
      MyForm.current &&
      Object.keys(MyForm.current.modified).length !== 0
    ) {
      setSelectedTab(e.selected);
      toggleDTabStripChange();
    } else {
      setSelected(e.selected);
    }
  };

  const [Subselected, setSubSelected] = React.useState(0);

  const handleSubSelect = (e) => {
    setSubSelected(e.selected);
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const CreateCriteria = (dataItem) => {
    if (dataItem.isValid) {
      if (AllData) {
        if (dataItem.isModified) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        setLoading(true);
        axios
          .post(APIURL + "/opera/screening/listcriteria/", {
            missing_fields: false,
            created_by: UserDetail.id,
            modified_by: null,
            study_subject: props.location.state.data.id,
            section: props.location.state.section.id,
            in_above_eighteen: dataItem.values.in_above_eighteen,
            in_required_treatement: dataItem.values.in_required_treatement,
            in_historical_cancer: dataItem.values.in_historical_cancer,
            in_informed_signed: dataItem.values.in_informed_signed,
            ex_another_malignancy: dataItem.values.ex_another_malignancy,
            ex_pregnant: dataItem.values.ex_pregnant,
            ex_another_trial: dataItem.values.ex_another_trial,
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          });
      }
    }
  };

  const CreateModification = (dataItem) => {
    toggleDModifyreason(false);
    setLoading(true);
    axios
      .put(APIURL + "/opera/screening/updatecriteria/" + AllData.id + "/", {
        created_by: AllData.created_by,
        modified_by: UserDetail.id,
        study_subject: props.location.state.data.id,
        section: props.location.state.section.id,
        in_above_eighteen: ModifiedData.in_above_eighteen,
        in_required_treatement: ModifiedData.in_required_treatement,
        in_historical_cancer: ModifiedData.in_historical_cancer,
        in_informed_signed: ModifiedData.in_informed_signed,
        ex_another_malignancy: ModifiedData.ex_another_malignancy,
        ex_pregnant: ModifiedData.ex_pregnant,
        ex_another_trial: ModifiedData.ex_another_trial,
        modification_reason: dataItem.modification_reason,
      })
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      });
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };
  const disableFunction = () => {
    let boolean = false;
    if (
      !EditSubject ||
      loading ||
      (checkfilldata && checkfilldata.message === "cant") ||
      approved
    ) {
      boolean = true;
    }
    return boolean;
  };

  const disabletitle = () => {
    let title = "";
    if (!EditSubject && approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  if (
    error &&
    Noteserror &&
    Attchementserror &&
    checkerror &&
    checkapproveerror
  )
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <TrialMap>
      {(!data || !notes || !attachements) && loadingPanel}
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader className="DetailFormHeader">
              <div className="row">
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.subjectid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString(
                    "custom.subjectinitials"
                  )}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.study")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.protocolid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.sitenumber")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.createdby")}
                </div>
              </div>
              <div className="row">
                <div className="col text-responsive-overflow subject-id-style">
                  {props.location.state ? props.location.state.data.crf_id : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_initial
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_name
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_protocol
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_center
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.createdBy
                    : ""}
                </div>
              </div>
              <hr className="RowRoleHr" />
              <div className="row">
                <div className="col pages-big-title">
                  <strong>
                    {localizationService.toLanguageString(
                      "custom.inclusionexclusioncriteria"
                    )}
                  </strong>
                </div>
                <div className="col">
                  <div className="float-right">
                    <Button onClick={goBack} className="back-button-style">
                      {localizationService.toLanguageString("custom.back")}
                    </Button>
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.inclusionexclusioncriteria"
              )}
            >
              <Form
                initialValues={{
                  in_above_eighteen: AllData
                    ? AllData.in_above_eighteen
                    : false,
                  in_required_treatement: AllData
                    ? AllData.in_required_treatement
                    : false,
                  in_historical_cancer: AllData
                    ? AllData.in_historical_cancer
                    : false,
                  in_informed_signed: AllData
                    ? AllData.in_informed_signed
                    : false,
                  ex_another_malignancy: AllData
                    ? AllData.ex_another_malignancy
                    : false,
                  ex_pregnant: AllData ? AllData.ex_pregnant : false,
                  ex_another_trial: AllData ? AllData.ex_another_trial : false,
                }}
                ref={MyForm}
                onSubmitClick={CreateCriteria}
                key={JSON.stringify(AllData)}
                render={(formRenderProps) => (
                  <FormElement className="">
                    <fieldset className={"k-form-fieldset"}>
                      <Tooltip
                        openDelay={0}
                        anchorElement="target"
                        parentTitle={true}
                        position={"top"}
                      >
                        <Prompt
                          when={PromptFunction(formRenderProps)}
                          message={localizationService.toLanguageString(
                            "custom.promptmessage"
                          )}
                        />

                        <AppBar className="FormAppBar">
                          <AppBarSection>
                            <div>
                              <strong>
                                {localizationService.toLanguageString(
                                  "custom.inclusioncriteria"
                                )}
                              </strong>
                            </div>
                          </AppBarSection>
                        </AppBar>
                        <div className="row mt-2">
                          <div className="col-12">
                            <Field
                              id={"in_above_eighteen"}
                              name={"in_above_eighteen"}
                              label={localizationService.toLanguageString(
                                "custom.agecondition"
                              )}
                              component={FormCheckbox}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12">
                            <Field
                              id={"in_historical_cancer"}
                              name={"in_historical_cancer"}
                              label={localizationService.toLanguageString(
                                "custom.cancerhistorycondition"
                              )}
                              component={FormCheckbox}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12">
                            <Field
                              id={"in_required_treatement"}
                              name={"in_required_treatement"}
                              label={localizationService.toLanguageString(
                                "custom.treatmentcondition"
                              )}
                              component={FormCheckbox}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12">
                            <Field
                              id={"in_informed_signed"}
                              name={"in_informed_signed"}
                              label={localizationService.toLanguageString(
                                "custom.consentcondition"
                              )}
                              component={FormCheckbox}
                            />
                          </div>
                        </div>
                        <AppBar className="FormAppBar">
                          <AppBarSection>
                            <div>
                              <strong>
                                {localizationService.toLanguageString(
                                  "custom.exclusioncriteria"
                                )}
                              </strong>
                            </div>
                          </AppBarSection>
                        </AppBar>
                        <div className="row mt-2">
                          <div className="col-12">
                            <Field
                              id={"ex_another_malignancy"}
                              name={"ex_another_malignancy"}
                              label={localizationService.toLanguageString(
                                "custom.otherdiseasecondition"
                              )}
                              component={FormCheckbox}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12">
                            <Field
                              id={"ex_pregnant"}
                              name={"ex_pregnant"}
                              label={localizationService.toLanguageString(
                                "custom.pregnancycondition"
                              )}
                              component={FormCheckbox}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12">
                            <Field
                              id={"ex_another_trial"}
                              name={"ex_another_trial"}
                              label={localizationService.toLanguageString(
                                "custom.othertrialcondition"
                              )}
                              component={FormCheckbox}
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </fieldset>
                    <Tooltip
                      openDelay={0}
                      anchorElement="target"
                      parentTitle={true}
                      position={"left"}
                    >
                      <div className="float-right">
                        <div title={disabletitle()}>
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                            disabled={disableFunction()}
                          >
                            {loading
                              ? localizationService.toLanguageString(
                                  "custom.loading"
                                )
                              : localizationService.toLanguageString(
                                  "custom.save"
                                )}
                          </Button>
                        </div>
                      </div>
                    </Tooltip>
                  </FormElement>
                )}
              />
            </TabStripTab>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.approvesection"
              )}
            >
              <ApproveStudySubject
                study_subject={props.location.state.data.id}
                section={props.location.state.section.id}
                data={props.location.state.data}
                parentCallBack={ChangeApproveSection}
              />
            </TabStripTab>

            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.notesnattachements"
              )}
            >
              <TabStrip selected={Subselected} onSelect={handleSubSelect}>
                <TabStripTab
                  title={localizationService.toLanguageString("custom.notes")}
                >
                  <GridTelerik data={notes}>
                    <GridToolbar>
                      <Button
                        primary={true}
                        onClick={() => {
                          {
                            EditSubject
                              ? toggleDaddNote()
                              : toggleDPermission();
                          }
                        }}
                      >
                        {localizationService.toLanguageString("custom.new")}
                      </Button>
                    </GridToolbar>

                    <Column
                      field={"note"}
                      title={localizationService.toLanguageString(
                        "custom.note"
                      )}
                      cell={NoteNameTemplate}
                    />
                    <Column
                      field={"date_created"}
                      title={localizationService.toLanguageString(
                        "custom.datecreated"
                      )}
                      cell={DateCreatedTemplate}
                    />
                    <Column
                      title={localizationService.toLanguageString(
                        "custom.actions"
                      )}
                      width="100px"
                      cell={Noteactions}
                    />
                  </GridTelerik>
                </TabStripTab>

                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.attachements"
                  )}
                >
                  <GridTelerik data={attachements}>
                    <GridToolbar>
                      <Button
                        primary={true}
                        onClick={() => {
                          {
                            EditSubject
                              ? toggleDaddFile()
                              : toggleDPermission();
                          }
                        }}
                      >
                        {localizationService.toLanguageString("custom.new")}
                      </Button>
                    </GridToolbar>
                    <Column
                      field={"title"}
                      title={localizationService.toLanguageString(
                        "custom.title"
                      )}
                    />

                    <Column
                      field={"date_created"}
                      title={localizationService.toLanguageString(
                        "custom.dateadded"
                      )}
                      cell={DateCreatedTemplate}
                    />

                    <Column
                      title={localizationService.toLanguageString(
                        "custom.actions"
                      )}
                      width="100px"
                      cell={Fileactions}
                    />
                  </GridTelerik>
                </TabStripTab>
              </TabStrip>
            </TabStripTab>
          </TabStrip>
        </div>
      </div>
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>

      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
          height={"400px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}
      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}
      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}

      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
    </TrialMap>
  );
}

export default AddInclusionExlusion;
