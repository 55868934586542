import React, { useRef, useState, useEffect, memo, useMemo } from 'react';
import { DownloadTableExcel, useDownloadExcel } from 'react-export-table-to-excel';
import axios from "axios";
import APIURL from "../APIURL";
import { string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const AllExcel = (props) => {
  const tableRef = useRef(null);
  const [render, setRender] = useState(false)
  const [arrayLength, setArrayLength] = useState([])
  const [state, setState] = useState([])
  const [keys, setKeys] = useState([])

  const { onDownload } = useDownloadExcel({
    filename: "Study Subjects",
    sheet: "subjects",
    currentTableRef: tableRef.current
  })

  const handleDownloads =  () => {
     onDownload();
    props.showexel();
  }

  const numbers = Array.from({ length: 300 }, (_, i) => i + 1)
  useEffect(() => {
    fetchData3()
  }, [])

  const fetchData3 = () => {
    let array = []

    setRender(false)
    axios.get(APIURL + "/common/getallsubjects/", {
      params: {
        pk: props.SID,
      },
    }).then((res) => {

      const data = res.data.message.map((item, i) => {

        const aproved_section = []
        arrayLength[Object.keys(item)[0]] = 1
        array[Object.keys(item)[0]] = [
          {
            demography: [],
            InclusionExclusion: [],
            vitalsigns: [],
            informed: [],
            smokingHistory: [],
            smokingForm: [],
            medicalHistory: [],
            mRCCHistory: [],
            metastaticHistory: [],
            PrognosticFactors: [],
            CurrentTherapy: [],
            LaboratoryTests: [],
            LaboratoryOtherTest: [],
            laboratoryotherparamedicaltests_LaboratoryTests: [],
            BloodChemistryTest: [],
            ConcomitantMedications: [],
            Month_3: [],
            Other_Month_3: [],
            Adverse_Month_3: [],
            laboratoryotherparamedicaltests_Month_3: [],
            Month_6: [],
            Other_Month_6: [],
            Adverse_Month_6: [],
            laboratoryotherparamedicaltests_Month_6: [],
            Month_12: [],
            Other_Month_12: [],
            Adverse_Month_12: [],
            laboratoryotherparamedicaltests_Month_12: [],
            Month_36: [],
            Other_Month_36: [],
            Adverse_Month_36: [],
            laboratoryotherparamedicaltests_Month_36: [],
            followup: [],
            Other_followup: [],
            Adverse_followup: [],
            laboratoryotherparamedicaltests_followup: [],
            Notes: [],
            aproved: [{}],
            mRCCHistoryTests: [],
            treatmentresponse: [{}],
          }
        ]

        item[Object.keys(item)[0]].map((n, key) => {
          let Json = {}
          if (typeof (n) === 'string') {
            Json = JSON.parse(n)
          } else {
            Json = n
          }

          if (Json[0].model === "treatment.treatmentresponse") {
            Json.map((a, i) => {
              array[Object.keys(item)[0]][0].treatmentresponse[0][a.fields.section] = [a.fields.response, a.fields.patient_status, a.fields.evaluated_response]
            })
          }


          if (Json[0].model === "baseline.neoadjuvanttherapy") {
            Json.map((a, i) => {
              const temp = a.fields
              const num = temp.number
              temp.number = 'NT' + num
              array[Object.keys(item)[0]][0].mRCCHistoryTests.push(a.fields)
            })
          }


          if (Json[0].model === "baseline.adjuvanttherapy") {
            Json.map((a, i) => {
              const temp = a.fields
              const num = temp.number
              temp.number = 'AT' + num
              array[Object.keys(item)[0]][0].mRCCHistoryTests.push(a.fields)
            })
          }

          if (Json[0].model === "baseline.othertherapy") {
            Json.map((a, i) => {
              const temp = a.fields
              const num = temp.number
              temp.number = 'OT' + num
              array[Object.keys(item)[0]][0].mRCCHistoryTests.push(a.fields)
            })
          }

          if (Json[0].model === "common.approvestudysection") {
            Json.map((a, i) => {

              array[Object.keys(item)[0]][0].aproved[0][a.fields.section] = [a.fields.approved, a.fields.approved_date, a.fields.notapproved_reason]

            })
          }

          if (Json[0].model === "common.notes") {
            Json.map((a, i) => {
              let newobj = [a.fields.note, a.fields.date_created]
              if (!array[Object.keys(item)[0]][0].Notes[a.fields.section]) {
                array[Object.keys(item)[0]][0].Notes[a.fields.section] = []
                array[Object.keys(item)[0]][0].Notes[a.fields.section].push(newobj)
              } else {
                array[Object.keys(item)[0]][0].Notes[a.fields.section].push(newobj)
              }
            })
          }

          if (Json[0].model === "informed") {
            array[Object.keys(item)[0]][0].informed.push(Json[0].fields)
          }

          if (Json[0].model === "screening.demography") {
            //18
            let Json = JSON.parse(n)

            array[Object.keys(item)[0]][0].demography.push(Json[0].fields)

          }
          if (Json[0].model === "baseline.vitalsigns") {
            // 14
            let obj = JSON.parse(n)

            array[Object.keys(item)[0]][0].vitalsigns.push(Json[0].fields)

          }
          if (Json[0].model === "baseline.smokinghistory") {
            let obj = JSON.parse(n)
            array[Object.keys(item)[0]][0].smokingHistory.push(Json[0].fields)
          }

          if (Json[0].model === "baseline.smokingform") {
            let obj = JSON.parse(n)
            obj.map((ob, i) => {
              array[Object.keys(item)[0]][0].smokingForm.push(ob.fields)

            })
          }

          if (Json[0].model === "baseline.medicalhistory") {
            let obj = JSON.parse(n)

            obj.map((ob, i) => {

              array[Object.keys(item)[0]][0].medicalHistory.push(ob.fields)

            })

            const lenss = array[Object.keys(item)[0]][0].medicalHistory.length
            if (arrayLength[Object.keys(item)[0]] < lenss) {
              arrayLength[Object.keys(item)[0]] = lenss
            }

          }
          if (Json[0].model === "baseline.metastaticsite") {
            let obj = JSON.parse(n)
            obj.map((ob, i) => {
              array[Object.keys(item)[0]][0].metastaticHistory.push(ob.fields)
            })
            const lenss = array[Object.keys(item)[0]][0].metastaticHistory.length
            if (arrayLength[Object.keys(item)[0]] < lenss) {
              arrayLength[Object.keys(item)[0]] = lenss
            }

          }
          if (Json[0].model === "baseline.prognosticfactors") {
            let obj = JSON.parse(n)
            array[Object.keys(item)[0]][0].PrognosticFactors.push(Json[0].fields)

          }
          if (Json[0].model === "baseline.currenttherapy") {
            let obj = JSON.parse(n)
            obj.map((ob, i) => {
              array[Object.keys(item)[0]][0].CurrentTherapy.push(ob.fields)
            })
            const lenss = array[Object.keys(item)[0]][0].CurrentTherapy.length
            if (arrayLength[Object.keys(item)[0]] < lenss) {
              arrayLength[Object.keys(item)[0]] = lenss
            }


          }
          if (Json[0].model === "common.laboratorytest") {
            let obj = JSON.parse(n)
            obj.map((ob, i) => {
              if (ob.fields.section === 15) {
                array[Object.keys(item)[0]][0].Other_Month_3.push(ob.fields)
              }
              if (ob.fields.section === 16) {
                array[Object.keys(item)[0]][0].Other_Month_6.push(ob.fields)
              }

              if (ob.fields.section === 17) {
                array[Object.keys(item)[0]][0].Other_Month_12.push(ob.fields)
              }

              if (ob.fields.section === 18) {
                array[Object.keys(item)[0]][0].Other_Month_36.push(ob.fields)
              }
              if (ob.fields.section === 12) {
                array[Object.keys(item)[0]][0].LaboratoryOtherTest.push(ob.fields)
              }
              if (ob.fields.section === 19) {
                array[Object.keys(item)[0]][0].Other_followup.push(ob.fields)
              }

            })
          }

          if (Json[0].model === "common.laboratorytest") {
            let obj = JSON.parse(n)
            obj.map((ob, i) => {
              if (ob.fields.section === 15) {
                array[Object.keys(item)[0]][0].laboratoryotherparamedicaltests_Month_3.push(ob.fields)
              }
              if (ob.fields.section === 16) {
                array[Object.keys(item)[0]][0].laboratoryotherparamedicaltests_Month_6.push(ob.fields)
              }

              if (ob.fields.section === 17) {
                array[Object.keys(item)[0]][0].laboratoryotherparamedicaltests_Month_12.push(ob.fields)
              }

              if (ob.fields.section === 18) {
                array[Object.keys(item)[0]][0].laboratoryotherparamedicaltests_Month_36.push(ob.fields)
              }
              if (ob.fields.section === 12) {
                array[Object.keys(item)[0]][0].laboratoryotherparamedicaltests_LaboratoryTests.push(ob.fields)
              }
              if (ob.fields.section === 19) {
                array[Object.keys(item)[0]][0].laboratoryotherparamedicaltests_followup.push(ob.fields)
              }

            })
          }

          if (Json[0].model === "common.bloodchemistrytest") {
            let obj = JSON.parse(n)

            obj.map((ob, i) => {
              if (ob.fields.section === 15) {
                array[Object.keys(item)[0]][0].Month_3.push(ob.fields)
              }
              if (ob.fields.section === 16) {
                array[Object.keys(item)[0]][0].Month_6.push(ob.fields)
              }

              if (ob.fields.section === 17) {
                array[Object.keys(item)[0]][0].Month_12.push(ob.fields)
              }

              if (ob.fields.section === 18) {
                array[Object.keys(item)[0]][0].Month_36.push(ob.fields)
              }
              if (ob.fields.section === 12) {
                array[Object.keys(item)[0]][0].LaboratoryTests.push(ob.fields)
              }
              if (ob.fields.section === 19) {
                array[Object.keys(item)[0]][0].followup.push(ob.fields)
              }
            })
          }

          if (Json[0].model === "treatment.adverseevent") {
            let obj = JSON.parse(n)
            obj.map((ob, i) => {
              if (ob.fields.section === 15) {
                array[Object.keys(item)[0]][0].Adverse_Month_3.push(ob.fields)
              }
              if (ob.fields.section === 16) {
                array[Object.keys(item)[0]][0].Adverse_Month_6.push(ob.fields)
              }
              if (ob.fields.section === 17) {
                array[Object.keys(item)[0]][0].Adverse_Month_12.push(ob.fields)
              }
              if (ob.fields.section === 18) {
                array[Object.keys(item)[0]][0].Adverse_Month_36.push(ob.fields)
              }
              if (ob.fields.section === 19) {
                array[Object.keys(item)[0]][0].Adverse_followup.push(ob.fields)
              }
            })

          }
          if (Json[0].model === "common.hematologytest") {
            let obj = JSON.parse(n)
            obj.map((ob, i) => {
              if (ob.fields.section === 15) {
                array[Object.keys(item)[0]][0].Month_3.push(ob.fields)
              }
              if (ob.fields.section === 16) {
                array[Object.keys(item)[0]][0].Month_6.push(ob.fields)
              }

              if (ob.fields.section === 17) {
                array[Object.keys(item)[0]][0].Month_12.push(ob.fields)
              }

              if (ob.fields.section === 18) {
                array[Object.keys(item)[0]][0].Month_36.push(ob.fields)
              }
              if (ob.fields.section === 12) {
                array[Object.keys(item)[0]][0].LaboratoryTests.push(ob.fields)
              }
              if (ob.fields.section === 19) {
                array[Object.keys(item)[0]][0].followup.push(ob.fields)
              }
            })
          }
          if (Json[0].model === "baseline.concomitantmedication") {
            let obj = JSON.parse(n)

            obj.map((ob, i) => {
              array[Object.keys(item)[0]][0].ConcomitantMedications.push(ob.fields)
            })
            const lenss = array[Object.keys(item)[0]][0].ConcomitantMedications.length
            if (arrayLength[Object.keys(item)[0]] < lenss) {
              arrayLength[Object.keys(item)[0]] = lenss
            }
          }
          if (Json[0].model === "screening.inclusionexclusion") {
            let obj = JSON.parse(n)

            array[Object.keys(item)[0]][0].InclusionExclusion.push(obj[0].fields)
          }
          if (Json[0].model === "baseline.cancerhistory") {
            let obj = JSON.parse(n)

            array[Object.keys(item)[0]][0].mRCCHistory.push(obj[0].fields)

          }

        })
        const lenss_3 = array[Object.keys(item)[0]][0].Month_3.length
        const lenss_6 = array[Object.keys(item)[0]][0].Month_6.length
        const lenss_12 = array[Object.keys(item)[0]][0].Month_12.length
        const lenss_36 = array[Object.keys(item)[0]][0].Month_36.length
        const notes = array[Object.keys(item)[0]][0].Notes.length
        const smokingform = array[Object.keys(item)[0]][0].smokingForm.length


        if (arrayLength[Object.keys(item)[0]] < lenss_3) {
          arrayLength[Object.keys(item)[0]] = lenss_3
        }
        if (arrayLength[Object.keys(item)[0]] < lenss_6) {
          arrayLength[Object.keys(item)[0]] = lenss_6
        }
        if (arrayLength[Object.keys(item)[0]] < lenss_12) {
          arrayLength[Object.keys(item)[0]] = lenss_12
        }
        if (arrayLength[Object.keys(item)[0]] < lenss_36) {
          arrayLength[Object.keys(item)[0]] = lenss_36
        }
        if (arrayLength[Object.keys(item)[0]] < notes) {
          arrayLength[Object.keys(item)[0]] = notes
        }
        if (arrayLength[Object.keys(item)[0]] < smokingform) {
          arrayLength[Object.keys(item)[0]] = smokingform
        }


      })

      setState(array)
      setKeys(Object.keys)
      setRender(true)


    })
  }
  console.log("lllllllllllllllllllllllllllllllll")
  const pages = [{
    1: 'screening-Demography',
    2: 'screening-Inclusion/Exclusion Criteria',
    3: 'screening-Informed Consent',
    5: 'baseline-Vital Signs',
    6: 'baseline-Smoking History',
    7: 'baseline-Medical History',
    8: 'baseline-mRCC History',
    9: 'baseline-Metastatic Sites',
    10: 'baseline-Prognostic Factors',
    11: 'baseline-Current Therapy',
    12: 'baseline-Laboratory Tests',
    13: 'baseline-Concomitant Medications',
    15: 'treatment-Month-3',
    16: 'treatment-Month-6',
    17: 'treatment-Month-12',
    18: 'treatment-Month-36',
    19: 'Follow Up-followup-1',
  }]

  const test = useMemo(() => {

    const keys = Object.keys(state)
    return (

      // keys.map((item, key) => (

      <tbody>
        <tr style={{ backgroundColor: '#0096FF' }}>
          <th colSpan="1" style={{ backgroundColor: '#2196F3' }}> Subject</th>
          <th colSpan="10" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>demography</th>
          <th colSpan="12" scope="colgroup" style={{ backgroundColor: '#2196F3' }}>Inclusion/Exclusion Criteria</th>
          <th colSpan="7" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>Informed Consent</th>
          <th colSpan="19" scope="colgroup" style={{ backgroundColor: '#2196F3' }}>Vital Signs</th>
          <th colSpan="17" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>Smoking History</th>
          <th colSpan="9" scope="colgroup" style={{ backgroundColor: '#2196F3' }}>Medical History</th>
          <th colSpan="31" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>mRCC History</th>
          <th colSpan="11" scope="colgroup" style={{ backgroundColor: '#2196F3' }}>Metastatic Sites</th>
          <th colSpan="9" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>Prognostic Factors</th>
          <th colSpan="16" scope="colgroup" style={{ backgroundColor: '#2196F3' }}>Current Therapy</th>
          <th colSpan="30" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>Laboratory Tests</th>
          <th colSpan="16" scope="colgroup" style={{ backgroundColor: '#2196F3' }}>Concomitant Medications</th>
          <th colSpan="41" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>Month-3</th>
          <th colSpan="41" scope="colgroup" style={{ backgroundColor: '#2196F3' }}>Month-6</th>
          <th colSpan="41" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>Month-12</th>
          <th colSpan="41" scope="colgroup" style={{ backgroundColor: '#2196F3' }}>Month-36</th>
          <th colSpan="41" scope="colgroup" style={{ backgroundColor: '#DEE2E6' }}>Follow Up</th>

        </tr>
        <tr>
          <th > Subject</th>
          {/* demography */}
          <th scope="col">Birth Date</th>
          <th scope="col">Nationality</th>
          <th scope="col">Other Nationality</th>
          <th scope="col">Gender</th>
          <th scope="col">Country</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/* Inclusion/Exclusion Criteria */}
          <th scope="col">Patients ≥ 18 years old</th>
          <th scope="col">Histologically confirmed metastatic renal cell cancer</th>
          <th scope="col">Patients treated by immunotherapy and/or TKI</th>
          <th scope="col">Signed informed consent form obtained prior to study entry only for living patients</th>
          <th scope="col">History of another malignancy within the past 5 years except cured basal cell carcinoma of the skin or excised carcinoma in situ of the cervix</th>
          <th scope="col">Pregnancy</th>
          <th scope="col">Current participation in another clinical trial</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/* informed consent */}
          <th scope="col">Was informed consent obtained</th>
          <th scope="col">Informed Date</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>


          {/* Vital Signs */}
          <th scope="col">Date Of Assessement</th>
          <th scope="col">Height(CM)</th>
          <th scope="col">Height Missing Reason</th>
          <th scope="col">Weight(KG)</th>
          <th scope="col">Weight Missing Reason</th>
          <th scope="col">Pulse(Beats/min)</th>
          <th scope="col">Pulse Missing Reason</th>
          <th scope="col">Respiratory Rate</th>
          <th scope="col">Respiratory Missing Reason</th>
          <th scope="col">Body Temperature(°C)</th>
          <th scope="col">Temperature Missing Reason</th>
          <th scope="col">Blood Pressure(mmHg/mmHg)</th>
          <th scope="col">Blood Pressure Missing Reason</th>
          <th scope="col">section information missing Reason</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/* smoking History */}
          <th scope="col">subject’s smoking status</th>
          <th scope="col">Number of packs per month</th>
          <th scope="col">Date smoking stopped</th>
          <th scope="col">Presence of other smoking forms?</th>
          <th scope="col">Date Created</th>
          <th scope="col">Reason</th>

          {/* smoking form */}
          <th scope="col">Code</th>
          <th scope="col">Start Date</th>
          <th scope="col">Smoking Forms</th>
          <th scope="col">Frequency</th>
          <th scope="col">Occurence</th>
          <th scope="col">Number of Months Smoking</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/* Medical History */}
          <th scope="col">Code</th>
          <th scope="col">History/Condition/Surgery</th>
          <th scope="col">Diagnosis/Surgery Date</th>
          <th scope="col">Active problem at start of study drug</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*mRCC History */}
          <th scope="col">Stage of RCC at initial diagnosis</th>
          <th scope="col">Date of initial diagnosis of RCC</th>
          <th scope="col">Stage Pathology</th>
          <th scope="col">Stage Missing Reason</th>
          <th scope="col">Date of initial diagnosis of RCC</th>
          <th scope="col">Location</th>
          <th scope="col">Pathology</th>
          <th scope="col">mRCC Missing Reason</th>
          <th scope="col">Neoadjuvant Therapy</th>
          <th scope="col">Adjuvant Therapy</th>
          <th scope="col">Surgery</th>
          <th scope="col">laparoscopic surgery was used</th>
          <th scope="col">Surgery Date</th>
          <th scope="col">Surgery Site</th>
          <th scope="col">Surgery Site</th>
          <th scope="col">Other</th>
          <th scope="col">Code</th>
          <th scope="col">Treatment Line</th>
          <th scope="col">Drug</th>
          <th scope="col">Dose</th>
          <th scope="col">Unit</th>
          <th scope="col">Frequency</th>
          <th scope="col">Number of cycles</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Previous Treatment</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/* metastatic site */}
          <th scope="col">Code</th>
          <th scope="col">Presence</th>
          <th scope="col">Site</th>
          <th scope="col">Date Of Assessement</th>
          <th scope="col">Method</th>
          <th scope="col">Other Method</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/* Prognostic Factors */}
          <th scope="col">MSK Poor Prognostic Factors (Memorial Sloan-kettering)</th>
          <th scope="col">Patient Risk Group</th>
          <th scope="col">Date Created</th>
          <th scope="col">missing Reason</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*Current Therapy */}
          <th scope="col">Code</th>
          <th scope="col">Section</th>
          <th scope="col">Treatment Line</th>
          <th scope="col">Therapy</th>
          <th scope="col">Dose</th>
          <th scope="col">Unit</th>
          <th scope="col">Frequency</th>
          <th scope="col">Start Date</th>
          <th scope="col">Until Present</th>
          <th scope="col">End Date</th>
          <th scope="col">Reason</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*Laboratory Tests */}
          <th scope="col">SECTION</th>
          <th scope="col">Test Name</th>
          <th scope="col">Presence</th>
          <th scope="col">Result</th>
          <th scope="col">Unit</th>
          <th scope="col">Lower limit of normal</th>
          <th scope="col">Upper limit of normal*</th>
          <th scope="col">Missing Reason</th>
          {/*Laboratory Other Tests */}
          <th scope="col">Urinary Sample Date</th>
          <th scope="col">Urinary Protein</th>
          <th scope="col">Urinary Missing Reason</th>
          <th scope="col">Creatinine Sample Date</th>
          <th scope="col">Creatinine Clearance</th>
          <th scope="col">Creatinine Unit</th>
          <th scope="col">Glomerular filtration rate</th>
          <th scope="col">Glomerular Unit</th>
          <th scope="col">Creatinine  Missing Reason</th>
          <th scope="col">Date of ECG</th>
          <th scope="col">ECG</th>
          <th scope="col">Name</th>
          <th scope="col">IF Other Name</th>
          <th scope="col">Other Date</th>
          <th scope="col">Paramedical  Missing Reason</th>
          <th scope="col">Karnofski Performance Status</th>
          <th scope="col">Additional Information  Missing Reason</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*Concomitant Medications*/}
          <th scope="col">Code</th>
          <th scope="col">Medication(Trade Name if Possible)"</th>
          <th scope="col">Reason</th>
          <th scope="col">Single dose</th>
          <th scope="col">Unit</th>
          <th scope="col">Other Unit</th>
          <th scope="col">Frequency</th>
          <th scope="col">Other Frequency</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">YES if continuous at final exam</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*Month-3*/}
          <th scope="col">TREATMENT</th>
          <th scope="col">Test Name</th>
          <th scope="col">Missing Reason</th>
          <th scope="col">Presence</th>
          <th scope="col">Result</th>
          <th scope="col">Unit</th>
          <th scope="col">Lower limit of normal</th>
          <th scope="col">Upper limit of normal*</th>

          {/*Month-3 Other Tests */}
          <th scope="col">Urinary Sample Date</th>
          <th scope="col">Urinary Protein</th>
          <th scope="col">Urinary Missing Reason</th>
          <th scope="col">Creatinine Sample Date</th>
          <th scope="col">Creatinine Clearance</th>
          <th scope="col">Creatinine Unit</th>
          <th scope="col">Glomerular filtration rate</th>
          <th scope="col">Glomerular Unit</th>
          <th scope="col">Creatinine  Missing Reason</th>
          <th scope="col">Date of ECG</th>
          <th scope="col">ECG</th>
          <th scope="col">Name</th>
          <th scope="col">IF Other Name</th>
          <th scope="col">Other Date</th>
          <th scope="col">Paramedical  Missing Reason</th>
          <th scope="col">Karnofski Performance Status</th>
          <th scope="col">Additional Information  Missing Reason</th>
          {/* Adverse Month_3  */}
          <th scope="col">Code</th>
          <th scope="col">Adverse Event</th>
          <th scope="col">AE meets definition of serious</th>
          <th scope="col">Severity</th>
          <th scope="col">Relationship to study drug</th>
          <th scope="col">Action Taken</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Mark if continuous at final exam</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>

          <th scope="col">Response</th>
          <th scope="col">Patient status</th>

          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*Month-6*/}
          <th scope="col">TREATMENT</th>
          <th scope="col">Test Name</th>
          <th scope="col">Missing Reason</th>
          <th scope="col">Presence</th>
          <th scope="col">Result</th>
          <th scope="col">Unit</th>
          <th scope="col">Lower limit of normal</th>
          <th scope="col">Upper limit of normal*</th>
          {/*Month-6 Other Tests */}
          <th scope="col">Urinary Sample Date</th>
          <th scope="col">Urinary Protein</th>
          <th scope="col">Urinary Missing Reason</th>
          <th scope="col">Creatinine Sample Date</th>
          <th scope="col">Creatinine Clearance</th>
          <th scope="col">Creatinine Unit</th>
          <th scope="col">Glomerular filtration rate</th>
          <th scope="col">Glomerular Unit</th>
          <th scope="col">Creatinine  Missing Reason</th>
          <th scope="col">Date of ECG</th>
          <th scope="col">ECG</th>
          <th scope="col">Name</th>
          <th scope="col">IF Other Name</th>
          <th scope="col">Other Date</th>
          <th scope="col">Paramedical  Missing Reason</th>
          <th scope="col">Karnofski Performance Status</th>
          <th scope="col">Additional Information  Missing Reason</th>

          {/* Adverse Month_6  */}
          <th scope="col">Code</th>
          <th scope="col">Adverse Event</th>
          <th scope="col">AE meets definition of serious</th>
          <th scope="col">Severity</th>
          <th scope="col">Relationship to study drug</th>
          <th scope="col">Action Taken</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Mark if continuous at final exam</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>

          <th scope="col">Response</th>
          <th scope="col">Patient status</th>

          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*Month-12*/}
          <th scope="col">TREATMENT</th>
          <th scope="col">Test Name</th>
          <th scope="col">Missing Reason</th>
          <th scope="col">Presence</th>
          <th scope="col">Result</th>
          <th scope="col">Unit</th>
          <th scope="col">Lower limit of normal</th>
          <th scope="col">Upper limit of normal*</th>
          {/*Month-12 Other Tests */}
          <th scope="col">Urinary Sample Date</th>
          <th scope="col">Urinary Protein</th>
          <th scope="col">Urinary Missing Reason</th>
          <th scope="col">Creatinine Sample Date</th>
          <th scope="col">Creatinine Clearance</th>
          <th scope="col">Creatinine Unit</th>
          <th scope="col">Glomerular filtration rate</th>
          <th scope="col">Glomerular Unit</th>
          <th scope="col">Creatinine  Missing Reason</th>
          <th scope="col">Date of ECG</th>
          <th scope="col">ECG</th>
          <th scope="col">Name</th>
          <th scope="col">IF Other Name</th>
          <th scope="col">Other Date</th>
          <th scope="col">Paramedical  Missing Reason</th>
          <th scope="col">Karnofski Performance Status</th>
          <th scope="col">Additional Information  Missing Reason</th>
          {/* Adverse Month_12  */}
          <th scope="col">Code</th>
          <th scope="col">Adverse Event</th>
          <th scope="col">AE meets definition of serious</th>
          <th scope="col">Severity</th>
          <th scope="col">Relationship to study drug</th>
          <th scope="col">Action Taken</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Mark if continuous at final exam</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Response</th>
          <th scope="col">Patient status</th>


          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*Month-36*/}
          <th scope="col">TREATMENT</th>
          <th scope="col">Test Name</th>
          <th scope="col">Missing Reason</th>
          <th scope="col">Presence</th>
          <th scope="col">Result</th>
          <th scope="col">Unit</th>
          <th scope="col">Lower limit of normal</th>
          <th scope="col">Upper limit of normal*</th>

          {/*Month-36 Other Tests */}
          <th scope="col">Urinary Sample Date</th>
          <th scope="col">Urinary Protein</th>
          <th scope="col">Urinary Missing Reason</th>
          <th scope="col">Creatinine Sample Date</th>
          <th scope="col">Creatinine Clearance</th>
          <th scope="col">Creatinine Unit</th>
          <th scope="col">Glomerular filtration rate</th>
          <th scope="col">Glomerular Unit</th>
          <th scope="col">Creatinine  Missing Reason</th>
          <th scope="col">Date of ECG</th>
          <th scope="col">ECG</th>
          <th scope="col">Name</th>
          <th scope="col">IF Other Name</th>
          <th scope="col">Other Date</th>
          <th scope="col">Paramedical  Missing Reason</th>
          <th scope="col">Karnofski Performance Status</th>
          <th scope="col">Additional Information  Missing Reason</th>

          {/* Adverse Month_36  */}
          <th scope="col">Code</th>
          <th scope="col">Adverse Event</th>
          <th scope="col">AE meets definition of serious</th>
          <th scope="col">Severity</th>
          <th scope="col">Relationship to study drug</th>
          <th scope="col">Action Taken</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Mark if continuous at final exam</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>
          <th scope="col">Response</th>
          <th scope="col">Patient status</th>



          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

          {/*followup*/}
          <th scope="col">TREATMENT</th>
          <th scope="col">Test Name</th>
          <th scope="col">Missing Reason</th>
          <th scope="col">Presence</th>
          <th scope="col">Result</th>
          <th scope="col">Unit</th>
          <th scope="col">Lower limit of normal</th>
          <th scope="col">Upper limit of normal*</th>

          {/*followup Other Tests */}
          <th scope="col">Urinary Sample Date</th>
          <th scope="col">Urinary Protein</th>
          <th scope="col">Urinary Missing Reason</th>
          <th scope="col">Creatinine Sample Date</th>
          <th scope="col">Creatinine Clearance</th>
          <th scope="col">Creatinine Unit</th>
          <th scope="col">Glomerular filtration rate</th>
          <th scope="col">Glomerular Unit</th>
          <th scope="col">Creatinine  Missing Reason</th>
          <th scope="col">Date of ECG</th>
          <th scope="col">ECG</th>
          <th scope="col">Name</th>
          <th scope="col">IF Other Name</th>
          <th scope="col">Other Date</th>
          <th scope="col">Paramedical  Missing Reason</th>
          <th scope="col">Karnofski Performance Status</th>
          <th scope="col">Additional Information  Missing Reason</th>

          {/* Adverse followup  */}
          <th scope="col">Code</th>
          <th scope="col">Adverse Event</th>
          <th scope="col">AE meets definition of serious</th>
          <th scope="col">Severity</th>
          <th scope="col">Relationship to study drug</th>
          <th scope="col">Action Taken</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Mark if continuous at final exam</th>
          <th scope="col">Note</th>
          <th scope="col">Note Date</th>

          <th scope="col">Response</th>
          <th scope="col">Patient status</th>
          <th scope="col">Approved</th>
          <th scope="col">Not Approved Reason</th>
          <th scope="col">Approved Date</th>

        </tr>
        {keys.map((item, key) => (


          numbers.slice(0, arrayLength[item]).map((obj, i) => {


            return (
              <tr key={i}>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}> {item} </td> : ''}
                {/* demography */}
                <td>{state[item][0].demography?.length > 0 ? state[item][0].demography[i]?.birthdate : ' '}</td>
                <td>{state[item][0].demography?.length > 0 ? state[item][0].demography[i]?.nationality : ' '}</td>
                <td>{state[item][0].demography?.length > 0 ? state[item][0].demography[i]?.other_nationality : ' '}</td>
                <td>{state[item][0].demography?.length > 0 ? state[item][0].demography[i]?.gender : ' '}</td>
                <td>{state[item][0].demography?.length > 0 ? state[item][0].demography[i]?.country[0].name : ' '}</td>
                <td>{state[item][0].demography?.length > 0 ? state[item][0].Notes?.[1]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].demography?.length > 0 ? state[item][0].Notes?.[1]?.[i]?.[1] : ' '}</td>

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][1] ? state[item][0].aproved[0][1][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][1] ? state[item][0].aproved[0][1][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][1] ? state[item][0].aproved[0][1][1] : ' '}</td> : ''}


                {/* Inclusion/Exclusion Criteria */}
                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].InclusionExclusion[i]?.in_above_eighteen ? 'YES' : '' : ' '}</td>
                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].InclusionExclusion[i]?.in_historical_cancer ? 'YES' : '' : ' '}</td>
                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].InclusionExclusion[i]?.in_required_treatement ? 'YES' : '' : ' '}</td>
                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].InclusionExclusion[i]?.in_informed_signed ? 'YES' : '' : ' '}</td>
                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].InclusionExclusion[i]?.ex_another_malignancy ? 'YES' : '' : ' '}</td>
                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].InclusionExclusion[i]?.ex_pregnant ? 'YES' : '' : ' '}</td>
                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].InclusionExclusion[i]?.ex_another_trial ? 'YES' : '' : ' '}</td>

                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].Notes?.[2]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].InclusionExclusion?.length > 0 ? state[item][0].Notes?.[2]?.[i]?.[1] : ' '}</td>

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][2] ? state[item][0].aproved[0][2][0] ? 'YES' : 'NO' : 'NO '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][2] ? state[item][0].aproved[0][2][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][2] ? state[item][0].aproved[0][2][1] : ' '}</td> : ''}


                {/* Informed consent */}
                <td>{state[item][0].informed?.length > 0 ? state[item][0].informed[i] ? state[item][0].informed[i]?.informed ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].informed?.length > 0 ? state[item][0].informed[i]?.informed_date : ' '}</td>
                <td>{state[item][0].informed?.length > 0 ? state[item][0].Notes?.[3]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].informed?.length > 0 ? state[item][0].Notes?.[3]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][3] ? state[item][0].aproved[0][3][0] ? 'YES' : 'NO' : ' NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][3] ? state[item][0].aproved[0][3][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][3] ? state[item][0].aproved[0][3][1] : ' '}</td> : ''}



                {/* Vital Signs */}
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.assessment_date : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.height : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.height_missing_reason : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.weight : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.weight_missing_reason : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.pulse : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.pulse_missing_reason : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.respiratory_rate : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.respiratory_missing_reason : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.body_temperature : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.bodytemp_missing_reason : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.blood_pressure ? `'${state[item][0].vitalsigns[i]?.blood_pressure}` : '' : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.bloodpressure_missing_reason : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].vitalsigns[i]?.missing_field_reason : ''}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].Notes?.[5]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].vitalsigns?.length > 0 ? state[item][0].Notes?.[5]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][5] ? state[item][0].aproved[0][5][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][5] ? state[item][0].aproved[0][5][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][5] ? state[item][0].aproved[0][5][1] : ' '}</td> : ''}


                {/* smokingHistory */}
                <td>{state[item][0].smokingHistory?.length > 0 ? state[item][0].smokingHistory[i]?.smoking_status : ' '}</td>
                <td>{state[item][0].smokingHistory?.length > 0 ? state[item][0].smokingHistory[i]?.nb_pack : ' '}</td>
                <td>{state[item][0].smokingHistory?.length > 0 ? state[item][0].smokingHistory[i]?.stop_date : ' '}</td>
                <td>{state[item][0].smokingHistory?.length > 0 ? state[item][0].smokingHistory[i] ? state[item][0].smokingHistory[i]?.presenceof_other_form ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].smokingHistory?.length > 0 ? state[item][0].smokingHistory[i]?.date_created : ' '}</td>
                <td>{state[item][0].smokingHistory?.length > 0 ? state[item][0].smokingHistory[i]?.missing_information_reason : ' '}</td>

                {/* smoking form */}
                <td>{state[item][0].smokingForm?.length > 0 ? state[item][0].smokingHistory[i] ? 'SF-' + state[item][0].smokingHistory[i].number : '' : ' '}</td>
                <td>{state[item][0].smokingForm?.length > 0 ? state[item][0].smokingHistory[i]?.start_date : ' '}</td>
                <td>{state[item][0].smokingForm?.length > 0 ? state[item][0].smokingHistory[i]?.smoking_form : ' '}</td>
                <td>{state[item][0].smokingForm?.length > 0 ? state[item][0].smokingHistory[i]?.frequency : ' '}</td>
                <td>{state[item][0].smokingForm?.length > 0 ? state[item][0].smokingHistory[i]?.occurence : ' '}</td>
                <td>{state[item][0].smokingForm?.length > 0 ? state[item][0].smokingHistory[i]?.nb_months : ' '}</td>

                <td>{state[item][0].smokingForm?.length > 0 ? state[item][0]?.Notes?.[6]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].smokingForm?.length > 0 ? state[item][0]?.Notes?.[6]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][6] ? state[item][0].aproved[0][6][0] ? 'YES' : 'NO' : ' NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][6] ? state[item][0].aproved[0][6][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][6] ? state[item][0].aproved[0][6][1] : ' '}</td> : ''}



                {/* Medical History */}
                <td>{state[item][0].medicalHistory?.length > 0 ? state[item][0].medicalHistory[i]?.number ? 'MH-' + state[item][0].medicalHistory[i]?.number : '' : ' '}</td>
                <td>{state[item][0].medicalHistory?.length > 0 ? state[item][0].medicalHistory[i]?.history : ' '}</td>
                <td>{state[item][0].medicalHistory?.length > 0 ? state[item][0].medicalHistory[i]?.date_diagnosis : ' '}</td>
                <td>{state[item][0].medicalHistory?.length > 0 ? state[item][0].medicalHistory[i]?.active_problem ? "YES" : '' : ' '}</td>
                <td>{state[item][0].medicalHistory?.length > 0 ? state[item][0]?.Notes?.[7]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].medicalHistory?.length > 0 ? state[item][0]?.Notes?.[7]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][7] ? state[item][0].aproved[0][7][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][7] ? state[item][0].aproved[0][7][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][7] ? state[item][0].aproved[0][7][1] : ' '}</td> : ''}


                {/*mRCC History */}
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.diagnosis_stage : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.stage_date_diagnosis : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.stage_pathology : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.mrcc_stagehistory_reason : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.diagnosis_date : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.location : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.pathology : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.mrcc_history_reason : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i] ? state[item][0].mRCCHistory[i]?.neoadjuvant_therapy ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i] ? state[item][0].mRCCHistory[i]?.adjuvant_therapy ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i] ? state[item][0].mRCCHistory[i]?.surgery ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i] ? state[item][0].mRCCHistory[i]?.laparoscopic_surgery ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.surgery_date : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.surgery_site : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i]?.surgery_type : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0].mRCCHistory[i] ? state[item][0].mRCCHistory[i]?.other ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.number : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.treatment_line : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.drug : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.dose : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.unit : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.frequency : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.nb_cycle : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.start_date : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.end_date : ' '}</td>
                <td>{state[item][0].mRCCHistoryTests?.length > 0 ? state[item][0].mRCCHistoryTests[i]?.mrcc_treatment_reason : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0]?.Notes?.[8]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].mRCCHistory?.length > 0 ? state[item][0]?.Notes?.[8]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][8] ? state[item][0].aproved[0][8][0] ? 'YES' : 'NO' : ' NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][8] ? state[item][0].aproved[0][8][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][8] ? state[item][0].aproved[0][8][1] : ' '}</td> : ''}


                {/* metastaticHistory */}
                <td>{state[item][0].metastaticHistory?.length > 0 ? state[item][0].metastaticHistory[i]?.number ? 'MS-' + state[item][0].metastaticHistory[i]?.number : '' : ' '}</td>
                <td>{state[item][0].metastaticHistory?.length > 0 ? state[item][0].metastaticHistory[i] ? state[item][0].metastaticHistory[i]?.spread_site ? 'YES' : 'NO' : '' : ' '}</td>{/* boolean */}
                <td>{state[item][0].metastaticHistory?.length > 0 ? state[item][0].metastaticHistory[i]?.site : ' '}</td>
                <td>{state[item][0].metastaticHistory?.length > 0 ? state[item][0].metastaticHistory[i]?.date_assessment : ' '}</td>
                <td>{state[item][0].metastaticHistory?.length > 0 ? state[item][0].metastaticHistory[i]?.method : ' '}</td>
                <td>{state[item][0].metastaticHistory?.length > 0 ? state[item][0].metastaticHistory[i]?.other_method : ' '}</td>
                <td>{state[item][0].metastaticHistory?.length > 0 ? state[item][0]?.Notes?.[9]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].metastaticHistory?.length > 0 ? state[item][0]?.Notes?.[9]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][9] ? state[item][0].aproved[0][9][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][9] ? state[item][0].aproved[0][9][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][9] ? state[item][0].aproved[0][9][1] : ' '}</td> : ''}


                {/* Prognostic Factors */}
                <td>{state[item][0].PrognosticFactors?.length > 0 ? state[item][0].PrognosticFactors[i]?.prognostic_factors : ' '}</td>
                <td>{state[item][0].PrognosticFactors?.length > 0 ? state[item][0].PrognosticFactors[i]?.risk_group : ' '}</td>
                <td>{state[item][0].PrognosticFactors?.length > 0 ? state[item][0].PrognosticFactors[i]?.date_created : ' '}</td>
                <td>{state[item][0].PrognosticFactors?.length > 0 ? state[item][0].PrognosticFactors[i]?.prog_factors_missing_reason : ' '}</td>
                <td>{state[item][0].PrognosticFactors?.length > 0 ? state[item][0]?.Notes?.[10]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].PrognosticFactors?.length > 0 ? state[item][0]?.Notes?.[10]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][10] ? state[item][0].aproved[0][10][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][10] ? state[item][0].aproved[0][10][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][10] ? state[item][0].aproved[0][10][1] : ' '}</td> : ''}

                {/* Current Therapy */}
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i] ? 'CT-' + state[item][0].CurrentTherapy[i]?.number : '' : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? pages[0][state[item][0].CurrentTherapy[i]?.section] : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i]?.treatment_line : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i]?.therapy : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i]?.dose : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i]?.unit : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i]?.frequency : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i]?.start_date : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i] ? state[item][0].CurrentTherapy[i]?.until_present ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i]?.end_date : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0].CurrentTherapy[i]?.reasone : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0]?.Notes?.[11]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].CurrentTherapy?.length > 0 ? state[item][0]?.Notes?.[11]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][11] ? state[item][0].aproved[0][11][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][11] ? state[item][0].aproved[0][11][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][11] ? state[item][0].aproved[0][11][1] : ' '}</td> : ''}

                {/* Laboratory Tests */}
                <td>{state[item][0].LaboratoryTests.length > 0 ? state[item][0].LaboratoryTests[i] ? 'LaboratoryTests' : '' : ' '}</td>
                <td>{state[item][0].LaboratoryTests.length > 0 ? state[item][0].LaboratoryTests[i]?.test_name : ' '}</td>
                <td>{state[item][0].LaboratoryTests.length > 0 ? state[item][0].LaboratoryTests[i] ? state[item][0].LaboratoryTests[i]?.test_present ? 'YES' : 'NO' : '' : ' '}</td>
                <td>{state[item][0].LaboratoryTests.length > 0 ? state[item][0].LaboratoryTests[i]?.result : ' '}</td>
                <td>{state[item][0].LaboratoryTests.length > 0 ? state[item][0].LaboratoryTests[i]?.unit : ' '}</td>
                <td>{state[item][0].LaboratoryTests.length > 0 ? state[item][0].LaboratoryTests[i]?.lower_limit : ' '}</td>
                <td>{state[item][0].LaboratoryTests.length > 0 ? state[item][0].LaboratoryTests[i]?.upper_limit : ' '}</td>
                <td>{state[item][0].LaboratoryTests.length > 0 ? state[item][0].LaboratoryTests[i]?.notpresent_reason : ' '}</td>

                {/* Laboratory Other Tests */}
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.date_urinarytest : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.urinary_protein : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.urinary_missing_reason : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.date_creantininetest : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.creatinine_clearance_value : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.creatinine_clearance_unit : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.gfr_value : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.gfr_unit : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.creatinine_missing_reason : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.date_paramedicaltest : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.ecg_status : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_LaboratoryTests.length > 0 ? state[item][0].laboratoryotherparamedicaltests_LaboratoryTests[i]?.other_param_test : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_LaboratoryTests.length > 0 ? state[item][0].laboratoryotherparamedicaltests_LaboratoryTests[i]?.other_param_option : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_LaboratoryTests.length > 0 ? state[item][0].laboratoryotherparamedicaltests_LaboratoryTests[i]?.other_param_date : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.paramedical_missing_reason : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.karnofski_status : ''}</td>
                <td>{state[item][0].LaboratoryOtherTest.length > 0 ? state[item][0].LaboratoryOtherTest[i]?.karnofski_missing_reason : ''}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0]?.Notes?.[12]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0]?.Notes?.[12]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][12] ? state[item][0].aproved[0][12][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][12] ? state[item][0].aproved[0][12][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][12] ? state[item][0].aproved[0][12][1] : ' '}</td> : ''}

                {/* <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0].LaboratoryTests[i]?.date_urinarytest : ' '}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0].LaboratoryTests[i]?.urinary_protein : ' '}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0].LaboratoryTests[i]?.date_creantininetest : ' '}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0].LaboratoryTests[i]?.creatinine_clearance_value : ' '}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0].LaboratoryTests[i]?.gfr_value : ' '}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0].LaboratoryTests[i]?.date_paramedicaltest : ' '}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0].LaboratoryTests[i]?.ecg_status : ' '}</td>
                <td>{state[item][0].LaboratoryTests?.length > 0 ? state[item][0].LaboratoryTests[i]?.karnofski_status : ' '}</td> */}

                {/* Concomitant Medications */}
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i] ? 'CM-' + state[item][0].ConcomitantMedications[i]?.number : '' : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.medication : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.reason : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.single_dose : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.unit : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.other_unit : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.frequency : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.other_frequency : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.start_date : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.end_date : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0].ConcomitantMedications[i]?.continuous_atfinal ? 'YES' : '' : ' '}</td>   {/* boolean */}
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0]?.Notes?.[13]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].ConcomitantMedications?.length > 0 ? state[item][0]?.Notes?.[13]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][13] ? state[item][0].aproved[0][13][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][13] ? state[item][0].aproved[0][13][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][13] ? state[item][0].aproved[0][13][1] : ' '}</td> : ''}



                {/* Month 3 */}
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0].Month_3[i] ? 'Month-3' : '' : ' '}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0].Month_3[i]?.test_name : ' '}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0].Month_3[i]?.notpresent_reason : ' '}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0].Month_3[i]?.test_present ? 'YES' : 'NO' : ' '}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0].Month_3[i]?.result : ' '}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0].Month_3[i]?.unit : ' '}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0].Month_3[i]?.lower_limit : ' '}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0].Month_3[i]?.upper_limit : ' '}</td>

                {/*  Month 3  Other Tests */}
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.date_urinarytest : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.urinary_protein : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.urinary_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.date_creantininetest : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.creatinine_clearance_value : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.creatinine_clearance_unit : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.gfr_value : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.gfr_unit : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.creatinine_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.date_paramedicaltest : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.ecg_status : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_3.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_3[i]?.other_param_test : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_3.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_3[i]?.other_param_option : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_3.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_3[i]?.other_param_date : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.paramedical_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.karnofski_status : ''}</td>
                <td>{state[item][0].Other_Month_3.length > 0 ? state[item][0].Other_Month_3[i]?.karnofski_missing_reason : ''}</td>


                {/* Adverse month 3 */}
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i] ? 'Adverse Event' : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i] ? 'AE-' + state[item][0].Adverse_Month_3[i].number : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i]?.adverse_event : ''}</td>
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i]?.adverse_serious : ''}</td>
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i]?.adverse_severity : ''}</td>
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i]?.studydrug_relationship ? 'YES' : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i]?.start_date : ''}</td>
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i]?.end_date : ''}</td>
                <td>{state[item][0].Adverse_Month_3.length > 0 ? state[item][0].Adverse_Month_3[i]?.cont_atfinalexam ? 'YES' : '' : ''}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0]?.Notes?.[15]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].Month_3?.length > 0 ? state[item][0]?.Notes?.[15]?.[i]?.[1] : ' '}</td>

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][15] ? state[item][0].treatmentresponse[0][15][0] : ''}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][15] ? state[item][0].treatmentresponse[0][15][1] : ' '}</td> : ''}

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][15] ? state[item][0].aproved[0][15][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][15] ? state[item][0].aproved[0][15][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][15] ? state[item][0].aproved[0][15][1] : ' '}</td> : ''}


                {/* Month 6 */}
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0].Month_6[i] ? 'Month-6' : '' : ' '}</td>
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0].Month_6[i]?.test_name : ' '}</td>
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0].Month_6[i]?.notpresent_reason : ' '}</td>
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0].Month_6[i]?.test_present : ' '}</td>
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0].Month_6[i]?.result : ' '}</td>
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0].Month_6[i]?.unit : ' '}</td>
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0].Month_6[i]?.lower_limit : ' '}</td>
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0].Month_6[i]?.upper_limit : ' '}</td>

                {/*  Month 6 Other Tests */}
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.date_urinarytest : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.urinary_protein : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.urinary_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.date_creantininetest : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.creatinine_clearance_value : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.creatinine_clearance_unit : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.gfr_value : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.gfr_unit : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.creatinine_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.date_paramedicaltest : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.ecg_status : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_6.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_6[i]?.other_param_test : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_6.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_6[i]?.other_param_option : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_6.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_6[i]?.other_param_date : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.paramedical_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.karnofski_status : ''}</td>
                <td>{state[item][0].Other_Month_6.length > 0 ? state[item][0].Other_Month_6[i]?.karnofski_missing_reason : ''}</td>

                {/* Adverse month 6 */}
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i] ? 'Adverse Event' : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i] ? 'AE-' + state[item][0].Adverse_Month_6[i].number : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i]?.adverse_event : ''}</td>
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i]?.adverse_serious : ''}</td>
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i]?.adverse_severity : ''}</td>
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i]?.studydrug_relationship ? 'YES' : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i]?.start_date : ''}</td>
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i]?.end_date : ''}</td>
                <td>{state[item][0].Adverse_Month_6.length > 0 ? state[item][0].Adverse_Month_6[i]?.cont_atfinalexam ? 'YES' : '' : ''}</td>

                <td>{state[item][0].Month_6?.length > 0 ? state[item][0]?.Notes?.[16]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].Month_6?.length > 0 ? state[item][0]?.Notes?.[16]?.[i]?.[1] : ' '}</td>

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][16] ? state[item][0].treatmentresponse[0][16][0] : ''}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][16] ? state[item][0].treatmentresponse[0][16][1] : ' '}</td> : ''}

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][16] ? state[item][0].aproved[0][16][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][16] ? state[item][0].aproved[0][16][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][16] ? state[item][0].aproved[0][16][1] : ' '}</td> : ''}


                {/* Month 12 */}
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0].Month_12[i] ? 'Month-12' : '' : ' '}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0].Month_12[i]?.test_name : ' '}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0].Month_12[i]?.notpresent_reason : ' '}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0].Month_12[i]?.test_present : ' '}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0].Month_12[i]?.result : ' '}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0].Month_12[i]?.unit : ' '}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0].Month_12[i]?.lower_limit : ' '}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0].Month_12[i]?.upper_limit : ' '}</td>

                {/*  Month 12  Other Tests */}
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.date_urinarytest : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.urinary_protein : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.urinary_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.date_creantininetest : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.creatinine_clearance_value : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.creatinine_clearance_unit : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.gfr_value : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.gfr_unit : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.creatinine_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.date_paramedicaltest : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.ecg_status : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_12.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_12[i]?.other_param_test : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_12.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_12[i]?.other_param_option : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_12.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_12[i]?.other_param_date : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.paramedical_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.karnofski_status : ''}</td>
                <td>{state[item][0].Other_Month_12.length > 0 ? state[item][0].Other_Month_12[i]?.karnofski_missing_reason : ''}</td>

                {/* Adverse month 12 */}
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i] ? 'Adverse Event' : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i] ? 'AE-' + state[item][0].Adverse_Month_12[i].number : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i]?.adverse_event : ''}</td>
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i]?.adverse_serious : ''}</td>
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i]?.adverse_severity : ''}</td>
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i]?.studydrug_relationship ? 'YES' : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i]?.start_date : ''}</td>
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i]?.end_date : ''}</td>
                <td>{state[item][0].Adverse_Month_12.length > 0 ? state[item][0].Adverse_Month_12[i]?.cont_atfinalexam ? 'YES' : '' : ''}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0]?.Notes?.[17]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].Month_12?.length > 0 ? state[item][0]?.Notes?.[17]?.[i]?.[1] : ' '}</td>

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][17] ? state[item][0].treatmentresponse[0][17][0] : ''}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F3FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][17] ? state[item][0].treatmentresponse[0][17][1] : ' '}</td> : ''}

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][17] ? state[item][0].aproved[0][17][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][17] ? state[item][0].aproved[0][17][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][17] ? state[item][0].aproved[0][17][1] : ' '}</td> : ''}

                {/* Month 36 */}
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0].Month_36[i] ? 'Month-36' : '' : ' '}</td>
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0].Month_36[i]?.test_name : ' '}</td>
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0].Month_36[i]?.notpresent_reason : ' '}</td>
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0].Month_36[i]?.test_present : ' '}</td>
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0].Month_36[i]?.result : ' '}</td>
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0].Month_36[i]?.unit : ' '}</td>
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0].Month_36[i]?.lower_limit : ' '}</td>
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0].Month_36[i]?.upper_limit : ' '}</td>

                {/*  Month 36  Other Tests */}
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.date_urinarytest : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.urinary_protein : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.urinary_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.date_creantininetest : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.creatinine_clearance_value : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.creatinine_clearance_unit : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.gfr_value : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.gfr_unit : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.creatinine_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.date_paramedicaltest : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.ecg_status : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_36.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_36[i]?.other_param_test : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_36.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_36[i]?.other_param_option : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_Month_36.length > 0 ? state[item][0].laboratoryotherparamedicaltests_Month_36[i]?.other_param_date : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.paramedical_missing_reason : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.karnofski_status : ''}</td>
                <td>{state[item][0].Other_Month_36.length > 0 ? state[item][0].Other_Month_36[i]?.karnofski_missing_reason : ''}</td>


                {/* Adverse month 36 */}
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i] ? 'Adverse Event' : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i] ? 'AE-' + state[item][0].Adverse_Month_36[i].number : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i]?.adverse_event : ''}</td>
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i]?.adverse_serious : ''}</td>
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i]?.adverse_severity : ''}</td>
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i]?.studydrug_relationship ? 'YES' : '' : ''}</td>
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i]?.start_date : ''}</td>
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i]?.end_date : ''}</td>
                <td>{state[item][0].Adverse_Month_36.length > 0 ? state[item][0].Adverse_Month_36[i]?.cont_atfinalexam ? 'YES' : '' : ''}</td>

                <td>{state[item][0].Month_36?.length > 0 ? state[item][0]?.Notes?.[18]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].Month_36?.length > 0 ? state[item][0]?.Notes?.[18]?.[i]?.[1] : ' '}</td>
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][18] ? state[item][0].treatmentresponse[0][18][0] : ''}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F3FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][18] ? state[item][0].treatmentresponse[0][`18`][1] : ' '}</td> : ''}

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][18] ? state[item][0].aproved[0][18][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][18] ? state[item][0].aproved[0][18][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][18] ? state[item][0].aproved[0][18][1] : ' '}</td> : ''}


                {/* follow up */}
                <td>{state[item][0].followup?.length > 0 ? state[item][0].followup[i] ? 'Follow Up' : '' : ' '}</td>
                <td>{state[item][0].followup?.length > 0 ? state[item][0].followup[i]?.test_name : ' '}</td>
                <td>{state[item][0].followup?.length > 0 ? state[item][0].followup[i]?.notpresent_reason : ' '}</td>
                <td>{state[item][0].followup?.length > 0 ? state[item][0].followup[i]?.test_present : ' '}</td>
                <td>{state[item][0].followup?.length > 0 ? state[item][0].followup[i]?.result : ' '}</td>
                <td>{state[item][0].followup?.length > 0 ? state[item][0].followup[i]?.unit : ' '}</td>
                <td>{state[item][0].followup?.length > 0 ? state[item][0].followup[i]?.lower_limit : ' '}</td>
                <td>{state[item][0].followup?.length > 0 ? state[item][0].followup[i]?.upper_limit : ' '}</td>

                {/*  Month 3  Other Tests */}
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.date_urinarytest : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.urinary_protein : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.urinary_missing_reason : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.date_creantininetest : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.creatinine_clearance_value : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.creatinine_clearance_unit : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.gfr_value : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.gfr_unit : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.creatinine_missing_reason : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.date_paramedicaltest : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.ecg_status : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_followup.length > 0 ? state[item][0].laboratoryotherparamedicaltests_followup[i]?.other_param_test : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_followup.length > 0 ? state[item][0].laboratoryotherparamedicaltests_followup[i]?.other_param_option : ''}</td>
                <td>{state[item][0].laboratoryotherparamedicaltests_followup.length > 0 ? state[item][0].laboratoryotherparamedicaltests_followup[i]?.other_param_date : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.paramedical_missing_reason : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.karnofski_status : ''}</td>
                <td>{state[item][0].Other_followup.length > 0 ? state[item][0].Other_followup[i]?.karnofski_missing_reason : ''}</td>


                {/* Adverse month 3 */}
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i] ? 'Adverse Event' : '' : ''}</td>
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i] ? 'AE-' + state[item][0].Adverse_followup[i].number : '' : ''}</td>
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i]?.adverse_event : ''}</td>
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i]?.adverse_serious : ''}</td>
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i]?.adverse_severity : ''}</td>
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i]?.studydrug_relationship ? 'YES' : '' : ''}</td>
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i]?.start_date : ''}</td>
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i]?.end_date : ''}</td>
                <td>{state[item][0].Adverse_followup.length > 0 ? state[item][0].Adverse_followup[i]?.cont_atfinalexam ? 'YES' : '' : ''}</td>


                <td>{state[item][0].followup?.length > 0 ? state[item][0]?.Notes?.[19]?.[i]?.[0] : ' '}</td>
                <td>{state[item][0].followup?.length > 0 ? state[item][0]?.Notes?.[19]?.[i]?.[1] : ' '}</td>

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][19] ? state[item][0].treatmentresponse[0][19][0] : ''}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F3FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].treatmentresponse[0][19] ? state[item][0].treatmentresponse[0][19][1] : ' '}</td> : ''}

                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][19] ? state[item][0].aproved[0][19][0] ? 'YES' : 'NO' : 'NO'}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][19] ? state[item][0].aproved[0][19][2] : ' '}</td> : ''}
                {i === 0 ? <td rowspan={arrayLength[item]} style={{ backgroundColor: '#E7F5FF', textAlign: 'center', verticalAlign: 'middle' }}>{state[item][0].aproved[0][19] ? state[item][0].aproved[0][19][1] : ' '}</td> : ''}

              </tr>)
          })
        ))}
      </tbody>


    )
  }, [state]);

  // useEffect(() => {
  //   onDownload()
  // }, [])

  return (


    <div>
      <head>
        <meta http-equiv="Content-Type" charset="ANSI"></meta>
      </head>

      {/* {render ?
        <> */}
        <>
                {/* {onDownload()} */}
                {render ?

           handleDownloads(): ''}

            {/* // <button onClick={onDownload} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" style={{ backgroundColor: '#ff6358', width: '100px', color: '#fff' }}> Export excel </button> */}
            {/* : */}
            {/* <Button style={{ backgroundColor: '#FF6358', border: '0px solid #FF6358', width: '100px', height: '30px', borderRadius: '0px' }} disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="YES"
              />
              Loading...
            </Button>}  */}

          <table ref={tableRef} border='1' style={{ display: 'none' }} >
            {test}
          </table>


        </> 
      
    </div>
  );
};


export default AllExcel
