//utilities
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import APIURL from "../../APIURL";
import { Prompt } from "react-router-dom";
//kendo component
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { useLocalization } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { requiredValidator } from "../../common/Kendo/validators";
import {
  FormCheckbox,
  RequiredFormInput,
} from "../../common/Kendo/form-components";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";

//component
import GeneralSetting from "../../setting/Settings";
import { add_study } from "../../redux/action";

export function AddRole(props) {
  //localization
  const localizationService = useLocalization();

  //redux
  const UserDetail = useSelector((state) => state.UserDetail);

  //variables
  const [NameError, setNameError] = useState("");
  const [Promptsave, setPromptsave] = useState(false);

  //roles
  const [receive_email, setreceive_email] = useState(
    props.location.state ? props.location.state.receive_email : false
  );
  const [view_all_subject, setview_all_subject] = useState(
    props.location.state ? props.location.state.view_all_subject : false
  );
  const [view_subject, setview_subject] = useState(
    props.location.state ? props.location.state.view_subject : false
  );
  const [add_subject, setadd_subject] = useState(
    props.location.state ? props.location.state.add_subject : false
  );
  const [edit_subject, setedit_subject] = useState(
    props.location.state ? props.location.state.edit_subject : false
  );
  const [approve_subject, setapprove_subject] = useState(
    props.location.state ? props.location.state.approve_subject : false
  );

  const [view_study, setview_study] = useState(
    props.location.state ? props.location.state.view_study : false
  );
  const [edit_study, setedit_study] = useState(
    props.location.state ? props.location.state.edit_study : false
  );
  const [add_study, setadd_study] = useState(
    props.location.state ? props.location.state.add_study : false
  );

  const [view_user_setting, setview_user_setting] = useState(
    props.location.state ? props.location.state.view_user_setting : false
  );
  const [edit_user_setting, setedit_user_setting] = useState(
    props.location.state ? props.location.state.edit_user_setting : false
  );
  const [add_user_setting, setadd_user_setting] = useState(
    props.location.state ? props.location.state.add_user_setting : false
  );

  const [all, setall] = useState(false);
  const [all_subject, setall_subject] = useState(false);
  const [all_email, setall_email] = useState(false);
  const [all_study, setall_study] = useState(false);
  const [all_user_setting, setall_user_setting] = useState(false);

  useEffect(() => {
    if (props.location.state) {
      if (
        props.location.state.receive_email &&
        props.location.state.view_all_subject &&
        props.location.state.edit_subject &&
        props.location.state.view_subject &&
        props.location.state.add_subject &&
        props.location.state.approve_subject &&
        props.location.state.view_study &&
        props.location.state.add_study &&
        props.location.state.edit_study &&
        props.location.state.view_user_setting &&
        props.location.state.edit_user_setting &&
        props.location.state.add_user_setting
      )
        setall(true);
      if (props.location.state.receive_email) setall_email(true);
      if (
        props.location.state.edit_study &&
        props.location.state.add_study &&
        props.location.state.view_study
      )
        setall_study(true);
      if (
        props.location.state.edit_user_setting &&
        props.location.state.add_user_setting &&
        props.location.state.view_user_setting
      )
        setall_user_setting(true);
      if (
        props.location.state.view_all_subject &&
        props.location.state.view_subject &&
        props.location.state.add_subject &&
        props.location.state.edit_subject &&
        props.location.state.approve_subject
      )
        setall_subject(true);
    }
  }, []);
  //   START THE FUNCTION
  const CreateRole = (dataItem) => {
    if (dataItem.isValid) {
      setPromptsave(true);
      if (props.location.state) {
        axios
          .put(APIURL + "/auth/roleupdate/" + props.location.state.id + "/", {
            created_by: props.location.state.created_by,
            modified_by: UserDetail.id,
            role_name: dataItem.values.role_name,

            view_all_subject: view_all_subject,
            view_subject: view_subject,
            edit_subject: edit_subject,
            add_subject: add_subject,
            approve_subject: approve_subject,

            view_study: view_study,
            edit_study: edit_study,
            add_study: add_study,

            view_user_setting: view_user_setting,
            add_user_setting: add_user_setting,
            edit_user_setting: edit_user_setting,

            receive_email: receive_email,
          })
          .then((res) => {
            if (res.data.checkrolename === "name already exist") {
              setNameError(
                localizationService.toLanguageString("custom.rolenametaken")
              );
            } else if (res.data.message === "success") {
              props.history.push("/settings/authentication/role/list");
            }
          });
      } else {
        axios
          .post(APIURL + "/auth/addrole/", {
            created_by: UserDetail.id,

            role_name: dataItem.values.role_name,

            view_all_subject: view_all_subject,
            view_subject: view_subject,
            edit_subject: edit_subject,
            add_subject: add_subject,
            approve_subject: approve_subject,

            view_study: view_study,
            edit_study: edit_study,
            add_study: add_study,

            view_user_setting: view_user_setting,
            add_user_setting: add_user_setting,
            edit_user_setting: edit_user_setting,

            receive_email: receive_email,
          })
          .then((res) => {
            if (res.data.checkrolename === "name already exist") {
              setNameError(
                localizationService.toLanguageString("custom.rolenametaken")
              );
            } else if (res.data.message === "success") {
              props.history.push("/settings/authentication/role/list");
            }
          });
      }
    }
  };

  //update state of email
  const OnReceiveEmailChange = (formRenderProps) => {
    if (receive_email) {
      setreceive_email(false);
      setall(false);
      setall_email(false);
    } else {
      setreceive_email(true);
      setall_email(true);

      if (all_study && all_user_setting && all_subject) {
        setall(true);
      }
    }
  };

  //update state of subject
  const OnApproveSubjectChange = (formRenderProps) => {
    if (approve_subject) {
      setapprove_subject(false);
      setall(false);
      setall_subject(false);
    } else {
      setapprove_subject(true);
      if (add_subject && view_subject && view_all_subject && edit_subject) {
        setall_subject(true);
      }
      if (
        all_email &&
        all_study &&
        all_user_setting &&
        add_subject &&
        view_subject &&
        view_all_subject &&
        edit_subject
      ) {
        setall(true);
      }
    }
  };

  const OnViewAllSubjectChange = (formRenderProps) => {
    if (view_all_subject) {
      setview_all_subject(false);
      setall(false);
      setall_subject(false);
    } else {
      setview_all_subject(true);
      if (add_subject && view_subject && approve_subject && edit_subject) {
        setall_subject(true);
      }
      if (
        all_email &&
        all_study &&
        all_user_setting &&
        add_subject &&
        view_subject &&
        approve_subject &&
        edit_subject
      ) {
        setall(true);
      }
    }
  };

  const OnEditSubjectChange = (formRenderProps) => {
    if (edit_subject) {
      setedit_subject(false);
      setall(false);
      setall_subject(false);
    } else {
      setedit_subject(true);
      if (add_subject && view_subject && approve_subject && view_all_subject) {
        setall_subject(true);
      }
      if (
        all_email &&
        all_study &&
        all_user_setting &&
        add_subject &&
        view_subject &&
        approve_subject &&
        view_all_subject
      ) {
        setall(true);
      }
    }
  };
  const OnAddSubjectChange = (formRenderProps) => {
    if (add_subject) {
      setadd_subject(false);
      setall(false);
      setall_subject(false);
    } else {
      setadd_subject(true);
      if (edit_subject && view_subject && approve_subject && view_all_subject) {
        setall_subject(true);
      }
      if (
        all_email &&
        all_study &&
        all_user_setting &&
        edit_subject &&
        view_subject &&
        approve_subject &&
        view_all_subject
      ) {
        setall(true);
      }
    }
  };
  const OnViewSubjectChange = (formRenderProps) => {
    if (view_subject) {
      setview_subject(false);
      setall(false);
      setall_subject(false);
    } else {
      setview_subject(true);
      if (edit_subject && add_subject && approve_subject && view_all_subject) {
        setall_subject(true);
      }
      if (
        all_email &&
        all_study &&
        all_user_setting &&
        edit_subject &&
        add_subject &&
        approve_subject &&
        view_all_subject
      ) {
        setall(true);
      }
    }
  };

  //update state of user setting
  const OnAddUserSettingChange = (formRenderProps) => {
    if (add_user_setting) {
      setadd_user_setting(false);
      setall(false);
      setall_user_setting(false);
    } else {
      setadd_user_setting(true);
      if (view_user_setting && edit_user_setting) {
        setall_user_setting(true);
      }
      if (
        all_email &&
        all_subject &&
        all_study &&
        edit_user_setting &&
        edit_user_setting
      ) {
        setall(true);
      }
    }
  };
  const OnEditUserSettingChange = (formRenderProps) => {
    if (edit_user_setting) {
      setedit_user_setting(false);
      setall(false);
      setall_user_setting(false);
    } else {
      setedit_user_setting(true);
      if (view_user_setting && add_user_setting) {
        setall_user_setting(true);
      }
      if (
        all_email &&
        all_subject &&
        all_study &&
        view_user_setting &&
        add_user_setting
      ) {
        setall(true);
      }
    }
  };

  const OnViewUserSettingChange = (formRenderProps) => {
    if (view_user_setting) {
      setview_user_setting(false);
      setall(false);
      setall_user_setting(false);
    } else {
      setview_user_setting(true);
      if (edit_user_setting && add_user_setting) {
        setall_user_setting(true);
      }
      if (
        all_email &&
        all_subject &&
        all_study &&
        edit_user_setting &&
        add_user_setting
      ) {
        setall(true);
      }
    }
  };

  //Study
  const OnStudyViewChange = (formRenderProps) => {
    if (view_study) {
      setview_study(false);
      setall(false);
      setall_study(false);
    } else {
      setview_study(true);
      if (edit_study && add_study) {
        setall_study(true);
      }
      if (
        all_email &&
        all_subject &&
        all_user_setting &&
        edit_study &&
        add_study
      ) {
        setall(true);
      }
    }
  };
  const OnStudyEditChange = (formRenderProps) => {
    if (edit_study) {
      setedit_study(false);
      setall(false);
      setall_study(false);
    } else {
      setedit_study(true);
      if (add_study && view_study) {
        setall_study(true);
      }
      if (
        all_email &&
        all_subject &&
        all_user_setting &&
        add_study &&
        view_study
      ) {
        setall(true);
      }
    }
  };
  const OnStudyAddChange = (formRenderProps) => {
    if (add_study) {
      setadd_study(false);
      setall(false);
      setall_study(false);
    } else {
      setadd_study(true);
      if (edit_study && view_study) {
        setall_study(true);
      }
      if (
        all_email &&
        all_subject &&
        all_user_setting &&
        edit_study &&
        view_study
      ) {
        setall(true);
      }
    }
  };

  const OnAllChange = (formRenderProps) => {
    if (all) {
      setall(false);
      setall_subject(false);
      setall_study(false);
      setall_email(false);
      setall_user_setting(false);

      setview_subject(false);
      setview_all_subject(false);
      setadd_subject(false);
      setedit_subject(false);
      setapprove_subject(false);

      setview_study(false);
      setadd_study(false);
      setedit_study(false);

      setview_user_setting(false);
      setadd_user_setting(false);
      setedit_user_setting(false);

      setreceive_email(false);
    } else {
      setall(true);
      setall_subject(true);
      setall_study(true);
      setall_email(true);
      setall_user_setting(true);

      setview_subject(true);
      setview_all_subject(true);
      setadd_subject(true);
      setedit_subject(true);
      setapprove_subject(true);

      setview_study(true);
      setadd_study(true);
      setedit_study(true);

      setview_user_setting(true);
      setadd_user_setting(true);
      setedit_user_setting(true);

      setreceive_email(true);
    }
  };

  const OnAllReceiveEmailChange = (formRenderProps) => {
    if (all_email) {
      setall(false);
      setall_email(false);
      setreceive_email(false);
    } else {
      setall_email(true);
      setreceive_email(true);
      if (all_subject && all_study && all_user_setting) {
        setall(true);
      }
    }
  };

  const OnAllUserSettingChange = (formRenderProps) => {
    if (all_user_setting) {
      setall(false);
      setall_user_setting(false);
      setadd_user_setting(false);
      setview_user_setting(false);
      setedit_user_setting(false);
    } else {
      setall_user_setting(true);
      setadd_user_setting(true);
      setview_user_setting(true);
      setedit_user_setting(true);
      if (all_subject && all_study && all_email) {
        setall(true);
      }
    }
  };
  const OnAllSubjectChange = (formRenderProps) => {
    if (all_subject) {
      setall(false);
      setall_subject(false);
      setview_all_subject(false);
      setview_subject(false);
      setadd_subject(false);
      setedit_subject(false);
      setapprove_subject(false);
    } else {
      setall_subject(true);
      setview_all_subject(true);
      setview_subject(true);
      setadd_subject(true);
      setedit_subject(true);
      setapprove_subject(true);
      if (all_study && all_user_setting && all_email) {
        setall(true);
      }
    }
  };

  const OnAllStudyChange = (formRenderProps) => {
    if (all_study) {
      setall(false);
      setall_study(false);
      setview_study(false);
      setadd_study(false);
      setedit_study(false);
    } else {
      setall_study(true);
      setview_study(true);
      setadd_study(true);
      setedit_study(true);
      if (all_subject && all_user_setting && all_email) {
        setall(true);
      }
    }
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (!Promptsave && formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  return (
    <GeneralSetting>
      <Card>
        <CardHeader>
          <CardTitle>
            {props.location.state
              ? localizationService.toLanguageString("custom.editrole")
              : localizationService.toLanguageString("custom.newrole")}
          </CardTitle>
        </CardHeader>
        <Form
          initialValues={{
            role_name: props.location.state
              ? props.location.state.role_name
              : "",

            view_all_subject: view_all_subject,
            view_subject: view_subject,
            edit_subject: edit_subject,
            add_subject: add_subject,
            approve_subject: approve_subject,

            view_study: view_study,
            edit_study: edit_study,
            add_study: add_study,

            view_user_setting: view_user_setting,
            add_user_setting: add_user_setting,
            edit_user_setting: edit_user_setting,

            all: all,
            all_subject: all_subject,
            all_study: all_study,
            all_user_setting: all_user_setting,
          }}
          onSubmitClick={CreateRole}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset>
                <Prompt
                  when={PromptFunction(formRenderProps)}
                  message={localizationService.toLanguageString(
                    "custom.promptmessage"
                  )}
                />

                <CardBody>
                  <div className="row">
                    <div className="col">
                      <Field
                        name={"role_name"}
                        component={RequiredFormInput}
                        label={localizationService.toLanguageString(
                          "custom.rolename"
                        )}
                        validator={requiredValidator}
                        onChange={() => setNameError("")}
                      />
                      <span className={"k-form-error"}>{NameError}</span>
                    </div>
                    <div className="col">
                      <Checkbox
                        label={localizationService.toLanguageString(
                          "custom.chooseall"
                        )}
                        name={"all"}
                        value={all}
                        onChange={() => OnAllChange(formRenderProps)}
                      />
                    </div>
                  </div>

                  <hr className="RowRoleHr" />

                  <div className="row">
                    <div className="col">
                      <h5>
                        {localizationService.toLanguageString("custom.access")}
                      </h5>
                    </div>
                    <div className="col">
                      <h5>
                        {localizationService.toLanguageString("custom.view")}
                      </h5>
                    </div>
                    <div className="col">
                      <h5>
                        {localizationService.toLanguageString(
                          "custom.viewallrecord"
                        )}
                      </h5>
                    </div>
                    <div className="col">
                      <h5>
                        {" "}
                        {localizationService.toLanguageString("custom.create")}
                      </h5>
                    </div>
                    <div className="col">
                      <h5>
                        {" "}
                        {localizationService.toLanguageString("custom.edit")}
                      </h5>
                    </div>

                    <div className="col">
                      <h5>
                        {" "}
                        {localizationService.toLanguageString("custom.approve")}
                      </h5>
                    </div>
                    <div className="col">
                      <h5>
                        {" "}
                        {localizationService.toLanguageString("custom.receive")}
                      </h5>
                    </div>
                    <div className="col">
                      <h5>
                        {" "}
                        {localizationService.toLanguageString("custom.all")}
                      </h5>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <h5>
                        {localizationService.toLanguageString("custom.email")}
                      </h5>
                    </div>

                    <div className="col"></div>
                    <div className="col"></div>

                    <div className="col"></div>
                    <div className="col"></div>

                    <div className="col"></div>
                    <div className="col">
                      {" "}
                      <Checkbox
                        name={"receive_email"}
                        value={receive_email}
                        onChange={() => OnReceiveEmailChange(formRenderProps)}
                      />
                    </div>

                    <div className="col">
                      <Checkbox
                        name={"all_email"}
                        value={all_email}
                        onChange={() =>
                          OnAllReceiveEmailChange(formRenderProps)
                        }
                      />
                    </div>
                  </div>

                  <hr className="RowRoleHr" />

                  <div className="row">
                    <div className="col">
                      <h5>
                        {localizationService.toLanguageString("custom.study")}
                      </h5>
                    </div>

                    <div className="col">
                      <Checkbox
                        name={"view_study"}
                        value={view_study}
                        onChange={OnStudyViewChange}
                      />
                    </div>
                    <div className="col"></div>

                    <div className="col">
                      <Checkbox
                        name={"add_study"}
                        value={add_study}
                        onChange={OnStudyAddChange}
                      />
                    </div>
                    <div className="col">
                      <Checkbox
                        name={"edit_study"}
                        value={edit_study}
                        onChange={OnStudyEditChange}
                      />
                    </div>

                    <div className="col"></div>
                    <div className="col"></div>

                    <div className="col">
                      <Checkbox
                        name={"all_study"}
                        value={all_study}
                        onChange={() => OnAllStudyChange(formRenderProps)}
                      />
                    </div>
                  </div>

                  <hr className="RowRoleHr" />

                  <div className="row">
                    <div className="col">
                      <h5>
                        {localizationService.toLanguageString(
                          "custom.studysubject"
                        )}
                      </h5>
                    </div>
                    <div className="col">
                      <Checkbox
                        name={"view_subject"}
                        value={view_subject}
                        onChange={OnViewSubjectChange}
                      />
                    </div>
                    <div className="col">
                      <Checkbox
                        name={"view_all_subject"}
                        value={view_all_subject}
                        onChange={OnViewAllSubjectChange}
                      />
                    </div>
                    <div className="col">
                      <Checkbox
                        name={"add_subject"}
                        value={add_subject}
                        onChange={OnAddSubjectChange}
                      />
                    </div>
                    <div className="col">
                      <Checkbox
                        name={"edit_subject"}
                        value={edit_subject}
                        onChange={OnEditSubjectChange}
                      />
                    </div>
                    <div className="col">
                      <Checkbox
                        name={"approve_subject"}
                        value={approve_subject}
                        onChange={OnApproveSubjectChange}
                      />
                    </div>
                    <div className="col"></div>
                    <div className="col">
                      <Checkbox
                        name={"all_subject"}
                        value={all_subject}
                        onChange={() => OnAllSubjectChange(formRenderProps)}
                      />
                    </div>
                  </div>

                  <hr className="RowRoleHr" />

                  <div className="row">
                    <div className="col">
                      <h5>
                        {localizationService.toLanguageString(
                          "custom.usersandroles"
                        )}
                      </h5>
                    </div>
                    <div className="col">
                      <Checkbox
                        name={"view_user_setting"}
                        value={view_user_setting}
                        onChange={OnViewUserSettingChange}
                      />
                    </div>
                    <div className="col"></div>

                    <div className="col">
                      <Checkbox
                        name={"add_user_setting"}
                        value={add_user_setting}
                        onChange={OnAddUserSettingChange}
                      />
                    </div>
                    <div className="col">
                      {" "}
                      <Checkbox
                        name={"edit_user_setting"}
                        value={edit_user_setting}
                        onChange={OnEditUserSettingChange}
                      />
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>

                    <div className="col">
                      <Checkbox
                        name={"all_user_setting"}
                        value={all_user_setting}
                        onChange={() => OnAllUserSettingChange(formRenderProps)}
                      />
                    </div>
                  </div>

                  <br />

                  <div className="float-right mb-2">
                    <Button primary={true} className={"mr-2"}>
                      {localizationService.toLanguageString("custom.save")}
                    </Button>
                    <Button
                      onClick={() =>
                        props.history.push("/settings/authentication/role/list")
                      }
                    >
                      {localizationService.toLanguageString("custom.cancel")}
                    </Button>
                  </div>
                </CardBody>
              </fieldset>
            </FormElement>
          )}
        />
      </Card>
    </GeneralSetting>
  );
}

export default AddRole;
