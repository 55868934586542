//utilities
import React from "react";
import axios from "axios";

import APIURL from "../../APIURL";

//kendo component

import { Button } from "@progress/kendo-react-buttons";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

export function DeleteObject(props) {
  //localization
  const localizationService = useLocalization();

  //FUNCTION

  const DeleteObject = (e) => {
    e.preventDefault();

    axios.delete(APIURL + props.api).then((res) => {
      if (props.parentCallback) {
        props.parentCallback();
      }
      props.onClose();
    });
  };

  return (
    <div>
      <p style={{ margin: "25px", textAlign: "center" }}>
        {localizationService.toLanguageString("custom.deleteconfirmation")}
        {"  "} "
        {props.Name.length > 20 ? props.Name.slice(0, 20) + "..." : props.Name}
        "?
      </p>
      <div className="row">
        <div className="col">
          <Button
            className="k-button mr-2em"
            style={{ width: "230px" }}
            primary={true}
            onClick={DeleteObject}
          >
            {localizationService.toLanguageString("custom.yes")}
          </Button>
          <Button
            className="k-button"
            onClick={props.onClose}
            style={{ width: "230px" }}
          >
            {localizationService.toLanguageString("custom.no")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeleteObject;
