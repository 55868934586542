//utilities
import React, { useState } from "react";
import axios from "axios";
import APIURL from "../../APIURL";

//kendo component
import { BiUpload } from "react-icons/bi";
import { FormUpload } from "../Kendo/form-components";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import { requiredValidator } from "../Kendo/validators";

export function AddFile(props) {
  //localization
  const localizationService = useLocalization();

  //variables
  const [progress, setProgress] = useState(0);
  const [Error, setError] = useState("");

  const options = {
    onUploadProgress: (e) => {
      const { loaded, total } = e;
      let percent = Math.round((100 * loaded) / total);
      // console.log(`${loaded} mb of ${total},${percent}`);
      if (percent < 100) {
        setProgress(percent);
      }
    },
  };

  //CREATE FILE
  const createFile = (dataItem) => {
    let files = dataItem.file;
    const formData = new FormData();
    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        if (files[i].validationErrors) {
          formData.append("error", files[i].getRawFile().name);
        } else {
          formData.append("attachement", files[i].getRawFile());
          formData.append("title", files[i].getRawFile().name);
        }
      }

      formData.append("section", props.section);
      formData.append("study_subject", props.subject);
      // console.log(
      //   "formdata title",
      //   formData.getAll("title"),
      //   formData.getAll("error").length
      // );
      if (formData.getAll("error").length === 0) {
        axios
          .post(APIURL + "/common/addnewfile/", formData, options)
          .then((res) => {
            if (res.data.message === "namenotunique") {
              setError(
                res.data.files +
                  " " +
                  localizationService.toLanguageString(
                    "custom.filenamealreadyexist"
                  )
              );
            } else if (res.data.message === "required") {
              setError(
                res.data.files +
                  localizationService.toLanguageString("custom.fieldrequired")
              );
            } else if (res.data.message === "fail") {
              setError(
                localizationService.toLanguageString("custom.failupload")
              );
            } else if (res.data.message === "success") {
              setProgress(100);
              setTimeout(() => {
                setProgress(0);

                props.onClose();
              }, 2000);
            }
          });
      }
    }
  };

  const HintTemplate = () => {
    return (
      <span style={{ fontSize: "12px", color: "#ff6358" }}>
        {localizationService.toLanguageString("custom.accept")}
        {" : "}
        {"doc/docx/xls/xlsx/pdf/pptx"}
      </span>
    );
  };

  return (
    <>
      <Form
        onSubmit={createFile}
        render={(formRenderProps) => (
          <>
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                <div className="row">
                  <div className="col-12">
                    <Field
                      restrictions={{
                        allowedExtensions: [
                          ".doc",
                          ".xls",
                          ".xlsx",
                          ".docx",
                          ".pdf",
                          ".pptx",
                        ],

                        maxFileSize: 6000000, //telerik is taking byte as unit , here 6 MB is allowed
                      }}
                      id={"file"}
                      name={"file"}
                      hint={HintTemplate()}
                      selectMessageUI={() => (
                        <>
                          <BiUpload
                            className="UploadActionButtons"
                            width={"10px"}
                          />
                        </>
                      )}
                      component={FormUpload}
                      validator={requiredValidator}
                    />
                  </div>
                </div>
                {progress ? (
                  <ProgressBar
                    value={progress}
                    style={{ height: "20px", width: "300px", color: "green" }}
                  />
                ) : (
                  ""
                )}

                <div
                  className="row FileActionSticky"
                  style={{ backgroundColor: "#eeeeee" }}
                >
                  <div className="col">
                    {Error ? <span className="k-form-error">{Error}</span> : ""}
                  </div>
                  <div className="col">
                    <div className="float-right">
                      <Button
                        primary={true}
                        className="mr-2em mt-2"
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {localizationService.toLanguageString("custom.upload")}
                      </Button>

                      <Button onClick={props.onClose} className="mt-2">
                        {localizationService.toLanguageString("custom.cancel")}
                      </Button>
                    </div>
                  </div>
                </div>
              </fieldset>
            </FormElement>
          </>
        )}
      />
    </>
  );
}

export default AddFile;
