//utilities
import React, { useState, useEffect } from "react";
import axios from "axios";
import APIURL from "../../APIURL";
import useSWR from "swr";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//kendo
import { Fade } from "@progress/kendo-react-animation";
import { Loader } from "@progress/kendo-react-indicators";
import { AppBar, AppBarSection } from "@progress/kendo-react-layout";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import {
  RequiredFormInput,
  RequiredDropDownList,
  FormCheckbox,
} from "../../common/Kendo/form-components";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  passwordValidator,
  requiredMax50Validator,
  emailValidator,
  requiredValidator,
} from "../../common/Kendo/validators";

//React Icons
import { RiUser2Fill } from "react-icons/ri";
import { RiLockPasswordLine } from "react-icons/ri";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//redux
import { user_detail } from "../../redux/action";

//component

import GlobalNotificationTimer from "../../common/commoncomponents/GlobalNotificationTimer";

export function UserProfile(props) {
  //localization
  const localizationService = useLocalization();

  //redux
  const UserDetail = useSelector((state) => state.UserDetail);

  const dispatch = useDispatch();

  const { data, error } = useSWR(
    APIURL + `/auth/userinfodetail/${UserDetail.id}/`
  );

  //variables
  const [reset, setReset] = useState(null);
  const [AllData, setAllData] = useState([]);
  const [Promptsave, setPromptsave] = useState(false);
  const [oldpassworderror, setOldPasswordError] = useState("");
  const [newpassworderror, setNewPasswordError] = useState("");
  const [emailerror, setEmailError] = useState("");

  //Notification
  const [state, setState] = React.useState({
    success: false,
    warning: false,
    nomodify: false,
    info: false,
    none: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, warning, info, none, nomodify } = state;

  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  //USE EFFECT
  useEffect(() => {
    if (data) {
      setAllData({
        email: data.email ? data.email : "",
      });
    }
  }, [data]);

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const UpdatePersonalInfo = (dataItem) => {
    if (dataItem.isValid) {
      if (dataItem.isModified) {
        axios
          .put(APIURL + `/auth/updatepersonalinfo/${UserDetail.id}/`, {
            email: dataItem.values.email,
            first_name: UserDetail.first_name,
            last_name: UserDetail.last_name,
            username: UserDetail.username,
            change_password: dataItem.values.change_password
              ? dataItem.values.change_password
              : false,
            confirm_password: dataItem.values.confirm_password,
            old_password: dataItem.values.old_password,
            new_password: dataItem.values.new_password,
          })
          .then((res) => {
            if (res.data.checkemail === "emailexist") {
              setEmailError(
                localizationService.toLanguageString("custom.emailtaken")
              );
            } else if (res.data.oldpassword == "incorrectpassword") {
              setOldPasswordError(
                localizationService.toLanguageString("custom.incorrectpassword")
              );
            } else if (res.data.newpasswordmatch == "passwordsnotmatching") {
              setNewPasswordError(
                localizationService.toLanguageString(
                  "custom.passwordnotmatching"
                )
              );
            } else if (res.data.newpasswordstructure == "passwordnotallowed") {
              setNewPasswordError(
                localizationService.toLanguageString(
                  "custom.yourpasswordnotpersonalinformation"
                )
              );
            } else if (res.data.message === "success") {
              onToggle("success");
              setTimeout(() => {
                setState({ success: false });
              }, GlobalNotificationTimer);

              reset.onChange("old_password", { value: "" });
              reset.onChange("new_password", { value: "" });
              reset.onChange("confirm_password", { value: "" });
              reset.onChange("change_password", { value: false });
              dispatch(
                user_detail({
                  id: UserDetail.id,
                  username: UserDetail.username,
                  email: dataItem.values.email,
                  country: UserDetail.country,

                  center: UserDetail.center,

                  first_name: UserDetail.first_name,
                  last_name: UserDetail.last_name,
                  role: UserDetail.role,
                  is_assistant: UserDetail.is_assistant,
                  sub_investigator_fn: UserDetail.sub_investigator_fn,
                  sub_investigator_ln: UserDetail.sub_investigator_ln,
                  is_active: dataItem.values.is_active,
                  role_id: UserDetail.role_id,
                  study_id: UserDetail.study_id,
                  study_name: UserDetail.study_name,
                })
              );
            }
          });
      } else {
        onToggle("nomodify");
        setTimeout(() => {
          setState({ ...state, nomodify: false });
        }, GlobalNotificationTimer);
      }
    }
  };

  //Change Password
  const handleCheckBoxChange = (e, formRenderProps) => {
    formRenderProps.onChange("new_password", {
      value: "",
    });
    formRenderProps.onChange("old_password", {
      value: "",
    });
    formRenderProps.onChange("confirm_password", {
      value: "",
    });
  };

  return (
    <>
      <div className="grid-layout-container">
        <Form
          initialValues={AllData}
          key={JSON.stringify(AllData)}
          onSubmitClick={UpdatePersonalInfo}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset>
                <Prompt
                  when={PromptFunction(formRenderProps)}
                  message={localizationService.toLanguageString(
                    "custom.promptmessage"
                  )}
                />{" "}
                <GridLayout
                  gap={{
                    rows: 1,
                    cols: 0,
                  }}
                >
                  <GridLayoutItem row={1} col={1} colSpan={8}>
                    <Card>
                      <CardHeader>
                        <CardTitle className="CardTitleStyle">
                          <RiUser2Fill />{" "}
                          {localizationService.toLanguageString(
                            "custom.updateuserinfo"
                          )}
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <GridLayout
                          gap={{
                            rows: 5,
                            cols: 2,
                          }}
                        >
                          <GridLayoutItem row={1} col={1}>
                            <Field
                              name={"email"}
                              component={RequiredFormInput}
                              label={localizationService.toLanguageString(
                                "custom.email"
                              )}
                              validator={emailValidator}
                              onChange={() => setEmailError("")}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem row={2} col={1}>
                            <Field
                              name={"change_password"}
                              component={FormCheckbox}
                              label={localizationService.toLanguageString(
                                "custom.changepassword"
                              )}
                              onChange={(e) =>
                                handleCheckBoxChange(e, formRenderProps)
                              }
                            />
                          </GridLayoutItem>
                          {formRenderProps.valueGetter("change_password") ===
                          true ? (
                            <>
                              <GridLayoutItem row={3} col={1}>
                                <Field
                                  name={"old_password"}
                                  type="password"
                                  component={RequiredFormInput}
                                  label={localizationService.toLanguageString(
                                    "custom.currentpassword"
                                  )}
                                  validator={requiredValidator}
                                  onChange={() => setOldPasswordError("")}
                                />
                                <span className={"k-form-error"}>
                                  {" "}
                                  {oldpassworderror}
                                </span>
                              </GridLayoutItem>
                              <GridLayoutItem row={4} col={1}>
                                <Field
                                  name={"new_password"}
                                  type="password"
                                  component={RequiredFormInput}
                                  label={localizationService.toLanguageString(
                                    "custom.newpassword"
                                  )}
                                  validator={passwordValidator}
                                  onChange={() => setNewPasswordError("")}
                                />
                                <span className={"k-form-error"}>
                                  {" "}
                                  {newpassworderror}
                                </span>
                              </GridLayoutItem>
                              <GridLayoutItem row={4} col={2}>
                                <Field
                                  type="password"
                                  name={"confirm_password"}
                                  component={RequiredFormInput}
                                  label={localizationService.toLanguageString(
                                    "custom.verifypassword"
                                  )}
                                  validator={passwordValidator}
                                  onChange={() => setNewPasswordError("")}
                                />
                              </GridLayoutItem>
                              <GridLayoutItem
                                row={5}
                                col={1}
                                className={"password-recommendation-style"}
                              >
                                {localizationService.toLanguageString(
                                  "custom.password"
                                )}
                                :
                                <li>
                                  {localizationService.toLanguageString(
                                    "custom.passwordnotpersonalinformation"
                                  )}
                                </li>
                                <li>
                                  {localizationService.toLanguageString(
                                    "custom.passwordcharacternb"
                                  )}
                                </li>
                                <li>
                                  {localizationService.toLanguageString(
                                    "custom.passwordnotnumeric"
                                  )}
                                </li>
                              </GridLayoutItem>
                            </>
                          ) : (
                            ""
                          )}
                        </GridLayout>
                      </CardBody>
                    </Card>
                  </GridLayoutItem>
                  <GridLayoutItem row={1} col={9}>
                    <Card
                      style={{
                        height: `calc(100vh - 34px)`,
                        maxHeight: `calc(100vh - 20px)`,
                        maxWidth: "300px",
                      }}
                    >
                      <CardHeader>
                        <CardTitle>
                          <div style={{ textAlign: "center" }}>
                            <Button
                              primary={true}
                              className="mr-2"
                              onClick={() => setReset(formRenderProps)}
                            >
                              {localizationService.toLanguageString(
                                "custom.save"
                              )}
                            </Button>
                          </div>
                        </CardTitle>
                      </CardHeader>
                    </Card>
                  </GridLayoutItem>
                </GridLayout>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  );
}

export default UserProfile;
