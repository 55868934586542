//utilities
import React from "react";

//localization
import { useLocalization } from "@progress/kendo-react-intl";

//component
import TrialMap from "../../studysubject/TrialMap";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

export function ViewNoPermission(props) {
  //localization
  const localizationService = useLocalization();

  return (
    <TrialMap>
      <div className="row" style={{ textAlign: "center", marginTop: "10px" }}>
        <div className="col-12">
          <BsFillExclamationTriangleFill color={"red"} size={"100px"} />
        </div>
        <div className="col-12">
          <span style={{ color: "red", fontSize: "50px" }}>
            {localizationService.toLanguageString("custom.noaccess")}
          </span>
        </div>
      </div>
    </TrialMap>
  );
}

export default ViewNoPermission;
